@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);



/*
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
*/

body {
  margin: 0;
  font-family: 'Roboto',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  overflow-y: scroll;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}





















html{
   
     /*Core color codes------global color codes #224C98 */
     --themeColor: #0c4dc4;/*#0956e3;/*#207ef5;/*#207ef5;*/
    /* #224C98;/*#207ef5;/* #224C98;/*#263c63;/* #1b3d7a;#4285F4*/
     --deepDarkThemeTextColor: #1b1e24;/*#29303d;/*#132545;/*#0e1c36;*/
     --greyTextColor: #5e5d5d;
     --cardBorderColor: #D3D3D3;
     --cardBorderColorLight : #E8E8E8;
     --greenColor1: #65c394;
     --redColor1: #ff726f;
     --chatCommentBkgColor:#E3EFFE ;

     
     --lightThemeColor1: #edf3fc;
     --lightThemeColor2: #f2f4f7;
     --lightThemeColor3: #dae0eb;
     --lightThemeColor4: #e9edf5;
     --tintGreyColor1: #5e5d5d;
     --tintGreyColor2: #cbd0d6;
     --tintGreyColor3: #dee0e3;
     --tintGreyColor4: #f0f2f5;
     --lightGreyTintTextColor: var(--tintGreyColor1);
     

     /*Header Colors*/
     --headerBkgColor: white;
     --headerIconColor: #8496b5;/*#9fbaed;*/
     --headerRightIconsColor: var(--deepDarkThemeTextColor);/* #9fbaed;/*#99add1;/* #9fbaed;*/
     --headerIconColorOnhover: white;
     --headerExpConBtnBkgColor: white;/*#1e458a;*/

     /*Side Nav Bar Colors*/
     --matchingTextColor1: #444655;/*use this color for all text in place of black*/
     --sideNavBarBtnhoverColor: lightgrey;/* var(--lightThemeColor3);*/

     --sideNavBarButtonTextColor: var(--deepDarkThemeTextColor);
     --sideNavBarIconColor: var(--deepDarkThemeTextColor);

     --sideNavBarBkgColor: white;/*var(--lightThemeColor3);/* #505050;*/

     --sideToolBarBkgColor: white;/*var(--lightThemeColor4);*/
     --sideToolBarBkgColor2: var(--sideNavBarBkgColor);

     /* Dashboard side nav Bar colors */
     --sideNavBarDashBoardBkgColor: var(--sideToolBarBkgColor);/* #f0f3fa;*/
     --sideNavBarDashBoardBtnColor: var(--deepDarkThemeTextColor);/*var(--darkThemeTextColor);*/
     --sideNavBarDashBoardBtnBkgColor: #f0f3fa;/*#dae0eb;*/
     --sideNavBarDashBoardOnClickBtnColor: white;
     --sideNavBarDashBoardOnClickBtnBkgColor: var(--themeColor);



     --userProfileInfoBoxBkgColor: white;   
     --mainAppContainerBkgColor: #E8E8E8;
     --ticketTextColor: #364a61;
     --TextColorDarkBlue1: #364a61;
     --clickBtnColorDash:read;/* #ff5349;*/
     --clickBtnColorDashBackground: #ffd4d1;

     /*body background color*/
     --bodyBkgColor: #f2f2f2;

     /*height, width, size settings*/
     --headerHeight:3rem;/* max( 2.5rem, calc( 0.065 * 100vh ) );*/
     --headerFontSize: calc( 0.35 * var(--headerHeight) );
     --headerLogoWidth: calc( 0.80 * var(--headerHeight));
     --headerLogoWidth: calc( 0.80 * var(--headerHeight)); 
     --headerTopMargin: 10px;
     --headerLeftMargin: 0.5%;
     --headerRightMargin: 0.5%;
     --headerTopMargin: calc( 0.17 * var(--headerHeight) ); 



     --sideNavBarWidth: calc( 4.0 * var(--headerHeight)) ;
     --sideNavBarWidthOnContract: 0px;
     --sideNavBarWidthMobile: 3.4rem;
     --sideNavBarFontSize: calc( 0.30 * var(--headerHeight) );
     --sideNavBarIconSize: calc( 0.35 * var(--headerHeight) );


     --sideToolBarHeight: calc(0.77 * var(--headerHeight));
     --topInfoBarInstructorWidth:calc( 7.0 * var(--headerHeight)); 
     --sideBarHidePoint: 850px;

      /*Top tool bar common style parameters inside the course */

      --topRightButtonWidth_Course: 6vw;
      --topRightButtonBkgColorInActive_Course: white;
      --topRightButtonTextColorInActive_Course: var(--deepDarkThemeTextColor);
      --topRightButtonBkgColorActive_Course:lightgrey;
      --topRightButtonTextColorActive_Course:var(--themeColor);
      --topRightButtonIconColorInActive:grey;
      --topRightButtonIconColorActive:var(--themeColor);
      --topRightButtonUnderlineColorActive: var(--bodyBkgColor);
      --topRightButtonUnderlineColorInActive: var(--bodyBkgColor);
      --topRightButtonTextFontSize_Course: calc(  1vw );/*calc( 0.25 * var(--headerHeight) );*/
      --topRightButtonIconFontSize_Course: calc( 1vw );/*calc( 0.30 * var(--headerHeight) );*/
      --topInnerBarWidth:80%;
      --topBarLeftTitleFontSize: calc( 0.4 * var(--headerHeight) );

      /*Top bar common style parameters in the dashboard/home*/
      --toolButtonDashBkgColorInActive: white;/*var(--lightThemeColor2);*/
      --toolButtonDashIconColorInActive: var(--deepDarkThemeTextColor);
      --toolButtonDashTextColorInActive: var(--deepDarkThemeTextColor);
      --toolButtonDashBorderColorInActive:lightgrey; 
      --toolButtonDashBoxShadowInActive:rgba(0, 0, 0, 0) 0px 0px 0px;

      --toolButtonDashBkgColorActive: white;
      --toolButtonDashIconColorActive: var(--deepDarkThemeTextColor);
      --toolButtonDashTextColorActive: var(--deepDarkThemeTextColor);
      --toolButtonDashBorderColorActive:grey; 
      --toolButtonDashBoxShadowActive:rgba(0, 0, 0, 0.16) 0px 1px 4px;
      --topRightButtonWidth_home: var(--topRightButtonWidth_Course);
      --topRightButtonTextFontSize_home: var(--topRightButtonTextFontSize_Course);
      --topRightButtonIconFontSize_home: var(--topRightButtonIconFontSize_Course);
      --toolButtonDashUnderlineColorInActive: var(--bodyBkgColor);/*var(--lightThemeColor2);*/
      --toolButtonDashUnderlineColorActive: var(--greyTextColor);

      --standardBoxShadow1: rgba(0, 0, 0, 0) 0px 0px 0px;
      --standardBoxShadow2: rgba(0, 0, 0, 0.16) 0px 1px 4px;

      /*Card specifications*/
      --cardBorderRadius:calc( 0.2 * var(--headerHeight) );


}




/*
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i');

*/

/*
@import url("https://fonts.googleapis.com/css2?family=Amatic+SC&family=Quicksand&display=swap");
*/

::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.5);
  border-radius: 20px;
  border: transparent;
}




.edrapp{

     width:100%;
     background-color: #f2f4f7;
     background-color: var(--lightThemeColor2);
     
     -ms-overflow-style: none; 
     scrollbar-width: none;
}

.edrapp::-webkit-scrollbar {
    display: none;
}



@media screen and (max-width: 1000px) {
html{
   --topRightButtonTextFontSize_Course:calc( 0.25 * var(--headerHeight) );
   --topRightButtonIconFontSize_Course: calc( 0.25 * var(--headerHeight) );
   --topRightButtonWidth_Course: 15vw;
 }

}



@media screen  and (max-width: 1400px)  {
html{
--headerHeight: 2.6rem; 

}


}





input::file-selector-button {
                 background-color: #0c4dc4;
                 background-color: var(--themeColor);
                 background-position-x: 0%;
                 background-size: 100%;
                 border: 0;
                 border-radius: 8px;
                 color: #fff;
                 padding: 10px;

}








.Header_styleDivHeader__O22e7{
height: 0px;/*calc( 0.5 * var(--headerTopMargin) );*/
width:96%;
background-color: var(--headerRightIconsColor);
margin-left: 2%;
margin-top: calc( 0.5 * 0.17 * var(--headerHeight) );
position: fixed;
border-radius:  var(--headerTopMargin)  var(--headerTopMargin) 0px 0px;
}





.Header_header__1VCKf{

     background-color: var(--headerBkgColor);
     height: var(--headerHeight);
     text-align: center;
     display: flex;
     justify-content: space-between;
     margin: auto;
     line-height:var(--headerHeight);
     font-size: var(--headerFontSize);
     box-shadow:none;
     position: fixed;
     width: 100%;
     z-index:2;
     /*border-radius: var(--headerTopMargin);
     margin-left: 0.5%;
     margin-right: 0.5%;
     top:  var(--headerTopMargin);*/
     border-style: none;
     border-color: lightgrey;
     border-width: 1px;
}








.HeaderLeft_headerLeft__20zk0{

    text-align: center;
    padding-left: 20px;
    display:flex;
    justify-content: flex-start;
    align-items: center; 
    overflow: hidden;
    border-bottom-style: solid;
    border-width: 1px;
    border-color: var(--cardBorderColor);
    }


.HeaderLeft_logo__2KFAj{/*
    width: var(--headerLogoWidth);
    height: var(--headerLogoWidth);
    border-radius: 50%;
    position: relative;
    top: 50%;
    -webkit-transform: perspective(1px) translateY(-50%);
    -ms-transform: perspective(1px) translateY(-50%);
    transform: perspective(1px) translateY(-50%);
    */}


.HeaderLeft_logoText__12JCx{
color: var(--themeColor);
font-size: calc( 0.40 * var(--headerHeight) );
margin: auto;
border-style: none;
display: flex;
justify-content: space-between;
align-items: center;
color: white;
height: calc( 0.40 * var(--headerHeight) );
border-radius: calc( 0.1 * var(--headerHeight) );
padding: calc( 0.16 * var(--headerHeight) );
position: relative;
cursor: pointer;

}   


.HeaderLeft_styleBar__38ffT{
width: 5px;
height: 3px;
background-color: var(--themeColor);
position: absolute;
top:18px;
}



.HeaderLeft_logoText__12JCx span{
margin-left: 10px;
}


@media screen and (max-width: 1000px) {
.HeaderLeft_logoText__12JCx span{
display: none;
}

}







@media only screen and (max-width: 700px) {

.HeaderLeft_logo__2KFAj{

display:none;
}

}









.HeaderLeft_courseNameButton__1tloe{
    margin-left: 20px;
    text-decoration: none;
    color: white;
    font-weight: lighter !important;
    font-style: normal;
    background-color: var(--headerBkgColor);
    /*border-style: solid;*/
    overflow: hidden;
    border-style: none;
    line-height: var(--headerHeight);
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 20px;
    }

.HeaderLeft_courseNameButton__1tloe i{
font-size: calc( 0.30 * var(--headerHeight) );
font-style: normal;
font-weight: lighter !important;
font-family: Roboto;
color: var(--deepDarkThemeTextColor);
}




.HeaderLeft_superScript__2b4Ov{
    position: relative;	
    vertical-align: top; 
    font-size: calc( 0.23 * var(--headerHeight) );
    top: -0.8em;
    font-family: Roboto; 
    }



.HeaderLeft_ExpConButton__2AUww{
height: calc( 0.7 * var(--headerHeight));
border-style: none;
background-color: var(--headerExpConBtnBkgColor);/* #2f4975;/*#294473;/*var(--headerExpConBtnBkgColor);*/ 
cursor: pointer;
display: flex;
justify-content: center;
align-items: center;
margin-left: 20px;
float: left;
border-radius: calc( 0.1 * var(--headerHeight) );
}


.HeaderLeft_ExpConButton__2AUww:hover{
background-color: lightgrey;
}


.HeaderLeft_ExpConIcon__amM7G{

font-size: calc( 0.35 * var(--headerHeight));
color: var(--deepDarkThemeTextColor);

}



.HeaderLeft_clickDash__h515Q{
color: var(--deepDarkThemeTextColor);

}

.HeaderLeft_clickDash__h515Q:hover{
cursor:pointer;
}




.HeaderLeft_backButton__3jRS_{
background-color: var(--headerBkgColor);
border-style: none;
color: var(--deepDarkThemeTextColor);
cursor: pointer;
padding-right: 20px;
}


.HeaderLeft_BackIcon__3nsU9{
font-size:calc( 0.5 * var(--headerHeight) );
}

.HeaderLeft_topperLogo__3PUgc{
width: 100px;
height:40px;

}


.HeaderLeft_homeuserinfo__3shpx{
display: flex;
height: 100%;
min-width:120px;
}




.CourseDropDown_DropDown__3i8Bo{

border-radius: 3px;
position: absolute;
top: var(--headerHeight);
left: 150px;
border-style: none;
box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
align-items: center;
vertical-align: middle;
z-index: 5;
width:250px;
background-color: white;
transition: height 1s;
padding-bottom: 50px;
/*max-height: 50px;
overflow-y: auto;*/
margin:auto;
padding:20px;

/*overflow-x: hidden;
overflow-y:auto;*/
}


.CourseDropDown_DropDown__3i8Bo::before {
  content: "";
  position: absolute;
  width: 0; 
  height: 0; 
  top: -7px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid white ; 
  z-index: 9999;
  left: 50px;
}








.CourseDropDown_DropDown__3i8Bo i{
display: block;
background-color: #f1f1f1;
font-weight: lighter;
font-style: normal;
text-align: left;
font-size: 1.2rem;
border-style: none;
height: 3rem;
vertical-align: middle;
line-height: 3rem;
width: 100%;
}



.CourseDropDown_DropDown__3i8Bo i:hover{
display: block;
border-style: none;
background-color: #ddd;
font-style: normal;
font-weight: lighter;
}

.CourseDropDown_courseSelectText__1O_TE{
width: 100%;
height: 50px;
font-size: calc( 0.3 * var(--headerHeight) );
background: white;
color: var(--themeColor);
}


.CourseDropDown_courseBox__11mja{
width: 90%;
height: 50px;
background: #b8d1ff;
border-radius: 4px;
border-style: none;
border-width: 1px;
border-color: #b8d1ff;
color: var(--themeColor);
display: flex;
flex-direction: column;
align-items: center;
margin: auto;
justify-content: center;
margin-bottom: 10px;
}


.CourseDropDown_courseNameBox__2v845{
width: 70%;
text-overflow: ellipsis;
white-space: nowrap;
overflow:hidden;
}




.CourseDropDown_courseBox__11mja:hover{
color: #ff5349;	
background: #ffd4d1;
border-style: none;
cursor: pointer;
}




.CourseDropDown_topGapBox__3Jxfk{
height: 50px;
width: 100%;
background-color: white;
color: grey;
font-weight: bold;
margin-bottom: 10px;

}



.HeaderRight_headerRight__3DGSn{

overflow: hidden;
text-align: center;
padding-right: 60px;
display: flex;
align-items: center;
border-style: none;
}



.HeaderRight_tmpBtn__3zkdf{
height: calc( 0.7 * var(--headerHeight));
width: calc( 0.7 * var(--headerHeight));
border-radius: 50%;
margin-right: 20px;
background-color: var(--themeColor);
border-style: solid;
border-color: white;
border-width: initial;
}

.HeaderRight_tmpIcon__pKKD-{
font-size: calc( 0.30 * var(--headerHeight) );
color: white;
margin-left: auto;
margin-right: auto;
display: block !important;
}



.ActionItemsHead_actionItemsParentDiv__30Qjh{ 
 background-color: var(--headerBkgColor);
 border-style: none;
 z-index: 9999;
}


.ActionItemsHead_test__2tvY1{
position: absolute;
background-color: red;
top: 10px;
height:200px;
z-index: 99999;
width: 100px;
}



.ActionItemsHead_UsersIcon__3L1Ze{
    font-size: calc(  0.40 * var(--headerHeight) );
    color: var(--headerRightIconsColor);
    vertical-align:middle;
    border-style: none;
    flex-shrink: 0;
    }


.ActionItemsHead_UsersIcon__3L1Ze:hover{
   color: var(--themeColor)

}    




.ActionItemsHead_ActionItemsButton__3OKFT{
    position: relative;
    height: calc( 0.7 * var(--headerHeight) );
    width: calc( 0.7 * var(--headerHeight) );
    border-style: none ;
    border-color: var(--headerRightIconsColor);
    border-width:calc( 0.06 * var(--headerHeight) );
    border-radius: 50%;
    background-color: var(--headerBkgColor);
    vertical-align: middle;
    cursor:pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    }


@media only screen and (max-width: 700px) {

.ActionItemsHead_ActionItemsButton__3OKFT{

border-style:none;
}

}






.ActionItemsHead_ActionItemsButton__3OKFT:hover{

background-color:var(--headerRightIconsColor);

}



.ActionItemsHead_alertMessage__2cW8j{
position: absolute;
top: calc( -0.15 * var(--headerHeight) );
left:calc( 0.3 * var(--headerHeight) );
min-width: calc( 0.244 * var(--headerHeight) );
height: calc( 0.244 * var(--headerHeight) );
font-size: calc( 0.244 * var(--headerHeight) );
background-color: #E34234;
border-radius: 50%;
border-color: var(--headerRightIconsColor);
color: white;
padding: 3px;
border-style: none;
display: flex;
align-items: center;
justify-content: center;
font-style: normal;
padding: 4px;
}











.CourseEnrollmentRequest_courseEnrollmentRequest__3aKUr{

background-color: white;
position: absolute;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
padding: 20px;
z-index: 99999000;
width: 200px;
top: var(--headerHeight);
border-radius: 4px;
height: 300px;
/*overflow-y: auto;
overflow-x: hidden;*/
line-height: normal;
}

.CourseEnrollmentRequest_courseEnrollmentRequest__3aKUr::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: -7px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid white ;
  z-index: 9999999;
  left: 7px;
}


.CourseEnrollmentRequest_allRequests__17jFD{
border-style: none;
height: 100%;
overflow-y: auto;
overflow-x: hidden;
}






.CourseEnrollmentRequest_ApproveRejectBtnDiv__Ek-tB{
display: flex;
flex-direction: column;
border-style: none;
justify-content: space-between;
height: 70px;
}



.CourseEnrollmentRequest_approveButton__1Hbz5{
border-style: none;
background-color: #65c394;
border-radius: 4px;
color: white;
padding:5px;
cursor: pointer;
}

.CourseEnrollmentRequest_approveButton__1Hbz5:hover{
background-color: #45966e;
}



.CourseEnrollmentRequest_rejectButton__3N9Kx{
border-style: none;
background-color: #ff726f;
border-radius: 4px;
color: white;
padding:5px;
cursor: pointer;
}



.CourseEnrollmentRequest_rejectButton__3N9Kx:hover{
background-color: #ad4442;
}

.CourseEnrollmentRequest_oneRequest__3IFKb{
border-style: solid;
border-width: 1px;
border-color: lightgrey;
margin-bottom: 20px;
padding: 20px;
line-height: normal;
}


.CourseEnrollmentRequest_textBox1__1566w{
font-size: 1rem;
color: grey;
}


.CourseEnrollmentRequest_textBox2__15SAP{
font-size: 1rem;
color: grey;
}


.CourseEnrollmentRequest_requesterName__2VWLD{
color: var(--themeColor);
}



.NotificationsHead_notificationItemsParentDiv__2Rmzv{
 background-color: var(--headerBkgColor);
 border-style: none;
 z-index: 9999;
 margin-left: 20px;
}






.NotificationsHead_UsersIcon__2Si8K{

    font-size: calc( 0.40 * var(--headerHeight) );
    color: var(--headerRightIconsColor);
    vertical-align:middle;
    flex-shrink: 0;
    }


.NotificationsHead_UsersIcon__2Si8K:hover{
   color: var(--themeColor)

}



.NotificationsHead_ActionItemsButton__3cXsB{
    position: relative;
    height: calc( 0.70 * var(--headerHeight) );
    width: calc( 0.70 * var(--headerHeight) );
    border-style: none;
    border-width:calc( 0.06 * var(--headerHeight) );
    border-color: var(--headerRightIconsColor);
    border-radius: 50%;
    background-color: var(--themeColor);
    vertical-align: middle;
    cursor:pointer;
    display: flex;
    justify-content: center;
    align-items: center; 
    }



@media only screen and (max-width: 700px) {

.NotificationsHead_ActionItemsButton__3cXsB{

border-style:none;
}

}





.NotificationsHead_ActionItemsButton__3cXsB:hover{

background-color:white;

}

.NotificationsHead_alertMessage__Grz51{
position: absolute;
top: calc( -0.15 * var(--headerHeight) );
left:calc( 0.3 * var(--headerHeight) );
min-width: calc( 0.244 * var(--headerHeight) );
height: calc( 0.244 * var(--headerHeight) );
font-size: calc( 0.244 * var(--headerHeight) );
background-color: #E34234;
border-radius: 50%;
border-color: var(--headerRightIconsColor);
color: white;
padding: 3px;
border-style: none;
display: flex;
align-items: center;
justify-content: center;
font-style: normal;
padding: 4px;
}




.NotificationContainer_notificationContainer__3PcPy{
background-color: white;
position: absolute;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
padding: 20px;
z-index: 99999000;
width: 200px;
top: var(--headerHeight);
border-radius: 4px;
height: 150px;
/*overflow-y: auto;
overflow-x: hidden;*/
line-height: normal;
}



.NotificationContainer_notificationContainer__3PcPy::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: -7px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid white ;
  z-index: 9999999;
  left: 30px;
}







.NotificationContainer_oneNotification__He2Fl{



}

.MessagesHead_messagesItemsParentDiv__2p96F{
 background-color: var(--headerBkgColor);
 border-style: none;
 z-index: 9999;
 margin-left: 20px;
}



.MessagesHead_UsersIcon__LHnmt{

    font-size: calc( 0.40 * var(--headerHeight) );
    color: red;
    color: var(--headerRightIconsColor);
    vertical-align:middle;
    flex-shrink: 0;
    }


.MessagesHead_UsersIcon__LHnmt:hover{
   color: var(--themeColor)

}



.MessagesHead_ActionItemsButton__2rGcB{
    position: relative;
    height: calc( 0.7 * var(--headerHeight) );
    width: calc( 0.7 * var(--headerHeight) );
    border-style: none;
    border-width:calc( 0.06 * var(--headerHeight) );
    border-color: var(--headerRightIconsColor);
    border-radius: 50%;
    background-color: var(--themeColor);
    vertical-align: middle;
    cursor:pointer;
    display:flex;
    align-items: center;
    justify-content: center;
    }



@media only screen and (max-width: 700px) {

.MessagesHead_ActionItemsButton__2rGcB{

border-style:none;
}

}









.MessagesHead_ActionItemsButton__2rGcB:hover{

background-color:white;

}



.MessagesHead_alertMessage__15OZj{
position: absolute;
top: calc( -0.15 * var(--headerHeight) );
left:calc( 0.3 * var(--headerHeight) );
min-width: calc( 0.244 * var(--headerHeight) );
height: calc( 0.244 * var(--headerHeight) );
font-size: calc( 0.244 * var(--headerHeight) );
background-color: #E34234;
border-radius: 50%;
border-color: var(--headerRightIconsColor);
color: white;
padding: 3px;
border-style: none;
display: flex;
align-items: center;
justify-content: center;
font-style: normal;
padding: 4px;
}






.MessagesContainer_notificationContainer__2EXAW{
background-color: white;
position: absolute;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
padding: 20px;
z-index: 99999000;
width: 200px;
top: var(--headerHeight);
border-radius: 4px;
height: 150px;
/*overflow-y: auto;
overflow-x: hidden;*/
line-height: normal;
}



.MessagesContainer_notificationContainer__2EXAW::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: -7px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid white ;
  z-index: 9999999;
  left: 30px;
}







.MessagesContainer_oneNotification__2XbNk{



}

.UserHead_UserHeadDiv__3keSx{

 background-color:var(--headerBkgColor);
 display: flex;
 justify-content: space-between;
 align-items: center;
}



.UserHead_UsersIconHead__1r0zC{

    font-size: calc( 0.40 * var(--headerHeight) );
    color:white; 
    vertical-align:middle;
    }


.UserHead_UsersIconHead__1r0zC:hover{

}    



.UserHead_UserHeadButton__3ZRmg{

    /*height: calc( 0.2 * var(--headerHeight) );
    width: calc( 0.2 * var(--headerHeight) );*/
    position: relative;	
    border-style: none ;
    border-color: var(--headerRightIconsColor);
    border-width:medium;
    background-color: var(--headerBkgColor); 
    vertical-align: middle;
    cursor:pointer;
    margin-left: 40px;
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: space-between;
    font-family: Roboto;
    color: var(--deepDarkThemeTextColor);
    }

.UserHead_UserHeadButton__3ZRmg:hover{


}


.UserHead_userImage__DomD8{

    width: calc( 0.7 *  var(--headerLogoWidth) );
    height: calc( 0.7 * var(--headerLogoWidth) );
    border-radius: 50%;
    position: relative;
    /*following lines are to align the image vertically in the middle*/
    /*top: 50%;
    -webkit-transform: perspective(1px) translateY(-50%);
    -ms-transform: perspective(1px) translateY(-50%);
    transform: perspective(1px) translateY(-50%);
    */



}


.UserHead_UserFirstName__1kaB4{

font-style: normal;
font-weight: 400;
color: var(--deepDarkThemeTextColor);
font-size:calc( 0.7 * var(--headerFontSize) );
overflow: hidden;
}



.UserHead_userType__3V-ST{
position: absolute;
width: calc( 0.16 * var(--headerHeight) ) ;
height: calc( 0.16 * var(--headerHeight) );
top: 0px;
right:  calc( -0.13 * var(--headerHeight) );
font-size: calc( 0.23 * var(--headerHeight) );
background-color: var(--headerBkgColor);
border-radius: 50%;
color: var(--deepDarkThemeTextColor);
padding: calc( 0.05 * var(--headerHeight) );
border-style: solid;
border-width: calc( 0.04 * var(--headerHeight) );
display: flex;
align-items: center;
justify-content: center;
}


.UserHead_userType__3V-ST i{
font-style: normal;
}





@media screen and (max-width: 1000px) {

.UserHead_UserFirstName__1kaB4{
display: none;
}
}









.UserHeadDropDown_DropDown__2Z6rE{

border-radius: 3px;
position: absolute;
top: var(--headerHeight);
right: 100px;
border-style: none;
box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
align-items: center;
vertical-align: middle;
}


.UserHeadDropDown_DropDown__2Z6rE::before {
  content: "";
  position: absolute;
  width: 0; 
  height: 0; 
  top: -7px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid grey ; 
  z-index: 9999;
  left: 130px;
}








.UserHeadDropDown_DropDown__2Z6rE i{
display: block;
background-color: #f1f1f1;
font-weight: lighter;
font-style: normal;
text-align: left;
font-size: 1.2rem;
border-style: none;
height: 3rem;
vertical-align: middle;
line-height: 3rem;
width: 170px;
}



.UserHeadDropDown_DropDown__2Z6rE i:hover{
display: block;
border-style: none;
background-color: #ddd;
font-style: normal;
font-weight: lighter;
cursor: pointer;
}


.UserHeadDropDown_profile__1f15e{

background: linear-gradient(to top, #f1f1f1 90%, grey 0%);

}


.UserHeadDropDown_profile__1f15e:hover{
background: linear-gradient(to top, #ddd 90%, grey 0%);

}

.UserHeadDropDown_icons__2RNDE{

font-size: 1rem;
padding-left: 0.5rem;
padding-right: 0.5rem;
}









.SideBarDashboard_sideBarDashboard__2LEOr{
      height: calc( 100vh - var(--headerHeight) - var(--sideToolBarHeight)  );
      width: var(--sideNavBarWidth);
      position: fixed;
      z-index: 1;
      top:calc( var(--headerHeight) );
      left: 0.5%;
      background-color: var(--sideNavBarDashBoardBkgColor);/*var(--sideNavBarDashBoardBkgColor);*/
      /*#c2c3c4;*/
      overflow-x: hidden;
      transition: 0.5s;
      padding-top: 0px;
      -ms-overflow-style: none;
      scrollbar-width: none;
      overflow-y: hidden;
      padding-top: 20px;
}


.SideBarDashboard_sideBarDashboard__2LEOr:hover{

overflow-y:auto;
}




/*@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i');
*/

.SideNavBarButtonDashboard_mainButtonContainer__2Eu-B{


border-style: none;

}

.SideNavBarButtonDashboard_subOptions__3r1Zh{

position: absolute;
left: 100px;
border-style: solid;
width:300px;
z-index:999999999;
}






 .SideNavBarButtonDashboard_sideNavBarButton__M-FQB {
      padding: calc( 0.16 * var(--headerHeight) );
      text-decoration: none;
      font-size: var(--sideNavBarFontSize);
      color: var(--sideNavBarIconColor);
      /*background-color:#c2c3c4;*/
      border-style: none;
      border-color:#757575;
      border-width: thin;
      display: block;
      transition: 0.3s;
      font-weight: 100;
      margin:calc( 0.2 * var(--headerHeight) );
      text-align: left;
      cursor: pointer;
      position:relative;
      align-items: center;
      border-style: none;
      background: red;
      width:100%;
      width: calc( var(--sideNavBarWidth) - calc( 0.3 * var(--headerHeight) )  );
      height:100%;
      padding-top: calc( 0.2 * var(--headerHeight) );
      padding-bottom: calc( 0.2 * var(--headerHeight) );
      padding-left: calc( 0.2 * var(--headerHeight) );
      font-family: Roboto;
    }


.SideNavBarButtonDashboard_sideNavBarButton__M-FQB:hover{

/*background: linear-gradient(to right, var(--sideNavBarBtnhoverColor) 90%, var(--themeColor) 0%) !important;*/

}


/*
.sideNavBarButton i {
      font-style: normal;
      font-family: sans-serif;
      padding-left: 0px;
      font-size: calc( 0.9 * var(--sideNavBarFontSize) );
    }
   
*/

.SideNavBarButtonDashboard_buttonText__3fHlp{
color: var(--themeColor);
vertical-align: middle;
}


.SideNavBarButtonDashboard_dashIcon__AN3Hr{
vertical-align: middle;
padding-right: 5px;
font-size:var(--sideNavBarIconSize);
color: var(--themeColor);
margin-left: calc( 0.14 * var(--headerHeight) );
}





.QuickMeetingPage_quickMeetingPage__3dwIv{

position: fixed;
width: 100vw;
height:100vh;
background-color: grey;
z-index: 10000;
right:0;
top:0;
background-color:rgba(0,0,0,0.1);
display: flex;
align-items: center;
justify-content: center;
}


.QuickMeetingPage_meetingInfoPage__2hetE{

position: relative;
width: 40vw;
height:60vh;
min-width:300px;
background-color: white;
border-radius: 5px;
border-style:none;
border-color: grey;
border-width:2px;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
display: flex;
flex-direction: column;
align-items: center;
overflow: hidden;


}

.QuickMeetingPage_closeButtonDiv__4_wBW{
width:100%;
display: flex;
background-color: white;
justify-content: flex-end;
border-radius: 5px;
}



.QuickMeetingPage_closeFormButton__17nm7{
border-style: none;
cursor: pointer;
background-color: white;
border-radius: 5px;
}


.QuickMeetingPage_closeButtonIcon__35lRH{

font-size: 25px;

}

.QuickMeetingPage_meetingLink__3GcYj{

color: var(--themeColor);
text-decoration: underline;
background-color:var(--lightThemeColor1);
padding-left: 20px;
padding-right: 20px;
border-radius: 5px;
border-style: none;
margin-top: 20px;
font-size: 17px;
padding: 20px;
cursor: pointer;

white-space: pre-wrap; /* CSS3 */    
white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
white-space: -pre-wrap; /* Opera 4-6 */    
white-space: -o-pre-wrap; /* Opera 7 */    
word-wrap: break-word; /* Internet Explorer 5.5+ */
width: 80%;

}



.QuickMeetingPage_copyButton__24EGR{

margin-left: 10px;
border-style: none;
background-color: lightgrey;
padding: 5px;
border-radius: 4px;
cursor: pointer;
}


.QuickMeetingPage_startMeetingNowButton__1_yRf{
margin-top: 50px;
border-style: none;
padding: 10px;
color: white;
background-color: var(--themeColor);
font-weight: bold;
border-radius: 5px;
cursor: pointer;
}










/*@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i');
*/

.SideNavBarButtonQMeeting_mainButtonContainer__1OwD2{


border-style: none;

}

.SideNavBarButtonQMeeting_subOptions__2EDqG{

position: absolute;
left: 100px;
border-style: solid;
width:300px;
z-index:999999999;
}






 .SideNavBarButtonQMeeting_sideNavBarButton__1mTQZ {
      padding: calc( 0.20 * var(--headerHeight) );
      text-decoration: none;
      font-size: var(--sideNavBarFontSize);;
      color: var(--sideNavBarIconColor);
      background-color:#c2c3c4;
      border-style: solid;
      border-color:#757575;
      border-width: thin;
      display: flex;
      flex-direction:column;
      transition: 0.3s;
      font-weight: 100;
      margin:5px;
      width: var(--sideNavBarWidth);
      text-align: left;
      cursor: pointer;
      position:relative;
      align-items: center;
      font-family: Roboto;
      border-style: solid;
      background: red;
      /*top: 150px;*/
      width:90%;
      margin: auto;
      height:100%;
      border-radius: 10px;
      margin-bottom:10px;
      margin-top: 10px;
    }


.SideNavBarButtonQMeeting_sideNavBarButton__1mTQZ:hover{

/*background: linear-gradient(to right, var(--sideNavBarBtnhoverColor) 90%, var(--themeColor) 0%) !important;*/

}



.SideNavBarButtonQMeeting_sideNavBarButton__1mTQZ i {
      font-family: sans-serif;
      padding-left: 0px;
      font-size: calc( 0.9 * var(--sideNavBarFontSize) );
    }
    


.SideNavBarButtonQMeeting_buttonText__30D19{
color: var(--themeColor);
vertical-align: middle;
font-size: var(--sideNavBarFontSize);
font-family: Roboto;
}


.SideNavBarButtonQMeeting_dashIcon__1b7oe{
vertical-align: middle;
padding-right: 5px;
font-size:var(--sideNavBarFontSize);
color: var(--themeColor);
}














.MainAppContainer_mainAppContainer2__2tu_5{
position: relative;
width: calc( 100% - var(--sideNavBarWidth) );
top:  calc( 1 * var(--headerHeight) );
left: var(--sideNavBarWidth);
display: grid;
grid-template-columns: 1fr;
grid-template-rows: calc(0.5 * var(--headerHeight)) calc(0.9 * var(--headerHeight)) 1fr;
grid-row-gap: 15px;
row-gap: 15px;
grid-column-gap: 15px;
-webkit-column-gap: 15px;
        column-gap: 15px;
transition: 0.5s;
border-style: solid;
}



.MainAppContainer_mainAppContainer__1_LfW{
position: relative;
/*background-color: red;#E8E8E8;*/
width: calc( 99% - var(--sideNavBarWidth)  );
top: var(--headerHeight);/* calc( var(--headerHeight) + var(--headerTopMargin) );*/
left: calc( var(--sideNavBarWidth) + 0.5% );
/*display: grid;
grid-template-columns: 1fr;
grid-template-rows: calc(0.5 * var(--headerHeight)) calc(0.9 * var(--headerHeight)) 1fr;
grid-row-gap: calc( 0.20 * var(--headerHeight) );*/
transition: 0.5s;
border-style: none;
height: calc( 100vh - var(--headerHeight) );
border-style: none;
border-color: cyan;
border-width: 1px;
overflow-x: hidden;
overflow-y: auto;
background-color: var(--bodyBkgColor);
}


.TopInfoBarUserProfile_topInfoBar__1ytIM{
background-color: #c2c3c4;
box-shadow: none;
font-family:'Roboto','Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
font-size: large;
text-align: left;
background-color: #c2c3c4;
display: flex;
height:calc( 0.5 * var(--headerHeight) );
align-items: center;
}









.TopInfoBarUserProfile_topInfoBar__instructor__-FMIH{
position: relative;
left: calc( 1.5 * 0.50 * 0.18 * 0.5 * var(--headerHeight) );
border-bottom: calc( 0.82 * 0.5 * var(--headerHeight) ) solid var(--themeColor);
border-right: 50px solid transparent;
height: 0;
width: 300px;
color: #c2c3c4;
}



.TopInfoBarUserProfile_topInfoBar__instructor__-FMIH i{
font-style: normal;
margin-left: 50px;
font-size: calc( 0.65 * 0.82 * 0.5 * var(--headerHeight) );
}



.TopInfoBarButton_topInfoBar__profilebtn__3u_Vq{

display: flex;
position:relative;
left: calc(0.05 * 0.5 * var(--headerHeight));
min-width: calc( 2.77 * var(--headerHeight) );
text-align: center;
color: var(--sideNavBarBtnhoverColor);
border-radius: 0 50px 0 50px;
background-color:#E8E8E8;
border-style: solid;
border-width: thin;
height:80%;
font-weight: bold;
border-color: grey;
align-items: center;
justify-content: center;
font-size: calc( 0.65 * 0.82 * 0.5 * var(--headerHeight) )
}

.TopInfoBarButton_topInfoBar__profilebtn__3u_Vq:hover{

cursor: pointer;
}





.TopInfoBarInstructor_topInfoBar__instructorr__2QzU3{

 position: relative;
 height: calc( 0.82 * 0.5 * var(--headerHeight) );
 left: calc( 0.50 * 0.18 * 0.5 * var(--headerHeight) );
 width: var(--topInfoBarInstructorWidth);
 text-align: center;
 color: #c2c3c4;
 vertical-align: middle;
 background-color:var(--themeColor);
 display:flex;
 border-radius: 0 50px 0 0;
 border-style: solid;
 border-width: thin;
 border-color: var(--themeColor);
 align-items: center;
}




.TopInfoBarInstructor_topInfoBar__instructor__2jayx{
position: relative;	
left: calc( 1.5 * 0.50 * 0.18 * 0.5 * var(--headerHeight) );	
border-bottom: calc( 0.82 * 0.5 * var(--headerHeight) ) solid var(--themeColor);
border-right: 50px solid transparent;
height: 0;
width: 500px;
color: #c2c3c4;
}



.TopInfoBarInstructor_topInfoBar__instructorName__3kuz_{
overflow: hidden;
font-style: normal;
margin-left: 50px;
font-size: calc( 0.65 * 0.82 * 0.5 * var(--headerHeight) );
}

@media only screen and (max-width: 600px) {

.TopInfoBarInstructor_topInfoBar__instructor__2jayx{
display: none;
}

}








/*
.topInfoBar__instructor i{
font-style: normal;
margin-left: 50px;
font-size: calc( 0.65 * 0.82 * 0.5 * var(--headerHeight) );
}
*/



.TopTitleBar_topTitleBar__3Bu2c{

color: var(--greyTextColor);
box-shadow: none;
font-family:'Roboto','Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
font-size: calc(0.45 * var(--headerHeight));
vertical-align: middle;
/*margin-left: 30px;*/
display: flex;
align-items: center;
height: calc( 1.2 * var(--headerHeight) );
border-style: none;
border-color: red;
}

.TopTitleBar_topTitleBar__Text__16Wv7{

margin-left: 30px;
font-style: normal;
font-family: Roboto;
}


.TopTitleBar_backButton__1F3Qs{

margin-right: 20px;
height: 20px;
border-style: none;
font-weight: bold;
color: var(--themeColor);
background-color: lightgrey;
}


.TopTitleBar_backButton__1F3Qs:hover{

cursor: pointer;
}



.TopTitleBar_backIcon__2TcRR{

font-size: 30px;

}



.ContentDivUserProfile_contentDivUserProfile__2w5IQ{
display: flex;
justify-content: space-between;
border-style: none;
border-color: orange;
padding-bottom: 20px;
/*
display: grid;
grid-template-columns: 1fr 2.3fr;
grid-template-rows: 1fr 1fr ;
grid-gap: 10px;
padding-bottom: 50px;
padding-right:20px;
*/




}



@media only screen and (max-width: 1100px) {
 .ContentDivUserProfile_contentDivUserProfile__2w5IQ{
     display: flex;
     flex-direction: column;
 }
}






.ProfileImageSec_profileImageSec__3DM82{


  display: flex;
  justify-content: flex-start;
  border-style: none;
  border-color: orange;
  border-width: 1px;
  width: 30%;
  margin-left: 30px;
  flex-direction: column;
  

}


.ProfileImageSec_unitColumn__2udlL{

width: 100%;
border-style: solid;
margin-bottom: 30px;
background-color: white;
border-color:var(--cardBorderColor);
border-width: 1px;
border-radius: var(--cardBorderRadius);
}


.ProfileImageSec_unitColumn__2udlL:before {/*
  content: "";
  display: block;
  height: 0;
  width: 0;
  padding-bottom: calc(5/3 * 100%);
  background-color:var(--themeColor);
*/}






@media only screen and (max-width: 1100px) {

.ProfileImageSec_profileImageSec__3DM82{

width: 95%;
margin: auto;

}

.ProfileImageSec_unitColumn__2udlL{



}


}









.ProfileImageSec_profileImageSec__3DM82:before {/*
  content: "";
  display: block;
  height: 0;
  width: 0;
  padding-bottom: calc(5/3 * 100%);
  background-color:var(--themeColor);
*/}


.ProfileImageSec_profileImageSec__pseudo__3v-Ga{

  background-color: white;
  width: 100%;
  height: 100%;
  border-radius: var(--cardBorderRadius);
  display: flex;
  flex-direction: column;
}






.ProfileImageSec_innerColumn__1RKjr{

height: 80%;
width: 80%;
border-style: solid;
margin: auto;
margin-top: 10%;
border-width: 1px;
border-color: lightgrey;
}

.ProfileImageSec_ProfileInfo2_titleBar__2mVpb{


width: 100%;
height: 40px;
display: flex;
align-items: center;
justify-content: center;
}

.ProfileImageSec_ProfileInfo2_titleBar__2mVpb i{
font-style: normal;
color: grey;
}



.ProfileImageSec_yourlinks__1ou3s{

width: 100%;
display: flex;
justify-content:center;
}

.ProfileImageSec_youtubeLink__1aga3{

color: red;
padding-right: 20px;
font-size: 25px;
}

.ProfileImageSec_linkedInLink__2Mp0g{
padding-right: 20px;
font-size: 25px;
color: #0072b1;
}


.ProfileImageSec_twitterLink__dPWZX{
font-size: 25px;
color: #00acee;
}









.ProfileImage_profileImageDiv__3pizm{

 position:relative;
 height:70%;
 padding: 10%;
 background-color:white;
 display: flex;
 flex-direction: column;
 border-radius: var(--cardBorderRadius);

}




.ProfileImage_userImage__1yoyd{
 position: relative;
 width: 100%;
 aspect-ratio:1/1; 
 border-radius: var(--cardBorderRadius);
}

.ProfileImage_changePicButton__1yxCV{
border-style: none;
height: 40px;
border-radius: var(--cardBorderRadius);
}


.ProfileImage_changePicButton__1yxCV:hover{
color: white;
background-color:var(--themeColor);
cursor: pointer;

}







.ProfileImage_imageUpload__3vHio{

width: 100%;
cursor:pointer;
width: 200px;
margin: auto;
}
.ProfileImage_image_field__3Rffp{
width:100%;
height: 100%;
text-decoration: none;
}


.ProfileImage_submit_button__3Duir{
width: 100%;
color: white;
background-color:var(--themeColor);
border-style: none;
margin-top:20px;
height:30px;
border-radius: var(--cardBorderRadius);
}

.ProfileImage_submit_button__3Duir:hover{
cursor: pointer;


}




.ProfileImageInfo_profileImageInfo__1PcDZ{
height: 40%;
padding:10%;
padding-top: 0px;
background-color: white;
text-align: center;
color: black;
display: flex;
flex-direction: column;
border-style: none;
border-radius: var(--cardBorderRadius);
}

.ProfileImageInfo_nameDiv__2QAq1{
height: 40%;
background-color: white;
}

.ProfileImageInfo_name_i__3SEsE{

font-style: normal;
color:var(--greyTextColor)
}

.ProfileImageInfo_profileImageInfo_position__2k4-Q{

height:40px;
background:linear-gradient(to top, white 98%, lightgrey 0%);
display: flex;
justify-content: space-between;
align-items: center;
/*font-size: calc( 0.30 * var(--headerHeight) );*/
}

.ProfileImageInfo_profileImageInfo_position__2k4-Q i{

font-style: normal;
position:relative;
left: 10px;
width: 100px;
/*font-size: calc( 0.30 * var(--headerHeight) );*/
}

.ProfileImageInfo_roleTxt__1clIE{

float: left;
}



.ProfileImageInfo_profileImageInfo_joiningDate__2lG62{

height:40px;
background:linear-gradient(to top, white 98%, lightgrey 0%);
display: flex;
justify-content: space-between;
align-items: center;
/*font-size: calc( 0.30 * var(--headerHeight) );*/
}


.ProfileImageInfo_profileImageInfo_joiningDate__2lG62 i{
font-style: normal;
position:relative;
left: 10px;
width: 100px;
/*font-size: calc( 0.30 * var(--headerHeight) );*/
}


.ProfileImageInfo_joinTxt__2R5YO{

float: left;

}




.ProfileImageInfo_profileImageInfo_status__18e3V{
height:40px;
background:linear-gradient(to top, white 98%, lightgrey 0%);
display: flex;
justify-content: space-between;
align-items: center;
/*font-size: calc( 0.30 * var(--headerHeight) );*/
}

.ProfileImageInfo_profileImageInfo_status__18e3V i{

font-style: normal;
color: white;
background-color: #4CBB17;
padding: 2px;
position:relative;
width: 100px;
/*font-size: calc( 0.30 * var(--headerHeight) );*/
}







.ProfileImageInfo_profileImageInfo_statusNo__37QWK{
height:40px;
background:linear-gradient(to top, white 98%, lightgrey 0%);
display: flex;
justify-content: space-between;
align-items: center;
/*font-size: calc( 0.30 * var(--headerHeight) );*/
}

.ProfileImageInfo_profileImageInfo_statusNo__37QWK i{

font-style: normal;
color: white;
background-color: red;
padding: 2px;
position:relative;
width: 100px;
/*font-size: calc( 0.30 * var(--headerHeight) );*/
}



.ProfileUnitBlock_profileImageSec__18YpO{


  background: white;
  display: flex;
  justify-content: space-between;
  margin-left: 30px;
  border-radius:var(--cardBorderRadius);
  /*box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;*/
  width: 70%;
  margin-right: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: var(--cardBorderColor);

}




@media only screen and (max-width: 1100px) {

.ProfileUnitBlock_profileImageSec__18YpO{

width: 95%;
margin: auto;
}


}









.ProfileUnitBlock_profileImageSec__18YpO:before {/*
  content: "";
  display: block;
  height: 0;
  width: 0;
  padding-bottom: calc(5/3 * 100%);
  background-color:var(--themeColor);
*/}


.ProfileUnitBlock_profileImageSec__pseudo___9Z-Q{
/*
  background-color: white;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
*/
}




.ProfileInformationAndSetting_profileInformationAndSetting__3nQOM{

  background: white;
  /*display: flex;
  justify-content: space-between;
  margin-left: 30px;
  border-radius:3px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  */
  /*grid-row-start:1;
  grid-row-end:4;*/
  border-style:none;
  width: 100%;
  border-radius: var(--cardBorderRadius);
}



.UserProfileContentBasic_userProfileContentBasic__2DEOg{
width: 100%;
height: calc( 100% - calc( 0.6 * var(--headerHeight) ) );
border-style: none;
border-radius: var(--cardBorderRadius);
}




.AboutBasic_about_Basic__1JInm{


width: 100%;
background-color: white;
display: flex;
align-items: center;
flex-direction: column;
border-style: none;
border-color: red;
border-radius:var(--cardBorderRadius);

}


.AboutBasic_aboutInfoContainer__3z9MS{

width:93%;
display: flex;
align-items: center;
background-color: #E8E8E8;
background-color:var(--userProfileInfoBoxBkgColor);
border-style: none;
border-width: 1px;
border-color: #E8E8E8;
border-color:green;
border-radius:3px;

}



.AboutBasic_aboutInfoContainerEd__2qK-Z{

width:93%;
display: flex;
flex-direction: column;
align-items: center;
background-color: #E8E8E8;
background-color:var(--userProfileInfoBoxBkgColor);
border-style: none;
border-width: 1px;
border-color: #E8E8E8;
border-color:green;
border-radius:3px;


}












@media only screen and (max-width: 1100px) {
 .AboutBasic_aboutInfoContainer__3z9MS{
     display: flex;
     justify-content: space-between;
     flex-direction: column;
     width: 90%;
     margin: auto;
     border-style: none;
     border-color: cyan;
     background-color: cyan;
 }



}








.AboutBasic_InfoBoxLeft__xaS49{
width: 100%;
background-color: white;
}


.AboutBasic_InfoBoxRight__2ZW9f{
width: 100%;
border-style: none;
background-color: white;
}



.AboutBasic_fieldTitle__bvW3j{
color: grey;
width: 120px;
}

.AboutBasic_oneFieldInfo__3lS2A{
height: 35px;
display: flex;
justify-content: flex-start;
align-items: center;
}


.AboutBasic_fieldValue__9VyFq{
margin-left: 10px;
}


.AboutBasic_aboutSection__7NjLM{


width: 90%;
margin-top: 20px;
}




















.UnitBar_unitBar__342j0{

width: 93%;
height: calc( 0.8 * var(--headerHeight) );
background-color:white;
border-style: none;
border-width: 1px;
border-color: grey;

}


.UnitBarFirstName_unitBarFirstName__lvdHB{

width: 93%;
height: calc( 0.5 * var(--headerHeight) );
background-color:white;
border-style: none;
border-width: 1px;
border-color: grey;
display: flex;
align-items: center;
margin-top:10px;
margin-bottom: 10px;
}


.UnitBarFirstName_firstNameTitle__412C8{

width:30%;
font-size:calc( 0.25 * var(--headerHeight) );
border-style: none;
border-width:1px;
height:100%;
display: flex;
align-items: center;
border-color: #E8E8E8;
border-color:lightgrey;
position: relative;
padding-left: 20px;
color: grey;
font-weight:bold;
}

.UnitBarFirstName_firstName__1P_Xf{
width:70%;
height: 100%;
font-size:calc( 0.25 * var(--headerHeight) );
text-align: left;
display: flex;
align-items: center;
background-color: var(--userProfileInfoBoxBkgColor);
border-style: none;
border-color:lightgrey;
border-width:1px;
position: relative;
padding-left:10px;
}



.UnitBarGenDOB_unitBarGenDOB__gK5fn{

width: 93%;
height: calc( 0.7 * var(--headerHeight) );
background-color:#E8E8E8;
background-color:var(--userProfileInfoBoxBkgColor);
border-style: none;
border-width: 1px;
border-color: grey;
display: flex;
align-items: center;
margin-top:10px;
margin-bottom: 10px;
justify-content: space-between;
}


.UnitBarGenDOB_genderDiv__2XT16{

width:45%;
font-size:calc( 0.25 * var(--headerHeight) );
border-style: none;
border-width:1px;
height:100%;
display: flex;
align-items: center;
border-color:#E8E8E8;
border-color:lightgrey;
position: relative;
padding-left: 20px;
color: grey;
background-color: white;
}

.UnitBarGenDOB_genderDiv__2XT16 i{

font-style: normal;
padding-left: 20px;
}



.UnitBarGenDOB_dobDiv__2542v{
width:45%;
height: 100%;
font-size:calc( 0.25 * var(--headerHeight) );
text-align: left;
display: flex;
align-items: center;
background-color: white;
border-style: solid;
border-color:#E8E8E8;
border-color:lightgrey;
border-width:1px;
color: #454444;
padding-left: 20px;
}

.UnitBarGenDOB_dobDiv__2542v i{
font-style: normal;
padding-left: 20px;
overflow: auto;

}










.UnitAboutIcon_unitAboutIcon__1aZSo{

width: 93%;
height: calc( 0.8 * var(--headerHeight) );
background-color:white;
border-style: none;
border-width: 1px;
display: flex;
align-items: center;
font-size: calc( 0.6 * var(--headerHeight) );
justify-content: space-between;

}

.UnitAboutIcon_unitAboutIcon__1aZSo div{
display: flex;
align-items: center;
}


.UnitAboutIcon_aboutIcon__2qK4s{
color: var(--themeColor);
font-size: 30px;
margin-right: 10px;
}


.UnitAboutIcon_unitAboutIcon__1aZSo i{
font-size: calc( 0.3 * var(--headerHeight) );
font-style: normal;
margin-left: 10px;
}

.UnitAboutIcon_editButtonAbout__1sZ4l{
left: calc( 0.3 * var(--headerHeight) );
background-color: white;
border-style: solid;
border-width: 1px;
border-radius: 5px;
padding:5px;
padding-left: 10px;
padding-right: 10px;
cursor: pointer;
color: var(--themeColor);
border-color: var(--cardBorderColor);
}


.UnitAboutIcon_editButtonAbout__1sZ4l:hover{
color:white;
background-color:var(--themeColor);

}












.AboutEditForm_aboutEditFormDivParent__1s23T{

position: fixed;
width: 100vw;
height:100vh;
background-color: grey;
z-index: 10000;
right:0;
top:0;
background-color:rgba(0,0,0,0.1); 
display: flex;
align-items: center;
justify-content: center;
}

.AboutEditForm_aboutEditForm__15AlT{

position: relative;
width: 100%;
height:80vh;
max-width:500px;
background-color: white;
border-radius: 5px;
border-style:none;
border-color: grey;
border-width:2px;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
display: flex;
flex-direction: column;
align-items: center;
overflow: auto;
}















.AboutEditForm_closeButtonDiv__BwRpY{
width:100%;
display: flex;
background-color: white;
justify-content: flex-end;
border-radius: 5px;
}



.AboutEditForm_closeFormButton__aT539{
border-style: none;
cursor: pointer;
background-color: white;
border-radius: 5px;
}


.AboutEditForm_closeButtonIcon__obepP{

font-size: 25px;

}


.AboutEditForm_logoAndTitleContainer__3C0V1{


height:150px;
width:80%;
background-color: white;
display: flex;
flex-direction: column;
margin-bottom: 50px;
}

.AboutEditForm_bwLogo__2ljgi{

height: 75px;
width: 75px;

}

.AboutEditForm_titleDiv__vULxh{
width: 100%;
display:flex;
justify-content:flex-start;
color: #585858;
}

.AboutEditForm_titleDiv__vULxh i{

font-size: 20px;
}


.AboutEditForm_name_div__kod4J{

display: flex;
flex-direction: column;
width:80%;
align-items: center;
justify-content: center;
margin: 10px;
}

.AboutEditForm_name_txt__2ewTD{
width: 100%;
position: relative;
background-color:white;
font-size: 20px;
color: grey;
font-weight: thin;
}

.AboutEditForm_name_inputDiv__1p0EX{
width: 100%;
}

.AboutEditForm_value_field__1fUqO{
height: 30px;
border-style: solid;
border-color: lightgrey;
width: 100%;
font-size: 18px;
border-radius: 10px;
padding-left: 10px;
}


.AboutEditForm_genpos_div__3BiMu{

display: flex;
flex-direction: row;
width:80%;
align-items: center;
justify-content: space-between;
margin: 20px;
}


.AboutEditForm_gender_div__2Ec8R{
display: flex;
flex-direction: column;
width: 45%;
background-color: red;
border-radius: 5px;
}


.AboutEditForm_position_div__wAw30{
display: flex;
flex-direction: column;
width: 45%;
background-color: green;
border-radius: 5px;
border-style: none;
}



.AboutEditForm_genpos_field__1d_ze{
width:100%;
height: 40px;
font-size: 20px;
padding: 5px;
}


.AboutEditForm_dob_div__3vnx5{
width: 80%;
height: 90px;
display: flex;
flex-direction: column;
background-color: white;
justify-content: flex-start;
align-items: flex-start;
}

.AboutEditForm_dob_txt__kk4Z8{
width: 100%;
position: relative;
background-color:white;
font-size: 20px;
color: grey;
font-weight: thin;

}

.AboutEditForm_dob_innerDiv__2Eo-d{
width: 100%;
position: relative;

}

.AboutEditForm_dob_innerDiv2__3wJvh{

position: relative;
width: 45%;

}


.AboutEditForm_dateofbirth_field__1LUyE{
position: relative;
width: 100%;
padding: 5px;
height: 30px;
font-size:20px;
min-width:200px;
}

.AboutEditForm_firstname_field__3QmwF , .AboutEditForm_lastname_field__aWN3y,.AboutEditForm_gender_field__l51CI,.AboutEditForm_position_field__1KRWz {
position: relative;
width: 100%;
padding: 5px;
height: 30px;
font-size:23px;
}


.AboutEditForm_submitButtonDiv__2AXNc{
width: 80%;	
display: flex;
justify-content: center;
background-color: white;
padding-top: 50px;
}


.AboutEditForm_submit_button__3skn3{
width: 300px;
border-style: none;
background-color: var(--themeColor);
color: white;
height:50px;
border-radius: 10px;
cursor: pointer;
margin-bottom: 50px;
font-size: 20px;
}






.EduDegreeForm_createTicketFormDivParent__2mtT6{

position: fixed;
width: 100vw;
height:100vh;
background-color: grey;
z-index: 10000;
right:0;
top:0;
background-color:rgba(0,0,0,0.1); 
display: flex;
align-items: center;
justify-content: center;
}

.EduDegreeForm_createTicketForm__18xRE{

position: relative;
width: 100%;
max-width:700px;
max-height: 90vh;
background-color: white;
border-radius: var(--cardBorderRadius);
border-style: none;
border-color: grey;
border-width:2px;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
align-items: center;
overflow-y: scroll;
}


.EduDegreeForm_input_field__32TSc{
height: 40px;
margin:auto;
margin-left: 50px;
}




.EduDegreeForm_dateFields__3ZMe2{
display: flex;
width: 75%;
margin: auto;
}




.EduDegreeForm_createTicketFormLoading__Rs7Ag{

position: relative;
width: 60vw;
height: 80vh;
min-width:300px;
background-color: white;
border-radius: 5px;
border-style: none;
border-color: grey;
border-width:2px;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}


.EduDegreeForm_submittingDiv__1Kxks{
padding-top: 100px;	
color: var(--themeColor);

}









.EduDegreeForm_closeButtonDiv__yjaDD{
width:100%;
display: flex;
background-color: white;
justify-content: flex-end;
border-radius: 5px;
}



.EduDegreeForm_closeFormButton__29x7m{
border-style: none;
cursor: pointer;
background-color: white;
border-radius: 5px;
}


.EduDegreeForm_closeButtonIcon__3g2UJ{

font-size: 25px;

}


.EduDegreeForm_logoAndTitleContainer__2ycSA{


height:150px;
width:80%;
background-color: white;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
border-style: none;
margin: auto;
}

.EduDegreeForm_bwLogo__1RQI7{

height: 75px;
width: 75px;

}

.EduDegreeForm_formTitleDiv__IYr7N{
width: 100%;
display:flex;
justify-content:flex-start;
color: #585858;
font-size: 30px;
margin: auto;
}

.EduDegreeForm_formTitleDiv__IYr7N i{

font-size: 25px;
font-style: normal;
}



.EduDegreeForm_submitButtonDiv__2gzFY{
width: 80%;	
display: flex;
justify-content: center;
background-color: white;
padding-top: 50px;
justify-content: center;
border-style: none;
margin: auto;
margin-bottom: 100px;
}


.EduDegreeForm_submit_button__3Hv9s{
width: 250px;
border-style: none;
background-color: var(--themeColor);
color: white;
height:50px;
border-radius: 10px;
cursor: pointer;
font-size: 20px;
}


.EduDegreeForm_emptyDiv__14X6c{
width: 80%;
border-style: solid;
height: 100px;
color: white;

}



.EduDegreeForm_name_div__256AX{

display: flex;
flex-direction: column;
width:80%;
align-items: center;
justify-content: center;
margin: auto;
margin-bottom: 20px;
}

.EduDegreeForm_name_txt__1PStr{
width: 100%;
position: relative;
background-color:white;
font-size: 20px;
color: grey;
font-weight: thin;
}

.EduDegreeForm_name_inputDiv__3LTLG{
width: 100%;
}

.EduDegreeForm_value_field__ziD-q{
height: 30px;
border-style: solid;
border-color: lightgrey;
width: 100%;
font-size: 18px;
border-radius: 10px;
padding-left: 10px;
}

.EduDegreeForm_yearsDiv__Rm1d7{
width: 80%;
margin: auto;
}



.UnitBarInstDegree_unitBarInstDegree__3RKJ5{
width: 90%;

border-style: solid;
border-width: 1px;
border-color: lightgrey;
display: flex;
margin-top: 20px;
padding-left: 20px;
padding-top:10px;
padding-bottom:10px;
border-radius: 10px;
justify-content: space-between;
align-items: center;
}


.UnitBarInstDegree_InstLogo__izn43{

width: 80px;
height: 80px;
margin-left: 30px;
}

.UnitBarInstDegree_InstInfo__2UQWX{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 0px;

}


.UnitBarInstDegree_InstInfo__degree__1Pv_b{
  color: black;
  font-weight: bold;
  font-size:20px;
}

.UnitBarInstDegree_InstInfo__duration__gS49J{
 color: var(--themeColor);
}


.UnitBarInstDegree_InstInfo__location__36cQ-{
  color: grey;
}



.UnitBarInstDegree_logoImage__27it5{

width:60px;
height: 60px;

}


.UnitBarInstDegree_deleteButton__1BWbb{
height: 30px;
margin-right:10px;
border-style: none;
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
}









.UnitBarAchievement_unitBarAchievement__365wE{

width: 93%;
border-bottom-style:solid ;
border-width: 1px;
margin-bottom: 20px;
border-color: lightgrey;
padding-bottom: 10px;
display: flex;
justify-content: space-between;
margin-top: 30px;

}


.UnitBarAchievement_infoDiv1___ln7S{
font-weight: bold;
font-size: 20px;
}

.UnitBarAchievement_deleteButton__2wnfl{
height: 30px;
width: 30px;
border-style: none;
border-radius: 5px;
cursor: pointer;
}




.MainAppContainer_mainAppContainer__2amSx{
position: relative;
/*background-color: red;#E8E8E8;*/
width: calc( 99% - var(--sideNavBarWidth)  );
top: var(--headerHeight);/* calc( var(--headerHeight) + var(--headerTopMargin) );*/
left: calc( var(--sideNavBarWidth) + 0.5% );
/*display: grid;
grid-template-columns: 1fr;
grid-template-rows: calc(0.5 * var(--headerHeight)) calc(0.9 * var(--headerHeight)) 1fr;
grid-row-gap: calc( 0.20 * var(--headerHeight) );*/
transition: 0.5s;
border-style: none;
height: calc( 100vh - var(--headerHeight) );
min-width: 400px;
border-style: none;
border-color: green;
border-width: 1px;
overflow-x: hidden;
overflow-y: hidden;
}




.GeneralContentDiv_contentDiv__3RQmf{
position: relative;
border-style: none;
border-width: 1px;
border-color: cyan;
width: 100% ;
height: 100%;
/*border-radius: 10px 0px 0px 0px;*/
overflow-x: hidden;
overflow-y: auto;
}


.GeneralContentDiv_createDocButton__KpwHW{

margin-top:30px;
margin-left: 30px;
padding: 10px;
border-radius: 5px;
border-style: solid;
border-color: lightgrey;
border-width: 1px;
width: 160px;
cursor: pointer;
background-color: white;
color: var(--themeColor);
}

.GeneralContentDiv_documentArea__1v7l3{


width:98%;
height:90%;
border-style: none;
margin: auto;
display: flex;
border-color: red;
}

.GeneralContentDiv_documentNames__2fTVZ{
width: 200px;
height: 100%;
border-style: solid;
border-color: grey;
}

.GeneralContentDiv_documentTextArea__1QzG3{

width: calc( 100% - 250px );
height: 100%;
border-style: none;
display: flex;
flex-direction: column;
}

.GeneralContentDiv_saveButton__3PihL{

height: 40px;
width: 150px;
background-color: var(--themeColor);
cursor:pointer;
}




.GeneralContentDiv_textAreaInput__2xbpA{
width: 100%;
height: 95%;
padding: 20px;
max-width: 1000px;
font-size: 20px;
}





.AppContentDiv_appContentDiv__1bhe2{
/*background-color: white; /*var(--mainAppContainerBkgColor);*/
border-style: none;
border-width: 2px;
border-color: red;
width: 100% ;
height: 100%;
position: relative;
background-color: var(--bodyBkgColor);/*var(--lightThemeColor2);*/
overflow-x: hidden;
overflow-y: hidden;
border-radius: var(--cardBorderRadius) 0px 0px 0px;
}

.AppContentDiv_pwdAppDirectory__2FivM{
display: flex;
align-items: center;
border-style: none;
height: calc( 0.7 * var(--headerHeight) );
}

.AppContentDiv_pwdAppDirectoryText__2cJTq{
font-style: normal;
margin-left: 50px;
color: grey;
font-size: 10px;
}


.SlotsContentDiv_contentDiv__3mtku{
position: relative;
background-color: var(--bodyBkgColor);
width: 100%;
border-style: none;
border-color: orange;
height: 100%;
overflow-y: auto;
overflow-x:hidden;
}


.SlotsContentDiv_createSlotButton__yPOqi{

height: 40px;
margin-left: 30px;
margin-top: 30px;
width: 150px;
border-radius: 5px;
border-color: lightgrey;
border-style: solid;
border-width: 1px;
background-color: white;
color: var(--themeColor);
cursor: pointer;
}



.SlotsContentDiv_slotsContainer__nWdh2{

border-style: none;
width: 100%;
height: 2000px;
margin-top:30px;
}


.SlotsContentDiv_buttonContainer__2Zds1{
width: 100%;
border-style: none;
}

.SlotsContentDiv_slotsTitle__1Hp20{
border-style: none;	
font-size: 20px;
max-width: 500px;
margin: auto;
margin-bottom: 30px;

}






.Classes_classes__2oqJx{


width: 100%;
margin: auto;
margin-top: 20px;
border-style: none;
height: 1000px;
}


.Classes_switchBar__3yjbc{
height: calc( 0.7 * var(--headerHeight) );
width:90%;
margin: auto;
border-color: lightgrey;
border-style: none;
display: flex;
justify-content: space-between;
}

.Classes_downArrow__14UKO{

border-style: none;
display: flex;
font-size: 12px;
margin-left: 20px;
}


.Classes_timeLine__1x_Eq{
height: 30px;
width: 90%;
border-style: none;
margin: auto;
align-items: center;
margin: auto;
display: flex;
color: var(--greyTextColor);
margin-top: 20px;
white-space: nowrap;
}


.Classes_dummyDiv__Ngt4U{
position: relative;
}


.Classes_dropDownDiv__2ostB{
border-style:solid;
background-color: white;
border-width: 1px;
border-color: lightgrey;
border-radius: 0px 0px calc( 0.6 * var(--cardBorderRadius) )  calc( 0.6 * var(--cardBorderRadius) );
position: absolute;
width: 100%;
z-index: 1000000;
padding-bottom: 20px;
}


.Classes_dropDownButton__1VHyH{
width: 100%;
background-color: white;
height: calc( 0.7 * var(--headerHeight) );
display: flex;
justify-content: flex-start;
align-items: center;
cursor: pointer;
border-style: none;

}


.Classes_dropDownButton__1VHyH span{

margin-left: 15px;
}


.Classes_dropDownButton__1VHyH:hover{

background-color: var(--cardBorderColor);
}



.Classes_navButton__1CJK2{
border-style: none;
border-width: 1px;
border-color: var(--cardBorderColor);
margin-left: 5px;
margin-right: 5px;
border-radius: 3px;
color: grey;
padding-left:10px;
padding-right: 10px;
display: flex;
align-items: center;
padding-top: 8px;
padding-bottom: 8px;
}

.Classes_navButton__1CJK2:hover{
background-color: lightgrey;
}



.Classes_infoText_i__1yKFh{
display: flex;
}


.Classes_classTimeLine__C7D_K{
width: 100%;
height: 1px;
background-color: var(--cardBorderColor);
display: flex;
align-items: center;
justify-content: space-between;
padding-right: 70px;
}


.Classes_oneClassBall__1Ganv{
height: 5px;
width: 5px;
border-radius: 50%;
background-color: grey;
margin-left: 5%;
position: relative
}


.Classes_oneClassExactTime__1L3gI{
position: absolute;
top: 5px;
}



.Classes_dateText__2nOE5{
border-style: none;
display: flex;
align-items: center;
}





.buttonStyle2_buttonStyle2__3Vw4E{
height: calc( 0.7 * var(--headerHeight) );
border-style: solid;
font-size: calc( 0.3 * var(--headerHeight) );
cursor: pointer;
border-width: calc( 0.04 * var(--headerHeight) );
border-color: var(--cardBorderColor);
background-color: white;
color: var(--themeColor);
font-family: Roboto;
border-radius: calc( 0.6 * var(--cardBorderRadius) );
padding-left: calc( 0.5 * var(--headerHeight) );
padding-right: calc( 0.5 * var(--headerHeight) );
border-width: 1px;
}




.CreateClassForm_createTicketFormDivParent__1NeUN{

position: fixed;
width: 100vw;
height:100vh;
background-color: grey;
z-index: 10000;
right:0;
top:0;
background-color:rgba(0,0,0,0.1); 
display: flex;
align-items: center;
justify-content: center;
}

.CreateClassForm_createTicketForm__3vbvw{

position: relative;
width: 60vw;
max-width: 700px;
height:90vh;
min-width:400px;
background-color: white;
border-radius: var(--cardBorderRadius);
border-style:none;
border-color: grey;
border-width:2px;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
/*display: flex;
flex-direction: column;*/
align-items: center;
overflow-y: scroll;
overflow-x: hidden;
}



.CreateClassForm_createTicketFormLoading__3kdjP{

position: relative;
width: 60vw;
height:80vh;
min-width:300px;
background-color: white;
border-radius: 5px;
border-style:none;
border-color: grey;
border-width:2px;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}


.CreateClassForm_submittingDiv__3ag2f{
padding-top: 100px;	
color: var(--themeColor);

}









.CreateClassForm_closeButtonDiv__HH6v_{
width:100%;
display: flex;
background-color: white;
justify-content: flex-end;
border-radius: 5px;
}



.CreateClassForm_closeFormButton__1Whcf{
border-style: none;
cursor: pointer;
background-color: white;
border-radius: 5px;
}


.CreateClassForm_closeButtonIcon__3VuUx{

font-size: 25px;

}


.CreateClassForm_logoAndTitleContainer__1kbw8{


width:80%;
background-color: white;
display: flex;
flex-direction: column;
align-items: center;
margin: auto;
border-style: none;
margin-bottom: 30px;
}

.CreateClassForm_bwLogo__3NOi-{

height: 75px;
width: 75px;

}

.CreateClassForm_formTitleDiv__wge7i{
width: 100%;
display:flex;
justify-content:flex-start;
color: #585858;
margin-top: 20px;
}

.CreateClassForm_formTitleDiv__wge7i i{

font-size: 25px;
font-style: normal;
}



.CreateClassForm_submitButtonDiv__1Xxpa{
width: 100%;	
display: flex;
justify-content: center;
background-color: white;
padding-top: 50px;
margin-bottom: 50px;
border-style: none;
}


.CreateClassForm_submit_button__1bHPi{
width: 300px;
border-style: none;
background-color: var(--themeColor);
color: white;
height:50px;
width:200px;
border-radius: 10px;
cursor: pointer;
font-size: 18px;
}


.CreateClassForm_emptyDiv__n-I7_{
width: 80%;
border-style: solid;
height: 50px;
color: white;

}




.CreateClassForm_singleNMultiFormOption__3pnjA{

height: 40px;
width: 80%;
display: flex;
justify-content: space-between;
margin-top: 0px;
border-bottom: none;
border-color: lightgrey;
border-width: 1px;
margin: auto;
}
.CreateClassForm_singleClass__2S9gD{
width: 50%;
height: 40px;
border-style: none;
display: flex;
align-items: center;
justify-content: center;
color: grey;
border-radius: var(--cardBorderRadius) 0px 0px var(--cardBorderRadius);
}

.CreateClassForm_singleClass__2S9gD:hover{
cursor: pointer;
}

.CreateClassForm_multiClass__3Ihfy{
width: 50%;
height:40px;
border-style: none;
border-width: 1px;
border-color: lightgrey;
font-family: Roboto;
display: flex;
align-items: center;
justify-content: center;
color: grey;
border-radius: 0px var(--cardBorderRadius) var(--cardBorderRadius) 0px;
}

.CreateClassForm_multiClass__3Ihfy:hover{
cursor:pointer;
}



.CreateClassForm_noCourseWarning__2I4lt{
font-style: normal;
color: grey;
font-size: 20px;
margin: auto;
border-style: none;
width: 80%;
}

.CreateClassForm_formElement1__ib9bI{
width: 80%;
margin: auto;
border-style: none;
margin-top: 30px;
}



.CreateClassForm_multiClassInfo__EIzCr{
margin: 20px;
color: grey;
font-size: 20px;
}

.CreateClassForm_multiContainer__KFrsx{
width: 100%;
display: grid;
grid-template-columns: repeat(auto-fit, minmax(150px, 1fr) );
grid-auto-rows: 100px ;
border-style: none;
grid-column-gap: 50px;
grid-row-gap: 20px;
border-color: red;
margin: auto;

}


.CreateClassForm_dayFieldContainer__1TPpO{
display: grid;
grid-row-gap: 20px;

}



.CreateClassForm_customTimePicker__1qvzm{

border-style: solid;
height: 40px;
width: 200px;
border-width: 1px;
border-color: lightgrey;
border-radius: 5px;
display: flex;
align-items: center;
justify-content: center;
color: grey;
position: relative;
}


.CreateClassForm_customTimePicker__1qvzm:hover{

border-color: var(--themeColor)

}

.CreateClassForm_timeDropDownOptions__YjaeM{
height: 400px;
width: 100%;
background-color: white;
position: absolute;
top: 40px;
z-index: 22;
box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
border-radius: 0px 0px 5px 5px;
display: flex;
visibility: hidden;
}


.CreateClassForm_customTimePicker__1qvzm:hover .CreateClassForm_timeDropDownOptions__YjaeM{

visibility: visible;
}






.CreateClassForm_hourDiv__ybsTe{
width: 33%;
height:100%;
border-style: none;
overflow-y: scroll;
overflow-x:hidden;
}

.CreateClassForm_minDiv__2-Fex{
width:33%;
height:100%;
border-style: none;
overflow-y: scroll;
overflow-x:hidden;
}

.CreateClassForm_ampmDiv__2y4rP{
height:100%;
width: 33%;
border-style: none;
}


.CreateClassForm_oneTimeDropDownBox__hl-Qh{
height: 30px;
display: flex;
align-items: center;
justify-content: center;
border-style:none;
width: 100%;
background-color: white;
color: var(--greyTextColor);
cursor: pointer;
}



.CreateClassForm_oneTimeDropDownBox1__1tzyX{
height: 30px;
display: flex;
align-items: center;
justify-content: center;
border-style:none;
width: 100%;
background-color: white;
color: var(--greyTextColor);
}





.CreateClassForm_oneTimeDropDownBox__hl-Qh:hover{
background-color: lightgrey;
color: grey;
}


.CreateClassForm_firstFieldBlock__3Tji9{

width: 100%;
border-style: none;
display: flex;
justify-content: space-between;
margin-bottom: 20px;
}

.CreateClassForm_firstFieldBlockSmall__tZjVa{
width: 200px;
border-style: none;
display: flex;
justify-content: space-between;
margin-top: 20px;
}



.CreateClassForm_firstFieldBlockLong__1nvem{
width: 100%;
border-style: none;
display: flex;
margin-top:20px;
}


.CreateClassForm_optionalSettings__2Qdjq{

height: 2px;
margin-top:50px;
display: flex;
justify-content: space-between;
border-style: none;
position: relative;
height: 30px;
align-items: center;
width: 100%;
cursor: pointer;
background-color: white;
}




.CreateClassForm_optionSettingDiv__2SNyp{
position: relative;
background-color: white;
border-style: none;
color: var(--themeColor);
}


.CreateClassForm_optionSettingButton__3zKL2{
position: relative;
background-color: white;
border-style: none;
color: var(--themeColor);
}



.CreateClassForm_chooseTopicForClassDiv__OGZHH{

width:100%;
max-width:300px;
border-style: none;
height: 30px;
border-radius: 5px;
height: 33px;
cursor: pointer;
margin-top: 35px;
color: var(--greyTextColor);
font-family: Roboto;
}


.CreateClassForm_selTopicsParentDiv__vnOxf{
margin-top:20px;

}

.CreateClassForm_selectedTopicDiv__2omAZ{

color: var(--themeColor);
}

.CreateClassForm_selTopicTitle__1pEEA{
 margin-bottom: 15px;
 color: grey;
}



.CreateClassForm_optionalSettingDiv__2puRB{
display: flex;
justify-content: space-between;
z-index: 1;
}


.CreateClassForm_noTopicsSelMessage__3gNte{

font-size: 13px;
margin-left: 20px;
color: red;
}





.Logo_logoText__2Te4W{
color: var(--themeColor);
font-size: calc( 0.40 * var(--headerHeight) );
border-style: none;
display: flex;
justify-content: space-between;
align-items: center;
background-color: var(--themeColor);
color: white;
height: calc( 0.40 * var(--headerHeight) );
border-radius: calc( 0.1 * var(--headerHeight) );
padding: calc( 0.16 * var(--headerHeight) );
} 


.Logo_styleBar__3avSA{
width: calc( 0.20 * var(--headerHeight) );
height: calc( 0.07 * var(--headerHeight) );
background-color: var(--themeColor);
position: absolute;
}


.FormInputObjects_name_div__wP-BI{
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
border-style: none;
margin-top: 10px;
position: relative;
width: 100%;
}

.FormInputObjects_name_txt__27Fqg{
width: 100%;
position: relative;
background-color:white;
font-size: 16px;
color: grey;
font-weight: thin;
}

.FormInputObjects_name_inputDiv__LY6xv{
width: 100%;
border-style: none;
margin-top: 5px;
height: 35px;
}


.FormInputObjects_name_inputDiv_Paragraph__2Bti0{
width: 100%;
border-style: none;
margin-top: 5px;
}




.FormInputObjects_input_field__F5cnn  {
position: relative;
width: 100%;
height: 100%;
box-sizing: border-box;
border-style:solid;
border-width: 1px;
border-color: lightgrey;
background-color: white;
border-radius: 5px;
padding-left: 10px;
font-size: 16px;
}


.FormInputObjects_input_field__F5cnn:focus {
    border-style: solid;
    border-width: 1px;    
    outline: none !important;
    border-color: var(--themeColor);
    border-radius:5px;
}

/*
.name_inputDivTimeField{
border-style: solid;
display: flex;
width: 100%;
margin-top: 10px;
border-width: 1px;
border-color: lightgrey;
border-radius: 5px;
height: 35px;
}
*/

.FormInputObjects_name_inputDivTimeField__297Az{
width: 100%;
border-style: none;
margin-top: 5px;
height: 35px;
display: flex;
border-style: solid;
border-width: 1px;
border-color: lightgrey;
border-radius: 5px;
}

.FormInputObjects_name_inputDivTimeField__297Az:focus{
  border-style: solid;
  border-width: 2px;
  border-color: var(--themeColor);
  border-radius:5px;
}




.FormInputObjects_input_field_time__1N6m6  {
position: relative;
width: 100%;
height:35px;
font-size:18px;
box-sizing: border-box;
border-style:solid;
border-width: 1px;
border-color: lightgrey;
background-color: white;
border-radius: 5px;
padding-left: 10px;
border-style: none;
-webkit-appearance: none;
-moz-appearance: none;
text-indent: 1px;
text-overflow: '';
}


.FormInputObjects_input_field_time__1N6m6:focus {
  border-style: none;
  border-width: 2px;
  outline: none !important;
  border-color: #719ECE;
  border-radius:5px;
}



.FormInputObjects_input_field_paragraph__1kinM{
position: relative;
width: 100%;
height: 150px;
box-sizing: border-box;
border-style:solid;
border-width: 1px;
border-color: lightgrey;
background-color: white;
border-radius: 5px;
padding: 10px;
font-size: 20px;
font-style: normal;
font-family: Roboto;
}

.FormInputObjects_input_field_paragraph__1kinM:focus{
border-style: solid;
border-width: 1px;
outline: none !important;
border-color: var(--themeColor);
border-radius:5px;

}



.FormInputObjects_input_field_date__1UYIJ  {
position: relative;
width: 100%;
max-width:300px;
height: 30px;
font-size:20px;
box-sizing: border-box;
}


.FormInputObjects_input_field_date__1UYIJ:focus {
    border-style: none;
    outline: none !important;
    border-color: #719ECE;
    box-shadow: 0 0 10px #719ECE;
    border-radius:3px;
    box-shadow: 0 0 5px rgba(81, 203, 238, 1);
}


.FormInputObjects_inputText_field__3B11j  {
position: relative;
width: 100%;
height: 200px;
font-size:20px;
box-sizing: border-box;
}


.FormInputObjects_inputText_field__3B11j:focus {
    border-style: none;
    outline: none !important;
    border-color: #719ECE;
    box-shadow: 0 0 10px #719ECE;
    border-radius:3px;
    box-shadow: 0 0 5px rgba(81, 203, 238, 1);
}


.FormInputObjects_name_inputDivDayOptions__2MWCp{
border-style: none;
display: flex;
width: 100%;
margin-top: 10px;
}



.FormInputObjects_searchDropDown__3g_cX{
display: flex;
flex-direction: column;
position: absolute;
border-style: none;
border-width: 1px;
box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
width: 100%;
top: 75px;
background-color: white;
z-index:2;
border-radius: 5px;
}


.FormInputObjects_oneSearchOptionButton__3c91-{
width: 100%;
background-color: white;
height: 40px;
border-style: none;
display: flex;
justify-content: flex-start;
align-items: center;
cursor: pointer;
}


.FormInputObjects_oneSearchOptionButton__3c91-:hover{

background-color: var(--cardBorderColor);

}

.FormInputObjects_input_field_searchnInsert__AU05u{
position: relative;
width: 100%;
height: 40px;
font-size:20px;
box-sizing: border-box;
border-style:solid;
border-width: 1px;
border-color: lightgrey;
background-color: white;
border-radius: 5px;
padding-left: 10px;
}


.FormInputObjects_input_field_searchnInsert__AU05u:focus{
    border-style: solid;
    border-width: 2px;
    outline: none !important;
    border-color: #719ECE;
    border-radius:5px;



}



{/*  */}

.FormInputObjects_customTimePicker__2rqVV{
border-style: solid;
height: 33px;
width:100%;
border-width: 1px;
border-color: lightgrey;
border-radius: 5px;
display: flex;
align-items: center;
justify-content: center;
color: grey;
position: relative;
font-size: 16px;
}

/*
position: relative;
width: 100%;
height: 100%;
box-sizing: border-box;
border-style:solid;
border-width: 1px;
border-color: lightgrey;
background-color: white;
border-radius: 5px;
padding-left: 10px;
*/








.FormInputObjects_customTimePicker__2rqVV:hover{

border-color: var(--themeColor)

}

.FormInputObjects_timeDropDownOptions__3Dz5c{
height: 400px;
width: 100%;
background-color: white;
position: absolute;
top: 35px;
z-index: 22;
box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
border-radius: 0px 0px 5px 5px;
display: flex;
visibility: hidden;
}


.FormInputObjects_customTimePicker__2rqVV:hover .FormInputObjects_timeDropDownOptions__3Dz5c{

visibility: visible;
}


.FormInputObjects_hourDiv__29j67{
width: 33%;
height:100%;
border-style: none;
overflow-y: scroll;
overflow-x:hidden;
}

.FormInputObjects_minDiv__1G7Ba{
width:33%;
height:100%;
border-style: none;
overflow-y: scroll;
overflow-x:hidden;
}

.FormInputObjects_ampmDiv__2sMkH{
height:100%;
width: 33%;
border-style: none;
}


.FormInputObjects_oneTimeDropDownBox__3sHa_{
height: 30px;
display: flex;
align-items: center;
justify-content: center;
border-style:none;
width: 100%;
background-color: white;
color: var(--greyTextColor);
cursor: pointer;
}



.FormInputObjects_oneTimeDropDownBox1__1M1oZ{
height: 30px;
display: flex;
align-items: center;
justify-content: center;
border-style:none;
width: 100%;
background-color: white;
color: var(--greyTextColor);
}


.FormInputObjects_name_txt_search__2y4Nu{



}


.FormInputObjects_selectedSpeaker__Mqcpu{
  border-style: none;
  width: 100%;
  color: var(--themeColor);
  margin-top: 5px;
  margin-bottom:20px;
}



.FormInputObjects_searchResultButton__3srCh{
padding: 5px;
border-style: solid;
border-radius: 5px;
border-width: 1px;
border-color: lightgrey;
cursor: pointer;
}

.FormInputObjects_searchResultButton__3srCh:hover{

background-color: var(--themeColor);
color: white;
border-style: none;
}



.FormInputObjects_searchResultButton__3srCh:hover i{
color: white;

}



.SyllabusTopics_syllabusTopics__1A3-D{


position: fixed;
top: 50px;
height: 700px;
min-width: 300px;
width: 80%;
max-width: 1000px;
border-style: none;
background-color: white;
border-radius: 10px;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
margin: auto;
right: 30px;
z-index: 9999;
}


.SyllabusTopics_closeBarButton__2lFH6{

height: 40px;
border-style: solid;

}

.SyllabusTopics_closeButtonDiv___m9o6{
width:100%;
display: flex;
background-color: white;
justify-content: flex-end;
border-radius: 5px;
}



.SyllabusTopics_closeFormButton__1LaMp{
border-style: none;
cursor: pointer;
background-color: white;
border-radius: 5px;
}


.SyllabusTopics_closeButtonIcon__3INbl{
font-size: 25px;
}

.SyllabusTopics_instructionTitle__213px{
  margin: auto;
  border-style: none;
  width: 80%;
  color: var(--themeColor);
}



.SyllabusTopics_syllabusTitle__qVSoc{
width: 90%;
border-style: none;
margin: auto;
color: var(--greyTextColor);
font-size: 20px;
margin-top:20px;
}



{/* Followin design is from sllabys standalone App  */}
.SyllabusTopics_courseSyllabus__3cehv{
width: 85%;
background-color: white;
display: flex;
align-items: center;
flex-direction: column;
align-items: flex-start;
margin-bottom: 200px;
padding-top:20px;
border-style: none;
margin:auto;
margin-top: 20px;
padding: 2.5%;
border-style: none;
border-width: 1px;
border-color: var(--cardBorderColor);
border-radius: var(--cardBorderRadius);
}

.SyllabusTopics_courseSyllabus__3cehv i{
font-style: normal;
font-size: calc( 0.30 * var(--headerHeight) );
color: grey;
display: flex;
}

.SyllabusTopics_courseSyllabusss__1TzBT button{
font-size:calc( 0.30 * var(--headerHeight) );
border-style: none;
background-color: white;
cursor: pointer;
color:var(--TextColorDarkBlue1);
display: flex;
align-items: center;
}

.SyllabusTopics_courseSyllabus__3cehv button:hover{
text-decoration: underline;
}


.SyllabusTopics_ExpandSyllIcon__2pTHl{
font-size:calc( 0.30 * var(--headerHeight) );
padding-left: 5px;
}


.SyllabusTopics_syllabusContent__ZQ6hZ{
position: relative;
width: 100%;
background-color: white;
font-size: calc( 0.30 * var(--headerHeight) );
color: grey;
}


.SyllabusTopics_topicCheck__1GZ6j{
font-size: calc( 0.35 * var(--headerHeight) );
color: green;
}


.SyllabusTopics_chapterNames__2pemw{
display: flex;
flex-direction: column;

}


.SyllabusTopics_completeIcon__1zFFv{
color: green;
}


.SyllabusTopics_numLectures__BwnRz{
color: white;
background-color: red;
float: right;
border-radius: 2px;
padding: 2px;
}

.SyllabusTopics_sectionHead__k6oAN{

border-style: solid;
border-width: 1px;
border-color: lightgrey;
margin: 10px;
}



.SyllabusTopics_sectionlist__3Xjmk{
counter-reset: item
}




ol { counter-reset: item }


li{ 
display: block; 
border-width: 1px;
border-color: lightgrey;
border-style: none;
padding: 10px;

}
li:before { content: counters(item, ".") " "; counter-increment: item }




.SyllabusTopics_topicsList__2TLyw{
display: flex;


}


.SyllabusTopics_sectionEditButton__3GPyO{
border-style: none;
float: right;
background-color: lightgrey;
border-radius: 3px;
color: grey;
padding: 2px;
padding-left: 5px;
padding-right: 5px;
font-size: 15px;
margin-left: 10px;
margin-right: 10px;
}

.SyllabusTopics_sectionEditButton__3GPyO:hover{
cursor: pointer;
}

.SyllabusTopics_editTopicUnit__58WdQ{
border-style: solid;
border-width: 1px;
border-radius: 3px;
background-color: #ebebeb;
border-color: lightgrey;
}



.SyllabusTopics_inputText_field__1gh49{
width: 100%;
height: 100%;

}


.SyllabusTopics_sectionEditBox__mZYF2{
min-height: 100px;
border-style: solid;
width: 100%;
border-style: solid;
border-width: 2px;
border-color:#e0ebff; 
}


.SyllabusTopics_sectionTextArea__3AvDA{
min-height: 100px;
border-style: solid;
width: 100%;
border-style: solid;
border-width: 2px;
border-color:#e0ebff;
padding: 20px;
}



.SyllabusTopics_sectionTextArea__3AvDA:focus {
    border-style: none;
    outline: none !important;
    border-color: #719ECE;
    box-shadow: 0 0 10px #719ECE;
    border-radius:3px;
    box-shadow: 0 0 5px rgba(81, 203, 238, 1);
}



.SyllabusTopics_sectionSaveButton__2-LXK{
border-style: none;
background: var(--themeColor);
color: white;
border-radius: 3px;
padding: 3px;
}

.SyllabusTopics_sectionSaveButton__2-LXK:hover{
cursor: pointer;
text-decoration: none;
}



.SyllabusTopics_deleteChapterButton__3DBp0{
float: right;
border-style: none;
background-color: lightgrey;
padding: 5px;
border-radius: 3px;
font-size: 20px;
color: grey;
cursor: pointer;
margin-left: 10px;
}

.SyllabusTopics_topBarSyllabus__2Ad1a{
color: grey;
display: flex;
}

.SyllabusTopics_topBarSyllabus_button__2UOsL{
border-style: none;
color: white;
background-color: #00aff0;
background-color: var(--themeColor);
padding: 5px;
margin-left: 20px;
border-radius: 3px;
cursor: pointer;
text-decoration: none;

}

.SyllabusTopics_topBarSyllabus_button__2UOsL:hover{
text-decoration: none;
}


.SyllabusTopics_toggleSwitch__u9DL3{
 margin-left: 20px;
 display: flex;
 align-items: center;
}





.SectionIter_oneTopicSelect__2PbLL{
border-style: solid;
border-width: 1px;
border-color:lightgrey;
background-color: var(--bodyBkgColor);
padding: 3px;
margin: 3px;
display: inline;
border-radius: 5px;
cursor: pointer;
}

.SectionIter_oneTopicSelect__2PbLL:hover{

background-color: #c8defa;
border-color: var(--themeColor);
}



.ClassesContainer_parentContainer__3Z16p{

width: 90%;
border-style: none;
margin: auto;
height: 1000px;
margin-top: calc( 0.5 * var(--headerHeight) );
}





.ClassesContainer_daySection__L_tGJ{
min-height: 50px;
border-style: none;
border-width:1px;
border-color: var(--cardBorderColor);
border-radius: var(--cardBorderRadius);
display: flex;
align-items: center;
color: var(--greyTextColor);
align-items: stretch;
margin-bottom: 10px;
}

.ClassesContainer_dayName__3YxB7{
width: 100%;
border-style: none;
margin-left:-10px;
margin-right: 10px;
background-color: var(--bodyBkgColor);
padding-top: 5px;
padding-bottom: 5px;
height: 20px;
margin: auto;
margin-left:-10px;
margin-right: 10px;
}



.ClassesContainer_dayClassContainer__Ww4t2{

width: 90%;
border-style: none;
padding-top: 10px;
border-width: 5px;
border-color: var(--bodyBkgColor);
border-color: white;
border-radius: var(--cardBorderRadius);
display: flex;
flex-direction: column;
justify-content: center;
}


.ClassesContainer_leftDayNameContainer__1-454{
width: 10%;
border-style: none;
border-color: var(--cardBorderColor);
border-width: 1px;
display: flex;
align-items:center;
position: relative;
align-items: stretch;
border-radius: var(--cardBorderRadius);
border-left-style: solid;
}




.ClassesContainer_noClassDiv__WbxBH{
border-style: solid;
border-width: 1px;
border-color: var(--cardBorderColor);
padding: 20px;
border-radius: var(--cardBorderRadius);

}


.ClassesContainer_timeLine__3nyAI{
height: 30px;
width: 100%;
border-style: none;
margin: auto;
align-items: center;
margin: auto;
display: flex;
color: var(--greyTextColor);
margin-top: 20px;
margin-bottom: 10px;
white-space: nowrap;
}


.ClassesContainer_navButton__3_1-R{
border-style: none;
border-width: 1px;
border-color: var(--cardBorderColor);
margin-left: 5px;
margin-right: 5px;
border-radius: 3px;
color: grey;
padding-left:10px;
padding-right: 10px;
display: flex;
align-items: center;
padding-top: 8px;
padding-bottom: 8px;
cursor: pointer;
}

.ClassesContainer_navButton__3_1-R:hover{
background-color: lightgrey;
}

.ClassesContainer_infoText_i__16XaW{
display: flex;
}

.ClassesContainer_dateText__3J-5y{
border-style: none;
display: flex;
align-items: center;
}


.ClassesContainer_classTimeLine__wGtUt{
width: 100%;
height: 1px;
background-color: var(--cardBorderColor);
display: flex;
align-items: center;
justify-content: space-between;
padding-right: 70px;
}

.ClassesContainer_oneClassBall__1r01b{
height: 5px;
width: 5px;
border-radius: 50%;
background-color: grey;
margin-left: 5%;
position: relative
}


.ClassesContainer_oneClassExactTime__1GnIc{
position: absolute;
top: 5px;
}





.ClassesContainer_monitorBoxes__2JkD9{
width: 90%;
border-style: none;
display: flex;
justify-content: space-between;
}


.ClassesContainer_monitorOneBox__3b_mV{
height:100px;
width: 250px;
border-radius: 10px;
background-color: white;
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
border-style: solid;
border-color: lightgrey;
border-width: 1px;
}

.ClassesContainer_boxnum__2vzjD{
font-size: 30px;
}

.ClassesContainer_sessionInfo__32H_I{
margin-bottom: 20px;
color: var(--themeColor);

}










.ClassViewShort_v2_courseViewDashboard__p3dir {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
    border-style: solid;
    border-width: 1px;
    border-radius: var(--cardBorderRadius);
    padding: 15px;
    border-color: lightgrey;
    background-color: white;
    margin-bottom: 10px;
  }
  
  .ClassViewShort_v2_courseViewDashboard__p3dir:hover {
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }
  
  .ClassViewShort_v2_titleDiv__2VYs3 {
    width: 100%;
    border-style: none;
    margin-left: -0%;
    border-width: 1px;
    border-color: lightgrey;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 20px;
  
  }
  
  .ClassViewShort_v2_classInfo__3pUG8 {
    /* background-color: var(--themeColor); */
    color: var(--greyTextColor);
    padding-right: 10px; 
    border-radius: 7px;
    font-size: 14px;
    display: flex;
    border-style: none;
    height: 30px;
    background-color: var(--themeColor);
  }
  
  .ClassViewShort_v2_time__2VOyh {
    margin-left: 5px;
    font-size: 14px;
    font-weight: 600;
    background-color: var(--themeColor);
    color: white;
  }
 


  .ClassViewShort_v2_timeAll__23Lpu{
   margin-left: 5px;
   font-size: 14px;
   font-weight: 600;
  }


  .ClassViewShort_v2_leftInfo__L6xRx {
    display: flex;
    align-items: center;
    border-style: none;
    border-color: green;
    height: 100%;
    width: calc(100% - 170px);
  }
  
  .ClassViewShort_v2_toprightBoxes__2_sz9 {
    display: flex;
    border-style: none;
    margin-right: 0px;
    width: 130px;
    justify-content: space-between;
    position: relative;
  }
 

 @media only screen and (max-width:600px){

    .ClassViewShort_v2_toprightBoxes__2_sz9{
  
       visibility: hidden;	    
  
    }

 }



  .ClassViewShort_v2_classId__3IpRx {
    background-color: white;
    color: var(--themeColor);
    border-radius: 2px;
    padding: 2px;
  }
  
  .ClassViewShort_v2_topicsTitle__fBWp_ {
   color: var(--deepDarkThemeTextColor);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
}
 
  
  .ClassViewShort_v2_classData__FlhNk {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    align-items: center;
    display: flex;
    margin: auto;
    font-size: 12px;
    color: var(--themeColor);
    background-color: var(--themeColor);
    color: white;
  }
 

  .ClassViewShort_v2_classDataAll__gcVll{
  
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    align-items: center;
    display: flex;
    margin: auto;
    font-size: 12px;
    color: var(--themeColor);
  
  }







  .ClassViewShort_v2_clockIcon__3dEe9 {
    height: 16px;
    width: 16px;
    color: var(--themeColor);
  }
  
  .ClassViewShort_v2_dotsButton__3Cn4r {
    float: right;
    border-style: none;
    /* margin-left: 20px; */
    background-color: white;
    cursor: pointer;
    padding: 4px;
    /* border: 1px solid red; */
  }
  
  .ClassViewShort_v2_dotsButton__3Cn4r:hover {
    background-color: var(--bodyBkgColor);
    border-radius: 20%;
  }
  
  .ClassViewShort_v2_middleDiv__aO8CN {
    width: 100%;
    height: 20px;
    display: flex;
    justify-content: space-between;
    border-style: solid;
    border-width: 1px;
    border-color: red;
    margin: auto;
  }
  
  .ClassViewShort_v2_authorDiv__3Bv7f {
    border-style: none;
    align-items: center;
    color: var(--lightGreyTintTextColor);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 20px;
    width: 80%;
    font-size: 14px;
    margin-top: 10px;
    display: flex;
    align-items: center;
  }
  
  
  .ClassViewShort_v2_addressDiv__2YTho {
    margin-top: 10px;
    border-style: none;
    border-width: 1px;
    color: var(--lightGreyTintTextColor);
    height: 20px;
    align-items: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 80%;
  }
  
  .ClassViewShort_v2_redirButtons__34LRu {
    display: flex;
    justify-content: right;
    border-style: none;
    float: right;
    align-items: center;
    border-color: yellow;
  }
  
  .ClassViewShort_v2_joinClassButton__2RpJZ {
    margin-top: 10px;
    height: 80%;
    border-style: none;
    border-radius: 2px;
    padding-left: 15px;
    padding-right: 15px;
    /* background-color: #d67b1a;
      background-color: orange; */
    color: white;
    width: 150px;
    /* border-radius: var(--cardBorderRadius); */
    cursor: pointer;
    font-family: Roboto;
    border-radius: 6px;
    background-color: transparent;
    color: #4c8bf5;
    border-radius: 4px;
    border: 1px solid #4c8bf5;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: orange;
    color: white;
    border-color:white;
    color: white;
    border-radius: 7px;
    font-weight: bold;
  }
  
 
  .ClassViewShort_v2_joinClassButton__2RpJZ:hover{
  
   box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  }


  .ClassViewShort_v2_joinText__Poc7d {
    padding-left: 10px;
  }
  
  .ClassViewShort_v2_viewClassButton__3mUAO {
    margin-top: 10px;
    height: 70%;
    margin-left: 10%;
    border-style: none;
    align-items: center;
    display: flex;
    width: 120px;
    cursor: pointer;
    /* border-radius: var(--cardBorderRadius); */
    border-radius: 4px;
    padding-left: 15px;
    color: var(--tintGreyColor1);
    font-family: Roboto;
    border: 1px sol id var(--cardBorderColor);
  }
  
  .ClassViewShort_v2_viewClassButton__3mUAO:hover {
    background-color: white;
    border: 1px solid var(--cardBorderColor);
  }
  
  .ClassViewShort_v2_viewdetailIcon__27ckc {
    margin-left: 10px;
  }
  
  .ClassViewShort_v2_classAddress__3rafn {
    color: var(--tintGreyColor1);
    border-style: none;
    display: flex;
    align-items: center;
  }
  
  .ClassViewShort_v2_lowerDiv__3p-1Z {
    margin-top: 10px;
    height: 40px;
    border-style: none;
    width: 100%;
    border-width: 1px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border: 1px solid blue; */
  }
  
  .ClassViewShort_v2_leftLowerDiv__1G-95 {
    height: 100%;
    width: calc(100% - 300px);
    /* border: 1px solid red; */
  }
  
  .ClassViewShort_v2_rightLowerDiv__2IhoP {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    /* border: 1px solid green; */
  }
  
  .ClassViewShort_v2_classStatus__35qLb {
    color: green;
    border-style: none;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
  }
  
  .ClassViewShort_v2_todayTopic__35p_q {
    font-size: 14px;
    color: var(--greyTextColor);
    font-weight: 600;
    margin-left:10px;
  }
  
  .ClassViewShort_v2_todayTopicDetail__dWixR {
    padding-left: 10px;
    font-size: 14px;
  }
  
  .ClassViewShort_v2_classTime__2tu6b {
    margin-right: 10px;
    border-width: 1px;
    padding: 5px;
    border-radius: 5px;
    border-color: var(--tintGreyColor2);
    color: var(--themeColor);
    color: var(--lightGreyTintTextColor);
    font-size: 14px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500;
  }
  
  .ClassViewShort_v2_classNum__qe-yY {
    margin-left: 10px;
    border-style: none;
    color: var(--deepDarkThemeTextColor);
    /* color: var(--themeColor); */
    padding: 5px;
    font-size: 14px;
    background-color: var(--bodyBkgColor);
    border-radius: 16%;
    color: grey;
  }



.ClassViewShort_v2_rightDotsDiv__3YrBz{


border-style: none;


}


.ClassViewShort_v2_dateText__2OmX5{
background-color: var(--themeColor);
color: white;
padding: 15px;
height: 30px;

}



.ClassViewShort_v2_minduration__2a3xX{
background-color: white;
border-radius: 5px;
padding-left: 5px;
padding-right: 5px;
}



.ClassViewShort_v2_studentNo__243Vv{
color: white;
background-color: #50C878;
border-radius: 5px;
height: 15px;
min-width: 15px;
border-style: none;
border-color: black;
display: inline-block;
font-weight: bold;
padding: 5px;
align-items: center;
display: flex;
justify-content: center;
margin-left: 10px;
}


.ClassViewShort_v2_paymentStatus__2xq1O{
margin-left: 10px;
}


.CourseViewDashboard_v2_courseViewDashboard__SU76g{
border-width: 1px;
background-color: white;
border-radius: var(--cardBorderRadius);
margin-bottom: 0px;
border-style: solid;
border-color: var(--headerRightIconsColor);
border-color: #e0ebff;
/*box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;*/
border-color: var(--tintGreyColor3);
}


.CourseViewDashboard_v2_courseViewDashboard__SU76g:hover{
box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}





.CourseViewDashboard_v2_courseViewDashboard__SU76g:hover .CourseViewDashboard_v2_courseNameButton__1eH4E{

color: var(--themeColor);

}


.CourseViewDashboard_v2_courseViewDashboard__SU76g:hover .CourseViewDashboard_v2_arrowButton__oZ6Em{
color: var(--themeColor);

}


.CourseViewDashboard_v2_courseViewDashboard__SU76g:hover .CourseViewDashboard_v2_enrolldisplayButton1__40jvB{
visibility: visible;
}


.CourseViewDashboard_v2_courseViewDashboard__SU76g:hover .CourseViewDashboard_v2_courseGoToButton__242Id{
visibility: visible;
}



.CourseViewDashboard_v2_innerMarginDiv__1EXFD{
border-style: none;
margin: calc( 0.5 * var(--headerHeight) );
}



.CourseViewDashboard_v2_upperPart__3iRN9{
height: calc( 2 * var(--headerHeight) );
width: 100%;
border-style: none;
border-width: 1px;
display: flex;
}


.CourseViewDashboard_v2_upperInfoDiv__3-aa_{
background-color: white;
height: calc( 3 * var(--headerHeight) );
width: calc( 100% - calc( 2.3 * var(--headerHeight) ));
margin-left: calc( 0.3 * var(--headerHeight) );
border-style: none;
display: flex;
flex-direction: column;
justify-content: flex-start;
}



.CourseViewDashboard_v2_picDiv__1WeL5{
height: calc( 2 * var(--headerHeight) );
width: calc( 2 * var(--headerHeight) );
background-color:white;
border-radius: 5px;
border-style: none;
position: relative;
}


.CourseViewDashboard_v2_picStyle__26Xpl{
width: calc( 2 * var(--headerHeight) );
height: calc( 2 * var(--headerHeight) );
border-radius: 5px;
}







.CourseViewDashboard_v2_topBar1__2tGhv{
border-style: none;
color: grey;
display: flex;
justify-content: space-between;
}

.CourseViewDashboard_v2_topBar1_left__2XheM{
display: flex;
width: 50%;
border-style: none;
justify-content: space-between;
font-size: calc( 0.25 * var(--headerHeight) );
}





.CourseViewDashboard_v2_topBar1_right__2XbCk{
display: flex;
border-style: none;
font-size: calc( 0.25 * var(--headerHeight) );
}


.CourseViewDashboard_v2_courseCode__z6eND{

background-color:#dcf7ea;
padding: calc( 0.05 * var(--headerHeight) );
border-radius:calc( 0.05 * var(--headerHeight) );
color: #65c394;
font-size: calc( 0.25 * var(--headerHeight) );
}

.CourseViewDashboard_v2_notification__5RTN6{
margin-left:calc( 0.20 * var(--headerHeight) );
margin-right:calc( 0.20 * var(--headerHeight) );
border-style: none;
display: flex;
flex-direction: column;
justify-content: center;
}

.CourseViewDashboard_v2_notIcon__BIr6s{
font-size: calc( 0.30 * var(--headerHeight) );

}



.CourseViewDashboard_v2_dotsButton__e7yi1{
border-style: none;
background-color: white;
cursor: pointer;
display: flex;
justify-content: center;
flex-direction: column;
position: relative;
font-size: calc( 0.25 * var(--headerHeight) );

}

.CourseViewDashboard_v2_verticalDotsIcon__2D_aT{
font-size: calc( 0.25 * var(--headerHeight) );
}




.CourseViewDashboard_v2_courseNameDiv__RZidm{
font-size: calc( 0.25 * var(--headerHeight) );
display: flex;
justify-content: space-between;
}

.CourseViewDashboard_v2_courseNameDiv_left__1sQ6g{
display: flex;
border-style: none;
width: 80%;
}


.CourseViewDashboard_v2_courseNameButton__1eH4E{
max-width: 100%;
border-style: none;
background-color: white;
font-size: calc( 0.30 * var(--headerHeight) );
cursor: pointer;
padding: 0px;
font-family: Roboto;
color: var(--deepDarkThemeTextColor);
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
}


.CourseViewDashboard_v2_arrowButton__oZ6Em{
border-style: none;
border-width: 1px;
background-color: white;
font-size: calc( 0.30 * var(--headerHeight) );
cursor: pointer;
padding: 0px;
font-family: Roboto;
color: var(--deepDarkThemeTextColor);
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
padding-left: 5%;
padding-right: 2%;
color: grey;
display: flex;
flex-direction: column;
justify-content: center;

}



.CourseViewDashboard_v2_middlePart__2JiQw{
border-style: none;
width: 100%;
border-width: 1px;
border-color: orange;
display: flex;
justify-content: space-between;
}


.CourseViewDashboard_v2_middlePart_left__2_Iza{
width: 100%;
height: 100%;
border-style: none;
border-width: 1px;
border-radius: 5px;
color: grey;
display: flex;
flex-direction: column;
justify-content: center;
font-size: calc( 0.25 * var(--headerHeight) );
}


.CourseViewDashboard_v2_middlePart_middle__36C-8{
height: 100%;
border-style: none;
border-width: 1px;

}






.CourseViewDashboard_v2_middlePart_right__yiYm3{
width: calc( 1.0 * var(--headerHeight) );
height: 100%;
border-style: none;
border-width: 1px;
display: flex;
flex-direction: column;
justify-content: center;

}


.CourseViewDashboard_v2_goToCourseButton__sOL0j{
height: 100%;
border-style: none;
color: grey;
cursor: pointer;
height: calc( 0.25 * var(--headerHeight) );
flex-shrink: 0;
}



.CourseViewDashboard_v2_goToCourseBtnIcon__1Y6JS{
font-size:calc( 0.25 * var(--headerHeight) );
flex-shrink: 0;
border-style: none;
border-width: 1px;
}

.CourseViewDashboard_v2_InfoDivLowest__137Je{
border-style: none;
height: 100%;
display: flex;
justify-content: space-between;
}

.CourseViewDashboard_v2_InfoDivLowest_left__1Ccgy{
height: 100%;
border-style: none;
width: calc( 3 * var(--headerHeight) );
display: flex;
flex-direction: column;
justify-content: flex-end;

}


.CourseViewDashboard_v2_courseGoToButton__242Id{

height: calc( 0.5 * var(--headerHeight) );
background-color: var(--greenColor1);
color: white;
border-style: none;
border-radius: calc( 0.1 * var(--headerHeight) );
font-size: calc( 0.30 * var(--headerHeight) );
margin-bottom: calc( 0.35 * var(--headerHeight) );
cursor: pointer;
visibility: hidden;
background-color: var(--themeColor);
/*background-color:white;
color: var(--themeColor);
border-style: solid;
border-width: 1px;
border-color:var(--themeColor);*/
}





.CourseViewDashboard_v2_courseGoToButton2__2qZjA{

height: calc( 0.5 * var(--headerHeight) );
background-color: var(--greenColor1);
color: white;
border-style: none;
border-radius: calc( 0.1 * var(--headerHeight) );
font-size: calc( 0.30 * var(--headerHeight) );
margin-bottom: calc( 0.35 * var(--headerHeight) );
cursor: pointer;
background-color: var(--themeColor);
/*background-color:white;
color: var(--themeColor);
border-style: solid;
border-width: 1px;
border-color:var(--themeColor);*/
}















.CourseViewDashboard_v2_InfoDivLowest_right__13qYL{
height: calc(2 * var(--headerHeight) );
border-style: none;
width: calc( 2 * var(--headerHeight) );
display: flex;
flex-direction: column;
justify-content: space-between;
border-left: 1px solid var(--tintGreyColor3);
padding-left: 10px;
}








.CourseViewDashboard_v2_lowerPart__NKvrr{
height: calc( 1 * var(--headerHeight) );
width: calc( 2 * var( --headerHeight) );
border-style: none;
border-width: 1px;
display: flex;
justify-content: space-between;
}

.CourseViewDashboard_v2_lowerPartTitle__gM0Ad{
color: var(--tintGreyColor1);
font-size: calc( 0.2 * var(--headerHeight) );
}


.CourseViewDashboard_v2_lowerPart_left__1Rmv9{
border-style: none;
height: 100%;
width: calc( 2 * var(--headerHeight) ) ;
display: flex;
flex-direction: column;
justify-content: center;
border-color: red;
}


.CourseViewDashboard_v2_lowerPartInfo1__2s9es{
margin-top: calc( 0.2 * var( --headerHeight) );	
width: 100%;
}



.CourseViewDashboard_v2_progressBar__1VGLi{
height:calc( 0.2 * var(--headerHeight) );
width: 100%;
background-color: #F0F0F0;
border-radius:5px;

}

.CourseViewDashboard_v2_progressWidth__XhTM_{
height:calc( 0.2 * var(--headerHeight) );
width:30%;
background-color: #AFE1AF;
border-radius:calc( 0.2 * var(--headerHeight) );
}




.CourseViewDashboard_v2_lowerPart_middle__3l79-{
height: calc( 1 * var(--headerHeight) );
border-style: none;
border-width: 2px;
display: flex;
justify-content: center;
width: calc( 3.0 * var(--headerHeight) );
display: flex;
flex-direction: column;
}






.CourseViewDashboard_v2_oneTool__38etM{
height: calc( 0.6 * var(--headerHeight) );
width: calc( 0.6 * var(--headerHeight) );
border-radius: calc( 0.2 * var(--headerHeight) );
border-style: solid;
border-color: lightgrey;
border-width: calc( 0.05 * var(--headerHeight) );
display: flex;
justify-content: center;
align-items: center;
color: lightgrey;
cursor: pointer;
font-size:calc( 0.3 * var(--headerHeight) );
}

.CourseViewDashboard_v2_oneTool__38etM:hover{
color: grey;
border-color:var(--tintGreyColor1);
}


.CourseViewDashboard_v2_lowerPartInfo2__33jbz{
display: flex;
width: 100%;
border-style: none;
justify-content: space-between;
height: calc( 1 * var(--headerHeight) );
align-items: center;
}




.CourseViewDashboard_v2_lowerPart_right__2v_AY{
border-style: solid;
width: calc( 2.5 * var(--headerHeight) );
display: flex;
flex-direction: column;
justify-content: center;
}



.CourseViewDashboard_v2_lowerPartInfo3__3fCs0{
color: var(--tintGreyColor1);
width: 50%;
border-style: none;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
font-size: calc( 0.25 * var(--headerHeight) );
margin-top: calc( 0.1 * var(--headerHeight) );
padding-bottom: calc( 0.1 * var(--headerHeight) );
}

























.CourseViewDashboard_v2_dropdownButtons__39Cy6{
width: 100px;
position: absolute;
border-style: solid;
z-index: 9999;
right: 2px;
top: 25px;
background-color: white;
border-width: 1px;
border-color: lightgrey;
border-radius: 3px;
display: flex;
flex-direction: column;
}

.CourseViewDashboard_v2_dropdownButton__3cRKR{

height: 35px;
border-style: none;
cursor: pointer;
}


.CourseViewDashboard_v2_dropdownButton__3cRKR:hover{

background-color: lightgrey;

}



.CourseViewDashboard_v2_upcomingClassTime__1IDmC{
margin-top: calc( 0.1 * var(--headerHeight) );
font-size: calc( 0.25 * var(--headerHeight) );
color: var(--tintGreyColor1);
}

.CourseViewDashboard_v2_upcomingClassTime__1IDmC span{
font-size: calc( 0.25 * var(--headerHeight) );
color: var(--tintGreyColor1);
}

.CourseViewDashboard_v2_courseAssociation__3FJR8{
margin-top: calc( 0.1 * var(--headerHeight) );
font-size: calc( 0.25 * var(--headerHeight) );
color: var(--themeColor);
border-style: solid;
border-width: 1px;
border-radius: 5px;
width:60px;
font-size: 10px;
padding: 3px;
}





.CourseViewDashboard_v2_enrolldisplayButton1__40jvB{

height: calc( 0.5 * var(--headerHeight) );
background-color: var(--greenColor1);
color: white;
border-style: none;
border-radius: calc( 0.1 * var(--headerHeight) );
font-size: calc( 0.30 * var(--headerHeight) );
margin-bottom: calc( 0.35 * var(--headerHeight) );
cursor: pointer;
visibility: hidden;
background-color: var(--themeColor);
background-color:white;
color: var(--themeColor);
border-style: solid;
border-width: 1px;
border-color:var(--themeColor);
}


.CourseViewDashboard_v2_uploadCourseImageButton__3y3Su{

position: absolute;
left:calc( 0.6 * var(--headerHeight) );
top: calc( 0.6 * var(--headerHeight) );
font-size: calc( 0.6 * var(--headerHeight) );
cursor: pointer;
visibility: hidden;
background: white;
border-style: none;
opacity:0.7;
border-radius: 5px;
}


.CourseViewDashboard_v2_picDiv__1WeL5:hover .CourseViewDashboard_v2_uploadCourseImageButton__3y3Su{
visibility: visible;
}

.CourseViewDashboard_v2_picDiv__1WeL5:hover{
cursor: inherit;
}




.ClassViewShort_ht_courseViewDashboard__3bE89{


box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
    border-style: solid;
    border-width: 1px;
    border-radius: var(--cardBorderRadius);
    padding: 15px;
    border-color: lightgrey;
    background-color: white;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
}


.ClassViewShort_ht_time__uo04v{
font-weight: bold;
color: var(--themeColor);
height: 30px;
}


.ClassViewShort_ht_duration__2XSiL{

color: grey;
border-style: solid;
border-width: 2px;
color: var(--themeColor);
border-radius:5px; 
padding: 5px;
}


.ClassViewShort_ht_date__CpEhW{
color: grey;
font-weight: bold;
}



.ClassViewShort_ht_imageTag__5gB7s{
height: 30px;
width: 30px;
border-radius: 5px;
}


.ClassViewShort_ht_joinButton__1FVWT{

height: 40px;
width: 150px;
background-color: #C35214;
border-style: none;
border-radius: 10px;
font-weight: bold;
color: white;
cursor: pointer;
background-color:green;
}


.ClassViewShort_ht_joinButtonComplete__5Ejm1{
height: 40px;
width: 150px;
background-color: #C35214;
border-style: none;
border-radius: 10px;
font-weight: bold;
color: white;
cursor: pointer;
background-color:lightgrey;

}




.ClassViewShort_ht_nameOfperson__3V69M{
  font-weight: bold;
}


.ClassViewShort_ht_timeDiv2__27aan{

  border-style: none;
  display: flex;
  flex-direction: column;

}

.ClassViewShort_ht_examName__39pgz{

margin-left: 10px;

}





@media only screen and (max-width: 700px) {

.ClassViewShort_ht_courseViewDashboard__3bE89{
flex-direction: column;
justify-content: center;
align-items:center;
}

.ClassViewShort_ht_personDiv__2z1xB{

display: flex;
margin-bottom: 20px;
}


.ClassViewShort_ht_timeDiv__8K4eI{
display: flex;
justify-content: space-between;
margin-bottom: 10px;
}

.ClassViewShort_ht_date__CpEhW{
display: flex;
}

.ClassViewShort_ht_timeDiv2__27aan{
flex-direction: row;
margin-bottom: 10px;
}

.ClassViewShort_ht_examName__39pgz{
margin-left:10px;
}


}








.AccountingContentDiv_contentDiv__2Xc31{
position: relative;
background-color: var(--bodyBkgColor);
width: 100%;
border-style: none;
border-color: orange;
height: 100%;
overflow-y: auto;
overflow-x:hidden;
}


.AccountingContentDiv_createSlotButton__2vAGb{

height: 40px;
margin-left: 30px;
margin-top: 30px;
width: 150px;
border-radius: 5px;
border-color: lightgrey;
border-style: solid;
border-width: 1px;
background-color: white;
color: var(--themeColor);
cursor: pointer;
}



.AccountingContentDiv_slotsContainer__1yVUd{

border-style: none;
width: 100%;
height: 2000px;
margin-top:30px;
}


.AccountingContentDiv_buttonContainer__4noqG{
width: 100%;
border-style: none;
}

.AccountingContentDiv_slotsTitle__375uT{
border-style: none;	
font-size: 20px;
max-width: 500px;
margin: auto;
margin-bottom: 30px;

}




.AccountingContentDiv_monitorBoxes__NR99N{
width: 90%;
border-style: none;
display: flex;
justify-content: space-between;
margin: auto;
margin-top: 50px;
}


.AccountingContentDiv_monitorOneBox__2688N{
height:70px;
width: 250px;
border-radius: 10px;
background-color: white;
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
border-style: solid;
border-color: lightgrey;
border-width: 1px;
}

.AccountingContentDiv_boxnum__3HqR9{
font-size: 18px;
display: flex;
align-items: center;
}








.AccountingContentDiv_setPriceBox__3tx66{
width: 90%;
border-style: none;
display: flex;
justify-content: flex-start;
margin: auto;
margin-top: 50px;

}

.AccountingContentDiv_priceAmount__2MDIG{
margin-left: 10px;
font-weight: bold;
color: var(--themeColor);
}


.AccountingContentDiv_BankDetails__jjbIP{
width: 90%;
border-style: solid;
display: flex;
justify-content: flex-start;
margin: auto;
margin-top: 50px;
border-width: 1px;
border-color: lightgrey;
border-radius: 10px;
flex-direction: column;
}


.AccountingContentDiv_infoDivBank__1SDUS{
border-bottom-style: solid;
border-width: 1px;
border-color: lightgrey;
height: 50px;
display: flex;
justify-content: flex-start;
align-items: center;
padding-left: 20px;

}


.AccountingContentDiv_bankTitle__J0mAg{
width: 200px;
}


.AccountingContentDiv_setPriceBox__3tx66{
height: 40px;
border-bottom-style: solid;
border-width: 1px;
border-color: lightgrey;
display: flex;
align-items:center;
}


.AccountingContentDiv_editPriceButton__3uUqk{
margin-left: 10px;
border-style: solid;
border-color: lightgrey;
border-width: 1px;
border-radius: 5px;
padding: 5px;
padding-left:10px;
padding-right: 10px;
cursor: pointer;
width: 100px;
}


.AccountingContentDiv_editPriceButton__3uUqk:hover{


}



.AccountingContentDiv_priceInputBox__32Ic9{

border-color: var(--themeColor)

}


.AccountingContentDiv_value_field__3nZYX{

height: 25px;
border-style: solid;
border-color: lightgrey;
font-size: 16px;
border-radius: 4px;
padding-left: 10px;
width: 120px;
}


.AccountingContentDiv_value_field__3nZYX:focus{
border-color: var(--themeColor);
outline: none !important;
color: var(--themeColor);
}


.AccountingContentDiv_boxtitle__3MK58{
font-size: 15px;
}


.AccountingContentDiv_editBankDetailButton__E0HF1{

width: 100px;
border-style: solid;
border-width: 1px;
border-color: lightgrey;
margin-top:10px;
margin-bottom:10px;
margin-left: 10px;
height: 30px;
border-radius: 5px;
cursor: pointer;
}












.AvailabilityContentDiv_contentDiv__2635K{
position: relative;
background-color: var(--bodyBkgColor);
width: 100%;
border-color: orange;
height: 100%;
overflow-y: auto;
overflow-x:hidden;
}


.AvailabilityContentDiv_createSlotButton__2Kvf-{

height: 40px;
margin-left: 30px;
margin-top: 30px;
width: 150px;
border-radius: 5px;
border-color: lightgrey;
border-style: solid;
border-width: 1px;
background-color: white;
color: var(--themeColor);
cursor: pointer;
}



.AvailabilityContentDiv_slotsContainer__2jv4C{

border-style: none;
width: 100%;
height: 2000px;
margin-top:30px;
}


.AvailabilityContentDiv_buttonContainer__2nCAX{
width: 100%;
border-style: none;
}

.AvailabilityContentDiv_slotsTitle__13gE7{
border-style: none;	
font-size: 20px;
max-width: 500px;
margin: auto;
margin-bottom: 30px;

}


.AvailabilityContentDiv_createSlotDiv__3CZ4A{
border-style: none;
display: flex;
}

.AvailabilityContentDiv_createSlotButton__2Kvf-{

}








.Classes_classes__wvUP5{


width: 100%;
margin: auto;
margin-top: 20px;
}


.Classes_switchBar__1wr_u{
height: calc( 0.7 * var(--headerHeight) );
width:90%;
margin: auto;
border-color: lightgrey;
border-style: none;
display: flex;
justify-content: space-between;
}

.Classes_downArrow__3H9r5{

border-style: none;
display: flex;
font-size: 12px;
margin-left: 20px;
}


.Classes_timeLine__2ZWe5{
height: 30px;
width: 90%;
border-style: none;
margin: auto;
align-items: center;
margin: auto;
display: flex;
color: var(--greyTextColor);
margin-top: 20px;
white-space: nowrap;
}


.Classes_dummyDiv__2EqAs{
position: relative;
}


.Classes_dropDownDiv__3_cnD{
border-style:solid;
background-color: white;
border-width: 1px;
border-color: lightgrey;
border-radius: 0px 0px calc( 0.6 * var(--cardBorderRadius) )  calc( 0.6 * var(--cardBorderRadius) );
position: absolute;
width: 100%;
z-index: 1000000;
padding-bottom: 20px;
}


.Classes_dropDownButton__2sUIn{
width: 100%;
background-color: white;
height: calc( 0.7 * var(--headerHeight) );
display: flex;
justify-content: flex-start;
align-items: center;
cursor: pointer;
border-style: none;

}


.Classes_dropDownButton__2sUIn span{

margin-left: 15px;
}


.Classes_dropDownButton__2sUIn:hover{

background-color: var(--cardBorderColor);
}



.Classes_navButton__3H2tw{
border-style: none;
border-width: 1px;
border-color: var(--cardBorderColor);
margin-left: 5px;
margin-right: 5px;
border-radius: 3px;
color: grey;
padding-left:10px;
padding-right: 10px;
display: flex;
align-items: center;
padding-top: 8px;
padding-bottom: 8px;
}

.Classes_navButton__3H2tw:hover{
background-color: lightgrey;
}



.Classes_infoText_i__1N3G-{
display: flex;
}


.Classes_classTimeLine__2jF-G{
width: 100%;
height: 1px;
background-color: var(--cardBorderColor);
display: flex;
align-items: center;
justify-content: space-between;
padding-right: 70px;
}


.Classes_oneClassBall__2bgj9{
height: 5px;
width: 5px;
border-radius: 50%;
background-color: grey;
margin-left: 5%;
position: relative
}


.Classes_oneClassExactTime__39xGu{
position: absolute;
top: 5px;
}



.Classes_dateText__13WYg{
border-style: none;
display: flex;
align-items: center;
}






.CreateClassForm_createTicketFormDivParent__1GX0H{

position: fixed;
width: 100vw;
height:100vh;
background-color: grey;
z-index: 10000;
right:0;
top:0;
background-color:rgba(0,0,0,0.1); 
display: flex;
align-items: center;
justify-content: center;
}

.CreateClassForm_createTicketForm__29jNY{

position: relative;
width: 60vw;
min-width:350px;
max-width: 700px;
background-color: white;
border-radius: var(--cardBorderRadius);
border-style:none;
border-color: grey;
border-width:2px;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
/*display: flex;
flex-direction: column;*/
align-items: center;
overflow-y: scroll;
overflow-x: hidden;
}



.CreateClassForm_createTicketFormLoading__HM5cT{

position: relative;
width: 60vw;
height:80vh;
min-width:300px;
background-color: white;
border-radius: 5px;
border-style:none;
border-color: grey;
border-width:2px;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}


.CreateClassForm_submittingDiv__1XDAZ{
padding-top: 100px;	
color: var(--themeColor);

}









.CreateClassForm_closeButtonDiv__24SIE{
width:100%;
display: flex;
background-color: white;
justify-content: flex-end;
border-radius: 5px;
}



.CreateClassForm_closeFormButton__1MMBv{
border-style: none;
cursor: pointer;
background-color: white;
border-radius: 5px;
}


.CreateClassForm_closeButtonIcon__3zW9x{

font-size: 25px;

}


.CreateClassForm_logoAndTitleContainer__3EQL1{


width:80%;
background-color: white;
display: flex;
flex-direction: column;
align-items: center;
margin: auto;
border-style: none;
margin-bottom: 30px;
}

.CreateClassForm_bwLogo__SkbF6{

height: 75px;
width: 75px;

}

.CreateClassForm_formTitleDiv__26zY_{
width: 100%;
display:flex;
justify-content:flex-start;
color: #585858;
margin-top: 20px;
}

.CreateClassForm_formTitleDiv__26zY_ i{

font-size: 25px;
font-style: normal;
}



.CreateClassForm_submitButtonDiv__nPTgt{
width: 100%;	
display: flex;
justify-content: center;
background-color: white;
padding-top: 50px;
margin-bottom: 50px;
border-style: none;
}


.CreateClassForm_submit_button__3gb19{
width: 300px;
border-style: none;
background-color: var(--themeColor);
color: white;
height:50px;
width:200px;
border-radius: 10px;
cursor: pointer;
font-size: 18px;
}


.CreateClassForm_emptyDiv__fBMJH{
width: 80%;
border-style: solid;
height: 50px;
color: white;

}




.CreateClassForm_singleNMultiFormOption__2CAkr{

height: 40px;
width: 80%;
display: flex;
justify-content: space-between;
margin-top: 0px;
border-bottom: none;
border-color: lightgrey;
border-width: 1px;
margin: auto;
}
.CreateClassForm_singleClass__1XtsM{
width: 50%;
height: 40px;
border-style: none;
display: flex;
align-items: center;
justify-content: center;
color: grey;
border-radius: var(--cardBorderRadius) 0px 0px var(--cardBorderRadius);
}

.CreateClassForm_singleClass__1XtsM:hover{
cursor: pointer;
}

.CreateClassForm_multiClass__1ad1b{
width: 50%;
height:40px;
border-style: none;
border-width: 1px;
border-color: lightgrey;
font-family: Roboto;
display: flex;
align-items: center;
justify-content: center;
color: grey;
border-radius: 0px var(--cardBorderRadius) var(--cardBorderRadius) 0px;
}

.CreateClassForm_multiClass__1ad1b:hover{
cursor:pointer;
}



.CreateClassForm_noCourseWarning__3Ojt5{
font-style: normal;
color: grey;
font-size: 20px;
margin: auto;
border-style: none;
width: 80%;
}

.CreateClassForm_formElement1__23a8h{
width: 80%;
margin: auto;
border-style: none;
margin-top: 30px;
}



.CreateClassForm_multiClassInfo__17ycM{
margin: 20px;
color: grey;
font-size: 20px;
}

.CreateClassForm_multiContainer__3DwRr{
width: 100%;
display: grid;
grid-template-columns: repeat(auto-fit, minmax(150px, 1fr) );
grid-auto-rows: 100px ;
border-style: none;
grid-column-gap: 50px;
grid-row-gap: 20px;
border-color: red;
margin: auto;

}


.CreateClassForm_dayFieldContainer__1iA6y{
display: grid;
grid-row-gap: 20px;

}



.CreateClassForm_customTimePicker__3Uxv4{

border-style: solid;
height: 40px;
width: 200px;
border-width: 1px;
border-color: lightgrey;
border-radius: 5px;
display: flex;
align-items: center;
justify-content: center;
color: grey;
position: relative;
}


.CreateClassForm_customTimePicker__3Uxv4:hover{

border-color: var(--themeColor)

}

.CreateClassForm_timeDropDownOptions__3LbO4{
height: 400px;
width: 100%;
background-color: white;
position: absolute;
top: 40px;
z-index: 22;
box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
border-radius: 0px 0px 5px 5px;
display: flex;
visibility: hidden;
}


.CreateClassForm_customTimePicker__3Uxv4:hover .CreateClassForm_timeDropDownOptions__3LbO4{

visibility: visible;
}






.CreateClassForm_hourDiv__2IMeq{
width: 33%;
height:100%;
border-style: none;
overflow-y: scroll;
overflow-x:hidden;
}

.CreateClassForm_minDiv__39Mss{
width:33%;
height:100%;
border-style: none;
overflow-y: scroll;
overflow-x:hidden;
}

.CreateClassForm_ampmDiv__2A5u2{
height:100%;
width: 33%;
border-style: none;
}


.CreateClassForm_oneTimeDropDownBox__1ouz4{
height: 30px;
display: flex;
align-items: center;
justify-content: center;
border-style:none;
width: 100%;
background-color: white;
color: var(--greyTextColor);
cursor: pointer;
}



.CreateClassForm_oneTimeDropDownBox1__a-iva{
height: 30px;
display: flex;
align-items: center;
justify-content: center;
border-style:none;
width: 100%;
background-color: white;
color: var(--greyTextColor);
}





.CreateClassForm_oneTimeDropDownBox__1ouz4:hover{
background-color: lightgrey;
color: grey;
}


.CreateClassForm_firstFieldBlock__2A7W3{

width: 100%;
border-style: none;
display: flex;
justify-content: space-between;
margin-bottom: 20px;
}

.CreateClassForm_firstFieldBlockSmall__5dea1{
width: 200px;
border-style: none;
display: flex;
justify-content: space-between;
margin-top: 20px;
}



.CreateClassForm_firstFieldBlockLong__19LUl{
width: 100%;
border-style: none;
display: flex;
margin-top:20px;
}


.CreateClassForm_optionalSettings__2NaEA{

height: 2px;
margin-top:50px;
display: flex;
justify-content: space-between;
border-style: none;
position: relative;
height: 30px;
align-items: center;
width: 100%;
cursor: pointer;
background-color: white;
}




.CreateClassForm_optionSettingDiv__1DYK9{
position: relative;
background-color: white;
border-style: none;
color: var(--themeColor);
}


.CreateClassForm_optionSettingButton__1bdu8{
position: relative;
background-color: white;
border-style: none;
color: var(--themeColor);
}



.CreateClassForm_chooseTopicForClassDiv__2TphZ{

width:100%;
max-width:300px;
border-style: none;
height: 30px;
border-radius: 5px;
height: 33px;
cursor: pointer;
margin-top: 35px;
color: var(--greyTextColor);
font-family: Roboto;
}


.CreateClassForm_selTopicsParentDiv__mohux{
margin-top:20px;

}

.CreateClassForm_selectedTopicDiv__2KRXv{

color: var(--themeColor);
}

.CreateClassForm_selTopicTitle__4mYul{
 margin-bottom: 15px;
 color: grey;
}



.CreateClassForm_optionalSettingDiv__2RP5k{
display: flex;
justify-content: space-between;
z-index: 1;
}


.CreateClassForm_noTopicsSelMessage__3XLPT{

font-size: 13px;
margin-left: 20px;
color: red;
}





.SyllabusTopics_syllabusTopics__3sUcC{


position: fixed;
top: 50px;
height: 700px;
min-width: 300px;
width: 80%;
max-width: 1000px;
border-style: none;
background-color: white;
border-radius: 10px;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
margin: auto;
right: 30px;
z-index: 9999;
}


.SyllabusTopics_closeBarButton__8cU2I{

height: 40px;
border-style: solid;

}

.SyllabusTopics_closeButtonDiv__2abic{
width:100%;
display: flex;
background-color: white;
justify-content: flex-end;
border-radius: 5px;
}



.SyllabusTopics_closeFormButton__2XEQ5{
border-style: none;
cursor: pointer;
background-color: white;
border-radius: 5px;
}


.SyllabusTopics_closeButtonIcon__3OTMj{
font-size: 25px;
}

.SyllabusTopics_instructionTitle__1t6-m{
  margin: auto;
  border-style: none;
  width: 80%;
  color: var(--themeColor);
}



.SyllabusTopics_syllabusTitle__3IhXX{
width: 90%;
border-style: none;
margin: auto;
color: var(--greyTextColor);
font-size: 20px;
margin-top:20px;
}



{/* Followin design is from sllabys standalone App  */}
.SyllabusTopics_courseSyllabus__WRV4H{
width: 85%;
background-color: white;
display: flex;
align-items: center;
flex-direction: column;
align-items: flex-start;
margin-bottom: 200px;
padding-top:20px;
border-style: none;
margin:auto;
margin-top: 20px;
padding: 2.5%;
border-style: none;
border-width: 1px;
border-color: var(--cardBorderColor);
border-radius: var(--cardBorderRadius);
}

.SyllabusTopics_courseSyllabus__WRV4H i{
font-style: normal;
font-size: calc( 0.30 * var(--headerHeight) );
color: grey;
display: flex;
}

.SyllabusTopics_courseSyllabusss__3FP-T button{
font-size:calc( 0.30 * var(--headerHeight) );
border-style: none;
background-color: white;
cursor: pointer;
color:var(--TextColorDarkBlue1);
display: flex;
align-items: center;
}

.SyllabusTopics_courseSyllabus__WRV4H button:hover{
text-decoration: underline;
}


.SyllabusTopics_ExpandSyllIcon__1V_Px{
font-size:calc( 0.30 * var(--headerHeight) );
padding-left: 5px;
}


.SyllabusTopics_syllabusContent__2Js6C{
position: relative;
width: 100%;
background-color: white;
font-size: calc( 0.30 * var(--headerHeight) );
color: grey;
}


.SyllabusTopics_topicCheck__3nt6N{
font-size: calc( 0.35 * var(--headerHeight) );
color: green;
}


.SyllabusTopics_chapterNames__3Gu6j{
display: flex;
flex-direction: column;

}


.SyllabusTopics_completeIcon__16UPU{
color: green;
}


.SyllabusTopics_numLectures__1jjZ8{
color: white;
background-color: red;
float: right;
border-radius: 2px;
padding: 2px;
}

.SyllabusTopics_sectionHead__2CJH3{

border-style: solid;
border-width: 1px;
border-color: lightgrey;
margin: 10px;
}



.SyllabusTopics_sectionlist__3j1s1{
counter-reset: item
}




ol { counter-reset: item }


li{ 
display: block; 
border-width: 1px;
border-color: lightgrey;
border-style: none;
padding: 10px;

}
li:before { content: counters(item, ".") " "; counter-increment: item }




.SyllabusTopics_topicsList__3vml6{
display: flex;


}


.SyllabusTopics_sectionEditButton__3bt7y{
border-style: none;
float: right;
background-color: lightgrey;
border-radius: 3px;
color: grey;
padding: 2px;
padding-left: 5px;
padding-right: 5px;
font-size: 15px;
margin-left: 10px;
margin-right: 10px;
}

.SyllabusTopics_sectionEditButton__3bt7y:hover{
cursor: pointer;
}

.SyllabusTopics_editTopicUnit__2VJfs{
border-style: solid;
border-width: 1px;
border-radius: 3px;
background-color: #ebebeb;
border-color: lightgrey;
}



.SyllabusTopics_inputText_field__3Mi2A{
width: 100%;
height: 100%;

}


.SyllabusTopics_sectionEditBox__2s2W6{
min-height: 100px;
border-style: solid;
width: 100%;
border-style: solid;
border-width: 2px;
border-color:#e0ebff; 
}


.SyllabusTopics_sectionTextArea__oAiHs{
min-height: 100px;
border-style: solid;
width: 100%;
border-style: solid;
border-width: 2px;
border-color:#e0ebff;
padding: 20px;
}



.SyllabusTopics_sectionTextArea__oAiHs:focus {
    border-style: none;
    outline: none !important;
    border-color: #719ECE;
    box-shadow: 0 0 10px #719ECE;
    border-radius:3px;
    box-shadow: 0 0 5px rgba(81, 203, 238, 1);
}



.SyllabusTopics_sectionSaveButton__3oHKh{
border-style: none;
background: var(--themeColor);
color: white;
border-radius: 3px;
padding: 3px;
}

.SyllabusTopics_sectionSaveButton__3oHKh:hover{
cursor: pointer;
text-decoration: none;
}



.SyllabusTopics_deleteChapterButton__2EUKh{
float: right;
border-style: none;
background-color: lightgrey;
padding: 5px;
border-radius: 3px;
font-size: 20px;
color: grey;
cursor: pointer;
margin-left: 10px;
}

.SyllabusTopics_topBarSyllabus__3hVaR{
color: grey;
display: flex;
}

.SyllabusTopics_topBarSyllabus_button__3O7If{
border-style: none;
color: white;
background-color: #00aff0;
background-color: var(--themeColor);
padding: 5px;
margin-left: 20px;
border-radius: 3px;
cursor: pointer;
text-decoration: none;

}

.SyllabusTopics_topBarSyllabus_button__3O7If:hover{
text-decoration: none;
}


.SyllabusTopics_toggleSwitch__1wkyh{
 margin-left: 20px;
 display: flex;
 align-items: center;
}





.SectionIter_oneTopicSelect__3T-jY{
border-style: solid;
border-width: 1px;
border-color:lightgrey;
background-color: var(--bodyBkgColor);
padding: 3px;
margin: 3px;
display: inline;
border-radius: 5px;
cursor: pointer;
}

.SectionIter_oneTopicSelect__3T-jY:hover{

background-color: #c8defa;
border-color: var(--themeColor);
}



.ClassesContainer_parentContainer__1P_D2{

width: 90%;
border-style: none;
margin: auto;
height: 1000px;
margin-top: calc( 0.5 * var(--headerHeight) );
}





.ClassesContainer_daySection__2m3Q5{
min-height: 50px;
border-style: none;
border-width:1px;
border-color: var(--cardBorderColor);
border-radius: var(--cardBorderRadius);
display: flex;
align-items: center;
color: var(--greyTextColor);
align-items: stretch;
margin-bottom: 10px;
}

.ClassesContainer_dayName__1DWw2{
width: 100%;
border-style: none;
margin-left:-10px;
margin-right: 10px;
background-color: var(--bodyBkgColor);
padding-top: 5px;
padding-bottom: 5px;
height: 20px;
margin: auto;
margin-left:-10px;
margin-right: 10px;
}



.ClassesContainer_dayClassContainer__2jgRE{

width: 90%;
border-style: none;
padding-top: 10px;
border-width: 5px;
border-color: var(--bodyBkgColor);
border-color: white;
border-radius: var(--cardBorderRadius);
display: flex;
flex-direction: column;
justify-content: center;
}


.ClassesContainer_leftDayNameContainer__1A-6s{
width: 10%;
border-style: none;
border-color: var(--cardBorderColor);
border-width: 1px;
display: flex;
align-items:center;
position: relative;
align-items: stretch;
border-radius: var(--cardBorderRadius);
border-left-style: solid;
}




.ClassesContainer_noClassDiv__2G_7I{
border-style: solid;
border-width: 1px;
border-color: var(--cardBorderColor);
padding: 20px;
border-radius: var(--cardBorderRadius);

}


.ClassesContainer_timeLine__2v96b{
height: 30px;
width: 100%;
border-style: none;
margin: auto;
align-items: center;
margin: auto;
display: flex;
color: var(--greyTextColor);
margin-top: 20px;
margin-bottom: 10px;
white-space: nowrap;
}


.ClassesContainer_navButton__1lSQP{
border-style: none;
border-width: 1px;
border-color: var(--cardBorderColor);
margin-left: 5px;
margin-right: 5px;
border-radius: 3px;
color: grey;
padding-left:10px;
padding-right: 10px;
display: flex;
align-items: center;
padding-top: 8px;
padding-bottom: 8px;
cursor: pointer;
}

.ClassesContainer_navButton__1lSQP:hover{
background-color: lightgrey;
}

.ClassesContainer_infoText_i__n58yA{
display: flex;
}

.ClassesContainer_dateText__3l6S3{
border-style: none;
display: flex;
align-items: center;
}


.ClassesContainer_classTimeLine__1tByO{
width: 100%;
height: 1px;
background-color: var(--cardBorderColor);
display: flex;
align-items: center;
justify-content: space-between;
padding-right: 70px;
}

.ClassesContainer_oneClassBall__3a2l2{
height: 5px;
width: 5px;
border-radius: 50%;
background-color: grey;
margin-left: 5%;
position: relative
}


.ClassesContainer_oneClassExactTime__rwR16{
position: absolute;
top: 5px;
}





.ClassesContainer_monitorBoxes__2q74G{
width: 90%;
border-style: none;
display: flex;
justify-content: space-between;
}


.ClassesContainer_monitorOneBox__3-T5y{
height:100px;
width: 250px;
border-radius: 10px;
background-color: white;
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
border-style: solid;
border-color: lightgrey;
border-width: 1px;
}

.ClassesContainer_boxnum__2_Zjn{
font-size: 30px;
}








.ClassViewShort_v2_courseViewDashboard__VO3cs {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
    border-style: solid;
    border-width: 1px;
    border-radius: var(--cardBorderRadius);
    padding: 15px;
    border-color: lightgrey;
    background-color: white;
    margin-bottom: 10px;
    max-width: 400px;
}
  
  .ClassViewShort_v2_courseViewDashboard__VO3cs:hover {
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }
  
  .ClassViewShort_v2_titleDiv__i-rDM {
    width: 100%; 
    border-style: none;
    margin-left: -0%;
    border-width: 1px;
    border-color: lightgrey;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
  
  }
  
  .ClassViewShort_v2_classInfo__1aasf {
    /* background-color: var(--themeColor); */
    color: var(--greyTextColor);
    padding-right: 10px; 
    border-radius: 7px;
    font-size: 14px;
    display: flex;
    border-style: none;
    align-items: center;

  }
  
  .ClassViewShort_v2_time__2nf09 {
    margin-left: 5px;
    font-size: 14px;
    font-weight: 600;
    background-color: var(--themeColor);
    color: white;
  }
 


  .ClassViewShort_v2_timeAll__3ae7Z{
   margin-left: 5px;
   font-size: 14px;
   font-weight: 600;
  }


  .ClassViewShort_v2_leftInfo__3-2ey {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-style: none;
    border-color: green;
    height: 100%;
    width: calc(100% - 170px);
    align-items: start;
  }
  
  .ClassViewShort_v2_toprightBoxes__3YF1i {
    display: flex;
    margin-right: 0px;
    justify-content: space-between;
    position: relative;
  }
 

 @media only screen and (max-width:600px){


 }



  .ClassViewShort_v2_classId__tRBUm {
    background-color: white;
    color: var(--themeColor);
    border-radius: 2px;
    padding: 2px;
  }
  
  .ClassViewShort_v2_topicsTitle__38Rmz {
   color: var(--deepDarkThemeTextColor);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    border-style: solid;
}
 
  
  .ClassViewShort_v2_classData__3gZcU {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    align-items: center;
    display: flex;
    margin: auto;
    font-size: 12px;
    color: var(--themeColor);
    color: white;
  }
 

  .ClassViewShort_v2_classDataAll__2XE6i{
  
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: auto;
    font-size: 12px;
    color: var(--themeColor);
  }







  .ClassViewShort_v2_clockIcon__GX5WE {
    height: 16px;
    width: 16px;
    color: var(--themeColor);
  }
  
  .ClassViewShort_v2_dotsButton__3Cb0e {
    border-style: none;
    float: right;
    /* margin-left: 20px; */
    background-color: white;
    cursor: pointer;
    padding: 4px;
    /* border: 1px solid red; */
  }
  
  .ClassViewShort_v2_dotsButton__3Cb0e:hover {
    background-color: var(--bodyBkgColor);
    border-radius: 20%;
  }
  
  .ClassViewShort_v2_middleDiv__Rnsyp {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-style: solid;
    border-width: 1px;
    border-color: red;
    margin: auto;
  }
  
  .ClassViewShort_v2_authorDiv__1FLOL {
    border-style: none;
    align-items: center;
    color: var(--lightGreyTintTextColor);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 20px;
    width: 80%;
    font-size: 14px;
    margin-top: 10px;
    display: flex;
    align-items: center;
  }
  
  
  .ClassViewShort_v2_addressDiv__XbUcx {
    margin-top: 10px;
    border-style: none;
    border-width: 1px;
    color: var(--lightGreyTintTextColor);
    align-items: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 80%;
  }
  
  .ClassViewShort_v2_redirButtons__3dIne {
    display: flex;
    justify-content: right;
    border-style: none;
    float: right;
    align-items: center;
    border-color: yellow;
  }
  
  .ClassViewShort_v2_joinClassButton__1rkgl {
    margin-top: 10px;
    height: 80%;
    border-style: none;
    border-radius: 2px;
    padding-left: 15px;
    padding-right: 15px;
    /* background-color: #d67b1a;
      background-color: orange; */
    color: white;
    width: 150px;
    /* border-radius: var(--cardBorderRadius); */
    cursor: pointer;
    font-family: Roboto;
    border-radius: 6px;
    background-color: transparent;
    color: #4c8bf5;
    border-radius: 4px;
    border: 1px solid #4c8bf5;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: orange;
    color: white;
    border-color:white;
    color: white;
    border-radius: 7px;
    font-weight: bold;
  }
  
 
  .ClassViewShort_v2_joinClassButton__1rkgl:hover{
  
   box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  }


  .ClassViewShort_v2_joinText__2KAIp {
    padding-left: 10px;
  }
  
  .ClassViewShort_v2_viewClassButton__KrI7x {
    margin-top: 10px;
    height: 70%;
    margin-left: 10%;
    border-style: none;
    align-items: center;
    display: flex;
    width: 120px;
    cursor: pointer;
    /* border-radius: var(--cardBorderRadius); */
    border-radius: 4px;
    padding-left: 15px;
    color: var(--tintGreyColor1);
    font-family: Roboto;
    border: 1px sol id var(--cardBorderColor);
  }
  
  .ClassViewShort_v2_viewClassButton__KrI7x:hover {
    background-color: white;
    border: 1px solid var(--cardBorderColor);
  }
  
  .ClassViewShort_v2_viewdetailIcon__15fWF {
    margin-left: 10px;
  }
  
  .ClassViewShort_v2_classAddress__3g7nU {
    color: var(--tintGreyColor1);
    border-style: none;
    display: flex;
    align-items: center;
  }
  
  .ClassViewShort_v2_lowerDiv__2sVB9 {
    margin-top: 10px;
    border-style: none;
    width: 100%;
    border-width: 1px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border: 1px solid blue; */
  }
  
  .ClassViewShort_v2_leftLowerDiv__2LkrF {
    height: 100%;
    width: calc(100% - 300px);
    /* border: 1px solid red; */
  }
  
  .ClassViewShort_v2_rightLowerDiv__oMxeT {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    /* border: 1px solid green; */
  }
  
  .ClassViewShort_v2_classStatus__1KNx9 {
    color: green;
    border-style: none;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
  }
  
  .ClassViewShort_v2_todayTopic__3QcGQ {
    font-size: 14px;
    color: var(--greyTextColor);
    font-weight: 600;
    margin-left:10px;
  }
  
  .ClassViewShort_v2_todayTopicDetail__1NJb2 {
    padding-left: 10px;
    font-size: 14px;
  }
  
  .ClassViewShort_v2_classTime__1mjWJ {
    margin-right: 10px;
    border-width: 1px;
    padding: 5px;
    border-radius: 5px;
    border-color: var(--tintGreyColor2);
    color: var(--themeColor);
    color: var(--lightGreyTintTextColor);
    font-size: 14px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500;
  }
  
  .ClassViewShort_v2_classNum__XVimq {
    margin-left: 10px;
    border-style: none;
    color: var(--deepDarkThemeTextColor);
    /* color: var(--themeColor); */
    padding: 5px;
    font-size: 14px;
    background-color: var(--bodyBkgColor);
    border-radius: 16%;
    color: grey;
  }



.ClassViewShort_v2_rightDotsDiv__1yE4x{




}


.ClassViewShort_v2_dateText__2pKB3{
color: white;
display: inline-block;
padding: 5px;
background-color: var(--themeColor);
border-radius: 5px;
}



.ClassViewShort_v2_minduration__2LMnA{
background-color: white;
border-radius: 5px;
padding-left: 5px;
padding-right: 5px;
color: var(--themeColor);
}



.ClassViewShort_v2_studentNo__UPTgx{
color: white;
background-color: #50C878;
border-radius: 5px;
height: 15px;
min-width: 15px;
border-style: none;
border-color: black;
display: inline-block;
font-weight: bold;
padding: 5px;
align-items: center;
display: flex;
justify-content: center;
margin-left: 10px;
}


.ClassViewShort_v2_paymentStatus__3XHwm{
margin-left: 10px;
}

.ClassViewShort_v2_dateInfo__2-iAl{
color: grey;
font-weight: medium;
margin-top: 5px;
}




.CourseViewDashboard_v2_courseViewDashboard__1IVlG{
border-width: 1px;
background-color: white;
border-radius: var(--cardBorderRadius);
margin-bottom: 0px;
border-style: solid;
border-color: var(--headerRightIconsColor);
border-color: #e0ebff;
/*box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;*/
border-color: var(--tintGreyColor3);
}


.CourseViewDashboard_v2_courseViewDashboard__1IVlG:hover{
box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}





.CourseViewDashboard_v2_courseViewDashboard__1IVlG:hover .CourseViewDashboard_v2_courseNameButton__1Iv9l{

color: var(--themeColor);

}


.CourseViewDashboard_v2_courseViewDashboard__1IVlG:hover .CourseViewDashboard_v2_arrowButton__1e12E{
color: var(--themeColor);

}


.CourseViewDashboard_v2_courseViewDashboard__1IVlG:hover .CourseViewDashboard_v2_enrolldisplayButton1__3In4a{
visibility: visible;
}


.CourseViewDashboard_v2_courseViewDashboard__1IVlG:hover .CourseViewDashboard_v2_courseGoToButton__1LTFL{
visibility: visible;
}



.CourseViewDashboard_v2_innerMarginDiv__2yDcC{
border-style: none;
margin: calc( 0.5 * var(--headerHeight) );
}



.CourseViewDashboard_v2_upperPart__1svmu{
height: calc( 2 * var(--headerHeight) );
width: 100%;
border-style: none;
border-width: 1px;
display: flex;
}


.CourseViewDashboard_v2_upperInfoDiv__g5Do9{
background-color: white;
height: calc( 3 * var(--headerHeight) );
width: calc( 100% - calc( 2.3 * var(--headerHeight) ));
margin-left: calc( 0.3 * var(--headerHeight) );
border-style: none;
display: flex;
flex-direction: column;
justify-content: flex-start;
}



.CourseViewDashboard_v2_picDiv__2Fops{
height: calc( 2 * var(--headerHeight) );
width: calc( 2 * var(--headerHeight) );
background-color:white;
border-radius: 5px;
border-style: none;
position: relative;
}


.CourseViewDashboard_v2_picStyle__24eza{
width: calc( 2 * var(--headerHeight) );
height: calc( 2 * var(--headerHeight) );
border-radius: 5px;
}







.CourseViewDashboard_v2_topBar1__27K83{
border-style: none;
color: grey;
display: flex;
justify-content: space-between;
}

.CourseViewDashboard_v2_topBar1_left__3hB-P{
display: flex;
width: 50%;
border-style: none;
justify-content: space-between;
font-size: calc( 0.25 * var(--headerHeight) );
}





.CourseViewDashboard_v2_topBar1_right__o8oDz{
display: flex;
border-style: none;
font-size: calc( 0.25 * var(--headerHeight) );
}


.CourseViewDashboard_v2_courseCode__GGyiz{

background-color:#dcf7ea;
padding: calc( 0.05 * var(--headerHeight) );
border-radius:calc( 0.05 * var(--headerHeight) );
color: #65c394;
font-size: calc( 0.25 * var(--headerHeight) );
}

.CourseViewDashboard_v2_notification__3x5rO{
margin-left:calc( 0.20 * var(--headerHeight) );
margin-right:calc( 0.20 * var(--headerHeight) );
border-style: none;
display: flex;
flex-direction: column;
justify-content: center;
}

.CourseViewDashboard_v2_notIcon__3ZAvH{
font-size: calc( 0.30 * var(--headerHeight) );

}



.CourseViewDashboard_v2_dotsButton__1B54U{
border-style: none;
background-color: white;
cursor: pointer;
display: flex;
justify-content: center;
flex-direction: column;
position: relative;
font-size: calc( 0.25 * var(--headerHeight) );

}

.CourseViewDashboard_v2_verticalDotsIcon__2bCG0{
font-size: calc( 0.25 * var(--headerHeight) );
}




.CourseViewDashboard_v2_courseNameDiv__1UOlW{
font-size: calc( 0.25 * var(--headerHeight) );
display: flex;
justify-content: space-between;
}

.CourseViewDashboard_v2_courseNameDiv_left__2MR3v{
display: flex;
border-style: none;
width: 80%;
}


.CourseViewDashboard_v2_courseNameButton__1Iv9l{
max-width: 100%;
border-style: none;
background-color: white;
font-size: calc( 0.30 * var(--headerHeight) );
cursor: pointer;
padding: 0px;
font-family: Roboto;
color: var(--deepDarkThemeTextColor);
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
}


.CourseViewDashboard_v2_arrowButton__1e12E{
border-style: none;
border-width: 1px;
background-color: white;
font-size: calc( 0.30 * var(--headerHeight) );
cursor: pointer;
padding: 0px;
font-family: Roboto;
color: var(--deepDarkThemeTextColor);
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
padding-left: 5%;
padding-right: 2%;
color: grey;
display: flex;
flex-direction: column;
justify-content: center;

}



.CourseViewDashboard_v2_middlePart__34Duo{
border-style: none;
width: 100%;
border-width: 1px;
border-color: orange;
display: flex;
justify-content: space-between;
}


.CourseViewDashboard_v2_middlePart_left__3ChDY{
width: 100%;
height: 100%;
border-style: none;
border-width: 1px;
border-radius: 5px;
color: grey;
display: flex;
flex-direction: column;
justify-content: center;
font-size: calc( 0.25 * var(--headerHeight) );
}


.CourseViewDashboard_v2_middlePart_middle__3p04L{
height: 100%;
border-style: none;
border-width: 1px;

}






.CourseViewDashboard_v2_middlePart_right__1NhWG{
width: calc( 1.0 * var(--headerHeight) );
height: 100%;
border-style: none;
border-width: 1px;
display: flex;
flex-direction: column;
justify-content: center;

}


.CourseViewDashboard_v2_goToCourseButton__3lN8n{
height: 100%;
border-style: none;
color: grey;
cursor: pointer;
height: calc( 0.25 * var(--headerHeight) );
flex-shrink: 0;
}



.CourseViewDashboard_v2_goToCourseBtnIcon__3QC1N{
font-size:calc( 0.25 * var(--headerHeight) );
flex-shrink: 0;
border-style: none;
border-width: 1px;
}

.CourseViewDashboard_v2_InfoDivLowest__2gYpX{
border-style: none;
height: 100%;
display: flex;
justify-content: space-between;
}

.CourseViewDashboard_v2_InfoDivLowest_left__3jmBO{
height: 100%;
border-style: none;
width: calc( 3 * var(--headerHeight) );
display: flex;
flex-direction: column;
justify-content: flex-end;

}


.CourseViewDashboard_v2_courseGoToButton__1LTFL{

height: calc( 0.5 * var(--headerHeight) );
background-color: var(--greenColor1);
color: white;
border-style: none;
border-radius: calc( 0.1 * var(--headerHeight) );
font-size: calc( 0.30 * var(--headerHeight) );
margin-bottom: calc( 0.35 * var(--headerHeight) );
cursor: pointer;
visibility: hidden;
background-color: var(--themeColor);
/*background-color:white;
color: var(--themeColor);
border-style: solid;
border-width: 1px;
border-color:var(--themeColor);*/
}





.CourseViewDashboard_v2_courseGoToButton2__2DbLE{

height: calc( 0.5 * var(--headerHeight) );
background-color: var(--greenColor1);
color: white;
border-style: none;
border-radius: calc( 0.1 * var(--headerHeight) );
font-size: calc( 0.30 * var(--headerHeight) );
margin-bottom: calc( 0.35 * var(--headerHeight) );
cursor: pointer;
background-color: var(--themeColor);
/*background-color:white;
color: var(--themeColor);
border-style: solid;
border-width: 1px;
border-color:var(--themeColor);*/
}















.CourseViewDashboard_v2_InfoDivLowest_right__1W8tV{
height: calc(2 * var(--headerHeight) );
border-style: none;
width: calc( 2 * var(--headerHeight) );
display: flex;
flex-direction: column;
justify-content: space-between;
border-left: 1px solid var(--tintGreyColor3);
padding-left: 10px;
}








.CourseViewDashboard_v2_lowerPart__J2ZcK{
height: calc( 1 * var(--headerHeight) );
width: calc( 2 * var( --headerHeight) );
border-style: none;
border-width: 1px;
display: flex;
justify-content: space-between;
}

.CourseViewDashboard_v2_lowerPartTitle__R9YrI{
color: var(--tintGreyColor1);
font-size: calc( 0.2 * var(--headerHeight) );
}


.CourseViewDashboard_v2_lowerPart_left__kys6c{
border-style: none;
height: 100%;
width: calc( 2 * var(--headerHeight) ) ;
display: flex;
flex-direction: column;
justify-content: center;
border-color: red;
}


.CourseViewDashboard_v2_lowerPartInfo1__3YjrO{
margin-top: calc( 0.2 * var( --headerHeight) );	
width: 100%;
}



.CourseViewDashboard_v2_progressBar__1VSF0{
height:calc( 0.2 * var(--headerHeight) );
width: 100%;
background-color: #F0F0F0;
border-radius:5px;

}

.CourseViewDashboard_v2_progressWidth__15RZk{
height:calc( 0.2 * var(--headerHeight) );
width:30%;
background-color: #AFE1AF;
border-radius:calc( 0.2 * var(--headerHeight) );
}




.CourseViewDashboard_v2_lowerPart_middle__2uvD1{
height: calc( 1 * var(--headerHeight) );
border-style: none;
border-width: 2px;
display: flex;
justify-content: center;
width: calc( 3.0 * var(--headerHeight) );
display: flex;
flex-direction: column;
}






.CourseViewDashboard_v2_oneTool__HyslV{
height: calc( 0.6 * var(--headerHeight) );
width: calc( 0.6 * var(--headerHeight) );
border-radius: calc( 0.2 * var(--headerHeight) );
border-style: solid;
border-color: lightgrey;
border-width: calc( 0.05 * var(--headerHeight) );
display: flex;
justify-content: center;
align-items: center;
color: lightgrey;
cursor: pointer;
font-size:calc( 0.3 * var(--headerHeight) );
}

.CourseViewDashboard_v2_oneTool__HyslV:hover{
color: grey;
border-color:var(--tintGreyColor1);
}


.CourseViewDashboard_v2_lowerPartInfo2__1tB-9{
display: flex;
width: 100%;
border-style: none;
justify-content: space-between;
height: calc( 1 * var(--headerHeight) );
align-items: center;
}




.CourseViewDashboard_v2_lowerPart_right__1deMc{
border-style: solid;
width: calc( 2.5 * var(--headerHeight) );
display: flex;
flex-direction: column;
justify-content: center;
}



.CourseViewDashboard_v2_lowerPartInfo3__3500z{
color: var(--tintGreyColor1);
width: 50%;
border-style: none;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
font-size: calc( 0.25 * var(--headerHeight) );
margin-top: calc( 0.1 * var(--headerHeight) );
padding-bottom: calc( 0.1 * var(--headerHeight) );
}

























.CourseViewDashboard_v2_dropdownButtons__1OREP{
width: 100px;
position: absolute;
border-style: solid;
z-index: 9999;
right: 2px;
top: 25px;
background-color: white;
border-width: 1px;
border-color: lightgrey;
border-radius: 3px;
display: flex;
flex-direction: column;
}

.CourseViewDashboard_v2_dropdownButton__2UvtE{

height: 35px;
border-style: none;
cursor: pointer;
}


.CourseViewDashboard_v2_dropdownButton__2UvtE:hover{

background-color: lightgrey;

}



.CourseViewDashboard_v2_upcomingClassTime__1lzyV{
margin-top: calc( 0.1 * var(--headerHeight) );
font-size: calc( 0.25 * var(--headerHeight) );
color: var(--tintGreyColor1);
}

.CourseViewDashboard_v2_upcomingClassTime__1lzyV span{
font-size: calc( 0.25 * var(--headerHeight) );
color: var(--tintGreyColor1);
}

.CourseViewDashboard_v2_courseAssociation__32YfN{
margin-top: calc( 0.1 * var(--headerHeight) );
font-size: calc( 0.25 * var(--headerHeight) );
color: var(--themeColor);
border-style: solid;
border-width: 1px;
border-radius: 5px;
width:60px;
font-size: 10px;
padding: 3px;
}





.CourseViewDashboard_v2_enrolldisplayButton1__3In4a{

height: calc( 0.5 * var(--headerHeight) );
background-color: var(--greenColor1);
color: white;
border-style: none;
border-radius: calc( 0.1 * var(--headerHeight) );
font-size: calc( 0.30 * var(--headerHeight) );
margin-bottom: calc( 0.35 * var(--headerHeight) );
cursor: pointer;
visibility: hidden;
background-color: var(--themeColor);
background-color:white;
color: var(--themeColor);
border-style: solid;
border-width: 1px;
border-color:var(--themeColor);
}


.CourseViewDashboard_v2_uploadCourseImageButton__1bnr6{

position: absolute;
left:calc( 0.6 * var(--headerHeight) );
top: calc( 0.6 * var(--headerHeight) );
font-size: calc( 0.6 * var(--headerHeight) );
cursor: pointer;
visibility: hidden;
background: white;
border-style: none;
opacity:0.7;
border-radius: 5px;
}


.CourseViewDashboard_v2_picDiv__2Fops:hover .CourseViewDashboard_v2_uploadCourseImageButton__1bnr6{
visibility: visible;
}

.CourseViewDashboard_v2_picDiv__2Fops:hover{
cursor: inherit;
}





.Login_mainDiv__1hvpq {
  /* width: 100vw;
  height: 100%;

  width: 99%;
  height: 99%; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 99vh;
  width: 99vw;
  margin: auto;
  border-style: none;
}

.Login_subMainDiv__G_Vwp {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;


  width: 800px;
  aspect-ratio:3/2;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 4px;
  border-style: none;
}


.Login_logoDiv__33Tr5{
border-style: none;

}

.Login_logoImage__2lmDh{
height: 50px;
aspect-ratio: 6.2/1;
margin-top: 20px;
}



.Login_image__804er {

  width: 100%;
  height: 100%;
}
.Login_coverImg__12skX {
  /* width: 450px;
  height: 550px; */
  width: 100%;
  height: 100%;
}

.Login_parentdiv__27AGB {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: black;
  /* width: 450px;
  height: 550px; */
  width: 100%;
  height: 100%;
  background-color: white;
  justify-content: space-between;
}

.Login_topBar__Tov5J {
  border-bottom: 1px solid;
  border-bottom-color: var(--cardBorderColorLight);

  /* height: 15%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.Login_logoContainer__6N-R6 {
  background-color: var(--themeColor);
  width: 50px;
  height: 50px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.Login_logoContainer__6N-R6:hover {
  cursor: pointer;
  border: none;
}

.Login_textDI__1I-pF {
  font-size: 24px;
  color: white;
  font-weight: 800;
}

.Login_loginTitle__3GxDX {
  color: black;
  margin-left: 10px;
  font-size: 26px;
  font-weight: bold;
  height: 60px;
  margin-top:20px;
}

.Login_midContent__59rBg {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid red;
  border-style: none; 
}
.Login_loginTitleText__3rXcp {
  font-size: 20px;
  font-weight: bold;
  color: var(--deepDarkThemeTextColor);
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* text-decoration: underline; */
}

.Login_mainContainerA1__2HsnN{
  width: 60%;
  margin-top: 20px;
  display: flex;
  align-items: start;
  justify-content: start;
  /*border: 1px solid red;*/


}

.Login_mainContainer__23-Yo {
  margin-top: 16px;
  display: flex;
  align-items: center;
  position: relative;
  border-style: solid;
  width: 315px;
  border-color: var(--themeColor);
  border-radius: 5px;
  height: 45px;
}


.Login_flagcode__1qF5B{
display: flex;
height: 80%;
align-items: center;
}




.Login_fieldtype__3hT3U{
font-size: 15px;
margin-left:10px;
margin-top:5px;
color: red;
max-width: 70%;
}



.Login_infoAboutMobEmail__bX14f{
position: absolute;
top: 28px;
background-color: white;
color: grey;
z-index: 999;
box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
padding: 20px;
border-radius: 10px;
visibility: hidden;
height:200px;
}


.Login_infoLine__2Pcxy{

margin-top:10px;
}

.Login_mainContainer__23-Yo:hover .Login_infoAboutMobEmail__bX14f{

visibility: visible;
}





.Login_flagImg__1YxNN {
  height: 100%;
  aspect-ratio: 1/1;
  border-radius: 2px;
  margin-left: 10px;

}

.Login_countryCode__383up {
  margin-left: 10px;
  color: var(--greyTextColor);
  font-size: 20px;
  font-weight: bold;
  color: black;
  display: flex;
  border-style: none;
  height: 100%;
  align-items: center;
  padding-right:5px;
}
.Login_editmobileNuBox__LtNp9 {
  border-radius: 4px;
  /* background-color: #f0f2f5; */
  height: 40px;
  border: 2px solid var(--themeColor);
  font-size: 20px;
  margin: auto;
  border-style: none;
  font-weight: bold;
  width:50%;
}

.Login_editmobileNuBox__LtNp9:focus {
  padding-left: 8px;
  padding-right: 8px;
  border-style: none;
  border-width: 1px;
  outline: none !important;
  border-color: var(--cardBorderColorLight);
  border-color: var(--cardBorderColor);
  background-color: white;
}

.Login_captcha__2Stx_ {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  /* border: 1px solid red; */
}
.Login_grecaptcha__BcVdo {

  /* height: 40px;
  width: 60%; */
  /* place-items: center; */
  display: flex;
  justify-content: center;
  /* align-self: center;
  transform: scale(0.69);
  -webkit-transform: scale(0.69);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0; */
  -webkit-transform:scale(0.6);
          transform:scale(0.6);
  -webkit-transform-origin:1 0;
          transform-origin:1 0;
}

.Login_sendOtpbtn__3l5P0 {
  margin-top: 20px;
  background-color: var(--themeColor);
  color: white;
  height: 45px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  font-size: 20px;
  font-weight: 700;
  border: none;
  /* border: 1px solid red; */
}

.Login_sendOtpbtn__3l5P0:hover {
  background-color: white;
  color: var(--themeColor);
  border: 1px solid var(--themeColor);
  cursor: pointer;
}



.Login_createBtn__12tlS {
  margin-top: 20px;
  background-color: var(--themeColor);
  color: white;
  height: 50px;
  width:320px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 700;
  border: none;
  /* border: 1px solid red; */
}

.Login_createBtn__12tlS:hover {
  background-color: white;
  color: var(--themeColor);
  border: 1px solid var(--themeColor);
  cursor: pointer;
}




.Login_horizontalContainer__3aXFd {
  margin-top: 30px;
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
}


.Login_horizontalContainer2__ltRVI{
  margin-top: 30px;
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Login_leftLine__21AtR {
  width: 50%;
  border-bottom: 1px solid var(--cardBorderColorLight);
  border-bottom-color: var(--cardBorderColorLight);
}

.Login_orText__QD3S4 {
  font-size: 10px;
  padding: 4px;
  font-weight: 700;
  border: 1px solid var(--cardBorderColorLight);
  border-radius: 50%;
  color: var(--cardBorderColorLight);
  background-color: var(--themeColor);
}
.Login_rightLine__bz1PQ {
  width: 50%;
  border-bottom: 1px solid var(--cardBorderColorLight);
  border-bottom-color: var(--cardBorderColorLight);
}

.Login_signInContainer__3jSMf {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: 60%;
  /* min-width: 300px; */
}

.Login_googleSignINContainer__2rGRq {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: start;
  border: 1px solid var(--cardBorderColorLight);
  border-radius: 4px;
}

.Login_googleSignINContainer__2rGRq:hover {
  background-color: white;
  cursor: pointer;
}

.Login_googleICON__3YHqP {
  margin-left: 10px;
}

.Login_signInGoogleTitle__3Zgyp {
  font-size: 13px;
  color: var(--deepDarkThemeTextColor);
  margin-left: 10px;
}

.Login_facebookICON__3Br47 {
  margin-left: 10px;
  color: #3b5998;
}

.Login_facebookSignINContainer__1qsVF {
  height: 30px;

  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: start;
  border: 1px solid var(--cardBorderColorLight);
  border-radius: 4px;
}

.Login_facebookSignINContainer__1qsVF:hover {
  background-color: white;
  cursor: pointer;
}

.Login_signInFacebookTitle__2Lu2R {
  font-size: 13px;
  color: var(--deepDarkThemeTextColor);
  margin-left: 10px;
}

.Login_linkedinICON__e_OqP {
  margin-left: 10px;
  color: #0a66c2;
}

.Login_linkSignINContainer__NE2zV {
  height: 30px;

  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: start;
  border: 1px solid var(--cardBorderColorLight);
  border-radius: 4px;
}

.Login_linkSignINContainer__NE2zV:hover {
  background-color: white;
  cursor: pointer;
}

.Login_signInLinkTitle__2rKJ6 {
  font-size: 13px;
  color: var(--deepDarkThemeTextColor);
  margin-left: 10px;
}

.Login_textContainer__119bN {
  height: 50px;
  width: 400px;
  margin-top: 20px;
  font-size: 14px;
  color: var(--greyTextColor);
  border: 1px solid red;
  margin-left: 50px;
  margin-right: 50px;
  display: flex;
  flex-direction: column;
}

.Login_hintText__2KKvj {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.Login_termTitle__KxXd_ {
  color: var(--themeColor);
  font-weight: 500;
  margin-left: 10px;
  margin-right: 10px;
}

.Login_privacyTitle__pJRyX {
  color: var(--themeColor);
  font-weight: 500;
  margin-left: 10px;
}

.Login_bottomBar__34lUE {
  border-top: 1px solid;
  border-top-color: var(--cardBorderColorLight);
  /* height: 15%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 12%;

}

.Login_bottomTopBar__2SKye {
  height: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Login_textTitleBottom__iSmC0 {
  margin-top: 20px;
  font-size: 12px;
  color: var(--greyTextColor);
  padding-left: 10px;
  padding-right: 10px;
  width: 90%;
  display: flex;
  justify-content: space-between;
}

.Login_textTitleBottom1__2bYiD {
  /* margin-top: 20px; */
  font-size: 12px;
  color: var(--greyTextColor);
  padding-left: 10px;
  padding-right: 10px;
  width: 90%;
  display: flex;
  justify-content: space-between;
}

.Login_contactUS__2q2rd {
  color: var(--greyTextColor);

  border: none;
  background-color: transparent;
}
.Login_contactUS__2q2rd:hover {
  color: var(--themeColor);
  cursor: pointer;
  font-weight: 500;
}

.Login_termOfService__3vgfZ {
  color: var(--greyTextColor);

  border: none;
  background-color: transparent;
}
.Login_termOfService__3vgfZ:hover {
  color: var(--themeColor);
  cursor: pointer;
  font-weight: 500;
}

.Login_privacyText__2Ewny {
  color: var(--greyTextColor);

  border: none;
  background-color: transparent;
}

.Login_privacyText__2Ewny:hover {
  color: var(--themeColor);
  cursor: pointer;
  font-weight: 500;
}

.Login_texttitle__dkV_f {
  font-size: 14px;
  color: var(--deepDarkThemeTextColor);
  margin-right: 10px;
}

.Login_create__1wBkM {
  margin-left: 6px;
  font-size: 14px;
  font-weight: 600;
  color: var(--themeColor);
  background-color: transparent;
  border: none;
}

.Login_create__1wBkM:hover {
  cursor: pointer; 
  border-radius: 4px;
  text-decoration: underline;
}


/* ============================================================================= */

/* ACCOUNT SUCCESSFULLY CREATED PAGE CSS */

.Login_mainContainerOpt__WD3TU {
  margin-top: 50px;
  display: flex;
  width: 300px;
  max-width: 300px;
  justify-content: space-around;
}

.Login_num1Box__1d8u8 {
  height: 40px;
  width: 40px;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid var(--cardBorderColor);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.Login_num1Box__1d8u8:focus {
  border-style: solid;
  border-width: 1px;
  outline: none !important;
  border-color: var(--cardBorderColor);
  background-color: white;
}

.Login_num2Box__MWtNE {
  height: 40px;
  width: 40px;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid var(--cardBorderColor);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.Login_num2Box__MWtNE:focus {
  border-style: solid;
  border-width: 1px;
  outline: none !important;
  border-color: var(--cardBorderColor);
  background-color: white;
}

.Login_num3Box__1f7Wm {
  height: 40px;
  width: 40px;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid var(--cardBorderColor);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.Login_num3Box__1f7Wm:focus {
  border-style: solid;
  border-width: 1px;
  outline: none !important;
  border-color: var(--cardBorderColor);
  background-color: white;
}

.Login_num4Box__29JVl {
  height: 40px;
  width: 40px;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid var(--cardBorderColor);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.Login_num4Box__29JVl:focus {
  border-style: solid;
  border-width: 1px;
  outline: none !important;
  border-color: var(--cardBorderColor);
  background-color: white;
}

.Login_num5Box__1-Rtr {
  height: 40px;
  width: 40px;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid var(--cardBorderColor);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.Login_num5Box__1-Rtr:focus {
  border-style: solid;
  border-width: 1px;
  outline: none !important;
  border-color: var(--cardBorderColor);
  background-color: white;
}

.Login_procedToLogin__Yd9vu {
  background-color: var(--themeColor);
  color: white;
  height: 30px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 700;
  border: none;
}


.Login_procedToLogin__Yd9vu:hover{
  background-color: white;
  color: var(--themeColor);
  border: 1px solid var(--themeColor);
  cursor: pointer;
}


.Login_successIconDiv__2YGEa {
  height: 50px;
  width: 50px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Login_iconSuccess__29FCr {
  height: 40px;
  width: 40px;
  color: var(--themeColor);
}

/* ============================================================================= */


/* OTP PAGE CSS */

.Login_mainContainerOpt__WD3TU {
  margin-top: 50px;
  display: flex;
  width: 300px;
  max-width: 300px;
  justify-content: space-around;
}

.Login_num1Box__1d8u8 {
  height: 40px;
  width: 40px;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid var(--cardBorderColor);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.Login_num1Box__1d8u8:focus {

  border-style: solid;
  border-width: 1px;
  outline: none !important;
  border-color: var(--cardBorderColor);
  background-color: white;
}

.Login_num2Box__MWtNE {
  height: 40px;
  width: 40px;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid var(--cardBorderColor);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.Login_num2Box__MWtNE:focus {

  border-style: solid;
  border-width: 1px;
  outline: none !important;
  border-color: var(--cardBorderColor);
  background-color: white;
}

.Login_num3Box__1f7Wm {
  height: 40px;
  width: 40px;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid var(--cardBorderColor);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.Login_num3Box__1f7Wm:focus {
  border-style: solid;
  border-width: 1px;
  outline: none !important;
  border-color: var(--cardBorderColor);
  background-color: white;
}

.Login_num4Box__29JVl {
  height: 40px;
  width: 40px;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid var(--cardBorderColor);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.Login_num4Box__29JVl:focus {

  border-style: solid;
  border-width: 1px;
  outline: none !important;
  border-color: var(--cardBorderColor);
  background-color: white;
}

.Login_num5Box__1-Rtr {
  height: 40px;
  width: 40px;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid var(--cardBorderColor);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.Login_num5Box__1-Rtr:focus {

  border-style: solid;
  border-width: 1px;
  outline: none !important;
  border-color: var(--cardBorderColor);
  background-color: white;
}


.Login_submitOtpbtn__1weCo{
  margin-top: 50px;
  background-color: var(--themeColor);
  color: white;
  height: 45px;
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 700;
  border: none;
}

.Login_submitOtpbtn__1weCo:hover {
  margin-top: 50px;
  background-color: white;
  color: var(--themeColor);
  border: 1px solid var(--themeColor);
  cursor: pointer;
}



.Login_userExistSymbol__3APnD{
color: green;
}


.Login_userExistSymbol2__ffgqh{
color: red;
}




.Login_sendOTPButton__3JHfi{

background-color: var(--themeColor);
padding: 10px;
border-style: none;
border-radius: 5px;
color: white;
}



.Login_diracAIButton__1HgUj{
border-style: none;
background-color: white;
cursor: pointer;
}
.Login_logologin__2zeBH{

background-color:#224C98;
border-radius: 5px;
color: white;
font-weight: bold;
padding: 5px;
margin-right: 5px;
}



/* =====================================================================*/
@media (max-width: 900px) {
  .Login_mainDiv__1hvpq {
    /* width: 100%; */
    /* aspect-ratio: 45/60; */
  }


  .Login_subMainDiv__G_Vwp {
    width: 90%;
    max-width: 400px;
    height: 90%;
    padding: 10px;

    border-style: none;
  }

  .Login_image__804er {
    display: none;
  }

  .Login_parentdiv__27AGB {
    width: 100%;
    border-style: none;
  }

  /* .image {
    width: 100%;
    height: 300px;
  }

  .parentdiv {
    width: 100%;
    height: 100%;
    max-width: 500px;
  }

  .topBar {
    height: 10%;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }

  .midContent {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40%;
  }

  .bottomBar {
    margin-top: 20px;
    padding-top: 20px;
    height: 10%;
  } */
}

@media (min-width: 1400px) {
  .Login_subMainDiv__G_Vwp {
    width: 900px;
  }

  .Login_grecaptcha__BcVdo{
    -webkit-transform:scale(0.8);
            transform:scale(0.8);
  -webkit-transform-origin:1 0;
          transform-origin:1 0;
  }
}

.Login_homeRedirectButton__3evqU{

margin-top: 20px;
margin-left: 50px;
padding: 20px;
border-style: none;
border-radius: 5px;
cursor: pointer;
}

.Login_homebuttonDiv__31KvX{
width: 100%;
}


.Login_alreadyRegisteredDiv__1qDGP{
margin-top:10px;
}

.Login_alreadyloginnow__2mqp6{
border-style: none;
background-color: white;
color: var(--themeColor);
font-size: 16px;
cursor: pointer;
}

@media (max-width:380px) {


.Login_checkBoxTermsAndConditions__3Faxm{
  width: 320px;
  margin: 0px;
}


.Login_checkBoxTermsAndConditionsLogin__2GwcB{
  width: 320px;
  margin: 0px;
}

  
}


.Login_checkBoxTermsAndConditions__3Faxm{
  margin: 15%;
  text-align: center;
}



.Login_checkBoxTermsAndConditionsLogin__2GwcB{
  margin-top: 30px;
  margin-left: 10%;
  margin-right: 10%;
}
.OTPinputUnit_otpUnit__2hz5Y{

height: 40px;
width: 30px;
font-size: 20px;
border-style: solid;
border-width: 1px;
border-radius: 5px ;
border-color: var(--cardBorderColor);
display: flex;
justify-content: center;
padding:5px;
overflow: hidden;

}

.OTPinputUnit_otpUnit__2hz5Y:focus{
border-style: solid;
border-width: 1px;
outline: none !important;
border-color: var(--themeColor);
border-radius:5px;
align-items: center;
padding:5px;
}




.LoginHeader_loginHeader__37kTg{

height: var(--headerHeight);
width: 100%;
display: flex;
align-items: center;
border-bottom: none;
border-color: lightgrey;
border-width: 1px;
/*box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
*/

}

.LoginHeader_homeIcon__2arrj{

height: calc( 0.6 * var(--headerHeight) );
width: calc( 0.6 * var(--headerHeight) );
margin-left: 50px;
color: var(--themeColor);
}


.LoginHeader_loginHeader__37kTg i{
font-style: normal;
color: var(--themeColor);
margin-left: 30px;
text-decoration: none;
}

.LoginHeader_goBackHome__1EASl{
border-style: none;
background-color: var(--lightThemeColor1) ;
cursor: pointer;


}

.Register_registerParent__Vi08B{
border-style: none;
height:100vh;
width:100vw;
align-items: center;
background-color: var(--bodyBkgColor);
}


.Register_registerDiv__3lmzt{
width: 100%;
max-width: 500px;
border-style: solid;
height: 85vh;
border-radius: var(--cardBorderRadius);
margin: auto;
background-color: white;
display: flex;
flex-direction: column;
margin-bottom: 30px;
padding-bottom: 30px;
border-color: var(--cardBorderColor);
border-width: 1px;
}


.Register_header__2Xg5e{

border-style: solid;
width: 100%;
height: 150px;
display: flex;
flex-direction: column;
justify-content: center;
color: var(--themeColor);
}



.Register_titleDiv__2gQBo{
width: 80%;
height:100px;
margin-top:100px;
color: var(--deepDarkThemeTextColor);
border-style: none;
margin: auto;
display: flex;
flex-direction: row;
justify-content: center;
display: flex;
align-items: center;
}

.Register_switchMethodDiv__1DbNq{
width: 80%;
height:30px;
border-style: none;
margin: auto;
}

.Register_phoneNumberDiv__15pp-{
width: 80%;
height:115px;
border-style: none;
margin: auto;

}

.Register_phonetitle__1C9d9{
font-size: 18px;
height:30px;
}

.Register_enterPhoneDiv__2G-Hj{
height: 50px;
border-style: none;
display: flex;
justify-content: space-between;

}


.Register_enterOTPDiv__10EGo{

height: 50px;
border-style: none;
display: flex;
justify-content: space-between;


}


.Register_otpUnit__chrFq{

height: 40px;
width: 30px;
font-size: 25px;
border-style: solid;
border-radius: calc( 0.5 * var(--cardBorderRadius) ) ;
border-color: var(--cardBorderColor);

}









.Register_phonetnc__YWKMh{
font-size: 12px;
height: 25px;
color: grey;
border-style:none;
display:flex;
justify-content:center;
flex-direction:column;
}




.Register_usertypeDiv__2wCT5{
width: 80%;
height:100px;
border-style: none;
margin: auto;
padding-top: 50px;

}




.Register_countryCodes__1DMg4{

width:150px;
display: flex;
align-items: center;

}


.Register_countryCodes__1DMg4 i{

font-style: normal;
font-size: 20px;
color: grey;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}


.Register_usernameInput__3eCJh{
width: 100%;
height: 100%;
font-size: 22px;
box-sizing: border-box;
border-style: solid;
border-width: 1px;
border-color: lightgrey;
border-radius: var(--cardBorderRadius);
padding-left: 5%;
}

.Register_usernameInput__3eCJh:focus{
outline: none !important;
border-style: solid;
border-width: 2px;
border-color:var(--themeColor);

}





.Register_inflag__3tiMY{
width:60px;
height:40px;

}


.Register_chooseUserTypeTitle__gJbIW{
height:30px;
display:flex;
flex-direction: column;
justify-content: center;
border-style: none;
font-size: 18px;
}



.Register_userTypeOptions__8dLBz{

height:70px;
border-style: none;
}



.Register_userTypeOptions__8dLBz{
display: flex;
flex-direction: column;
justify-content: center;

}


.Register_userTypeOptionsInner__X6YdO{
display: flex;
justify-content:space-between;
color: var(--themeColor);
font-weight: bold;

}





.Register_submitButtonDiv__3xtvF{
width: 80%;
height:50px;
border-style: none;
margin: auto;
margin-top: 50px;
display: flex;
justify-content: center;
}


.Register_sendOTPButton__1UEyT{

height: 40px;
width:60%;
border-style: none;
cursor: pointer;
border-radius: var(--cardBorderRadius);
}

.Register_radioButton__38flq{
cursor: pointer;
height:20px;
width: 20px;
}


.Register_linkToAccountCreation__2lvWU{
border-style: none;
background-color: white;
text-decoration: underline;
color: var(--themeColor);
cursor: pointer;
}




.Website_website__3ORt4 {
  align-items: center;
}

.Website_innerDiv__1ZsT8 {
  width: 1200px;
  background-color: white;
  margin: 0 auto;
  border-style: none;
}

.Website_headrparent__2fKxK {
  border: 1px solid red;
}

.Website_backgroundPicDiv__3kmqw {
  border-style: solid;
}

@media (max-width: 1300px) {
  .Website_innerDiv__1ZsT8 {
    width: 80%;
  }
}

.Website_topbar__anUC0 {
  width: 100%;
  border: 1px solid red;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Website_rightContainer__2AE32 {
  display: flex;
}

.Website_firstTopContainer__2uY4n {
  display: flex;
}

.Website_emailIcon__19qs5 {
  margin-left: 10px;
  height: 20px;
  width: 20px;
  background-color: #5e5d5d;
}

.Website_textEmail__16KuD {
  margin-left: 10px;
}

.Website_secTopContainer__2ET2l {
  margin-left: 20px;
  border: 1px solid green;
  display: flex;
}

.Website_mobileIcon__2O9WV {
  margin-left: 10px;
  height: 20px;
  width: 20px;
  background-color: #5e5d5d;
}

.Website_mobileNumber__2w1sT {
  margin-left: 10px;
}

.Website_socialiconContainer__1h4ka {
  background-color: #207ef5;
  border: 1px solid red;
  display: flex;
  margin-right: 10px;
}

.Website_fbicon__3hwe_ {
  margin: 10px;
  background-color: grey;
  height: 26px;
  width: 26px;
}

.Website_topNavigationBar__3EW1U {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid red;
}

.Website_nFirstCotainer__2qiTx {
  display: flex;
  align-items: center;
}
.Website_logo__ynx4l {
  height: 50px;
  width: 100px;
  background-color: #207ef5;
}

.Website_Name__N44mm {
  margin-left: 10px;
  font-size: 26px;
  font-weight: bold;
}
.Website_navigationMenu__2OmLS {
  display: flex;
}

.Website_websiteContent2__WeWp- {
  margin-top: 50px;
  width: 100%;
  display: flex;
}

.Website_HomeImageContainer2__PqF-W {
  width: 50%;
  background-color: green;
}

.Website_detailContainer2__4wSGl {
  margin-left: 20px;
  width: 50%;
}

.Website_aboutHeading1__25fOC {
  color: var(--themeColor);
  font-size: 20px;
}

.Website_aboutHeading2__3EP-9 {
  margin-top: 20px;
  color: var(--darkText);
  font-size: 40px;
}

.Website_aboutDetails__11A4r {
  margin-top: 20px;
  width: 280px;
  font-size: 16px;
  color: var(--lightText);
}

.Website_footer__33Njg {
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #207ef5;
  padding: 10px;
}

.Website_block1__12h2g {
  width: 30%;

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.Website_logotextContainer__1JupW {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Website_logopic__21Cll {
  width: 100px;
  background-color: white;
  height: 50px;
}

.Website_websiteNametext__1SvRE {
  margin-left: 10px;
  font-size: 20px;
}

.Website_websiteDetail__1dq_v {
  margin-top: 10px;
  color: white;
  font-size: 14px;
}

.Website_block2__3nH00 {
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.Website_cont1__2uIM7 {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Website_cont1Icon__w33_1 {
  height: 30px;
  width: 30px;
  background-color: white;
  border-radius: 50%;
}

.Website_cont1Text__I0T9g {
  margin-left: 10px;
  color: white;
}

.Website_block3__HGa8- {
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.Website_block4__2SeYR {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.Website_cont4__2wGL3 {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Website_cont4Icon__3GNZb {
  height: 30px;
  width: 30px;
  background-color: white;
  border-radius: 30px;
}

.Website_cont4textContainer__2x7AE {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

/*background-image: url("tbg.png");*/

.Website_backgroundImage__yo5OG {
/*   

  background-image: url("https://media.istockphoto.com/id/941562950/photo/hand-holding-another-hand.jpg?s=1024x1024&w=is&k=20&c=2olan6rtEiTXh8DskRG1QeZHRxijJJv_iYLAbfTXnRc=");

  background-image: url("https://media.istockphoto.com/id/625736338/photo/stack-of-hands-showing-unity.jpg?s=2048x2048&w=is&k=20&c=m0q5TaMHzRZEzojxhGIFsFMCwWdHdkFwqtAAi1Bf67Q="); */

  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed; /* Optional: This property ensures that the background image remains fixed when scrolling */
}




/* .backgroundImage::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 128, 0, 0.1); 

  } */
/* ================================================ */
/* .website {
  position: relative;

} */

.Website_carousel-content__3ReBK{
  transition-timing-function: ease-out;
  
}

.Website_mainContainer__3iHi- {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Website_arrow__1fjXT {
  top: 50%;
  font-size: 24px;
  background: rgba(255, 255, 255, 0.5);
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.Website_left-arrow__3YI_Q {
  visibility: hidden;

}

.Website_right-arrow__1OKf5 {
  visibility: hidden;

}

.Header_topbar__dXW4p {
  width: 100%;
  display: flex;
  justify-content: center;
  border-style: none;
  height: 70px;
  background-color: white;
}

.Header_parentFooter__2QReY {
  width: 80%;
  padding-top: 15px;
  padding-bottom: 15px;
}

.Header_inner_topbar__3UBnv {
  width: 1200px;
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  display: grid;
  justify-self: center;
  align-self: center;
  grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
  background-color: white;
  border-style:none;
}

.Header_topNavigationBar__axKlu {
  padding-right: 10px;
  padding-left: 10px;
  width: 1200px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: none;
  border-color: red;
  align-items: center;
  /* background-color: var(--themeColor); */
  border-radius: 10px;
  /* border: 1px solid var(--themeColor); */
  border-style: none;
  background-color: var(--themeColor);
  background-color: white;
  /* margin-top: 25px; */
}

/* .inner_topbar {
  width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  display: grid;
  grid-template-columns: auto auto;
  background-color: var(--themeColor);
  margin: auto;  
} */

.Header_rightContainer__1d4DV {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: none;
  border-color: orange;
}

.Header_firstTopContainer__3ccc- {
  width: 100%;
  display: flex;
  align-items: center;
  /* justify-content: flex-end;
  align-items: center; */
}

.Header_helloToppersText__11ab3{
font-weight: bold;
margin-left: 10px;
font-size: 25px;
}



.Header_logoCSS__2nioK {
height:40px;
width:40px;
}

.Header_textEmail__1mS9H {
  margin-left: 10px;
  color: var(--lightThemeColor1);
}

.Header_secTopContainer__2fOrM {
  margin-left: 20px;
  display: flex;
}

.Header_mobileIcon__39cAi {
  margin-left: 10px;
  height: 20px;
  width: 20px;
  background-color: #5e5d5d;
}

.Header_mobileNumber__3tb6w {
  margin-left: 10px;
}

.Header_socialiconContainer__1xMNK {
  display: flex;
  justify-content: end;
  align-items: end;
  border-style: none;
  border-color: cyan;
}

.Header_innerSocialMediaContainer__1dBrB{
  display: flex;
  justify-content: end;
  align-items: end;
  border-color: cyan;
}



.Header_fbicon__Ol6I8 {
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--themeColor);
  border-style: none;
  background-color: var(--lightThemeColor1);
  cursor: pointer;
  border-radius: 50%;
  height: 30px;
  width: 30px;
}

.Header_parentFooter__2QReY {
  width: 100%;
  background-color: var(--themeColor);
  background-color: white;
}

.Header_nFirstCotainer__ola8h {
  display: flex;
  align-items: center;
  border-style: none;
  background-color: transparent;
}

.Header_nFirstCotainer__ola8h{
  cursor: pointer;
}

.Header_navigationMenu__1eXfn {
  display: flex;
  border-style: none;
}

.Header_navigationMenuMobile__3MX_j{
display: none;
border-style: solid;
border-color: red;

}



.Header_downButtonHeader__smbAI{

visibility: hidden;
}



@media (max-width: 800px) {
  .Header_inner_topbar__3UBnv {
    width: 100%;
    height: 35px;
    display: flex;
    border-style:none;
    justify-content: space-between;
  }

  .Header_topNavigationBar__axKlu {
    width: auto;
    border-style: none;
    border-color: var(--themeColor);
    border-width: 1px;
    background-color:var( --chatCommentBkgColor);
  }


  .Header_navigationMenu__1eXfn {
    flex-direction: column;
    border-style: none;
    height: 200px;
    display: none;
  }

  .Header_navigationMenuMobile__3MX_j{
  
   display: flex;
   flex-direction: column;
   border-style: none;
  }




  .Header_downButtonHeader__smbAI{
   visibility: visible;	
   border-style: none;
   width: 50px;
   color: var(--themeColor);
   background-color: white;
   cursor: pointer;
   }

   .Header_registrationButton__1wRs0{
   display: none;

   }


  .Header_socialiconContainer__1xMNK{
   width: 50%;

  }

  .Header_parentFooter__2QReY{
  
   border-radius: 20px 20px 0px 0px;

  }
  .Header_helloToppersText__11ab3{
  visibility: hidden;
  }



}

.Header_logo__2kbWP {
  height: 50px;
  width: 60px;
}

.Header_Name__2MHD5 {
  font-weight: bold;
  color: var(--themeColor);
  font-size: 20px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: var(--themeColor);
}

.Header_headerButtonDiv__1Ts3n {
  margin-left: 30px;
  border-style: none;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.Header_headerButton__10qwG {
  font-family: Roboto;
  font-size: 18px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-style: none;
  cursor: pointer;
  background-color: transparent;
}

.Header_headerButton__10qwG:hover {
  color: white;
}

.Header_underlineDiv__2COYu {
  height: 3px;
  width: 90%;
  border-radius: 10px;
  margin-top: 3px;
}

.Header_headerButtonText__1tl9I {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--themeColor);
  font-weight: bold;
  text-transform: uppercase;
}

.Header_dropdownOne__2kQgZ {
  height: 130px;
  width: 170px;
  border-style: solid;
  position: absolute;
  padding: 5px;
}

.Header_csslogo__2NidG {
  font-size: 30px;
  font-weight: bold;
  background-color: var(--themeColor);
  color: white;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-right: 10px;
  position: relative;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  /*background-image: linear-gradient(to right, var(--themeColor), #d7e7fc);*/
}

.Header_styleDiv1__QXdpT {
  width: 10px;
  color: red;
  height: 4px;
  position: absolute;
  border-style: none;
  top: 21px;
  left: 10px;
  z-index: 9999;
  border-radius: 0px 5px 5px 0px;
  background: transparent;
  background-color: var(--themeColor);
}

.Header_styleDiv2__2WZ6R {
  width: 10px;
  color: red;
  height: 4px;
  position: absolute;
  border-style: none;
  top: 13px;
  left: 10px;
  z-index: 9999;
  border-radius: 0px 5px 5px 0px;
  background-color: var(--themeColor);
}


@media (max-width: 1300px) {
  .Header_topNavigationBar__axKlu {
    width: auto;
  }






  .Header_inner_topbar__3UBnv {
    margin: auto;
    width: 90%;
    height: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .Header_textEmail__1mS9H {
    font-size: 14px;
  }

  .Header_rightContainer__1d4DV {
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: none;
    border-color: red;
    height: 100%;
    width: 100%;
    min-width: 100px;
  }


  .Header_firstTopContainer__3ccc- {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    
  }
}



.Header_loginButton__pGizD{

  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--themeColor);
  border-style: none;
  background-color: var(--lightThemeColor1);
  cursor: pointer;
  border-radius: 5px;
  height: 30px;
  width: 100px;


}


.Header_alreadyLoggedInButton__3t2sW{

  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--themeColor);
  border-style: none;
  background-color: var(--lightThemeColor1);
  cursor: pointer;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  border-style: solid;
  border-color: lightgrey;

}


.Header_dropDownBarButton__jG9Lr{
 margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--themeColor);
  border-style: none;
  background-color: var(--lightThemeColor1);
  cursor: pointer;
  border-radius: 5px;
  height: 30px;
  width: 100px;
  visibility: hidden;
}


.Header_registrationButton__1wRs0{
 margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--themeColor);
  border-style: none;
  background-color: var(--lightThemeColor1);
  cursor: pointer;
  border-radius: 5px;
  height: 30px;
  width: 100px;

}







@media (max-width: 800px) {


 .Header_registrationButton__1wRs0{
 display: none;
 }
 
 .Header_dropDownBarButton__jG9Lr{
 visibility: visible;
 }


.Header_innerSocialMediaContainer__1dBrB{

   visibility: hidden;

  }

  .Header_headerButtonDiv__1Ts3n{
  margin-bottom: 10px;
  }



.Header_fbicon1__1NWLA{
visibility: hidden;
}

.Header_fbicon__Ol6I8{
visibility: hidden;
}

}

.Header_HomeText__kKiR3 {
  font-weight: bold;
  color: var(--themeColor);
  text-transform: uppercase;
}


.Header_logoImage__3Txb0{
height: 35px;
aspect-ratio: 6.2/1;

}




.DropDownOne_dropdownOne__fwXHo{
width: 200px;
border-style: none;
position: absolute;
padding: 5px;
background-color: white;
box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
padding: 15px;
border-radius: 10px;
box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}



.DropDownOne_dropdownTwoDiv__1Q_Op{
width: 150px;
border-style: none;
position: absolute;
background-color: white;
display: flex;
flex-direction: column;
justify-content: flex-start;
visibility: hidden;
left: 50px;
padding: 20px;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
border-radius: 10px;
}




.DropDownOne_dropdownOneDiv3__2a6zQ:hover .DropDownOne_dropdownTwoDiv__1Q_Op{

visibility:visible;	

}







.DropDownOne_dropDownTwoButton__FYxL5{

 border-style: none;
 background-color: white;
 font-size: 16px;
 padding: 10px;
 cursor: pointer;
 height: 40px;
 display: flex;
 justify-content: flex-start;
}


.DropDownOne_dropDownTwoButton__FYxL5:hover{

color: var(--themeColor);	
}



.Footer_footer__2Vjt9 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  background-color: var(--themeColor);
  padding-top: 30px;
  padding-bottom: 30px;
  border-style: none;
}

.Footer_mainFooterContainer__1PWNn {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, auto));
  grid-row-gap: 20px;
  row-gap: 20px;
  grid-column-gap: 0px;
  -webkit-column-gap: 0px;
          column-gap: 0px;

  width: 100%;
  max-width: 1200px;
  margin: auto;
}

.Footer_cont44__38c6R {
  visibility: hidden;
}

@media (max-width: 1300px) {
  .Footer_mainFooterContainer__1PWNn {
    width: 80%;
  }
}

.Footer_csslogo__35LMF {
  font-size: 30px;
  font-weight: bold;
  background-color: var(--themeColor);
  color: white;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-right: 10px;
  position: relative;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  /*background-image: linear-gradient(to right, var(--themeColor), #d7e7fc);*/
}

.Footer_styleDiv1__31YEl {
  width: 10px;
  color: red;
  height: 4px;
  position: absolute;
  border-style: none;
  top: 21px;
  left: 10px;
  z-index: 9999;
  border-radius: 0px 5px 5px 0px;
  background: transparent;
  background-color: var(--themeColor);
}

.Footer_otherLink__32TVP {
  color: white;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
}

.Footer_block1__NO3vh {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.Footer_logotextContainer__3OmHh {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.Footer_logopic__GzFFz {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 50px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Footer_websiteNametext__2BHZ7 {
  margin-left: 10px;
  font-size: 20px;
}

.Footer_websiteDetail__fY4xX {
  margin-top: 10px;
  color: var(--footerText);
  color: white;
  font-size: 14px;
  text-align: justify;
  width: 80%;
}

.Footer_block2__39Z-e {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-content: center;
}

.Footer_cont1__2nD6g {
  margin-top: 16px;
  display: flex;
  justify-content: start;
  align-items: center;
  color: var(--footerText);
  font-size: 16px;
  background-color: transparent;
  border: none;
}

.Footer_cont1__2nD6g:hover {
  cursor: pointer;
}

.Footer_cont2__SrsQh {
  margin-top: 16px;
  display: flex;
  justify-content: s;
  align-items: center;
  color: var(--footerText);
  font-size: 16px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.Footer_cont1Icon__UDIqV {
  height: 10px;
  width: 10px;
  background-color: white;
  border-radius: 50%;
}

.Footer_cont1Text__2FQCn {
  margin-left: 10px;
  color: white;
  font-size: 14px;
}

.Footer_block3__3c9Fo {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.Footer_block4__24NQl {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.Footer_cont4__noGNV {
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.Footer_cont4Icon__fw95c {
  height: 20px;
  width: 20px;
  background-color: #185fb8;
  background: linear-gradient(#dfe3e9, #ffffff);
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--themeColor);
  padding: 10px;
  margin-right: 6px;
}

.Footer_cont4textContainer__3-Ij6 {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  color: white;
}

.Footer_cont4Title1__2rbOG {
  font-size: 14px;
}

.Footer_cont4Title2__OwpL2 {
  margin-top: 5px;
  font-size: 14px;
}

.Footer_horiLine__xAGLX {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 98%;
  height: 1px;
  border-bottom: 1px solid #70a188;
  margin-top: 20px;
  margin-bottom: 20px;
}

.Footer_bottomBar__byU4Z {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.Footer_text1__2woX0 {
  color: white;
}

.Footer_cmpDetail__ECJYf {
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Footer_powerBy__WOLys {
  margin-right: 10px;
  color: white;
}

.Footer_footerLogoBtn__3imWB {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
}

.Footer_footerLogoBtn__3imWB:hover {
  cursor: pointer;
}

.Footer_logo__1moVx {
  height: 35px;
  width: 35px;
  background-color: #185fb8;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  color: white;
  font-size: 18px;
}
.Footer_websiteDetails__3kMsQ {
  margin-left: 10px;
  background-color: transparent;
  border: none;
  font-size: 16px;
  color: white;
  font-weight: 600;
  cursor: pointer;
}

.Home_homeParent__2q6-Q {
  width: 100%;
}

.Home_topBar__2oT1w {
  width: 100%;
  height: 30px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Home_National__2rtmi {
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--lightText);
}

.Home_State__3TGXJ {
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--lightText);
}

.Home_newsBlock__2-M5K {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, auto));
  grid-row-gap: 10px;
  row-gap: 10px;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

.Home_latest__EKOQ- {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.Home_latestTitle__1xW46 {
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
}

.Home_latestsubTitle__2m8AL {
  margin-top: 20px;
  font-weight: bold;
  color: #08203d;
  line-height: 55px;
  font-size: 45px;
}

.Home_panImageBkg__1K5F2 {
  background-color: #e7efeb;
  background-repeat: no-repeat;
  height: auto;
  background-image: url("https://images.unsplash.com/photo-1523192193543-6e7296d960e4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80");
}

.Home_newsBlock__2-M5K {
  width: 100%;
}

.Home_stateNewsBlock__2aW-7 {
  width: 100%;
}


.Home_mainExamTitle__2LnNH {
  width: 100%;
  margin-top: 40px;
  font-size: 40px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--deepDarkThemeTextColor);
}



.Home_HowitWorksContainer__L2ux1 {
  margin-top: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--chatCommentBkgColor);
  padding-top: 40px;
  padding-bottom: 60px;
  border-style: none;
}

.Home_HowitworksTitle__21rYw {
  font-size: 40px;
  font-weight: bold;
  color: var(--deepDarkThemeTextColor);
  background-color:var(--chatCommentBkgColor);
  margin-bottom: 50px;
}

.Home_helloTopContainer__2qPmU {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-auto-rows: auto;
  grid-row-gap: 30px;
  row-gap: 30px;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  border-style: none;
  background-color: var(--chatCommentBkgColor);
}

.Home_mainWhytoppersContainer__1ju6g {
  margin-top: 30px;
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Home_whyToppersTitle__VqmF0 {
  font-size: 40px;
  font-weight: bold;
  color: var(--deepDarkThemeTextColor);
}

.Home_whyToppersContainer__1rytw {
  margin-top: 50px;

  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-auto-rows: auto;
  grid-row-gap: 30px;
  row-gap: 30px;
  grid-column-gap: 30px;
  -webkit-column-gap: 30px;
          column-gap: 30px;
}

.Home_ExamContainer__2uNv6 {
  margin-top: 30px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-row-gap: 30px;
  row-gap: 30px;
  grid-column-gap: 30px;
  -webkit-column-gap: 30px;
          column-gap: 30px;
}

.Home_bottombox__2Sjgw {
  margin-top: 50px;
  padding-bottom: 30px;
  padding-top:50px;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--chatCommentBkgColor);
  border-style: none;

 /* box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;*/
}

.Home_marginDivBottom__nDpvz{
border-style: solid;
width:80%;
}



.Home_bTitle__2dasK {
  margin-top: 15px;
  font-size: 30px;
  font-weight: bold;
  color: var(--deepDarkThemeTextColor);
}

.Home_bdetails__Ek-ae {
  margin-top: 15px;
  font-size: 20px;
}

.Home_bbutton__gXl4F {
  margin-top: 25px;
  width: 150px;
  height: 40px;
  border-radius: 4px;
  background-color: var(--themeColor);
  color: white;
  font-size: 16px;
  border: none;
}

.Home_bbutton__gXl4F:hover {
  border: 1px solid var(--themeColor);
  background-color: white;
  cursor: pointer;
  color: var(--themeColor);
}

.Home_paarent__2vY46 {
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px; 
  background-color: white;
}

.Home_paarent__2vY46:hover {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}


.Home_boxSlideMobile__1B60L{
  display: none;
}



.Home_logo__yTfcW {
  margin-top: 30px;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Home_imagesExam__KH-TF {
  width: 80px;
  height: 80px;
  transition: all 0.3s ease;
}

.Home_imagesExam__KH-TF:hover {
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
}


.Home_title__2Z19u {
  margin-top: 20px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.Home_description__2SCwo {
  margin-top: 20px;
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
  width: 90%;
}

@media (max-width: 1300px) {
  .Home_latestTitle__1xW46 {
    font-size: 20px;
  }

  .Home_latestsubTitle__2m8AL {
    margin-top: 10px;
    font-size: 30px;
  }

  .Home_homeParent__2q6-Q {
    width: 98%;
  }

  .Home_mainExamTitle__2LnNH {
    width: 100%;
    text-align: center;
    font-size: 25px;
  }

  .Home_HowitworksTitle__21rYw {
    width: 100%;
    text-align: center;
    font-size: 25px;
  }

  .Home_HowitWorksContainer__L2ux1 {
  }

  .Home_paarent__2vY46 {
    width: 100%;
  }

  .Home_whyToppersTitle__VqmF0 {
    width: 100%;
    text-align: center;
    font-size: 25px;
  }

  .Home_whyToppersContainer__1rytw {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .Home_bottombox__2Sjgw {
    width: 100%;
  }

  .Home_bTitle__2dasK {
    font-size: 22px;
    text-align: center;
  }
  .Home_bdetails__Ek-ae {
    font-size: 18px;
    text-align: justify;
  }
}



@media (max-width: 600px) {
.Home_boxSlideMobile__1B60L{
display: block;
border-style: none;
}

.Home_boxSlideDesktop__33LQq{
display: none;
}

.Home_mainExamTitle__2LnNH{
}




}

.IntroductionBlock_MainIntroductionBlockParent__2HbTL {
  width: 100%;
  display: flex;
  background-color: var(--chatCommentBkgColor);
  display: flex;
  justify-content: center;
  align-items: center;
  /*background-image:url("./firstblockimage1.png");*/
}

.IntroductionBlock_IntroductionBlockParent__r_PRP {
  border-style: none;
  width: 1200px;
  height: 500px;
  display: flex;
}

.IntroductionBlock_leftBox__1v_fv {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
}

.IntroductionBlock_rightBox__2TYZ2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.IntroductionBlock_img1__2HG3e {
  width: 200px;
  height: 350px;
  object-fit: fill;
  margin-right: 10px;
  margin-bottom: 100px;
}

.IntroductionBlock_img2__28r4x {
  object-fit: fill;

  width: 250px;
  height: 450px;
}



.IntroductionBlock_imgOneBig__2hawO{
 object-fit: fill;
 width:580px;

}





.IntroductionBlock_mainCard__1vclk {
  min-width: 350px;
  width: 65%;
  height: 300px;
  background-color: white;
  border-radius: 10px;
  padding: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
}

.IntroductionBlock_mainheadings__2nsvY {
  font-size: 35px;
  font-weight: 800;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: var(--themeColor);
  border-style: none;
  width: 100%;
  margin-left:30px;
}

.IntroductionBlock_searchboxmaterial__2G5ss {
  margin-top: 20px;
  width: 90%;

  background-color: white;
  border-radius: 10px;
  height: 35px;
  font-size: 14px;
  border: 1px solid var(--cardBorderColor);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-style: none;
}

.IntroductionBlock_searchbyexamEditBox__wRm0n {
  border: none;
  width: 80%;
  height: 30px;
  border-radius: 10px;
  margin-left: 10px;
}

.IntroductionBlock_searchbyexamEditBox__wRm0n:focus {
  border-style: solid;
  background-color: white;
  border-width: 1px;
  outline: none !important;
  border: none;
}

.IntroductionBlock_dropdownContainer__3mTr7 {
  width: 20%;
  height: 37px;
  background-color: var(--themeColor);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.IntroductionBlock_downArrow__3sp6W {
  color: white;
  height: 20px;
  width: 20px;
}

.IntroductionBlock_dropdownContainer__3mTr7:hover {
  cursor: pointer;
}

.IntroductionBlock_btnTitle__1v2AI {
  margin-top: 20px;
  width: 90%;
  color: white;
  background-color: var(--themeColor);
  border-radius: 10px;
  border: none;
  height: 35px;
  font-size: 16px;
}

.IntroductionBlock_dropDownListitemmenu__1WTHE {
  width: 200px;
}

.IntroductionBlock_btnTitle__1v2AI:hover {
  cursor: pointer;
  border: 1px solid var(--themeColor);
  color: var(--themeColor);
  background-color: white;
}

.IntroductionBlock_websiteContent__3ZCdY {
  width: 100%;
  display: flex;
  border-style: none;
  border-color: red;
}

.IntroductionBlock_detailContainer__1mz05 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-style: none;
  color: green;
  width: 50%;
  padding-top: 50px;
}

.IntroductionBlock_HomeImageContainer1__2oBpT {
  width: 50%;
  display: flex;
  justify-content: start;
  margin-top: 50px;
  border-style: none;
  border-color: cyan;
}

.IntroductionBlock_rightImage__1F8Z0 {
  border-style: none;
  color: orange;
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 10px;
}

.IntroductionBlock_instructionInfo__RPlJn {
  border-style: none;
  color: black;
  font-size: 18px;
  text-align: left;
  width: 100%;
}

.IntroductionBlock_btnContainer__2kYC1 {
  margin-right: 40px;
  justify-content: center;
  width: 100%;
  display: flex;
  flex-direction: column;

  padding: 20px;
}

.IntroductionBlock_btn1__2mzxe {
  font-weight: bold;

  width: 100%;
  height: 30px;
  border-radius: 4px;
  background-color: white;
  border: none;
  border: 1px solid var(--themeColor);
  color: var(--themeColor);
  font-size: 16px;
  display: flex;
  justify-content: start;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}

.IntroductionBlock_btn1__2mzxe:hover {
  background-color: var(--themeColor);
  color: white;
  cursor: pointer;
}

.IntroductionBlock_btn2__1GkLO {
  margin-top: 16px;
  font-weight: bold;

  width: 100%;
  height: 30px;
  border-radius: 4px;
  background-color: white;
  border: none;
  border: 1px solid var(--themeColor);
  color: var(--themeColor);
  font-size: 16px;
  display: flex;
  justify-content: start;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}

.IntroductionBlock_btn2__1GkLO:hover {
  background-color: var(--themeColor);
  color: white;
  cursor: pointer;
}

.IntroductionBlock_btn3__3VQXe {
  margin-top: 16px;
  font-weight: bold;

  width: 100%;
  height: 30px;
  border-radius: 4px;
  background-color: white;
  border: none;
  border: 1px solid var(--themeColor);
  color: var(--themeColor);
  font-size: 16px;
  display: flex;
  justify-content: start;
  align-items: center;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}

.IntroductionBlock_btn3__3VQXe:hover {
  background-color: var(--themeColor);
  color: white;
  cursor: pointer;
}

.IntroductionBlock_btn4__fX6zS {
  font-weight: bold;
  margin-top: 16px;
  width: 100%;
  height: 30px;
  border-radius: 4px;
  background-color: white;
  border: none;
  border: 1px solid var(--themeColor);
  color: var(--themeColor);
  font-size: 16px;
  display: flex;
  justify-content: start;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}

.IntroductionBlock_btn4__fX6zS:hover {
  background-color: var(--themeColor);
  color: white;
  cursor: pointer;
}

.IntroductionBlock_detailContainer1__1jkgI {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-style: none;
  width: 100%;
  border-color: orange;
}

.IntroductionBlock_detailContainer2__3VqP7 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-style: none;
  width: 100%;
  border-color: orange;
}

.IntroductionBlock_odisaMap__2O2Dk {
  width: 100%;
}

.IntroductionBlock_textHeading1__2FY2H {
  padding-top: 90px;
  width: 180px;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;

  /* margin-top: 100px;
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase; */
  background-color: transparent;
  fill: var(--themeColor);
}

.IntroductionBlock_textHeading2__2LVnd {
  width: 100%;
  margin-top: 50px;
  font-weight: bold;
  color: white;
  line-height: 55px;
  font-size: 40px;
  border-radius: 10px;
  text-align: left;
  color: black;
}

.IntroductionBlock_textHeading3__20gHW {
  display: flex;
  justify-content: flex-start;
  border-style: none;
  width: 100%;
  border-color: red;
}

.IntroductionBlock_findTopperButton__SZUyB {
  width: 353px;
  height: 66px;
  border-radius: 5px;
  border-style: none;
  cursor: pointer;
  color: var(--themeColor);
  font-size: 20px;
}

.IntroductionBlock_findTopperButton__SZUyB:hover {
  color: white;
  background-color: var(--themeColor);
}

.IntroductionBlock_leftTopHeadingText__1c-8Y {
  text-align: left;
}

.IntroductionBlock_textDetails__1fWsO {
  visibility: hidden;
  margin-top: 30px;
  color: var(--darkText);
  font-size: 18px;
  text-align: justify;
  line-height: 34px;
  font-weight: bolder;
  background-color: #e7efeb;
  border-radius: 10px;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

@media (max-width: 800px) {
  .IntroductionBlock_websiteContent__3ZCdY {
    flex-direction: column;
  }
  .IntroductionBlock_detailContainer__1mz05 {
    width: 100%;
  }

  .IntroductionBlock_HomeImageContainer1__2oBpT {
    width: 100%;
  }

  .IntroductionBlock_textHeading2__2LVnd {
    font-size: 20px;
  }

  .IntroductionBlock_imgOneBig__2hawO{
  width: 100%;
  margin: auto;
  display: block; 
  }

  .IntroductionBlock_img1__2HG3e{
  display: none;
  }

  .IntroductionBlock_img2__28r4x{
  display: none;
  }




}

.IntroductionBlock_subHead__31CyX{
font-weight: bold;
}


@media (max-width: 1300px) {

.IntroductionBlock_IntroductionBlockParent__r_PRP {
width:80%;
}	
}


@media (max-width: 1000px) {

  .IntroductionBlock_MainIntroductionBlockParent__2HbTL{
    width: 98%;
    padding: 10px;
  }
  .IntroductionBlock_IntroductionBlockParent__r_PRP {
  width: 100%;
  height: auto;
    display: flex;
    flex-direction: column-reverse;
  }

  .IntroductionBlock_rightBox__2TYZ2 {
    width: 95%;
    display: flex;

  }

  .IntroductionBlock_img1__2HG3e{
    padding: 20px;
    width: 130px;
    height: 220px;

  }

  .IntroductionBlock_img2__28r4x{
  
    width: 130px;
    height: 220px;

  }

  .IntroductionBlock_leftBox__1v_fv{
    width: 100%;
    display: flex;
    justify-self: center;
    margin-bottom: 40px;
  }

  .IntroductionBlock_mainCard__1vclk{
width: 100%;
height: 100%;
min-width: 0;
  }

  .IntroductionBlock_mainheadings__2nsvY{
   font-size: 25px;
   text-align: center;
  }

  .IntroductionBlock_subHead__31CyX{
    margin-top: 10px;
    text-align: center;
  }

}




.OptionField_name_div__32_uY{
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
border-style: none;
margin-top: 10px;
position: relative;
width: 100%;
border-width: 1px;
height: 35px;
}

.OptionField_input_field__sNSdO{
position: relative;
width: 100%;
height: 100%;
box-sizing: border-box;
border-style:solid;
border-width: 1px;
border-color: lightgrey;
background-color: white;
border-radius: 10px 10px 10px 10px;
padding-left: 10px;
padding-right: 10px;
font-size: 16px;

}
.OptionField_input_field__sNSdO:focus {
    border-style: solid;
    border-width: 1px;
    outline: none !important;
    border-color: var(--themeColor);
    border-radius:10px 10px 10px 10px;
}






.CenterColumnLeftRightGrid_centerImageLeftRightGrid__3vOgo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.CenterColumnLeftRightGrid_title__IJ0TB {
  margin-top: 40px;
  color: blue;
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
}

.CenterColumnLeftRightGrid_subTitle__1mWsx {
  margin-top: 20px;
  font-weight: bold;
  color: #08203d;
  line-height: 55px;
  font-size: 45px;
}

.CenterColumnLeftRightGrid_details__bAdbE {
  margin-top: 40px;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  color: var(--lightText);
}

.CenterColumnLeftRightGrid_serviceSection__1dfh9 {
  margin-top: 20px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-auto-rows: auto;
  grid-row-gap: 10px;
  row-gap: 10px;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

.CenterColumnLeftRightGrid_leftSideContent__2VgLy {
  width: 100%;

}

.CenterColumnLeftRightGrid_features__1dNIB {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--themeColor);
  font-weight: bold;
}

/* .a1box {
  max-height: 2.4em;
  overflow: hidden;
  transition: max-height 0.3s ease; 
}

.a1box:hover {
  max-height: none; 
} */

.CenterColumnLeftRightGrid_serviceImage__1FLJy {
  height: 60px;
  width: 60px;
  background-color: white;
  visibility: hidden;
}

.CenterColumnLeftRightGrid_a1box__3r_tH {
  height: auto;
  margin: 12px;
  padding: 8px;
  border: 1px solid var(--cardBorderColor);
  border-radius: 1px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  overflow: hidden;
}

.CenterColumnLeftRightGrid_serviceHeading1__3qd08 {
  font-size: 18px;
  font-weight: 500;
  font-weight: bold;
  color: #08203d;
}

.CenterColumnLeftRightGrid_serviceHeading2__TdJ_d {
  margin-top: 6px;
  font-size: 14px;
  color: var(--lightText);
  line-height: 23px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  transition: max-height 0.3s ease;
}

.CenterColumnLeftRightGrid_serviceHeading2__TdJ_d:hover {
  height: auto;
  max-height: none;
  cursor: pointer;
}


.CenterColumnLeftRightGrid_a1box__3r_tH:hover .CenterColumnLeftRightGrid_serviceHeading2__TdJ_d{
  -webkit-line-clamp: 8;
  transition: max-height 0.3s ease;
}

.CenterColumnLeftRightGrid_centerImage__I9U9D {
  margin-top: 35px;
  width: 100%;
  height: 620px;
  min-height: 620px;
  max-height: 620px;
  background-color: white;
  object-fit: cover;
  border-style: none;
  border-radius: 10px;
  display: flex;


}

.CenterColumnLeftRightGrid_rightSideContent__3Wwqw {
  width: 100%;

}

@media (max-width: 1300px) {
  .CenterColumnLeftRightGrid_title__IJ0TB {
    font-size: 20px;
  }

  .CenterColumnLeftRightGrid_subTitle__1mWsx {
    margin-top: 10px;
    font-size: 30px;
  }
  .CenterColumnLeftRightGrid_a1box__3r_tH {
    width: auto;
    padding: 10px;
    margin-top: 10px;
  }

  .CenterColumnLeftRightGrid_centerImage__I9U9D {
    width: 96%;
    display: flex;
    justify-self: center;
    align-self: center;
    
  }

  .CenterColumnLeftRightGrid_serviceSection__1dfh9 {
    margin-top: 20px;
    width: auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-auto-rows: auto;
    grid-row-gap: 10px;
    row-gap: 10px;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
  }
}

.Testimonial_ourClientDetails__30Fm7 {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.Testimonial_clientTitle__GbhQa {
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
}

.Testimonial_clientSubTitle__2ZGWY {
  margin-top: 20px;
  font-weight: bold;
  color: var(--deepDarkThemeTextColor);
  line-height: 55px;
  font-size: 40px;
}

.Testimonial_clientFeedbackContainer__3JIdY {
  margin-top: 50px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-auto-rows: auto;
  grid-row-gap: 30px;
  row-gap: 30px;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;

  /* display: flex;
  overflow-x: scroll;
  white-space: nowrap; */
}

.Testimonial_aboutClinetBox__3Jpjh {
  width: auto;
  background-color: white;
  margin: 10px;
  padding: 20px;
  border-radius: 1px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.Testimonial_aboutClinetBox__3Jpjh:hover {
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.Testimonial_clientImg__1nZob {
  margin-top: -50px;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.Testimonial_clientFeedback__KV150 {
  display: flex;
  justify-content: space-between;
}

.Testimonial_socialNetworkImage__25f4X {
  visibility: hidden;
  height: 30px;
  width: 30px;
  color: #00acee;
}

.Testimonial_clientDetails__1bdJL {
  font-size: 14px;
  margin-top: 10px;
  color: var(--lightText);
  line-height: 24px;
  text-align: justify;
}

.Testimonial_clientName__1W4U5 {
  margin-top: 20px;
  font-size: 18px;
  font-weight: 500;
  color: #08203d;
}

.Testimonial_clientStatus__1XuHk {
  margin-top: 10px;
  color: var(--themeColor);
  font-size: 14px;
}

@media (max-width: 1300px) {
  .Testimonial_TestimonialParent__3HHE0 {
   border-style: none;	  
  }

  .Testimonial_clientTitle__GbhQa {
    font-size: 20px;
    text-align: center;
  }

  .Testimonial_clientSubTitle__2ZGWY {
    margin-top: 10px;
    font-size: 30px;
    text-align: center;
  }

  .Testimonial_clientFeedbackContainer__3JIdY{
    display: flex;
    align-items: center;
    flex-direction: column;
  }

}


.Testimonial_@media__28-tS (max-width: 600px) {

.Testimonial_clientFeedbackContainer__3JIdY{
  display: flex;
  overflow-y: scroll;
  overflow-x:hidden;
}


}



.Pricing_subscriptionDetails__7_aSD {
  display: flex;
  margin-top: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Pricing_PlanTextTitle__1fGCM {
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.Pricing_PlanTextsubTitle__2CVGx {
  margin-top: 20px;
  font-weight: bold;
  color: #08203D;
  line-height: 55px;
  font-size: 45px;
  
}

.Pricing_planContainer__21CgL {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.Pricing_basicPlan__19cHP {
  margin: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 33.33%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  border-radius: 8px;
}

.Pricing_basicPlanContent__15tMi {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Pricing_pic__1OaKU {
  margin-top: 20px;
  height: 60px;
  width: 60px;
  background-color: white;
  border-radius: 50%;
  border: 1px solid var(--cardBorderColorLight);
  display: flex;
  justify-content: center;
  align-items: center;
}


.Pricing_rupees_icon__22ll4{
  height: 30px;
  width: 30px;
  color: var(--lightText);
}

.Pricing_planTitle__2PnLY {
  margin-top: 20px;
  font-size: 25px;
  color: var(--darkText);
  letter-spacing: 5px;
  font-weight: bold;
}

.Pricing_aboutPrice__2XCJj {
  margin-top: 20px;
  display: flex;
  justify-content: end;
  align-items: end;
}

.Pricing_amount__3AJnu {
  font-size: 40px;
  font-weight: bold;
  color: var(--themeColor);
}

.Pricing_amountMonth__1Nndx {
  font-size: 20px;
  display: flex;
  align-items: end;
  font-weight: bold;
  color: var(--lightText);
}

.Pricing_horizontalLine__3kQAT {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  background-color: var(--darkText);
  border-bottom: 1px solid var(--cardBorderColor);
}

.Pricing_features__1ufAA {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
}

.Pricing_checkIcon__1q-Vy {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: gray;
}

.Pricing_textplanDetails__3TTmt {
  margin-left: 10px;
  color: var(--lightText);
}

.Pricing_basicPlanBtn__tk6jM {
  height: 24px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: white;
  color: var(--themeColor);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  border-radius: 30px;
  margin-bottom: 40px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 6px;
  padding-bottom: 6px;
  border: 1px solid var(--themeColor);
}

.Pricing_standardPlan__FswLz {
  width: 33.33%;
  margin: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 33.33%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.Pricing_unlimitedPlan__2zk0K {
  margin: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 33.33%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  border-radius: 8px;
  width: 33.33%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.FAQ_faqContent__2e4kQ {
  margin-top: 40px;
  width: 100%;
  display: grid;
  justify-content: flex-end;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  grid-row-gap: 20px;
  row-gap: 20px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  padding-bottom: 40px;
  padding-top: 30px;
}

.FAQ_faqdetailContainer__FChNN {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
}

.FAQ_faqImages__3ff5j {
  margin-left: 20px;
  margin-right: 20px;
  width: 92%;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio:  calc(2729 / 2674 * 100%);;
  background-color: var(--themeColor);
  border-radius: 10px;
  background-color: red;
  
}

.FAQ_faqtextHeading1__1JEk1 {
  margin-left: 20px;

  display: flex;
  justify-content: start;
  align-items: start;
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.FAQ_qaContainer__FDX2L {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 10px;
  background-color: var(--bgColor);
  border: 1px solid var(--cardBorderColor);
  border-radius: 10px;
}

.FAQ_questionsText__HT8VF {
  margin-left: 10px;
}

.FAQ_questionsAnsContainer__ivyn5 {
  display: flex;
  justify-content: start;
  align-items: center;
}
.FAQ_imgarrowContainer__2vfhC {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: var(--themeColor);
}

.FAQ_ansText__1rlR- {
  margin-left: 20px;

  margin-top: 10px;
  color: var(--lightText);
}

.TeamBlock_ourTeam__2nxBy {
  width: 100%;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.TeamBlock_ourTeamTitle__3C7sd {
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
}

.TeamBlock_ourTeamSubTitle__QYOb6 {
  margin-top: 20px;
  font-weight: bold;
  color: #08203d;
  line-height: 55px;
  font-size: 45px;
}

.TeamBlock_allTeamMemberdetail__bsYYj {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, auto));
  grid-row-gap: 10px;
  row-gap: 10px;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

.TeamBlock_ourTeamdetailContainer__1XBgH {
  width: auto;
  margin: 10px;
  padding: 10px;
  border: 1px solid var(--cardBorderColor);
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.TeamBlock_developerPic1__io2p3 {
  border-radius: 10px;
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;

  background-color: #207ef5;
}

.TeamBlock_developerPic2__2rsR5 {
  border-radius: 10px;

  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;

  background-color: #207ef5;
}

.TeamBlock_developerPic3__3-6hM {
  border-radius: 10px;

  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;

  /* aspect-ratio:calc(1600 * 1067 /100%); */
  background-color: #207ef5;
}

.TeamBlock_developerPic4__lk9r2 {
  border-radius: 10px;

  width: 100%;
  aspect-ratio: 5/5;
  object-fit: cover;
  background-color: #207ef5;
}

.TeamBlock_developerName__DMtxC {
  margin-top: 16px;
  font-size: 18px;
  font-weight: 500;
  color: var(--darkText);
}

.TeamBlock_developerRole__3cr5z {
  font-size: 16px;
  margin-top: 10px;
  color: var(--themeColor);
}

.TeamBlock_socialNetworking__1l49J {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.TeamBlock_pic1__2jgcf {
  height: 20px;
  width: 20px;
  background-color: #e3effe;
  border-radius: 50%;
  border: 1px solid var(--cardBorderColor);
  color: var(--themeColor);
  padding: 8px;
  border-style: none;
}

.TeamBlock_pic2__14lyr {
  margin-left: 16px;
  margin-right: 16px;
  height: 20px;
  width: 20px;
  padding: 8px;
  background-color: #e3effe;
  border-radius: 50%;
  border: 1px solid var(--cardBorderColor);
  color: var(--themeColor);
  border-style: none;
}

.TeamBlock_pic3__3kDYd {
  height: 20px;
  width: 20px;
  padding: 8px;
  background-color: #e3effe;
  border-radius: 50%;
  border: 1px solid var(--cardBorderColor);
  color: var(--themeColor);
  border-style: none;
}

@media (max-width: 1300px) {
  .TeamBlock_ourTeamTitle__3C7sd {
    font-size: 20px;
  }

  .TeamBlock_ourTeamSubTitle__QYOb6 {
    margin-top: 10px;
    font-size: 30px;
  }
}

.Apointment_bookAppointment__1VfsX {
  margin-top: 40px;
  display: flex;
  width: 100%;
  background-color: white;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));

  background-color: var(--cardBorderColorLight);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.Apointment_bookpic__-nNKg {
  width: 100%;
  background-color: white;

  image-resolution: from-image;
  height: 350px;
}

.Apointment_bookDetail__1MLjs {
  width: 100%;
  height: 350px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: start;
}

.Apointment_bookDetailContainer__1HDfb {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  align-content: center;
  padding-left: 30px;
}

.Apointment_bookTitle__2g33Y {
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
}

.Apointment_booksubTitle__3N40s {
  margin-top: 30px;
  width: 90%;
  font-weight: bold;
  color: #08203d;
  line-height: 55px;
  font-size: 45px;
}

.Apointment_bookaptBtn__1XWl- {
  margin-top: 30px;
  padding: 10px;
  background-color: var(--themeColor);
  border-radius: 10px;
  color: white;
  border: none;
}
.Apointment_bookaptBtn__1XWl-:hover{
  cursor: pointer;

}

@media (max-width: 1300px) {
  .Apointment_bookTitle__2g33Y {
    margin-top: 10px;
    font-size: 20;
  }

  .Apointment_bookDetailContainer__1HDfb {
    padding-left: 20px;
  }
  .Apointment_booksubTitle__3N40s {
    margin-top: 20px;
    font-size: 24px;
    line-height: 35px;
  }

  .Apointment_textDetails__3-_cH {
    margin-top: 10px;
    font-size: 18px;
  }
}

.BlogAndNewsBlock_latest__3p3dt {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.BlogAndNewsBlock_latestTitle__1uGa3 {
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
}

.BlogAndNewsBlock_latestsubTitle__2ZxBa {
  margin-top: 20px;
  font-weight: bold;
  color: #08203d;
  line-height: 55px;
  font-size: 45px;
}
.BlogAndNewsBlock_newsContainer__1Bnil {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, auto));
  grid-row-gap: 10px;
  row-gap: 10px;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

.BlogAndNewsBlock_singleContainer__3-a5o {
  margin: 10px;
  height: 500px;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.BlogAndNewsBlock_newsImage__293Bt {
  width: 100%;
  height: 200px;
  max-height: 200px;
  min-height: 200px;
  background-color: white;
}

.BlogAndNewsBlock_newsTitle__1b-16 {
  margin-top: 20px;
  margin-left: 10px;
  font-size: 20px;
  font-weight: 500;
  color: #08203d;
}

.BlogAndNewsBlock_newsDesc__29MgQ {
  margin-top: 10px;
  margin-left: 10px;
  font-size: 15px;
  color: var(--lightText);
  text-align: justify;
  margin-right: 10px;
  line-height: 19px;
  height: 180px;
  max-height: 180px;
  min-height: 180px;
}

.BlogAndNewsBlock_readMoreBtn__1bwbl {
  margin-left: 10px;
  font-size: 16px;
  color: var(--lightText);
  margin-bottom: 20px;
  color: var(--themeColor);
  padding: 6px;
  border-radius: 2px;
}

@media (max-width: 1300px) {
  .BlogAndNewsBlock_latestTitle__1uGa3 {
    font-size: 20px;
  }

  .BlogAndNewsBlock_latestsubTitle__2ZxBa {
    margin-top: 10px;
    font-size: 30px;
  }
}


.SingleBlog_singleContainer__xTdk1 {
    margin: 10px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  }
  
  .SingleBlog_newsImage__3uSGB {
    width: 100%;
    height: 200px;
    max-height: 200px;
    min-height: 200px;
    background-color: white;
    object-fit: cover;
  }
  
  .SingleBlog_newsTitle__35Aw3 {
    margin-top: 20px;
    margin-left: 10px;
    font-size: 20px;
    font-weight: 500;
    color: #08203d;
  }
  
  .SingleBlog_newsDesc__1k7mF {
    margin-top: 10px;
    margin-left: 10px;
    font-size: 15px;
    color: var(--lightText);
    text-align: justify;
    margin-right: 10px;
    line-height: 19px;
    height: auto;
    /* max-height: 180px;
    min-height: 180px; */
  }
  
  .SingleBlog_readMoreBtn__1CDjO {
    margin-top: 20px;
    font-weight: bold;
    margin-left: 10px;
    font-size: 16px;
    color: var(--lightText);
    margin-bottom: 20px;
    color: var(--themeColor);
    padding: 6px;
    border-radius: 2px;
    border: none;
    background-color: transparent;
  }
  .SingleBlog_readMoreBtn__1CDjO:hover{
    cursor: pointer;
  }
  
.LeftImageRightText_websiteContent2__2E-_p {
  margin-top: 100px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-row-gap: 10px;
  row-gap: 10px;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.LeftImageRightText_HomeImageContainer2__rLald {
  width: 100%;
  height: 400px;
  background-color: green;
  border-radius: 10px;
  object-fit: cover;

}

.LeftImageRightText_detailContainer2__3YUG- {
  width: 100%;
}

.LeftImageRightText_aboutHeading1__3iREq {
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
}

.LeftImageRightText_aboutHeading2__1-hWe {
  font-weight: bold;
  color: #08203d;
  line-height: 28px;
  font-size: 25px;
}

.LeftImageRightText_aboutDetails__29Jbh {
  margin-top: 20px;
  font-size: 18px;
  color: var(--lightText);
  line-height: 32px;
  text-align: justify;
}

@media (max-width: 1300px) {
  .LeftImageRightText_detailContainer2__3YUG- {
  }
  .LeftImageRightText_aboutHeading1__3iREq {
    font-size: 20;
  }
  .LeftImageRightText_aboutHeading2__1-hWe {
    margin-top: 10px;
    font-size: 30px;
    line-height: 40px;
  }

  .LeftImageRightText_aboutDetails__29Jbh {
    margin-top: 10px;
    font-size: 18px;
    line-height: 30px;
  }
}

.OneNewsBlock_parentNews__1NdPu {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.OneNewsBlock_latest__3ykIR {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.OneNewsBlock_latestTitle__gkyKG {
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
}

.OneNewsBlock_latestsubTitle__26aXd {
  margin-top: 20px;
  font-weight: bold;
  color: #08203d;
  line-height: 55px;
  font-size: 45px;
}

.OneNewsBlock_newsContainer__2uw0j {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.OneNewsBlock_newsBlock1__3_r8q {
  margin-bottom: 20px;
  width: 100%;
  height: auto;
  display: flex;
  padding: 20px;
  border-radius: 10px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.OneNewsBlock_imgAndHeading__Jc9lx {
  width: 40%;
}

.OneNewsBlock_mainImg__ZZWLH {
  width: 100%;
  height: 100%;
  background-color: aqua;
  border-radius: 10px;
  object-fit: cover;
}

.OneNewsBlock_mainTitle__2rM_B {
  color: var(--darkText);
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
}

.OneNewsBlock_contentDetails__3CjEf {
  margin-left: 16px;
  width: 60%;
}

.OneNewsBlock_newsDetails__awXnW {
  margin-top: 20px;
  text-align: justify;
  line-height: 26px;
  font-size: 18px;
  color: var(--lightText);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 7;
}

.OneNewsBlock_readMore__1hGoi {
  float: left;
  margin-top: 20px;
  width: 120px;
  height: 30px;
  display: flex;
  justify-content: end;
  color: white;
  font-weight: 500;
  border: none;
  padding: 6px;
  background-color: var(--themeColor);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  font-size: 12px;
}

.OneNewsBlock_readMore__1hGoi:hover {
  cursor: pointer;
}

/* .newsBlock2 {
  width: 100%;
  height: 210px;
  display: flex;
  margin-left: 20px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
} */

@media (max-width: 1000px) {
  .OneNewsBlock_parentNews__1NdPu {
    width: 100%;
    margin: 0;
    font-size: 14px;
  }

  .OneNewsBlock_imgAndHeading__Jc9lx {
    width: 100%;

    height: 400px;
    object-fit: cover;
  }

  .OneNewsBlock_newsBlock1__3_r8q {
    display: flex;
    flex-direction: column;
  }

  .OneNewsBlock_contentDetails__3CjEf {
    width: 98%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0;
  }

  .OneNewsBlock_mainTitle__2rM_B {
    margin-top: 10px;
    width: 100%;
  }

  .OneNewsBlock_newsDetails__awXnW {
    margin-top: 10px;
    width: 100%;
  }
}

.FirstBlock_websiteContent2__3RdCZ {
    margin-top: 100px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-row-gap: 10px;
    row-gap: 10px;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
  }
  
  .FirstBlock_HomeImageContainer2__3-kOD {
    width: 100%;
    height: 400px;
    background-color: green;
    border-radius: 10px;
    object-fit: cover;
  
  }
  
  .FirstBlock_detailContainer2__3EFFv {
    width: 100%;
  }
  
  .FirstBlock_aboutHeading1__1UmVx {
    font-weight: bold;
    color: var(--themeColor);
    font-size: 24px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    text-transform: uppercase;
  }
  
  .FirstBlock_aboutHeading2__1BeoP {
    font-weight: bold;
    color: #08203d;
    line-height: 28px;
    font-size: 25px;
  }
  
  .FirstBlock_aboutDetails__x0O7v {
    margin-top: 20px;
    font-size: 18px;
    color: var(--lightText);
    line-height: 32px;
    text-align: justify;
  }
  
  @media (max-width: 1300px) {
    .FirstBlock_detailContainer2__3EFFv {
    }
    .FirstBlock_aboutHeading1__1UmVx {
      font-size: 20;
    }
    .FirstBlock_aboutHeading2__1BeoP {
      margin-top: 10px;
      font-size: 30px;
      line-height: 40px;
    }
  
    .FirstBlock_aboutDetails__x0O7v {
      margin-top: 10px;
      font-size: 18px;
      line-height: 30px;
    }
  }
  
.SecondBlock_websiteContent2__3jXpv {
  margin-top: 100px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-row-gap: 10px;
  row-gap: 10px;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.SecondBlock_HomeImageContainer2__1CK4B {
  width: 100%;
  height: 400px;
  background-color: green;
  border-radius: 10px;
  object-fit: cover;
}

.SecondBlock_detailContainer2__1vPvJ {
  width: 100%;
}

.SecondBlock_aboutHeading1__2MPE7 {
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
}

.SecondBlock_aboutHeading2__1Uyr3 {
  font-weight: bold;
  color: #08203d;
  line-height: 28px;
  font-size: 25px;
}

.SecondBlock_aboutDetails__IvPCm {
  margin-top: 20px;
  font-size: 18px;
  color: var(--lightText);
  line-height: 32px;
  text-align: justify;
}

@media (max-width: 1300px) {
  .SecondBlock_detailContainer2__1vPvJ {
  }
  .SecondBlock_aboutHeading1__2MPE7 {
    font-size: 20;
  }
  .SecondBlock_aboutHeading2__1Uyr3 {
    margin-top: 10px;
    font-size: 30px;
    line-height: 40px;
  }

  .SecondBlock_aboutDetails__IvPCm {
    margin-top: 10px;
    font-size: 18px;
    line-height: 30px;
  }
}

.Exambox_parentBox__1j2ug {
  width: 270px;

  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--cardBorderColor);
}

.Exambox_parentBox__1j2ug:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.Exambox_logo__k0KiI {
  width: 100%;
  height: 150px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Exambox_imagesExam__Zzfoi {
  width: 100%;
  height: 100%;
}

.Exambox_title__3aj_t {
  margin-top: 20px;
  font-size: 20px;
  font-weight: bolder;
  text-align: center;
}

.Exambox_description__1vbR9 {
  margin-top: 10px;
  font-size: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Exambox_ViewToppersBtn__2jA1y {
  margin-top: 20px;
  width: 100px;
  height: 30px;
  background-color: var(--themeColor);
  color: white;
  border-radius: 20px;
  border: none;
  margin-bottom: 20px;
}

.Exambox_ViewToppersBtn__2jA1y:hover {
  border: 1px solid var(--themeColor);
  background-color: white;
  cursor: pointer;
  color: var(--themeColor);
}

.ToppersCard_parentBox__2r0u4 {
  width: 270px;

  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--cardBorderColor);
}

.ToppersCard_parentBox__2r0u4:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.ToppersCard_logo__GeSuu {
  width: 100%;
  height: 250px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ToppersCard_imagesExam__1LOnI {
  width: 100%;
  height: 100%;
}

.ToppersCard_title__5QLin {
  margin-top: 20px;
  font-size: 20px;
  font-weight: bolder;
  text-align: center;
}

.ToppersCard_description1__3N2zZ {
  margin-top: 10px;
  font-size: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ToppersCard_description2__1jqLi {
  margin-top: 10px;
  font-size: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ToppersCard_ViewToppersBtn__2ZYqi {
  margin-top: 20px;
  width: 100px;
  height: 30px;
  background-color: var(--themeColor);
  color: white;
  border-radius: 20px;
  border: none;
  margin-bottom: 20px;
}

.ToppersCard_ViewToppersBtn__2ZYqi:hover {
  border: 1px solid var(--themeColor);
  background-color: white;
  cursor: pointer;
  color: var(--themeColor);
}

.WhyHelloToppersCard_paarent__19_Cp {
  width: 300px;

  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  padding: 20px;
}

.WhyHelloToppersCard_logo__t7bA8 {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.WhyHelloToppersCard_title__3SjhC {
  margin-top: 20px;
  font-size: 20px;
  font-weight: bold;
}

.WhyHelloToppersCard_description__3GYoi {
  margin-top: 20px;
  font-size: 16px;
  text-align: center;
}

.HowitWorks_paarent__8umdA {

  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.HowitWorks_logo__3cW2O {
  margin-top: 30px;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.HowitWorks_imagesExam__5xEOL {
  width: 80px;
  height: 80px;
}

.HowitWorks_title__51Bgr {
  margin-top: 20px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.HowitWorks_description__vUD-X {
  margin-top: 20px;
  font-size: 16px;
  text-align: center;
}

.ExamBoxSlide_slider__4-TB6 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-style: none;
  border-color: red;
  margin: auto;
}

.ExamBoxSlide_cards__1XH49 {
  width: 100%;
  margin-top: 30px;
  border-style: none;
  overflow: hidden;
  position: relative;
  direction: rtl;
}

.ExamBoxSlide_mobileCards__1aSkk{
border-style: solid;
width: 100%;
overflow-y: scroll;
visibility: hidden;
height: 0px;
border-color: green;
}


.ExamBoxSlide_textDiv__2ikmr{

}



.ExamBoxSlide_wrapper__15mvI {
  display: flex;
  transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
  border-style: none;
  border-color: red;
  width:200%;
}



.ExamBoxSlide_wrapperMobile__2ZZEP {
  display: flex;
  transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
  border-style: solid;
  border-color: red;
  width:550%;
  height: 0px;
  
}


.ExamBoxSlide_childMobile1__1SVWw{
display: inline-block;
width: 300px;
}
.ExamBoxSlide_slideDiv__ngvhs{
display: flex;
}





.ExamBoxSlide_dot__ZTHk5 {
  /* Styles for regular dots */
  width: 10px;
  height: 10px;
  background-color: gray;
  margin: 0 5px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  border-style: none;
}





@media (max-width: 600px) {
 .ExamBoxSlide_wrapper__15mvI {
   visibility: hidden;
   height:0px;
 }

 .ExamBoxSlide_dot__ZTHk5 {
  width: 30px;
  height: 20px;
  background-color: grey;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  border-style: solid;
}

  .ExamBoxSlide_wrapperMobile__2ZZEP {
  
   visibility: visible;
   height: auto;
}



.ExamBoxSlide_mobileCards__1aSkk{
  visibility: visible;
  height: auto;
  overflow-x: scroll;
  width: 100%;
  border-color: cyan;
  display: flex; 
  border-style: none; 
}

.ExamBoxSlide_textDiv__2ikmr{
border-style: solid;
flex: 0 0 80%;
box-sizing: border-box;
margin-left: 10px;
}




}







.ExamBoxSlide_child__2eKQZ {
  width: 16.6%;
  box-sizing: border-box;
  border: 1px solid #000;
  border-style: none;
  font-size: 24px;
  display: inline-block;
}



.ExamBoxSlide_childMobile__2kF-s {
  width: 16.6%;
  box-sizing: border-box;
  border: 1px solid #000;
  border-style: none;
  font-size: 24px;
  display: inline-block;
}






.ExamBoxSlide_cardDiv__3e7z9{
border-style: none;
border-color: red;
}


.ExamBoxSlide_card__1IEAO {
  width: 95%;
  background-color: white;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--cardBorderColor);
  border: 1px solid var(--cardBorderColor);
  margin: auto;
}

.ExamBoxSlide_card__1IEAO:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}







.ExamBoxSlide_img__d8kyu {

  width: 100%;
  aspect-ratio:1.7/1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px 7px 0px 0px;
}

.ExamBoxSlide_title__2tY8g {
  margin-top: 20px;
  font-size: 20px;
  font-weight: bolder;
  text-align: center;
}

.ExamBoxSlide_description1__AeoWV {
  margin-top: 10px;
  font-size: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ExamBoxSlide_description2__b4KdQ {
  margin-top: 10px;
  font-size: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ExamBoxSlide_ViewToppersBtn__3gZlo {
  margin-top: 20px;
  width: 150px;
  height: 30px;
  background-color: var(--themeColor);
  color: white;
  border-radius: 20px;
  border: none;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.ExamBoxSlide_ViewToppersBtn__3gZlo:hover {
  border: 1px solid var(--themeColor);
  background-color: white;
  cursor: pointer;
  color: var(--themeColor);
}

.ExamBoxSlide_indicatorDots__4HxDi {
  margin-top: 40px;
  display: flex; /* Make the dots container a flex container */
  justify-content: center; /* Center the dots horizontally */
  
}


.ExamBoxSlide_activeDot__VrFZh {
  /* Styles for active dot */
  background-color: var(--themeColor);
}



.ExamBoxSlide_mobileCards__1aSkk::-webkit-scrollbar {
  display: none;
}


.ExamBoxSlide_mobileBoxSlider__3wrfG{
display: flex;
width: 1140px;
}



@media only screen and (max-width: 600px) {
   
       
.ExamBoxSlide_indicatorDots__4HxDi{
margin-top:0px;
visibility: hidden;
}


}






.OneExamCardMobile_card__3WbtJ {
  background-color: white;
  border-radius: 6px;
  align-items: center;
  border: 1px solid var(--cardBorderColor);
  border: 1px solid var(--cardBorderColor);
  margin: auto;
  flex: 0 0 80%;
  box-sizing: border-box;
  margin-left: 15px;



}


.OneExamCardMobile_img__j0fZZ {

  width: 100%;
  aspect-ratio:1.7/1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px 7px 0px 0px;
}
.OneExamCardMobile_title__3Omdn {
  margin-top: 20px;
  font-size: 20px;
  font-weight: bolder;
  text-align: center;
}

.OneExamCardMobile_description1__iiveX {
  margin-top: 10px;
  font-size: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.OneExamCardMobile_description2__2GnBZ {
  margin-top: 10px;
  font-size: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.OneExamCardMobile_ViewToppersBtn__2On3j{
height: 40px;
background-color: var(--themeColor);
display: flex;
justify-content: center;
align-items: center;
border-radius: 20px;
padding-left: 30px;
padding-right: 30px;
color: white;
margin: auto;
margin-bottom: 20px;
margin-top: 20px;
width: 200px;
cursor: pointer;
}



.ExamBoxSlide_slider__1aETj {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-style: none;
    border-color: red;
    margin: auto;
  }
  
  .ExamBoxSlide_cards__2DQJz {
    width: 100%;
    margin-top: 30px;
    border-style: none;
    overflow: hidden;
    position: relative;
    direction: rtl;
  }
  
  .ExamBoxSlide_mobileCards__3Wvgc{
  border-style: solid;
  width: 100%;
  overflow-y: scroll;
  visibility: hidden;
  height: 0px;
  border-color: green;
  }
  
  
  .ExamBoxSlide_textDiv__13MWq{
  
  }
  
  
  
  .ExamBoxSlide_wrapper__2U7pt {
    display: flex;
    transition: -webkit-transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
    border-style: none;
    border-color: red;
    width:175%;
  }
  
  
  
  .ExamBoxSlide_wrapperMobile__2meMI {
    display: flex;
    transition: -webkit-transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
    border-style: solid;
    border-color: red;
    width:550%;
    height: 0px;
    
  }
  
  
  .ExamBoxSlide_childMobile1__2qTDH{
  display: inline-block;
  width: 300px;
  }
  .ExamBoxSlide_slideDiv__1lin6{
  display: flex;
  }
  
  
  
  
  
  .ExamBoxSlide_dot__3E-4V {
    /* Styles for regular dots */
    width: 10px;
    height: 10px;
    background-color: gray;
    margin: 0 5px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    border-style: none;
  }
  
  
  
  
  
  @media (max-width: 600px) {
   .ExamBoxSlide_wrapper__2U7pt {
     visibility: hidden;
     height:0px;
   }
  
   .ExamBoxSlide_dot__3E-4V {
    width: 30px;
    height: 20px;
    background-color: grey;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    border-style: solid;
  }
  
    .ExamBoxSlide_wrapperMobile__2meMI {
    
     visibility: visible;
     height: auto;
  }
  
  
  
  .ExamBoxSlide_mobileCards__3Wvgc{
    visibility: visible;
    height: auto;
    overflow-x: scroll;
    width: 100%;
    border-color: cyan;
    display: flex; 
    border-style: none; 
  }
  
  .ExamBoxSlide_textDiv__13MWq{
  border-style: solid;
  flex: 0 0 80%;
  box-sizing: border-box;
  margin-left: 10px;
  }
  
  
  
  
  }
  
  
  
  
  
  
  
  .ExamBoxSlide_child__3bgGv {
    width: 16.6%;
    box-sizing: border-box;
    border: 1px solid #000;
    border-style: none;
    font-size: 24px;
    display: inline-block;
  }
  
  
  
  .ExamBoxSlide_childMobile__3GjG3 {
    width: 14.2%;
    box-sizing: border-box;
    border: 1px solid #000;
    border-style: none;
    font-size: 24px;
    display: inline-block;
  }
  
  
  
  
  
  
  .ExamBoxSlide_cardDiv__18zjY{
  border-style: none;
  border-color: red;
  }
  
  
  .ExamBoxSlide_card__1kDxo {
    width: 95%;
    background-color: white;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--cardBorderColor);
    border: 1px solid var(--cardBorderColor);
    margin: auto;
  }
  
  .ExamBoxSlide_card__1kDxo:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  
  
  
  
  
  
  
  .ExamBoxSlide_img__3mY5V {
  
    width: 100%;
    aspect-ratio:1.7/1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px 7px 0px 0px;
  }
  
  .ExamBoxSlide_title__Rkp2d {
    margin-top: 20px;
    font-size: 20px;
    font-weight: bolder;
    text-align: center;
  }
  
  .ExamBoxSlide_description1__oH-ZB {
    margin-top: 10px;
    font-size: 16px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ExamBoxSlide_description2__oeHyh {
    margin-top: 10px;
    font-size: 16px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .ExamBoxSlide_ViewToppersBtn__3HzqX {
    margin-top: 20px;
    width: 150px;
    height: 30px;
    background-color: var(--themeColor);
    color: white;
    border-radius: 20px;
    border: none;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }
  
  .ExamBoxSlide_ViewToppersBtn__3HzqX:hover {
    border: 1px solid var(--themeColor);
    background-color: white;
    cursor: pointer;
    color: var(--themeColor);
  }
  
  .ExamBoxSlide_indicatorDots__1-gI1 {
    margin-top: 40px;
    display: flex; /* Make the dots container a flex container */
    justify-content: center; /* Center the dots horizontally */
    
  }
  
  
  .ExamBoxSlide_activeDot__1iIbS {
    /* Styles for active dot */
    background-color: var(--themeColor);
  }
  
  
  
  .ExamBoxSlide_mobileCards__3Wvgc::-webkit-scrollbar {
    display: none;
  }
  
  
  .ExamBoxSlide_mobileBoxSlider__38Uj5{
  display: flex;
  width: 1140px;
  }
  
  
  
  @media only screen and (max-width: 600px) {
     
         
  .ExamBoxSlide_indicatorDots__1-gI1{
  margin-top:0px;
  visibility: hidden;
  }
  
  
  }
  
.ToppersCardSlide_slider__WSesh {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-style:none;
  margin: auto;
}

.ToppersCardSlide_cards__2dxZv {
  width: 100%;
  margin-top: 30px;
  display: flex;
  background-color: white;
}

.ToppersCardSlide_card__bqbR9 {
  width: 30%;
  margin-left: 20px;
  margin-right: 20px;
  background-color: white;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--cardBorderColor);
  border: 1px solid var(--cardBorderColor);
}

.ToppersCardSlide_card__bqbR9:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.ToppersCardSlide_img__-8ocv {
  width: 100%;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ToppersCardSlide_title__hGgm0 {
  margin-top: 20px;
  font-size: 20px;
  font-weight: bolder;
  text-align: center;
}

.ToppersCardSlide_description1__2Z6Aw {
  margin-top: 10px;
  font-size: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ToppersCardSlide_description2__juJ6n {
  margin-top: 10px;
  font-size: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ToppersCardSlide_ViewToppersBtn__w7NZn {
  margin-top: 20px;
  width: 150px;
  height: 30px;
  background-color: var(--themeColor);
  color: white;
  border-radius: 20px;
  border: none;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ToppersCardSlide_ViewToppersBtn__w7NZn:hover {
  border: 1px solid var(--themeColor);
  background-color: white;
  cursor: pointer;
  color: var(--themeColor);
}

.ToppersCardSlide_indicatorDots__2LEsp {
  margin-top: 40px;
  display: flex; /* Make the dots container a flex container */
  justify-content: center; /* Center the dots horizontally */
}

.ToppersCardSlide_dot__3HOW7 {
  /* Styles for regular dots */
  width: 10px;
  height: 10px;
  background-color: gray;
  margin: 0 5px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
}

.ToppersCardSlide_activeDot__3vAVl {
  /* Styles for active dot */
  background-color: black;
}

.About_about__1uqlv {
  display: flex;
  flex-direction: column;
  font-size: 30px;
  margin-bottom: 40px;
}

.About_mainTitle__3q_Xj {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
}

.About_topTitle__1ulAd {
  width: 100%;
  margin-top: 50px;
  line-height: 20px;
  display: grid;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 15px;
  color: var(--darkText);
}

.About_parentCard__Bv8ls {
  margin: 10px;
  display: flex;
  width: 70%;
  border-radius: 6px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  padding: 20px;
}

.About_leftContainer__10ze7 {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.About_picContainer__1xmgm {
  margin-top: 20px;
  width: 150px;
  height: 150px;
  border-radius: 100%;
}

.About_personpic__se1Gh {
  width: 150px;
  height: 150px;
  border-radius: 100%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.About_price__1vHbK {
  margin-top: 20px;
  font-weight: bolder;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 24px;
  color: var(--themeColor);
}

.About_pricephr__2CXjo {
  margin-top: 10px;
  font-weight: bold;
  font-size: 14px;
}

.About_midContainer__3KGD6 {
  width: 60%;

  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  font-size: 14px;
}

.About_FeaturedTitleBtn__135Rk {
  width: 90px;
  background-color: green;
  color: white;
  padding: 2px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.About_Username__2npEa {
  margin-top: 10px;
  font-weight: bolder;
  font-size: 20px;
}

.About_aContainer__cMlZN {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.About_earthIcon__319ix {
  height: 20px;
  width: 20px;
  background-color: cadetblue;
  border-radius: 10px;
}

.About_cityName__C6Aa8 {
  margin-left: 10px;
}

.About_langContainer__PDdOi {
  margin-top: 10px;
  display: flex;
}

.About_l1Container__1g6YH {
  display: flex;
  align-items: center;
}

.About_l2Container__2Wvh6 {
  margin-left: 10px;
  display: flex;
  align-items: center;
}

.About_l1icon__1-xz0 {
  height: 20px;
  width: 20px;
  background-color: cadetblue;
  border-radius: 10px;
}

.About_l1Lang__On_vH {
  margin-left: 10px;
}

.About_lessonContainer__3q7pt {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.About_lessionIcon__1jayJ {
  height: 20px;
  width: 20px;
  background-color: cadetblue;
  border-radius: 10px;
}

.About_firstContainer__2B4Ny {
  margin-left: 10px;
  display: flex;
}

.About_lessionTitle__tgir6 {
  font-size: 14px;
  font-weight: bold;
  color: var(--darkText);
}

.About_lessionDetails__eHDlK {
}

.About_secContainer__2CrEo {
  margin-left: 10px;
  display: flex;
}

.About_lessionTitle__tgir6 {
  font-size: 14px;
  font-weight: bold;
  color: var(--darkText);
}

.About_lessionDetails__eHDlK {
  margin-left: 10px;
}

.About_aboutuserDetails__37J8r {
  margin-top: 10px;
  line-height: 24px;
}

.About_readMoreBtn__2Hqnn {
  margin-top: 10px;
  color: var(--themeColor);
  font-weight: bold;
  text-decoration: underline;
}

.About_rightContainer__PO3Oi {
  width: 15%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.About_ratingContainer__1daf_ {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.About_starContainer__2HRVX {
  height: 20px;
  width: 20px;
  background-color: aquamarine;
  border-radius: 10px;
}

.About_star__1V6Kg {
  margin-left: 10px;
  font-weight: bold;
  font-size: 14px;
}

.About_totalRating__1NMvL {
  margin-top: 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.About_bookBtnContainer__1kuVt {
  margin-top: 10px;
  width: 100px;
  height: 25px;
  background-color: var(--themeColor);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}

.About_bookTitle__1h20f {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.About_bottomContainer__1rYiC {
  float: right;
  border-color: 1px solid gainsboro;
}
.About_iconContainerheart__yZVlv {
  float: right;
  width: 20px;
  height: 20px;
  background-color: black;
  margin: 10px;
  border-radius: 100%;
}

@media (max-width: 1300px) {
  .About_about__1uqlv {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}




{/*findtoppers css classes*/}

.About_searchToppersCard__1-62m {
  width: calc(100% - 20px);
  height: 270px;
  background-color: var(--chatCommentBkgColor);
  display: flex;
  justify-content: start;
  align-items: center;
  padding-left: 20px;
  margin-bottom: 20px;
  border-style: none;
  margin: auto;
}


.About_detailContainer2__2DzhP{
width: 1200px;
border-style: none;
margin: auto;
}


.About_mainHeading__1q6Pg {
  font-size: 20px;
  font-weight: bolder;
  color: black;
}

.About_subHeading__2gwUF {
  margin-top: 10px;
  font-size: 18px;
}

.About_textHeading3__1hwhR{
display: flex;
border-style: none;
align-items:end ;
margin-top: 20px;
}


.About_findTopperButton__3F9z9{
    width: 100px;
    height: 35px;
    background-color: var(--themeColor);
    color: white;
    border: none;
    border-radius: 0px 10px 10px 0px;
}

.About_findTopperButton__3F9z9{
    cursor: pointer;
}



.About_noResultMessage__1GtXB{
width: 1150px;
margin: auto;
height: 300px;
border-style: none;
padding-top:30px;
}

@media (max-width: 1300px) {

  .About_searchToppersCard__1-62m{
    width: 100%;
    margin: 0;
    padding: 10px;

  }

  .About_detailContainer2__2DzhP{
    width: 100%;
    margin: 0;
    padding: 0;
  }

}





.LeftImageRightText_websiteContent2__1NlCJ {
    margin-top: 50px;
    width: 100%;
    display: flex;
  }
  
  .LeftImageRightText_HomeImageContainer2__2fTxc {
    width: 50%;
    background-color: green;
  }
  
  .LeftImageRightText_detailContainer2__33iVZ {
    margin-left: 30px;
    width: 50%;
  }
  
  .LeftImageRightText_aboutHeading1__3pN6p {
    font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
  }
  
  .LeftImageRightText_aboutHeading2__2WN-L {
    margin-top: 20px;
    font-weight: bold;
    color: #08203D;
    line-height: 55px;
    font-size: 45px;
  }
  
  .LeftImageRightText_aboutDetails__g4jue {
    margin-top: 20px;
    font-size: 18px;
    color: var(--lightText);
    line-height: 32px;
    text-align: justify;
  }



.LeftImageRightText_aboutTitle__34oia{
display: flex;
justify-content:center;
color: var(--themeColor);
font-weight: bold;

}




.findTopperscard_about__1vIG3 {
  display: flex;
  flex-direction: column;
  font-size: 30px;
  margin-top: 40px;
}

.findTopperscard_mainTitle__26uS0 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
}

.findTopperscard_topTitle__1aHU8 {
  width: 100%;
  margin-top: 50px;
  line-height: 20px;
  display: grid;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 15px;
  color: var(--darkText);
}

.findTopperscard_parentCard__2xuxa {
  margin: 10px;
  display: flex;
  width: 70%;
  border-radius: 6px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  padding: 20px;
  max-width: 1100px;
  margin: auto;
  margin-top: 30px;
}

.findTopperscard_leftContainer__2Lxcq {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.findTopperscard_picContainer__2Ze3- {
  margin-top: 20px;
  width: 150px;
  height: 150px;
  border-radius: 100%;
}

.findTopperscard_personpic__2Fvvl {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 100%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.findTopperscard_price__3LeRD {
  margin-top:5px;
  font-weight: bolder;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 24px;
  color: var(--themeColor);
  display: flex;
  justify-content: center;
}

.findTopperscard_pricephr__3Ym3y {
  margin-top: 10px;
  font-weight: bold;
  font-size: 14px;
}

.findTopperscard_midContainer__UJ3aB {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  font-size: 14px;
}

.findTopperscard_FeaturedTitleBtn__3C-oc {
  width: 130px;
  background-color: white;
  color: var(--themeColor);
  font-weight: bold;
  padding: 5px;
  border-radius: 6px;
  border: 2px solid var(--themeColor);
  display: flex;
  align-items: center;
  justify-content: center;
}

.findTopperscard_Username__1IUgT {
  margin-top: 10px;
  font-weight: bolder;
  font-size: 20px;
  cursor: pointer;
}

.findTopperscard_Username__1IUgT:hover {
  color: var(--themeColor);
}

.findTopperscard_aContainer__24KlX {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.findTopperscard_earthIcon__2bJ4n {
  height: 20px;
  width: 20px;
  background-color: grey;
  border-radius: 10px;
}

.findTopperscard_cityName__jwme1 {
  margin-left: 10px;
}

.findTopperscard_langContainer__EEnKZ {
  margin-top: 10px;
  display: flex;
}

.findTopperscard_l1Container__zejY9 {
  display: flex;
  align-items: center;
}

.findTopperscard_l2Container__33j-b {
  margin-left: 10px;
  display: flex;
  align-items: center;
}

.findTopperscard_l1icon__3kcBS {
  color: var(--themeColor);
}

.findTopperscard_l1Lang__qiza3 {
  margin-left: 10px;
}

.findTopperscard_lessonContainer__1Uc4_ {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.findTopperscard_lessionIcon__3yltf {
  color: var(--themeColor);
}

.findTopperscard_firstContainer__1KyUy {
  margin-left: 10px;
  display: flex;
}

.findTopperscard_lessionTitle__3Qvbs {
  font-size: 14px;
  font-weight: bold;
  color: var(--darkText);
}

.findTopperscard_lessionDetails__3Pjy6 {
}

.findTopperscard_secContainer__YgwSs {
  margin-left: 10px;
  display: flex;
}

.findTopperscard_lessionTitle__3Qvbs {
  font-size: 14px;
  font-weight: bold;
  color: var(--darkText);
}

.findTopperscard_lessionDetails__3Pjy6 {
  margin-left: 10px;
}

.findTopperscard_aboutuserDetails__3wrCf {
  margin-top: 10px;
  line-height: 24px;
  border-style: none;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  text-align: justify;
  border-style: none;
}


.findTopperscard_aboutuserDetails1__3f-8B{
margin-top: 10px;
  line-height: 24px;
  border-style: none;
  overflow: hidden;
  width: 100%;
  display: -webkit-box; /* Add this line */
  -webkit-line-clamp: 3; /* Adjust the number of lines as needed */
  -webkit-box-orient: vertical; /* Add this line */
  text-align: justify;
  border-style: solid;
}

.findTopperscard_aboutuserDetails1__3f-8B {
  margin-top: 10px;
  line-height: 24px;
  border-style: none;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-align: justify;
  border-style: solid;
  position: relative; /* Add this line */
}

.findTopperscard_aboutuserDetails1__3f-8B::after {
  content: '...'; /* Add ellipsis content */
  position: absolute;
  bottom: 0;
  right: 0;
  padding-left: 30px; /* Adjust as needed */
}





.findTopperscard_readMoreBtn__EDOI_ {
  margin-top: 10px;
  color: var(--themeColor);
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
  border-style: none;
  background-color: white;
}

.findTopperscard_rightContainer__tuTNo {
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.findTopperscard_ratingContainer__2-Bz3 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.findTopperscard_starContainer__2Jsr8 {
  border-style: none;
  height: 30px;
  width: 30px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.findTopperscard_star__2hcpK {
  margin-left: 10px;
  font-weight: bold;
  font-size: 14px;
}

.findTopperscard_totalRating__1BvpP {
  margin-top: 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.findTopperscard_bookBtnContainer__ssLtQ {
  margin-top: 10px;
  width: 120px;
  height: 35px;
  background-color: var(--themeColor);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border-style: none;
  cursor: pointer;
  visibility: hidden;
}



.findTopperscard_bookBtnContainer__ssLtQ:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}



.findTopperscard_bookBtnContainer1__2dsS2 {
  margin-top: 10px;
  width: 120px;
  height: 35px;
  background-color: var(--themeColor);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border-style: none;
  cursor: pointer;
  margin-left: 20px;
}



.findTopperscard_bookBtnContainer1__2dsS2:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}







.findTopperscard_bookTitle__3yHWT {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.findTopperscard_bottomContainer__2Z_AO {
  float: right;
  border-color: 1px solid gainsboro;
}

.findTopperscard_iconContainerheart__1ZWPT {
  float: right;
  width: 20px;
  height: 20px;
  margin: 10px;
}

.findTopperscard_buttonContainer__3pWTk{
  display: flex;
  align-items: center;
  width: 100%;
  
}

@media (max-width: 1300px) {
  .findTopperscard_about__1vIG3 {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .findTopperscard_parentCard__2xuxa {
    margin-top: 10px;
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
  }

  .findTopperscard_leftContainer__2Lxcq {
    width: 100%;
  }

  .findTopperscard_midContainer__UJ3aB {
    width: 100%;
    padding: 10px;
  }

  .findTopperscard_aboutuserDetails__3wrCf {
    width: 94%;
    text-align: justify;
  }

  .findTopperscard_rightContainer__tuTNo {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
  }

  .findTopperscard_ratingContainer__2-Bz3 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .findTopperscard_bookBtnContainer__ssLtQ {
    margin-top: 20px;
    visibility: visible;
  }

  .findTopperscard_bookBtnContainer1__2dsS2{
  visibility: hidden;
  }





}

.Rating_stars__2W26S {
    display: flex;
  }
  
  .Rating_star__rHnOU {
    width: 24px;
    height: 24px;
    background-color: lightgray; /* Empty star color */
    position: relative;
    -webkit-clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
            clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
  }
  
  .Rating_filledStar__X3tKu {
    height: 100%;
    background-color: #ffd203; /* Filled star color */
    position: absolute;
    top: 0;
    left: 0;
    -webkit-clip-path: inherit;
            clip-path: inherit;
    transition: width 0.2s ease-in-out; /* Smooth transition */
  }
  
.searchFindToppers_searchToppersCard__1WIr0 {
  width: 100%;
  height: 320px;
  background-color: var(--chatCommentBkgColor);
  display: flex;
  justify-content: start;
  align-items: center;
  padding-left: 20px;
  margin-bottom: 20px;
  border-style: none;
  margin: auto;
}


.searchFindToppers_detailContainer2__33Rp6{
width: 1200px;
border-style: none;
margin: auto;
}


.searchFindToppers_mainHeading___4jQ5 {
  font-size: 20px;
  font-weight: bolder;
  color: black;
  border: 1px solid red;


}

.searchFindToppers_subHeading__2LtAY {
  margin-top: 10px;
  font-size: 18px;
}

.searchFindToppers_textHeading3__1ba5L{
}


.searchFindToppers_findTopperButton__1af4X{
    width: 100px;
    height: 30px;
    background-color: var(--themeColor);
    color: white;
    border: none;
    border-radius: 6px;
}

.searchFindToppers_findTopperButton__1af4X{
    cursor: pointer;
}


@media (max-width: 1300px) {


  .searchFindToppers_searchToppersCard__1WIr0{
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .searchFindToppers_detailContainer2__33Rp6{
    margin: 0;
    padding: 0;
    width: 100%;
    border: 1px solid red;
  }
}





.OptionField_name_div__1lvVh{
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
border-style: none;
margin-top: 10px;
position: relative;
width: 100%;
border-width: 1px;
height: 35px;
}

.OptionField_input_field__oXAbZ{
position: relative;
width: 100%;
height: 100%;
box-sizing: border-box;
border-style:solid;
border-width: 1px;
border-color: lightgrey;
background-color: white;
border-radius: 10px 0px 0px 10px;
padding-left: 10px;
font-size: 16px;

}
.OptionField_input_field__oXAbZ:focus {
    border-style: solid;
    border-width: 1px;
    outline: none !important;
    border-color: var(--themeColor);
    border-radius:10px 0px 0px 10px;
}






.App_profileParent__A5QCr {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
}

.App_mainContainer__2TtUy {
    width: 80%;
    display: flex;
    flex-direction: row;
    grid-gap: 20px;
    gap: 20px;
}

.App_leftContainer__2uQwa {
    width: 60%;
    display: flex;
    flex-direction: column;
    grid-gap: 50px;
    gap: 50px;
}

.App_rightContainer__Gc9V4 {
    width: 40%;
}

.App_middleContainer__1Sivo,
.App_midContainer__2AcWy,
.App_fqaContainer__1JUhW {
    width: 100%;
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
        rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.App_aboutmeContainer__JaKUP,
.App_educationContainer__150KO,
.App_experienceContainer__ctwFM,
.App_faqTitle__38aum {
    margin: 30px;
}

.App_aboutmeTitle__16vvy,
.App_experienceTitle__3fPDO,
.App_educationTitle__T7mNn,
.App_faqTitle__38aum {
    font-size: 25px;
    font-weight: bolder;
    margin-bottom: 10px;
}

.App_aboutmeDiscription__3NBqr,
.App_educationDescription__gXYnb,
.App_experienceDescription__3SikM {
    font-size: 14px;
    font-weight: 500;
    margin-left: 10px;
}

.App_qualificationContainer__3opv0,
.App_locationContainer__1ZP1d {
    margin-bottom: 5px;
}

.App_qualificationContainer__3opv0 {
    font-weight: 600;
}

.App_mentorHeading__3vZY_ {
    margin: 25px;
    font-size: 25px;
    font-weight: bolder;
}

.App_mentorPoint__3VysP {
    margin-left: 50px;
    margin-bottom: 30px;

}
.App_experienceCard__2jCtM{
    font-size: 15px;
    margin-bottom: 20px;
}

.App_mentorPointQuestion__1ZfNb {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
}

.App_mentorPointAnswer__1hGTQ {
    margin-left: 30px;
    font-size: 14px;
    font-weight: 500;
}

.App_faqList__36y3y {
    list-style: none;
    padding: 0;
    margin: 0;
}

.App_faqList__36y3y li {
    position: relative;
    padding-left: 30px;
    margin-bottom: 10px;
    display: flex;
    width: calc(100% - 100px);
    align-items: center;
    justify-content: space-between;
}

.App_faqList__36y3y li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 10px;
    height: 10px;
    background-color: black;
    border-radius: 50%;
}

.App_faqDescription__3EEIk {
    margin-left: 30px;
}

.App_faqDescriptionTitle__1-LXl {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
}

.App_faqDescriptionPoints__2alph {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 40px;
    margin-left: 20px;
}

.App_bookYourSlotContainer__17iRY {
    width: 100%;
}

.App_bodyContainer__1CwGU {
    display: none;
}

@media (min-width:600px) and (max-width:1200px) {}


@media (max-width:600px) {
    .App_mainContainer__2TtUy {
        display: none;
    }
    .App_profileParent__A5QCr{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .App_bodyContainer__1CwGU {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;

    }

    .App_rightContainer__Gc9V4 {
        width: 90%;
    }

    .App_fqaContainer__1JUhW {
        width: 100%;
    }

    .App_leftContainer__2uQwa {
        width: 90%;
    }

    .App_bookingCard__2tH_R {
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        bottom: 0px;
        z-index: 10000;
        width: 100%;
    }

    .App_bookSlotContainer__1Cjay {
        background-color: var(--chatCommentBkgColor);
        color: white;
        border-radius: 50px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        margin-top: 20px;
        width: 90%;


    }

    .App_priceContainer__22r1Z {
        font-size: 12px;
        font-weight: bolder;
        color: black;
        margin: 10px 15px 10px 15px;
    }
    .App_bookButtonConatiner__1NaDn{
        background-color: var(--themeColor);
        width: 60%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        font-weight: bold;
      }


}
.ProfileCard_profileCardDetails__3Zu04 {
    margin-top: 20px;
    width: 100%;
    display: flex;
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
        rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.ProfileCard_leftSide__2qzDI {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
}

.ProfileCard_rightSide__hyewU {
    width: 70%;
}

.ProfileCard_profilePic__2P9cr {
    width: 200px;
    height: 200px;
    border: 10px;
}

.ProfileCard_mainprofilePic__2VwcE {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 100%;
}

.ProfileCard_designationContainer__2tNos{
    margin-top: 20px;
    font-weight: bold;
}

.ProfileCard_bookbtn__225EQ {
    width: 120px;
    height: 30px;
    background-color: var(--themeColor);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
}

.ProfileCard_bookbtn__225EQ:hover {
    border: 1px solid var(--themeColor);
    cursor: pointer;
    background-color: white;
    color: var(--themeColor);
}

.ProfileCard_bookbtn1__1vhhp {

    width: 120px;
    height: 30px;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    font-weight: bold;

}

.ProfileCard_bookNowContainer__WSuCf {
    padding: 7px 15px 7px 15px;
    font-size: 20px;
    background-color: var(--themeColor);
    color: white;
    font-weight: bolder;
    border-radius: 50px;
    cursor: pointer;
}

.ProfileCard_priceHolder__32yHl {
    display: flex;
    grid-gap: 50px;
    gap: 50px;
    align-items: center;
    margin-right: 20px;
}

.ProfileCard_priceContainer__26PrZ {
    font-size: 20px;
    font-weight: bolder;
}

.ProfileCard_topContainer__obsy6 {
    margin: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ProfileCard_NameContainer__3jNqG {
    font-size: 25px;
    font-weight: bold;
}

.ProfileCard_rattingContainer__2G86y {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ProfileCard_rattingIcon__24G4J {
    height: 30px;
    width: 30px;
    color: sandybrown;
}

.ProfileCard_ratingNumber__kSHr3 {
    margin-left: 10px;
    font-size: 16px;
    font-weight: bold;
    color: var(--greyTextColor);
}

.ProfileCard_HeartBtn__1dWiU {
    height: 30px;
    width: 30px;
    color: var(--greyTextColor);
}

.ProfileCard_midContainer__25iut {
    margin: 10px 20px 1px 20px;
    display: flex;
    align-items: center;
}

.ProfileCard_location__zq-pF {
    height: 25px;
    width: 25px;
    color: var(--themeColor);
    margin-right: 5px;
}

.ProfileCard_location1__2jLZd {
    height: 30px;
    width: 30px;
    color: var(--greyTextColor);
}

.ProfileCard_locationName__XEgSU {
    margin-left: 10px;
    color: var(--themeColor);
}

.ProfileCard_bottomContainer__1p-m4 {
    margin: 20px;
    display: flex;
    align-items: start;
    justify-content: space-between;
}

.ProfileCard_bookMainContainer__2vf2C{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.ProfileCard_teachesContainer__1sPWQ {
    display: flex;
    align-items: center;
    padding-top: 8px;
    margin-bottom: 8px;
    padding-left: 12px;
    padding-right: 12px;
    background-color: var(--cardBorderColorLight);
    border-radius: 10px;
    border: 1px solid var(--cardBorderColor);
    border-style: none;
}

.ProfileCard_icon1__WNXnC {
    height: 30px;
    width: 30px;
    border-radius: 10px;
}

.ProfileCard_detailsContainer__5hrHA {
    margin-left: 10px;
    margin-top: 6px;
    margin-bottom: 6px;
}

.ProfileCard_heading__1HqbO {
    font-weight: bold;
}

.ProfileCard_headingDetails___x3Wl {
    margin-top: 6px;
}

.ProfileCard_languageContainer__GJJ8f {
    display: flex;
    align-items: center;
    margin: 20px;
    margin-top: 10px;
}

.ProfileCard_languageTitle__18DXO {
    font-size: 18px;
    font-weight: bold;
}

.ProfileCard_lang__2knBV {
    margin-left: 10px;
}

.ProfileCard_expertContainer__12Hhu {
    font-size: 15px;
    font-weight: 600;
    background-color: white;
    padding: 5px 15px 5px 15px;
    color: var(--themeColor);
    border: 2px solid var(--themeColor);
    border-radius: 10px;
    margin-top: 20px;
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 15px;

}

.ProfileCard_priceHolder__32yHl {}

.ProfileCard_ratingContainer__1g7Hk {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 30px;
    margin-bottom: 30px;
}

@media (max-width: 1000px) {
    .ProfileCard_profileParent__kCn_2 {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 500px;


    }

    .ProfileCard_mainContainer__2kZSG {
        width: 98%;
        padding: 0px;
    }

    .ProfileCard_profileCardDetails__3Zu04 {
        display: flex;
        flex-direction: column;
    }

    /* .topContainer{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }
  
  .ratingContainer{
    margin-top: 10px;
  
  }
  .HeartBtn{
    margin-top: 10px;
  
  }
  
  .bottomContainer{
    display: flex;
    flex-direction: column;
  } */

}

@media (max-width: 600px) {
    .ProfileCard_profileCardDetails__3Zu04 {
        width: 100%;
        margin: 0px;
        flex-direction: column;
    }

    .ProfileCard_leftSide__2qzDI {
        padding: 20px 0px 20px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .ProfileCard_priceHolder__32yHl {
        position: fixed;
        grid-gap: 100px;
        gap: 100px;
        align-items: center;
        bottom: 50px;
        background-color: white;
        border-radius: 10px;
        z-index: 1000;
        right: 0px;
        left: 20px;
        width: calc(100% - 150px);
        padding: 20px 50px 20px 50px;
        justify-content: center;
    }

    .ProfileCard_rightSide__hyewU {
        width: 100%;
    }

    .ProfileCard_profilePic__2P9cr {
        width: calc(100% - 40px);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .ProfileCard_mainprofilePic__2VwcE {
        width: 200px;
        border-radius: 200px;
    }


    .ProfileCard_topContainer__obsy6 {
        flex-direction: column;
        grid-gap: 10px;
        gap: 10px;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 30px;
    }




}


@media (min-width:600px) and (max-width:1200px) {
    .ProfileCard_profileCardDetails__3Zu04 {
        width: 100%;
        margin: 0px;
        align-items: center;
        flex-direction: column;
    }

    .ProfileCard_leftSide__2qzDI {
        padding: 20px 0px 20px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        
    }

    .ProfileCard_priceHolder__32yHl {
        position: fixed;
        grid-gap: 100px;
        gap: 100px;
        align-items: center;
        bottom: 50px;
        background-color: white;
        border-radius: 10px;
        z-index: 1000;
        right: 0px;
        left: 20px;
        width: calc(100% - 150px);
        padding: 20px 50px 20px 50px;
        justify-content: center;
    }

    .ProfileCard_topContainer__obsy6 {
        flex-direction: column;
        grid-gap: 10px;
        gap: 10px;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 30px;
    }

    .ProfileCard_rightSide__hyewU {
        margin-left: 20%;
    }
}
.Slot_slotsContainer__e-g68 {
  width: calc(70% - 60px);
  background-color: white;
  border-style: none;
  display: flex;
  flex-direction: column;
  padding: 20px 30px 0px 10px;
  border-radius: 10px;
  grid-gap: 50px;
  gap: 50px;
  
}

.Slot_slotsCard__25E3H{
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Creates 2 equal columns */
}

.Slot_nextPreButtonsDiv__1ZatY {
  height: 50px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
}


.Slot_navButton__kEllB {
  border-style: none;
  padding: 5px;
  color: var(--themeColor);
  background-color: var(--chatCommentBkgColor);
  width: 100px;
  cursor: pointer;
  border-radius: 5px;
  height: 35px;
}


.Slot_dateText__3gUMM {
  margin: 20px;
  font-weight: bold;
  border-style: solid;
  border-width: 1px;
  border-color: var(--chatCommentBkgColor);
  border-radius: 5px;
  height: 35px;
  display: flex;
  align-items: center;
  width: 150px;
  justify-content: center;
}

.Slot_leftContainer__2xNzR{
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 10px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px; 

}

.Slot_rightContainer__3T-KW{
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 10px;
}

.Slot_bookButtonConatiner__JTdm7{
  background-color: var(--themeColor);
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  font-weight: bold;
}

.Slot_bookSlotContainer__aNKji{
  background-color: var(--chatCommentBkgColor);
  color: white;
  border-radius: 50px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}


.Slot_videoMentoringContainer__2H0w1{
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}

.Slot_availableContainer__1lBA6{
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Slot_bookingContainer__3R6_y{
  display: flex;
  align-items: center;
  grid-gap: 30px;
  gap: 30px;
}

.Slot_priceContainer__PJpkZ{
  font-size: 13px;
  font-weight: bolder;
  color: black;
  margin: 10px 15px 10px 15px;
}

.Slot_bookButton__EjCTs{
  padding: 10px 15px 10px 15px;
  font-size: 25px;
  font-weight: bolder;
  background-color: green;
  color: white;
  border-radius: 10px;
}

.Slot_headingContainer__1bGmt{
  font-size: 20px;
  font-weight: bolder;
  margin: 20px;
  margin-bottom: 10px;

}

.Slot_titleContainer__25F8C{
  font-size: 18px;
  font-weight: 600;
  margin-left: 20px;
  margin-bottom: 20px;
}

.Slot_sessionContainer__6EmP0{
  margin-bottom: 20px;
}

.Slot_pointsContainer__-5mjq {
  list-style-type: none; /* Remove default bullets */
  padding: 0;
  margin: 0;
}

.Slot_eachContainer__vaCui {
  position: relative;
  padding-left: 30px;  /* Spacing between items */
  margin-left: 30px;
  font-size: 15px;
}

.Slot_eachContainer__vaCui::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 10px; /* Circle size */
  height: 10px; /* Circle size */
  background-color: black; /* Customize your circle color */
  border-radius: 50%; /* Makes the circle */
}


@media (max-width:600px) {
  .Slot_slotsContainer__e-g68{
    flex-direction: column;
    grid-gap: 50px;
    gap: 50px;
    width: 100%;
    padding: 0px;
    margin-top: 50px;
  }
  .Slot_slotsCard__25E3H{
    grid-template-columns: repeat(1, 1fr);
  }
  .Slot_priceContainer__PJpkZ{
    font-size: 12px;
  }
}

@media (min-width:600px) and (max-width:1200px ) {
  .Slot_slotsContainer__e-g68{
    flex-direction: column;
    grid-gap: 100px;
    gap: 100px;

  }

  .Slot_rightContainer__3T-KW{
    margin-left: 30%;
  }

}
.OneSlot_oneSlot__1Rd8w {
    margin: 20px;
    width: calc(100% - 40px);
    height: 50px;
    border-style: solid;
    border-width: 1px;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    border-color: var(--themeColor);
    background-color: var(--chatCommentBkgColor);
    cursor: pointer;
    font-size: 20px;
    display: flex;
    justify-content: center;
    border-color: #b4cdfa;
}


.OneSlot_oneSlot__1Rd8w:hover {

    background-color: var(--themeColor);
    color: white;
}


.OneSlot_oneSlot__1Rd8w:hover .OneSlot_timeDiv__3C757 {
    color: white;

}


.OneSlot_bookNowButton__3nTBQ {
    width: 30%;
    min-width: 120px;
    border-radius: 0px 10px 10px 0px;
    border-style: solid;
    background-color: var(--themeColor);
    color: white;
    cursor: pointer;
    border-color: var(--themeColor);
}


.OneSlot_timeDiv__3C757 {

    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--themeColor);
    font-weight: bold;
}


.OneSlot_timeSpan__3nbkf {
    font-size: 13px;
}


.OneSlot_loginDiv__3GAwq {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: grey;
    z-index: 10000;
    right: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.OneSlot_closeLoginButton__3x0Si {
    border-radius: 5px;
    border-style: solid;
    width: 120px;
    height: 70px;
    background-color: white;
    cursor: pointer;
    border-width: 1px;
}



@media only screen and (max-width: 600px) {

    .OneSlot_oneSlot__1Rd8w {

        height: 40px;
        width: 250px;
        font-size: 17px;
    }


}
.PayForm_bookingForm__2lTYF {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: grey;
  z-index: 10000;
  right: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

}

.PayForm_formBox__pOiDw {
  margin-top: 5%;
  width: 380px;
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  justify-content: center;
}


.PayForm_editmobileNuBox__1rtU_ {
  background-color: var(--bodyBkgColor);
  border-radius: 5px;
  /* background-color: #f0f2f5; */
  height: 40px;
  width: 90%;
  padding-left: 10px;
  border: 2px solid var(--cardBorderColorLight);
  font-size: 14px;
  margin: auto;
  border-color: grey;
}

.PayForm_editmobileNuBox__1rtU_:focus {
  padding-left: 8px;
  padding-right: 8px;
  border-style: solid;
  border-width: 2px;
  outline: none !important;
  border-color: var(--themeColor);
  background-color: white;
}

.PayForm_formContentBox__1fRCI {

  margin-top: 50px;
  padding: 30px;
}


.PayForm_textInfoForm__2ju1g {
  height: 30px;
  font-weight: bold;
}


.PayForm_fieldtype__1InAn {
  border-style: none;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  border-radius: 5px;
  padding: 10px;
  background-color: pink;
}

.PayForm_tosContainer__1zocS{
  display: inline;
  color: blue;
  text-decoration: underline;
  cursor: pointer
}

.PayForm_bookSlotButton__2tsci {
  width: 100%;
  background-color: var(--themeColor);
  border-style: none;
  border-radius: 5px;
  color: white;
  margin: auto;
  height: 45px;
  cursor: pointer;
  margin-top: 30px;
  font-size: 20px;
}

.PayForm_errorContainer__3cZV4{
  margin-top: 10px;
  color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bolder;
}

.PayForm_bookSlotButton__2tsci:hover {}


.PayForm_closeButton__19FNz {}

.PayForm_closeButtonDiv__1snum {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}


.PayForm_loginToMeetingLinkButton__c-G3G {
  margin-top: 20px;
  border-style: none;
  height: 35px;
  width: 100%;
  border-radius: 5px;
  color: var(--themeColor);
  cursor: pointer;
}




.PayForm_mainContainer__4xATL {
  margin-top: 16px;
  display: flex;
  align-items: center;
  position: relative;
  border-style: solid;
  width: 315px;
  border-color: var(--themeColor);
  border-radius: 5px;
  height: 45px;
}
.PayForm_detailsContainer__1_6vO{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 315px;
  border: 3px solid var(--themeColor);
  height: 45px;
  border-radius: 5px;
  margin-top: 15px;
}

.PayForm_detailsLabel__Psp_l{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}
.PayForm_flagcode__1QUF6 {
  display: flex;
  height: 80%;
  align-items: center;
}

.PayForm_flagImg__vxxkX {
  height: 100%;
  aspect-ratio: 1/1;
  border-radius: 2px;
  margin-left: 10px;

}

.PayForm_countryCode__kAJ8Z {
  margin-left: 10px;
  color: var(--greyTextColor);
  font-size: 20px;
  font-weight: bold;
  color: black;
  display: flex;
  border-style: none;
  height: 100%;
  align-items: center;
  padding-right: 5px;
}

.PayForm_editmobileNuBox__1rtU_ {
  border-radius: 4px;
  /* background-color: #f0f2f5; */
  height: 40px;
  border: 2px solid var(--themeColor);
  font-size: 20px;
  margin: auto;
  border-style: none;
  font-weight: bold;
  width: 50%;
}
.PayForm_detailsInput__2rKgK{
  border-radius: 4px;
  height: 40px;
  border: 2px solid var(--themeColor);
  font-size: 20px;
  margin: auto;
  border-style: none;
  font-weight: bold;
  width: 70%;
  background-color: #f0f2f5;
}

.PayForm_editmobileNuBox__1rtU_:focus {
  padding-left: 8px;
  padding-right: 8px;
  border-style: none;
  border-width: 1px;
  outline: none !important;
  border-color: var(--cardBorderColorLight);
  border-color: var(--cardBorderColor);
  background-color: white;
}






.PayForm_checkBoxTermsAndConditions__1PReG {
  margin-top: 50px;
  text-align: center;
}
.UnitBarInstDegree_unitBarInstDegree__3h6fW{
width: 100%;

border-bottom-style: solid;
border-width: 1px;
border-color: lightgrey;
display: flex;
margin-top: 20px;
padding-left: 20px;
padding-top:10px;
padding-bottom:10px;
justify-content: space-between;
align-items: center;
}


.UnitBarInstDegree_InstLogo__oke35{

width: 80px;
height: 80px;
margin-left: 30px;
}

.UnitBarInstDegree_InstInfo__3iHvP{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 0px;

}


.UnitBarInstDegree_InstInfo__degree__V-E2C{
  color: black;
  font-weight: bold;
  font-size:20px;
}

.UnitBarInstDegree_InstInfo__duration__OCJlK{
 color: var(--themeColor);
}


.UnitBarInstDegree_InstInfo__location__-DICD{
  color: grey;
}



.UnitBarInstDegree_logoImage__1SKve{

width:60px;
height: 60px;

}


.UnitBarInstDegree_deleteButton__B9VH2{
height: 30px;
margin-right:10px;
border-style: none;
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
}









.UnitBarAchievement_unitBarAchievement__3bJSs{

width: 100%;
border-bottom-style:solid ;
border-width: 1px;
margin-bottom: 20px;
border-color: lightgrey;
padding-bottom: 10px;
display: flex;
justify-content: space-between;
margin-top: 30px;

}


.UnitBarAchievement_infoDiv1__2iDmC{
font-weight: bold;
font-size: 20px;
}

.UnitBarAchievement_deleteButton__2qowM{
height: 30px;
width: 30px;
border-style: none;
border-radius: 5px;
cursor: pointer;
}



.Faq_faqContainer__2ru_f {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: var(--cardBorderColorLight);
    background-color: var(--bodyBkgColor);
    border-radius: 10px;
    margin-top: 30px;
    padding: 20px;
}

.Faq_mainTitleContainer__1zfFS {
    margin-top: 30px;
    font-size: 30px;
    font-weight: bolder;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Faq_mainContainer__2PudS {
    margin-top: 30px;

    width: 100%;
    display: flex;
    flex-direction: column;
}

.Faq_questionsContainer__18ejR {
    margin: 10px;
    display: flex;
    flex-direction: column;

    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 10px;
    background-color: white;
    border: 1px solid var(--cardBorderColor);
}

.Faq_queContainer__22yYq {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px;
}

.Faq_que1__3soGy {
    font-size: 18px;
    font-weight: bold;
    color: black;
}

.Faq_arrow__2suBm {
    width: 20px;
    height: 20px;
    background-color: var(--cardBorderColorLight);
    border-radius: 30px;
    padding: 6px;
}

.Faq_ans1__2NW0J {
    color: black;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 20px;
}

@media (max-width: 1000px) {
    .Faq_faqContainer__2ru_f {
        width: 100%;
        padding: 0;
    }

    .Faq_mainTitleContainer__1zfFS {
        padding-top: 20px;
    }

    .Faq_mainContainer__2PudS {
        width: 100%;
    }
}
.BlogAndNewsBlock_latest__1k8fC {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;

  
}

.BlogAndNewsBlock_latestTitle__2elTT {
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
}

.BlogAndNewsBlock_latestsubTitle__2Hh_8 {
  margin-top: 20px;
  font-weight: bold;
  color: #08203d;
  line-height: 55px;
  font-size: 45px;
}

.BlogAndNewsBlock_newsContainer__D8UAw {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.BlogAndNewsBlock_singleContainer__34Qir {
  margin: 10px;
  height: 500px;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.BlogAndNewsBlock_newsImage__3fP48 {
  width: 100%;
  height: 200px;
  max-height: 200px;
  min-height: 200px;
  background-color: white;
}

.BlogAndNewsBlock_newsTitle__Bz8U2 {
  margin-top: 20px;
  margin-left: 10px;
  font-size: 20px;
  font-weight: 500;
  color: #08203d;
}

.BlogAndNewsBlock_newsDesc__2cl5M {
  margin-top: 10px;
  margin-left: 10px;
  font-size: 15px;
  color: var(--lightText);
  text-align: justify;
  margin-right: 10px;
  line-height: 19px;
  height: 180px;
  max-height: 180px;
  min-height: 180px;
}

.BlogAndNewsBlock_readMoreBtn__2OmK7 {
  margin-left: 10px;
  font-size: 16px;
  color: var(--lightText);
  margin-bottom: 20px;
  color: var(--themeColor);
  padding: 6px;
  border-radius: 2px;
}



.OneFriend_oneFriend__1qxur{


width: 150px;
height: 190px;
border-style: solid;
display: flex;
align-items: center;
flex-direction: column;
background-color: white;
padding: 30px;
border-color: var(--cardBorderColor);
border-width: 1px;
border-radius: var(--cardBorderRadius);
}


.OneFriend_friendImage__2S223{

width:150px;
height:150px; 
margin: auto;
border-radius: var(--cardBorderRadius);
}

.OneFriend_InfoBox__1L6_T{
height: 40px;
color: grey;
border-style: none;
}

.News_parentContainer__oaz8b {
  display: flex;
  flex-direction: column;
  border-style: none;
}

.News_topPic__27B6F {
  width: 100%;
  height: 400px;
  object-fit: cover;
  background-color: var(--chatCommentBkgColor);
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  border-style: none;
}

.News_topContentDiv__3kHNL{
width: 1200px;
border-style: none;
margin: auto;
}

.News_Heading1s__3Djwv {
  font-weight: bolder;
  font-size: 30px;
  margin-left: 40px;
}

.News_subheading1s__3f2xO {
  margin-top: 20px;
  font-size: 18px;
  color: var(--deepDarkThemeTextColor);
  margin-left: 40px;
}

.News_editbtnContainer__3FESF {
  margin-left: 40px;
  margin-top: 30px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.News_emailEditBox__2auuS {
  width: 250px;
  background-color: white;
  border-radius: 8px;
  height: 28px;
  padding-left: 10px;
  font-size: 14px;
  border: 1px solid var(--themeColor);
}

.News_emailEditBox__2auuS:focus {
  border-style: solid;
  background-color: white;
  border-width: 1px;
  outline: none !important;
  border-color: var(--themeColor);
}

.News_subBtn__4yZVS {
  font-weight: bolder;
  margin-left: 20px;
  width: 250px;
  height: 30px;
  font-size: 14px;
  background-color: var(--themeColor);
  border: none;
  color: white;
  border-radius: 8px;
}

.News_subBtn__4yZVS:hover {
  cursor: pointer;
}

.News_latest__2pe7B {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.News_latestTitle__1jOmD {
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
}

.News_latestsubTitle__ziEt_ {
  margin-top: 20px;
  font-weight: bold;
  color: #08203d;
  line-height: 55px;
  font-size: 45px;
}

.News_newsContainer__1Lg27 {
  width: 1200px;
  display: flex;
  flex-direction: column;
  margin: auto;
  /* display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, auto));
  row-gap: 10px;
  column-gap: 10px; */
}

.News_natitonalNewsBlock__jvYkK {
  width: 100%;
}

.News_stateNewsBlock__1PP1b {
  width: 100%;
}

.News_National__1kFmi {
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--lightText);
}

.News_State__1zXIL {
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--lightText);
}

@media (max-width: 1300px) {
.News_topContentDiv__3kHNL{
width: 90%;
}

.News_newsContainer__1Lg27{
width: 90%;
}

}


@media (max-width: 1000px) {
  .News_parentContainer__oaz8b {
    width: 100%;
  }

  .News_topPic__27B6F {
    width: 100%;
    height: auto;
    object-fit: cover;
    margin: 0;
  }

  .News_Heading1s__3Djwv {
    width: 100%;
    margin: 0;
    text-align: center;
    margin-top: 20px;
    margin-left: 0;
  }

  .News_subheading1s__3f2xO {
    width: 100%;
    margin: 0;
    text-align: center;
    margin-top: 20px;
    margin-left: 0;
  }

  .News_editbtnContainer__3FESF {
    width: 100%;
    margin: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-left: 0;
  }

  .News_subBtn__4yZVS {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 0;
  }
}

.ContactUs_contactUsParent__3qW_D {
  width: 100%;
  margin-top: 100px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-row-gap: 30px;
  row-gap: 30px;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

.ContactUs_headingContainer__2Ij7C{
  background-color: var(--chatCommentBkgColor);
  font-size: 30px;
  font-weight: bolder;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

.ContactUs_content__3gbin {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.ContactUs_heading1__34Z6- {
  color: var(--darkText);
  font-size: 22px;
  font-weight: bold;
  width: 50%;
  line-height: 26px;
}

.ContactUs_addressContainer__3hy_O {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.ContactUs_addressIcon__GfdSE {
  border: 1px solid var(--themeColor);
  height: 20px;
  width: 20px;
  border-radius: 20px;
  padding: 4px;
  color: var(--themeColor);
}

.ContactUs_addressHead__9JbhG {
  margin-left: 10px;
  font-weight: bold;
  color: var(--darkText);
}

.ContactUs_addressDetails__3Mi9i {
  margin-left: 10px;
}

.ContactUs_phoneContainer__1V9lG {
  display: flex;
  align-items: center;
}

.ContactUs_phoneIcon__37nmh {
  border: 1px solid var(--themeColor);
  height: 20px;
  width: 20px;
  border-radius: 20px;
  padding: 4px;
  color: var(--themeColor);
}

.ContactUs_phoneHead__vl9Ij {
  margin-left: 10px;
  font-weight: bold;
  color: var(--darkText);
}

.ContactUs_phoneDetails__1xhUv {
  margin-left: 10px;
}

.ContactUs_emailContainer__1qT0I {
  display: flex;
  align-items: center;
}

.ContactUs_emailIcon__2hl0A {
  border: 1px solid var(--themeColor);
  height: 20px;
  width: 20px;
  border-radius: 20px;
  padding: 4px;
  color: var(--themeColor);
}

.ContactUs_emailHead__oeyha {
  margin-left: 10px;
  font-weight: bold;
  color: var(--darkText);
}

.ContactUs_emailDetails__3w0XT {
  margin-left: 10px;
}

.ContactUs_SocialMediaTitle__14SMA {
  margin-top: 20px;
  font-size: 20px;
  font-weight: bold;
}

.ContactUs_mainContainerIcon__2SKo5 {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.ContactUs_icon1Container__170Y4 {
  height: 30px;
  width: 30px;
}

.ContactUs_icon2Container__3NPMF {
  margin-left: 16px;
  height: 30px;
  width: 30px;
}

.ContactUs_icon3Container__Q-hM8 {
  margin-left: 16px;

  height: 30px;
  width: 30px;
}

.ContactUs_icon4Container__3STa4 {
  margin-left: 16px;

  height: 30px;
  width: 30px;
}

.ContactUs_BusinessDetails__3F9ES {
  margin-top: 20px;
  font-size: 20px;
  font-weight: bold;
}

.ContactUs_details1__2mlXf {
  margin-top: 10px;
  color: var(--lightText);
  font-size: 16px;
}

.ContactUs_bContainer1__2aFLn {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.ContactUs_bContainer2__2jwI2 {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.ContactUs_dayIcon__1t7mb {
  height: 20px;
  width: 20px;
  padding: 4px;
  color: var(--themeColor);
}

.ContactUs_dayHead__3ev_b {
  margin-left: 10px;
  font-weight: bold;
}

.ContactUs_daytimeDetails__1SGvC {
  margin-left: 10px;
}

.ContactUs_details2__iKSLy {
  margin-top: 20px;
}

.ContactUs_getinTouchTitle__3u6Lx {
  margin-top: 20px;
  font-size: 20px;
  font-weight: bold;
}

.ContactUs_getintouchdetails3__2i4ZA {
  margin-top: 10px;
  line-height: 24px;
}

.ContactUs_headingDetails1__23dXA {
  width: 80%;
  margin-top: 20px;
  font-size: 15px;
  color: var(--lightText);
  line-height: 22px;
  text-align: justify;
}

.ContactUs_heading2__KB0oU {
  margin-top: 20px;
  color: var(--darkText);
  font-size: 16px;
  font-weight: bold;
}

.ContactUs_OfficeImageContainer__3XWAx {
  margin-top: 20px;
  width: 80%;
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
}

.ContactUs_bottomDetailsContainer__3hkeF {
  margin-top: 20px;
  width: 100%;
  display: flex;
}

.ContactUs_firstContainer__2Vn-N {
  width: 100%;
}

.ContactUs_firstTitle__hUOYC {
  font-size: 16px;
  color: var(--darkText);
  font-weight: 500;
  visibility: hidden;
}

.ContactUs_subFIrstContainer__GhnQ9 {
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
}

.ContactUs_sub_FIrstContainer__3v_wm {
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
}

.ContactUs_locationIcon__1Wvhz {
  border: 1px solid var(--cardBorderColor);
  height: 16px;
  width: 16px;
  min-width: 16px;
  min-height: 16px;
  color: var(--lightText);
  display: flex;
  justify-content: start;
  align-items: start;
  background-color: var(--cardBorderColorLight);
  padding: 8px;
  border-radius: 50%;
}

.ContactUs_locationIcon2__342JH {
  border: 1px solid var(--cardBorderColor);
  height: 16px;
  width: 16px;
  min-width: 16px;
  min-height: 16px;
  color: var(--lightText);
  display: flex;
  justify-content: start;
  align-items: start;
  background-color: var(--cardBorderColorLight);
  padding: 8px;
  border-radius: 50%;
}

.ContactUs_firstAddressText__1eaXK {
  margin-right: 10px;
  margin-left: 6px;
  font-size: 14px;
  color: var(--lightText);
  line-height: 20px;
}

.ContactUs_secContainer__1ZEv8 {
  width: 100%;
}

.ContactUs_form__2s7QD {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  justify-self: center;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.ContactUs_formContainer__3l8Of {
  width: 90%;
}

.ContactUs_headingText__3B6j9 {
  padding-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.ContactUs_horizontalLine__3mjWp {
  margin-top: 14px;
  margin-bottom: 6px;
  border-bottom: 1px solid var(--cardBorderColor);
}

.ContactUs_topContainer__2Z_df {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.ContactUs_firstContainer__2Vn-N {
  width: 100%;
}

.ContactUs_firstNameText__2j3Rq {
  margin-top: 20px;
  color: var(--lightText);
}

.ContactUs_firstNameEditBox__2j1YQ {
  width: 90%;
  margin-right: 5px;
  margin-top: 4px;
  background-color: white;
  border-radius: 8px;
  height: 28px;
  padding-left: 10px;
  font-size: 14px;
  border: 1px solid var(--cardBorderColorLight);
}

.ContactUs_firstNameEditBox__2j1YQ:focus {
  border-style: solid;
  background-color: white;
  border-width: 1px;
  outline: none !important;
  border-color: var(--themeColor);
}

.ContactUs_secContainer__1ZEv8 {
  width: 50%;
}
.ContactUs_secContainer2__jyeTj {
  width: 50%;
  visibility: hidden;
}

.ContactUs_lastNameText__1ZKg6 {
  margin-left: 10px;
  margin-top: 20px;
  color: var(--lightText);
}
.ContactUs_lastNameEditBox__2fNaa {
  width: 90%;
  margin-left: 10px;
  margin-top: 4px;
  background-color: white;
  border-radius: 8px;
  height: 28px;
  padding-left: 10px;
  font-size: 14px;
  border: 1px solid var(--cardBorderColorLight);
}

.ContactUs_lastNameEditBox__2fNaa:focus {
  border-style: solid;
  background-color: white;
  border-width: 1px;
  outline: none !important;
  border-color: var(--themeColor);
}

.ContactUs_emailContainer__1qT0I {
  width: auto;
}

.ContactUs_textEmail__1Wdrq {
  margin-top: 10px;
  color: var(--lightText);
}

.ContactUs_emailEditBox__C1Hop {
  width: 90%;
  margin-top: 4px;
  background-color: white;
  border-radius: 8px;
  height: 28px;
  padding-left: 10px;
  font-size: 14px;
  border: 1px solid var(--cardBorderColorLight);
}

.ContactUs_emailEditBox__C1Hop:focus {
  border-style: solid;
  background-color: white;
  border-width: 1px;
  outline: none !important;
  border-color: var(--themeColor);
}

.ContactUs_companyContainer__2Ypal {
  margin-top: 20px;
  width: auto;
}

.ContactUs_textCompany__1P4FE {
  margin-top: 10px;
  color: var(--lightText);
}

.ContactUs_companyEditBox__10gvQ {
  width: 97%;
  margin-top: 4px;
  background-color: white;
  border-radius: 8px;
  height: 28px;
  padding-left: 10px;
  font-size: 14px;
  border: 1px solid var(--cardBorderColorLight);
}

.ContactUs_companyEditBox__10gvQ:focus {
  border-style: solid;
  background-color: white;
  border-width: 1px;
  outline: none !important;
  border-color: var(--themeColor);
}

.ContactUs_phoneContainer__1V9lG {
  width: auto;
}

.ContactUs_textphone__3V_0h {
  margin-top: 10px;
  color: var(--lightText);
}

.ContactUs_phoneEditBox__12urW {
  width: 97%;
  margin-top: 4px;
  background-color: white;
  border-radius: 8px;
  height: 28px;
  padding-left: 10px;
  font-size: 14px;
  border: 1px solid var(--cardBorderColorLight);
}

.ContactUs_phoneEditBox__12urW:focus {
  border-style: solid;
  background-color: white;
  border-width: 1px;
  outline: none !important;
  border-color: var(--themeColor);
}

.ContactUs_messageContainer__GP5tj {
  margin-top: 20px;
  width: 97%;
}

.ContactUs_textMessage__rrVKi {
  margin-top: 10px;
  color: var(--lightText);
}

.ContactUs_messageEditBox__2Wuto {
  width: 100%;
  height: 100px;
  text-align: start;
  margin-top: 4px;
  background-color: white;
  padding-top: 0;
  padding-left: 0;
  line-height: 1em;
  border-radius: 8px;
  padding: 10px;
  font-size: 14px;
  border: 1px solid var(--cardBorderColorLight);
  display: flex;
  justify-content: start;
  align-items: start;
}

.ContactUs_messageEditBox__2Wuto:focus {
  border-style: solid;
  background-color: white;
  border-width: 1px;
  outline: none !important;
  border-color: var(--themeColor);
}

.ContactUs_sendBtnContainer__5gmHj {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 40px;
}

.ContactUs_sendBtn__30XlK {
  margin-top: 40px;
  width: 60%;
  height: 40px;
  background-color: var(--themeColor);
  border-radius: 8px;
  color: white;
  font-size: 18px;

  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.ContactUs_sendBtn__30XlK:hover {
  cursor: pointer;
}

.ContactUs_horizontalBorder__1Faf- {
  margin-top: 40px;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--cardBorderColor);
}

.ContactUs_t1__Plpv- {
  margin-top: 20px;
  font-size: 18px;
  color: var(--darkText);
  font-weight: bolder;
}

.ContactUs_d1__95dgX {
  margin-top: 10px;
  font-size: 16px;
  color: var(--lightText);
}

.ContactUs_d2__182r7 {
  margin-top: 20px;
  font-size: 18px;
  color: var(--darkText);
  font-weight: bold;
}

@media (max-width: 1300px) {
  .ContactUs_contactUsParent__3qW_D {
    width: 98%;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .ContactUs_OfficeImageContainer__3XWAx {
    width: 96%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .ContactUs_form__2s7QD {
    margin-right: 10px;

    width: 96%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.Notices_notices__19fhP {
}

.Notices_searchbar__3iWob {
  margin-top: 100px;
  margin-bottom: 34px;
  width: 700px;
  border: 1px solid var(--cardBorderColor);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Notices_serchIcon__37jII {
  margin-left: 10px;
  color: var(--lightText);
}

.Notices_searchInput__1rPS7 {
  width: 94%;
  margin-right: 5px;
  background-color: white;
  height: 30px;
  font-size: 14px;
  font-size: 18px;
  border: none;
}

@media (max-width: 1300px) {
  .Notices_searchbar__3iWob {
    width: 90%;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.Notices_mainContainer__2iz2n {
  margin-top: 20px;
  width: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-row-gap: 10px;
  row-gap: 10px;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.Notices_leftContainer__PTNWI {
}

.Notices_title__3Zeij {
  font-weight: bold;
  color: black;

  margin-left: 10px;
}

.Notices_topBar__2LoWX {
  width: 100%;
  height: 35px;
  background-color: var(--cardBorderColorLight);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  display: flex;
  font-size: 18px;
  justify-content: start;
  align-items: center;
  border-bottom: 1px solid var(--cardBorderColor);
}

.Notices_mainImg__1P3Hg {
  width: 100%;
}

.Notices_pic__2h72B {
  width: 100%;
  height: 100%;
}

.Notices_docBox__2Fk33 {
  height: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border: 1px solid var(--cardBorderColor);
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

.Notices_law__2VLnK {
  margin: 10px;
  line-height: 30px;
}

.Notices_uploadDocument__1qovi {
  margin-top: 10px;
  height: 30px;
  background-color: var(--themeColor);
  color: white;
  border: 1px solid var(--themeColor);
  border-radius: 6px;
}

.Notices_rightContainer__3se8q {
}

.Notices_changesContainer__2Eo7C {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Notices_circleContainer__1k7vz {
  width: 130px;
  height: 130px;
  margin-top: 50px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: var(--themeColor);
  border: 2px solid var(--themeColor);
}

.Notices_changesTitle__23nxY {
  font-weight: bolder;
  font-size: 22px;
}

.Notices_arrowIcon__1yZpG {
  margin-top: 10px;
  color: var(--themeColor);
  height: 30px;
  width: 30px;
}

.Notices_mainChangesDetailsContainer__3qGn_ {
  margin-top: 50px;
  width: 45%;
  text-align: justify;
  height: auto;
  padding: 10px;
  line-height: 30px;
  border: 1px solid var(--themeColor);
  border-radius: 10px;
}


@media (max-width: 1300px) {

  .Notices_mainContainer__2iz2n{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .Notices_leftContainer__PTNWI{
    width: 97%;
  }

  .Notices_rightContainer__3se8q{
    margin-top: 40px;
    width: 97%;
  }

  .Notices_mainChangesDetailsContainer__3qGn_{
    width: 85%;
  }
}
.OneNotice_oneNotice__dHsoH {
  height: auto;
  margin-top: 16px;
  padding: 20px;
  border-radius: 16px;
  display: flex;
  border-bottom: 1px solid var(--cardBorderColorLight);
}

.OneNotice_noticeBg__2WF_T {
  height: 30px;
  width: 30px;
  max-width: 30px;
  max-width: 30px;
  background-color: var(--cardBorderColorLight);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--cardBorderColor);
}

.OneNotice_noticeNumber__2fkl3 {
  color: var(--darkText);
}

.OneNotice_contentContainer__3gRi1 {
  margin-left: 20px;
  width: 80%;
}

.OneNotice_titleDiv__3v7QQ {
  font-size: 20px;
  font-weight: bold;
  color: var(--darkText);
}

.OneNotice_contentDiv__3x3Xs {
  margin-top: 10px;
  color: var(--lightText);
  font-size: 18px;
  line-height: 26px;
  text-align: justify;
  margin-bottom: 16px;
}

@media (max-width: 1300px) {
  .OneNotice_oneNotice__dHsoH {
    padding: 0px;
  }

}
.JoinAsTopper_rulesAndRegulationsParent__oleTz {
  margin-top: 40px;
  width: 100%;
}

.JoinAsTopper_rulesAndRegulationsMain__i_A2o {
  width: 100%;
  border-style: none;
}

.JoinAsTopper_innerDiv__1XlSP {
  width: 1200px;
  margin: auto;
}

.JoinAsTopper_authorName__2UnD_{
  font-size: 10px;
  color: black;
  position: relative;
  top: 20px;
  left: -20px;
}

.JoinAsTopper_serchContainer__1hIoi {
  background-color: var(--bgColor);
  height: 50px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--cardBorderColor);
}

.JoinAsTopper_serchbox__Xt1SG {
  margin-left: 10px;
  width: 90%;

  background-color: white;
  border-radius: 8px;
  height: 28px;
  padding-left: 10px;
  font-size: 14px;
  border: none;
  border: 1px solid var(--cardBorderColorLight);
}

.JoinAsTopper_serchbox__Xt1SG:focus {
  border-style: solid;
  background-color: white;
  border-width: 2px;
  outline: none !important;
  border-color: var(--themeColor);
}

.JoinAsTopper_btnContainer__23j8G {
  width: 100px;
  margin-left: 20px;
  height: 28px;
  background-color: var(--themeColor);
  border: none;
  border-radius: 8px;
  color: white;
  margin-right: 10px;
}

.JoinAsTopper_btnContainer__23j8G:hover {
  cursor: pointer;
  background-color: white;
  color: var(--themeColor);
  border: 1px solid var(--themeColor);
}

.JoinAsTopper_parentDiv__2VNnX {
  margin-top: 20px;
  display: flex;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(700px, auto));
  grid-row-gap: 20px;
  row-gap: 20px;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.JoinAsTopper_leftBox__2clkZ {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border: 1px solid var(--cardBorderColor);
}

.JoinAsTopper_topHeadingSection__2UJLV {
  height: 40px;
  display: flex;
  justify-content: start;
  align-items: center;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background-color: var(--bgColor);
  border-bottom: 1px solid var(--cardBorderColor);
}

.JoinAsTopper_iconContainer__W39D6 {
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  background-color: white;
  border-radius: 50%;
  border: 1px solid var(--cardBorderColor);
}

.JoinAsTopper_title__rq2jn {
  width: 100%;
  margin-left: 10px;
  font-weight: bold;
  color: var(--darkText);
  text-align: center;
}

.JoinAsTopper_rightBoxContainer__1bycp {
}

.JoinAsTopper_topBox__3syTw {
  width: 100%;

  border: 1px solid var(--cardBorderColor);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.JoinAsTopper_midBox__3grrJ {
  margin-top: 20px;
  width: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border: 1px solid var(--cardBorderColor);
}

.JoinAsTopper_bottomBox__HGx05 {
  margin-top: 20px;
  width: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border: 1px solid var(--cardBorderColor);
}

.JoinAsTopper_mainContainer__227gn {
}

.JoinAsTopper_mainIcon__3bu2z {
}

.JoinAsTopper_noticeIcon__1CWda {
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.JoinAsTopper_regIcon__Sc0Ep {
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.JoinAsTopper_ruleIcon__3SWZ7 {
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.JoinAsTopper_actIcon__3c_U0 {
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.JoinAsTopper_rulesAndRegulationsParent__oleTz {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-row-gap: 20px;
  row-gap: 20px;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.JoinAsTopper_reraData__1QC-p {
  margin-top: 30px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(195px, 1fr));
  grid-row-gap: 20px;
  row-gap: 20px;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.JoinAsTopper_Block1__3_dQk {
  height: 500px;
  border: 1px solid var(--themeColor);
}

.JoinAsTopper_Title__1G2Uw {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid var(--themeColor);
  background-color: var(--themeColor);
  font-weight: bold;
  color: white;
}

.JoinAsTopper_mainContainer__227gn {
  padding-top: 10px;
  display: flex;
  padding-right: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--cardBorderColorLight);
}

.JoinAsTopper_mainContainer__227gn:hover {
  background-color: var(--bgColor);
  cursor: pointer;
}

.JoinAsTopper_nu__1r4tn {
  display: flex;
  justify-content: center;
  width: 15%;
  font-size: 14px;
}

.JoinAsTopper_details__3TXTo {
  margin-top: 10px;
  width: 100%;
  font-size: 15px;
  line-height: 24px;
  text-align: justify;
}

.JoinAsTopper_Block2__2Ctpx {
  height: 500px;
  border: 1px solid var(--themeColor);
}

.JoinAsTopper_Block3__3LKCS {
  height: 500px;
  border: 1px solid var(--themeColor);
}

.JoinAsTopper_Block4__yn_AQ {
  height: 500px;
  border: 1px solid var(--themeColor);
}

.JoinAsTopper_threeCardContainer__3_l0g {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-row-gap: 20px;
  row-gap: 20px;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.JoinAsTopper_topCard__2gqwa {
  width: 1200px;
  border-style: none;
  display: flex;
  margin: auto;
  
}

.JoinAsTopper_topSectionJoinTopper__2gaCB {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.JoinAsTopper_textDiv__239On {
  border-style: none;
}

.JoinAsTopper_largeText__3xEzb {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}

.JoinAsTopper_smallText__2a0oW {
  margin-top: 30px;
  text-align: center;
  font-size: 18px;
}

.JoinAsTopper_joinNowButton__-irKQ {
  height: 40px;
  width: 230px;
  border-radius: 30px;
  border-style: none;
  background-color: var(--themeColor);
  margin-top: 30px;
  cursor: pointer;
  font-family: roboto;
  color: white;
  font-size: 16px;
  font-weight: bold;
}









.JoinAsTopper_joinNowButton__-irKQ:hover{

color: var(--themeColor);
background: white;
border-style: solid;
border-color: var(--themeColor);
border-width: 1px;
}



.JoinAsTopper_topRightSection__zm2un {
  width: 50%;
  object-fit: cover;
}

.JoinAsTopper_girlIMageTop__2xFNm {
  width: 400px;
  height: 400px;
  object-fit: cover;
}

.JoinAsTopper_card1__3noKv {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.JoinAsTopper_card2__354Gi {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.JoinAsTopper_card3__1Tg8c {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.JoinAsTopper_contentContainer__1hsBt {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.JoinAsTopper_img__63sDK {
  width: 60px;
  height: 60px;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s; /* Add a smooth transition for the rotation effect */
  /* Initial state (not hovered) */
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}

.JoinAsTopper_card1__3noKv:hover .JoinAsTopper_img__63sDK {
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
}

.JoinAsTopper_card2__354Gi:hover .JoinAsTopper_img__63sDK {
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
}

.JoinAsTopper_card3__1Tg8c:hover .JoinAsTopper_img__63sDK {
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
}

.JoinAsTopper_title__rq2jn {
  width: 80%;
  margin-top: 10px;
  font-size: 22px;
  color: var(--deepDarkThemeTextColor);
  font-weight: bold;
  line-height: 28px;
}

/* .details {
  margin-top: 10px;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
} */

.JoinAsTopper_inconstructionMessage__3MmX8 {
  margin-top: 40px;
  color: red;
  font-size: 25px;
  border-style: solid;
  padding: 20px;
  border-radius: 10px;
}

.JoinAsTopper_shapethefutureCard__Pq-lw {
  margin-top: 30px;
  width: 100%;
  margin-bottom: 50px;
  background-color: var(--themeColor);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 10px;
}

.JoinAsTopper_shapetheFutureTitle__3pFwu {
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 30px;
  color: white;
}

.JoinAsTopper_shapetheFutureButton__2ivMx {
  width: 100px;
  height: 30px;
  margin-top: 10px;
  margin-bottom: 30px;
  background-color: white;
  color: black;
  border: none;
  border-radius: 6px;
}

.JoinAsTopper_shapetheFutureButton__2ivMx:hover {
  cursor: pointer;
  color: var(--themeColor);
  border: 1px solid var(--themeColor);
  background-color: white;
}

.JoinAsTopper_shapethefutureDetails__35-wD {
  width: 80%;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  line-height: 26px;
  color: white;
  font-weight: 500;
  font-size: 18px;
}

.JoinAsTopper_mainTitle1__1lhYu {
  margin-top: 30px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 30px;
  color: var(--deepDarkThemeTextColor);
  display: flex;
  align-items: center;
  justify-content: center;
}

.JoinAsTopper_submainTitle__1IUjH {
  margin-top: 50px;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: bold;
  color: var(--deepDarkThemeTextColor);
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: oblique;
  color: var(--themeColor);
  margin-left: 50px;
}

.JoinAsTopper_details1__3nCnr {
  margin-top: 50px;
  margin-bottom: 10px;
  text-align: center;
  line-height: 26px;
  color: var(--deepDarkThemeTextColor);
  font-weight: 500;
  font-size: 18px;
}

.JoinAsTopper_details2__3PdL9 {
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
  line-height: 26px;
  color: var(--deepDarkThemeTextColor);
  font-weight: 500;
  font-size: 18px;
}

.JoinAsTopper_mainTitle2__DVvd6 {
  margin-top: 50px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 30px;
  color: var(--deepDarkThemeTextColor);
  display: flex;
  align-items: center;
  justify-content: center;
}

.JoinAsTopper_joinToppersCard__3oeOg {
  width: 100%;
  background-color: var(--chatCommentBkgColor);
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 20px;
  border-style: none;
  margin: auto;
}

.JoinAsTopper_newContainer__NoKKq {
  margin-top: 50px;
  padding: 10px;
}

.JoinAsTopper_mainheadingTitle__2O_pO {
  font-weight: bolder;
  font-size: 30px;
  color: black;
  margin-bottom: 30px;
  text-align: center;
}

.JoinAsTopper_mainHead1__1r7lN {
  margin-top: 15px;
  font-size: 17px;
  font-weight: bolder;
  color: var(--deepDarkThemeTextColor);
}

.JoinAsTopper_mainDetail1__Fr4bS {
  margin-top: 6px;
  font-size: 16px;
  color: var(--lightColor);
}

.JoinAsTopper_howitworks__3RLyF {
  margin-top: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.JoinAsTopper_howitworksTitle__1CzNw {
  text-align: center;
  font-weight: bolder;
  font-size: 30px;
  color: black;
  margin-bottom: 30px;
  text-align: center;
}

.JoinAsTopper_lastContainer__2XFgX {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: start;
}

.JoinAsTopper_mainCircle__3T9kW {
  width: 50px;
  height: 50px;
  border: 1px solid var(--cardBorderColor);
  border-radius: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.JoinAsTopper_Acontaienr__2VUF1 {
  width: 80%;
  border: 1px solid var(--cardBorderColor);
  border-radius: 10px;
  margin: 10px;
}
.JoinAsTopper_titleA__1qWsp {
  background-color: var(--cardBorderColorLight);
  height: 30px;
  font-weight: bold;
  font-size: 18px;
  display: flex;
  right: 10px;
  align-items: center;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding-left: 10px;
}

.JoinAsTopper_desA__7B5Zm {
  height: 30px;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

@media (max-width: 1000px) {
  .JoinAsTopper_joinToppersCard__3oeOg {
    width: 100%;
    padding: 0;
    margin: 0;
  }

  .JoinAsTopper_topCard__2gqwa {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin: 0;
  }

  .JoinAsTopper_submainTitle__1IUjH{
    text-align: center;
  }

  .JoinAsTopper_innerDiv__1XlSP {
    width: 98%;
    margin: 0;
  }

  .JoinAsTopper_mainTitle1__1lhYu {
    text-align: center;
  }

  .JoinAsTopper_details1__3nCnr {
    text-align: justify;
    margin: 10px;
  }

  .JoinAsTopper_details2__3PdL9 {
    text-align: justify;
    margin: 10px;
  }

  .JoinAsTopper_threeCardContainer__3_l0g {
    margin: 10px;
  }

  .JoinAsTopper_topSectionJoinTopper__2gaCB {
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .JoinAsTopper_topRightSection__zm2un {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .JoinAsTopper_shapethefutureCard__Pq-lw {
    width: 100%;
  }

  .JoinAsTopper_mainTitle2__DVvd6 {
    text-align: center;
  }

  .JoinAsTopper_howitworks__3RLyF {
    margin: 0;
    padding: 0;
  }

  .JoinAsTopper_lastContainer__2XFgX {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
  }

  .JoinAsTopper_Acontaienr__2VUF1 {
  }

  .JoinAsTopper_titleA__1qWsp {
    height: 30px;
  }
  .JoinAsTopper_desA__7B5Zm {
    height: auto;
  }
}

.JoinAsTopper_f_points_star1__9ej9k {
  /* Modify size here: */
  --size: 30px;
  --half-size: calc(var(--size) / 2);

  position: relative;
  display: block;
  color: var(--themeColor);
  width: 0px;
  height: 0px;
  border-right: var(--half-size) solid transparent;
  border-bottom: calc(var(--half-size) * 0.7) solid #333;
  border-left: var(--half-size) solid transparent;
  -webkit-transform: rotate(35deg);
          transform: rotate(35deg);
}
.JoinAsTopper_f_points_star1__9ej9k:before {
  border-bottom: calc(var(--half-size) * 0.8) solid #333;
  border-left: calc(var(--half-size) * 0.3) solid transparent;
  border-right: calc(var(--half-size) * 0.3) solid transparent;
  position: absolute;
  height: 0;
  width: 0;
  top: calc(var(--half-size) * 0.45 * -1);
  left: calc(var(--half-size) * 0.65 * -1);
  display: block;
  content: "";
  -webkit-transform: rotate(-35deg);
          transform: rotate(-35deg);
}
.JoinAsTopper_f_points_star1__9ej9k:after {
  position: absolute;
  display: block;
  color: #333;
  top: calc(var(--half-size) * 0.03);
  left: calc(var(--half-size) * -1.05);
  width: 0px;
  height: 0px;
  border-right: var(--half-size) solid transparent;
  border-bottom: calc(var(--half-size) * 0.7) solid #333;
  border-left: var(--half-size) solid transparent;
  -webkit-transform: rotate(-70deg);
          transform: rotate(-70deg);
  content: "";
}

.JoinAsTopper_f_points_star__1frEl{
  
}

.JoinAsTopper_whyToppersContainer__Uw_jl {
  margin-top: 50px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-auto-rows: auto;
  grid-row-gap: 30px;
  row-gap: 30px;
  grid-column-gap: 30px;
  -webkit-column-gap: 30px;
          column-gap: 30px;
}

.JoinAsTopper_paarent__1tT73 {
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px; 
  background-color: white;
}

.JoinAsTopper_paarent__1tT73:hover {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.JoinAsTopper_logo__1UU4x {
  margin-top: 30px;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.JoinAsTopper_imagesExam__2rvF1 {
  width: 80px;
  height: 80px;
  transition: all 0.3s ease;
}

.JoinAsTopper_imagesExam__2rvF1:hover {
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
}

.JoinAsTopper_title__rq2jn {
  margin-top: 20px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.JoinAsTopper_description__aNS7w {
  margin-top: 20px;
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
  width: 90%;
}
.LeftImageRightText_websiteContent2__3Lmpy {
  margin-top: 100px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-row-gap: 10px;
  row-gap: 10px;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.LeftImageRightText_HomeImageContainer2__1dWVs {
  width: 100%;
  height: 400px;
  background-color: green;
  border-radius: 10px;
  object-fit: cover;
}

.LeftImageRightText_detailContainer2__2jrpN {
  width: 100%;
}

.LeftImageRightText_aboutHeading1__TqaNp {
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
}

.LeftImageRightText_aboutHeading2__zi1C5 {
  margin-top: 20px;
  font-weight: bold;
  color: #08203d;
  line-height: 55px;
  font-size: 25px;
}

.LeftImageRightText_aboutDetails__27MQt {
  margin-top: 20px;
  font-size: 18px;
  color: var(--lightText);
  line-height: 32px;
  text-align: justify;
}

/* .bulletList{
  list-style-type: circle;
  padding-left: 0px;

}

.li {
  font-size: 16px;
  list-style-type: circle;

}

ul  {
  font-size: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  list-style-position: inside;
}  */

.LeftImageRightText_topContainer__1RJfb {
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
}

.LeftImageRightText_bulleticon__1ALQo {
  width: 8px;
  height: 8px;
  background-color: black;
  border-radius: 50px;
}

.LeftImageRightText_MainHeading__1Aqrl {
  margin-left: 8px;
  font-weight: bold;
  font-size: 16px;
}

.LeftImageRightText_mainDetails__ri1D- {
  margin-top: 10px;
  font-size: 16px;
  line-height: 22px;
}

.LeftImageRightText_topperRequirementDiv__1fJIX {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1000px) {
  .LeftImageRightText_LeftImageRightTextParent__386-G {
    width: 98%;
    margin-right: 10px;
    margin-left: 10px;
  }

  .LeftImageRightText_websiteContent2__3Lmpy {
  }

  .LeftImageRightText_topperRequirementDiv__1fJIX {
  }

  .LeftImageRightText_aboutHeading1__TqaNp {
    text-align: center;
  }

  .LeftImageRightText_aboutHeading2__zi1C5 {
    margin-right: 10px;
    text-align: center;
    margin-left: 10px;
  }
}

.LeftImageRightText_topperRequirementDiv__1fJIX {
  font-size: 20px;
}

.FirstBlock_websiteContent2___OoRi {
  margin-top: 100px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-row-gap: 10px;
  row-gap: 10px;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.FirstBlock_HomeImageContainer2__3Lohx {
  width: 100%;
  height: 400px;
  background-color: green;
  border-radius: 10px;
  object-fit: cover;
}

.FirstBlock_detailContainer2__2Xuxa {
  width: 100%;
}

.FirstBlock_aboutHeading1__inyF3 {
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
}

.FirstBlock_aboutHeading2__jU040 {
  margin-top: 20px;
  font-weight: bold;
  color: #08203d;
  line-height: 55px;
  font-size: 45px;
}

.FirstBlock_aboutDetails__29ZOj {
  margin-top: 20px;
  font-size: 18px;
  color: var(--lightText);
  line-height: 32px;
  text-align: justify;
}

@media (max-width: 1000px) {

  .FirstBlock_LeftImageRightTextParent__37q_8{

    width: 98%;
    margin-right: 10px;
    margin-left: 10px;
  }
  .FirstBlock_websiteContent2___OoRi {

  }

  .FirstBlock_topperRequirementDiv__2yi-p {
  }

  .FirstBlock_aboutDetails__29ZOj {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 30px;
  }

  .FirstBlock_aboutHeading1__inyF3 {
    text-align: center;
  }

  .FirstBlock_aboutHeading2__jU040 {
    text-align: center;
  }
}

.SecondBlock_websiteContent2__1L5dP {
  margin-top: 100px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-row-gap: 10px;
  row-gap: 10px;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.SecondBlock_HomeImageContainer2__1u1Rx {
  width: 100%;
  height: 400px;
  background-color: green;
  border-radius: 10px;
  object-fit: cover;
}

.SecondBlock_detailContainer2__DRwEe {
  width: 100%;
}

.SecondBlock_aboutHeading1__1l5gc {
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
}

.SecondBlock_aboutHeading2__3LsCP {
  margin-top: 20px;
  font-weight: bold;
  color: #08203d;
  line-height: 55px;
  font-size: 45px;
}

.SecondBlock_aboutDetails__1H5d0 {
  margin-top: 20px;
  font-size: 18px;
  color: var(--lightText);
  line-height: 32px;
  text-align: justify;
}

@media (max-width: 1000px) {

  .SecondBlock_LeftImageRightTextParent__2hPTR{
    width: 98%;
    margin-right: 10px;
    margin-left: 10px;

  }



  .SecondBlock_detailContainer2__DRwEe {
  }
  .SecondBlock_aboutHeading1__1l5gc {
    font-size: 20;
    text-align: center;
  }
  .SecondBlock_aboutHeading2__3LsCP {
    margin-top: 10px;
    font-size: 30px;
    line-height: 40px;
    text-align: center;
  }

  .SecondBlock_aboutDetails__1H5d0 {
    margin-top: 10px;
    margin-right: 20px;
    font-size: 18px;
    line-height: 30px;
  }
  .SecondBlock_HomeImageContainer2__1u1Rx{
  }
}

.Testimonial_ourClientDetails__3BFA0 {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.Testimonial_clientTitle__1h8MH {
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
}

.Testimonial_clientSubTitle__3XU3H {
  margin-top: 20px;
  font-weight: bold;
  color: #08203d;
  line-height: 55px;
  font-size: 45px;
}

.Testimonial_clientFeedbackContainer__rj0Dl {
  margin-top: 50px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-auto-rows: auto;
  grid-row-gap: 30px;
  row-gap: 30px;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;


  /* display: flex;
  overflow-x: scroll;
  white-space: nowrap; */
}

.Testimonial_aboutClinetBox__3L0nN {
  width: auto;
  background-color: white;
  margin: 10px;
  padding: 20px;
  border-radius: 1px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.Testimonial_clientImg__3JHBY {
  margin-top: -50px;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.Testimonial_clientFeedback__2V9cq {
  display: flex;
  justify-content: space-between;
}

.Testimonial_socialNetworkImage__hs3zm {
  visibility: hidden;
  height: 30px;
  width: 30px;
  color: #00acee;
}

.Testimonial_clientDetails1__rWiH9 {
  font-size: 14px;
  margin-top: 10px;
  color: var(--lightText);
  line-height: 24px;
  text-align: justify;
}

.Testimonial_clientDetails2__2eT1L {
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 40px;
  color: var(--lightText);
  line-height: 24px;
  text-align: justify;
}

.Testimonial_clientDetails3__20lzI {
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 70px;
  color: var(--lightText);
  line-height: 24px;
  text-align: justify;
}

.Testimonial_clientName__2YimI {
  margin-top: 20px;
  font-size: 18px;
  font-weight: 500;
  color: #08203d;
}

.Testimonial_clientStatus__2lxL9 {
  margin-top: 10px;
  color: var(--themeColor);
  font-size: 14px;
}

@media (max-width: 1300px) {
  .Testimonial_clientTitle__1h8MH {
    font-size: 20px;
  }

  .Testimonial_clientSubTitle__3XU3H {
    margin-top: 10px;
    font-size: 30px;
  }
}

.Faqlist_faqContainer__1V-jK {
  width: 100%;

  background-color: var(--cardBorderColorLight);
  background-color: var(--bodyBkgColor);
  border-radius: 10px;
  margin-top: 30px;
  padding: 20px;
}

.Faqlist_mainTitleContainer__1VSDh {
  margin-top: 30px;
  font-size: 30px;
  font-weight: bolder;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Faqlist_mainContainer__1j6ta {
  margin-top: 30px;

  width: 100%;
  display: flex;
  flex-direction: column;
}

.Faqlist_questionsContainer__30P1Y {
  margin: 10px;
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 10px;
  background-color: white;
  border: 1px solid var(--cardBorderColor);
}

.Faqlist_queContainer__1eZdS {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
}
.Faqlist_que1__2_Vin {
  font-size: 18px;
  font-weight: bold;
  color: black;
}

.Faqlist_arrow__p5UTu {
  width: 20px;
  height: 20px;
  background-color: var(--cardBorderColorLight);
  border-radius: 30px;
  padding: 6px;
}

.Faqlist_ans1__3nfDu {
  color: black;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 20px;
}

@media (max-width: 1000px) {
  .Faqlist_faqContainer__1V-jK {
    width: 100%;
    padding: 0;
  }

  .Faqlist_mainTitleContainer__1VSDh {
    padding-top: 20px;
  }

  .Faqlist_mainContainer__1j6ta {
    width: 100%;
  }
}

.OneProject_mainContainer__3MFZw {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 20px;
}

.OneProject_oneProject__13_GJ {
  margin: 16px;
}

.OneProject_imageProject__3SzZH {
  width: 100%;
  height: 300px;
  border-radius: 20px;
}

.OneProject_projectName__psoaG {
  margin-top: 20px;
  font-size: 20px;
  font-weight: bold;
}

.OneProject_discription__1_RHt {
  margin-top: 10px;
  font-size: 16px;
  line-height: 22px;
  color: var(--lightText);
}

.OneProject_locationContainer__3ScqM {
  margin-top: 10px;

  display: flex;
}

.OneProject_locationTitle__2myoZ {
  color: var(--lightText);
  width: 100px;
}

.OneProject_location__1Whz0 {
  color: var(--darkText);
  font-weight: bold;
}

.OneProject_launchDateContainer__3qFYJ {
  margin-top: 10px;

  display: flex;
}

.OneProject_launchTitle__3zLEm {
  color: var(--lightText);
  width: 100px;
}

.OneProject_launchDate__21Mdl {
  font-weight: bold;
}

.OneProject_statusContainer__3Gk1O {
  margin-top: 10px;

  display: flex;
}

.OneProject_statusTitle__3KGyt {
  color: var(--lightText);
  width: 100px;
}

.OneProject_status__1UID0 {
  font-weight: bold;
  color: var(--darkText);
}

.OneProject_reraContainer__3qDIx {
  margin-top: 10px;

  display: flex;
}

.OneProject_rereTitle__IKtEn {
  color: var(--lightText);
  width: 100px;
}

.OneProject_rera__1WMx0 {
  font-weight: bold;
  color: var(--darkText);
}

.OneProject_bottomContnet__3uryA {
  margin-top: 10px;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.OneProject_noOfUnitsContainer__VyEwi {
  display: flex;
}

.OneProject_noofUnitsTitle__3Hscu {
  color: var(--lightText);
  width: 100px;
}

.OneProject_no_fu_units__2PwNj {
  font-weight: bold;
  color: var(--darkText);
}

.OneProject_btnRedirect__2UYQZ {
  color: blue;
  background-color: transparent;
  border: none;
  font-size: 16px;
  padding-right: 10px;
}

.OneProject_btnRedirect__2UYQZ:hover {
  cursor: pointer;
}

.Projects_projectMain__10ciX{

    margin-top: 100px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
    grid-row-gap: 20px;
    row-gap: 20px;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
}


@media (max-width: 1300px) {
 
    .Projects_projectMain__10ciX{
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));

    }
  }
.TermsofUse_parent__LGANS {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
.TermsofUse_mainContainer__1b0Kj {
  width: 80%;
  background-color: white;
}

.TermsofUse_titleheading__3UF5P {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
}

.TermsofUse_subtitle__3ba-g {
  margin-top: 20px;
  line-height: 20px;
}

.TermsofUse_heading1__2gtv- {
  margin-top: 20px;
  font-weight: bold;
  font-size: 16px;
}

.TermsofUse_block1__1eyQ4 {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: start;
}

.TermsofUse_dot__St1P6 {
  margin-left: 10px;
  height: 5px;
  width: 5px;
  min-height: 5px;
  min-width: 5px;
  background-color: black;
  display: flex;
  align-items: start;
  justify-content: start;
}

.TermsofUse_dot2__qForN {
  margin-top: 8px;
  display: flex;
  justify-self: start;
  align-self: flex-start;
  margin-left: 10px;
  height: 5px;
  width: 5px;
  min-height: 5px;
  min-width: 5px;
  background-color: black;
}

.TermsofUse_title1___n-CA {
  width: -webkit-max-content;
  width: max-content;

  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.TermsofUse_details1__KY_VJ {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 22px;
}
.TermsofUse_title2__1hc42 {
  margin-top: 30px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
}

.CookiesPolicy_parent__3SyQB {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top:50px;
}
.CookiesPolicy_mainContainer__1uKJV {
  width: 80%;
  background-color: white;
}

.CookiesPolicy_titleheading__dugKk {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
}

.CookiesPolicy_subtitle__24C1X {
  margin-top: 20px;
  line-height: 20px;
}

.CookiesPolicy_heading1__AiNin {
  margin-top: 20px;
  font-weight: bold;
  font-size: 16px;
}

.CookiesPolicy_block1__3VfQM {
  margin-top: 10px;
  display: flex;
}

.CookiesPolicy_dot__34DWs {
    margin-top: 6px;
  margin-left: 10px;
  height: 5px;
  width: 5px;
  min-height: 5px;
  min-width: 5px;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CookiesPolicy_dot2__1JsgX {
  margin-top: 8px;
  display: flex;
  justify-self: start;
  align-self: flex-start;
  margin-left: 10px;
  height: 5px;
  width: 5px;
  min-height: 5px;
  min-width: 5px;
  background-color: black;
}

.CookiesPolicy_title1__3x3xl {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  white-space: nowrap;
  margin-left: 10px;
  display: flex;

  font-weight: bold;
}

.CookiesPolicy_details1__tj4M3 {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 22px;
}
.CookiesPolicy_title2__1OfCx {
  margin-top: 30px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
}

.Disclaimer_parent__3faMX {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
.Disclaimer_mainContainer__3fgmW {
  width: 80%;
  background-color: white;
}

.Disclaimer_titleheading__1nn5Z {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
}
.Disclaimer_headingContainer__qGZt2{
  margin-top: 10px;
}

.Disclaimer_subtitle__gg6D0 {
  margin-top: 20px;
  line-height: 20px;
}

.Disclaimer_heading1__fIxQv {
  margin-top: 20px;
  font-weight: bold;
  font-size: 16px;
}

.Disclaimer_block1__1YAHg {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: start;
}

.Disclaimer_dot__22oOO {
  margin-left: 10px;
  height: 5px;
  width: 5px;
  min-height: 5px;
  min-width: 5px;
  background-color: black;
  display: flex;
  align-items: start;
  justify-content: start;
}


.Disclaimer_dot2__X78A5 {
    margin-top: 8px;
    display: flex;
    justify-self: start;
    align-self: flex-start;
    margin-left: 10px;
    height: 5px;
    width: 5px;
    min-height: 5px;
    min-width: 5px;
    background-color: black;
 

  }





.Disclaimer_title1__1RE6H {
  width: -webkit-max-content;
  width: max-content;

  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.Disclaimer_details1__1LSie {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 22px;
}
.Disclaimer_title2__1wOn6 {
  margin-top: 30px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
}

.Privacypolicy_parent__2Ypdq {
    width: 100%;
     display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
  }
  .Privacypolicy_mainContainer__9Awy- {
    width: 80%;
    background-color: white;
  }
  
  .Privacypolicy_titleheading__1-cpS{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
  }
  
  .Privacypolicy_subtitle__2YemX{
    margin-top: 20px;
    line-height: 20px;
  
  }
  
  .Privacypolicy_heading1__3eoJd{
    margin-top: 20px;
    font-weight: bold;
    font-size: 16px;
  }
  
  .Privacypolicy_block1__31MLY{
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: start;
  }
  
  .Privacypolicy_dot__2H0no{
    margin-left: 10px;
    height: 5px;
    width: 5px;
    min-height: 5px;
    min-width: 5px;
    background-color: black;
    
  }
  
  .Privacypolicy_title1__5yVDf{
    width: -webkit-max-content;
    width: max-content;
  
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }
  
  .Privacypolicy_details1__2r_ba{
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  
  }
  .Privacypolicy_title2__22za9{
    margin-top: 30px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 20px;
  }

.SuccessStoryDetails_parent__3ASGD {
  margin-top: 20px;
  display: flex;
  width: 100%;
}

.SuccessStoryDetails_leftContainer__pH_ly {
  width: 60%;
  margin: auto;
  margin-bottom: 50px;
}

.SuccessStoryDetails_imgAndHeading__NEGUF {
  object-fit: cover;
}

.SuccessStoryDetails_mainImg__1kVyC {
  width: 720px;

  object-fit: cover;
}
.SuccessStoryDetails_title__22AWU {
  margin-top: 20px;
  line-height: 26px;
  font-size: 20px;
  font-weight: bold;
}

.SuccessStoryDetails_details__1dhaU {
  margin-top: 20px;
  text-align: justify;
  line-height: 26px;
  font-size: 16px;
}

.SuccessStoryDetails_rightContainer__27SNT {
  width: 40%;
  display: flex;
  justify-content: center;
}

.SuccessStoryDetails_boxContainer__3vkX5 {
  width: 80%;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  border: 1px solid gray;
}

.SuccessStoryDetails_recentPost__QHjhN {
  margin-top: 10px;
  color: black;
  font-weight: bold;
}

.SuccessStoryDetails_recentPost1__2jwWG {
  margin-top: 20px;
}

.SuccessStoryDetails_horiBorder__1s-NI {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 80%;
  border-bottom: 1px solid gray;
}

.SuccessStoryDetails_recentPost2__1XPT0 {
}

.SuccessStoryDetails_container1__euuWR {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.SuccessStoryDetails_iconCal__1cqkk {
  width: 30px;
  height: 30px;
  background-color: gainsboro;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--greyTextColor);
}
.SuccessStoryDetails_date__2j_Vx {
  margin-left: 10px;
  font-weight: bold;
}

@media (max-width: 1000px) {
  .SuccessStoryDetails_parent__3ASGD {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }

  .SuccessStoryDetails_leftContainer__pH_ly {
    width: 100%;
  }

  .SuccessStoryDetails_mainImg__1kVyC {
    margin-top: 20px;
    width: 100%;
    height: 400px;
    object-fit: cover;
  }

  .SuccessStoryDetails_rightContainer__27SNT {
    visibility: hidden;
  }
}

.AboutUs_mt__3cpdn{

    margin-top: 30px;
    font-size: 20px;
    font-weight: bold;
    color: var(--deepDarkThemeTextColor);

}

.AboutUs_d__38ODe{
    margin-top: 10px;
    font-size: 16px;
    color: var(--deepDarkThemeTextColor);
    line-height: 24px;
}

.AboutUs_t__Q8lQD{
    margin-top: 20px;
    font-size: 16px;
    font-weight: bold;
    color: var(--deepDarkThemeTextColor);
}


.AboutUs_highlight__5Acjy{
}

.AboutUs_bottomContainer__DTkyH{
    margin-top: 20px;
    background-color: var( --chatCommentBkgColor);
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.AboutUs_btnContainer__2yL33{
    background-color: var(--themeColor);
    padding: 4px;
    color: white;
    margin: 20px;
    font-size: 14px;
    border-radius: 6px;
}
.HelpCenter_parent__2_NQ_ {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.HelpCenter_mainContainer__2bAd7 {
  width: 80%;
  background-color: white;
}

.HelpCenter_titleheading__1psS9 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
}

.HelpCenter_subtitle__CTCtq {
  margin-top: 20px;
  line-height: 20px;
}

.HelpCenter_heading1__2HwBQ {
  margin-top: 20px;
  font-weight: bold;
  font-size: 16px;
}

.HelpCenter_heading2__14AcQ {
  margin-top: 20px;
  font-weight: bold;
  font-size: 14px;
  margin-left: 10px;
}

.HelpCenter_block1__30jHy {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: start;
}

.HelpCenter_dot__1x-9F {
  margin-left: 10px;
  height: 5px;
  width: 5px;
  min-height: 5px;
  min-width: 5px;
  background-color: black;
  display: flex;
  align-items: start;
  justify-content: start;
}

.HelpCenter_dot2__11Zvd {
  margin-top: 8px;
  display: flex;
  justify-self: start;
  align-self: flex-start;
  margin-left: 10px;
  height: 5px;
  width: 5px;
  min-height: 5px;
  min-width: 5px;
  background-color: black;
}

.HelpCenter_title1__dgUca {
  width: -webkit-max-content;
  width: max-content;

  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.HelpCenter_details1__L1h04 {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 22px;
}
.HelpCenter_title2__3hrV2 {
  margin-top: 30px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
}

.ReraAct_rulesAndRegulationsParent__cqwZu {
  margin-top: 40px;

  width: 100%;
}

.ReraAct_serchContainer__2cFoy {
  background-color: var(--bgColor);
  height: 50px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--cardBorderColor);
}

.ReraAct_serchbox__2lIF9 {
  margin-left: 10px;
  width: 90%;

  background-color: white;
  border-radius: 8px;
  height: 28px;
  padding-left: 10px;
  font-size: 14px;
  border: none;
  border: 1px solid var(--cardBorderColorLight);
}

.ReraAct_serchbox__2lIF9:focus {
  border-style: solid;
  background-color: white;
  border-width: 2px;
  outline: none !important;
  border-color: var(--themeColor);
}

.ReraAct_btnContainer__3cKy0 {
  width: 100px;
  margin-left: 20px;
  height: 28px;
  background-color: var(--themeColor);
  border: none;
  border-radius: 8px;
  color: white;
  margin-right: 10px;
}

.ReraAct_btnContainer__3cKy0:hover {
  cursor: pointer;
  background-color: white;
  color: var(--themeColor);
  border: 1px solid var(--themeColor);
}

.ReraAct_parentDiv__2pD1M {
  margin-top: 20px;
  display: flex;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(480px, auto));
  grid-row-gap: 20px;
  row-gap: 20px;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.ReraAct_leftBox__1wVcz {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border: 1px solid var(--cardBorderColor);
}

.ReraAct_topHeadingSection__1fFO0 {
  height: 40px;
  display: flex;
  justify-content: start;
  align-items: center;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background-color: var(--bgColor);
  border-bottom: 1px solid var(--cardBorderColor);
}

.ReraAct_iconContainer__2f9NP {
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  background-color: white;
  border-radius: 50%;
  border: 1px solid var(--cardBorderColor);
}

.ReraAct_title__1CeZP {
  margin-left: 10px;
  font-weight: bold;
  color: var(--darkText);
}

.ReraAct_rightBoxContainer__1pXKW {
}

.ReraAct_topBox__1eAYk {
  width: 100%;

  border: 1px solid var(--cardBorderColor);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.ReraAct_midBox__1YZw5 {
  margin-top: 20px;
  width: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border: 1px solid var(--cardBorderColor);
}

.ReraAct_bottomBox__1kBa5 {
  margin-top: 20px;
  width: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border: 1px solid var(--cardBorderColor);
}

.ReraAct_mainContainer__2keBT {
}

.ReraAct_mainIcon__2tTOF {
}

.ReraAct_noticeIcon__1iQRK {
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ReraAct_regIcon__3269M {
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ReraAct_ruleIcon__GqxHL {
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ReraAct_actIcon__RRUQh {
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SingleReraAct_parent__15Xg7 {
  align-items: center;
  border-bottom: 1px solid var(--cardBorderColor);
}
.SingleReraAct_parent__15Xg7:hover{
  background-color: var(--cardBorderColorLight);
}
.SingleReraAct_parent__15Xg7:hover .SingleReraAct_downloadBtnContainer__2miX-{
  background-color: white;
}

.SingleReraAct_detailsContainer__24HyT {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.SingleReraAct_rightSide__eLZjm {
  display: flex;
  justify-content: start;
  align-items: center;
}

.SingleReraAct_nu__3_3HK {
  width: 20px;
  font-size: 16px;
  font-weight: bold;
}

.SingleReraAct_title__2T6A1 {
  margin-left: 20px;
  width: 80%;
  color: var(--darkText);
  font-weight: bold;
}
.SingleReraAct_details__2vgyy {
  width: 50%;
  color: var(--lightText);
  line-height: 28px;
}

.SingleReraAct_downloadBtnContainer__2miX- {
  border: 1px solid var(--cardBorderColor);
  background-color: var(--cardBorderColorLight);
  width: 30px;
  height: 30px;
  min-height: 30px;
  min-width: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.SingleReraAct_downloadBtnContainer__2miX- :hover{
 cursor: pointer;
}

.SingleReraAct_downloadIcon__2ZSOI {
  height: 20px;
  width: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
}





.SuccessPage_successpage__2evRf{


height: 500px;
width: 380px;
display: flex;
justify-content: center;
margin: auto;
align-items: center;
flex-direction: column;
}

.SuccessPage_topDiv__18zyv{

display: flex;
align-items: center;
}


.SuccessPage_loginButton__C6KiP{

margin-top: 20px;
width: 200px;
height: 60px;
border-style: none;
background-color: var(--themeColor);
border-radius: 10px;
color: white;
font-size: 20px;
cursor: pointer;
}

.SuccessPage_mainContainer__1lOA8{
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 500px;
    grid-gap: 50px;
    gap: 50px;

}
.Margadarshak_mainContainer__27_q5 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 20px;
    gap: 20px;
}

.Margadarshak_mainExamTitle__204XT {
    width: 100%;
    margin-top: 40px;
    font-size: 40px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--deepDarkThemeTextColor);
}

.Margadarshak_boxSlideMobile__3YaqD {
    display: none;
}

.Margadarshak_mainExamDescription__Z97LH{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    font-size: 20px;
}

@media (max-width:1300px) {
    .Margadarshak_mainExamTitle__204XT {
        width: 100%;
        text-align: center;
        font-size: 25px;
    }
}

@media (max-width:600px) {
    .Margadarshak_mainExamTitle__204XT {}

    .Margadarshak_boxSlideMobile__3YaqD {
        display: block;
        border-style: none;
    }

    .Margadarshak_boxSlideDesktop__1UTAJ {
        display: none;
    }
}
.introduction_MainIntroductionBlockParent__1ImXp {
    width: 100%;
    display: flex;
    background-color: var(--chatCommentBkgColor);
    display: flex;
    justify-content: center;
    align-items: center;
}

.introduction_IntroductionBlockParent__2A9oL {
    border-style: none;
    width: 1200px;
    height: 400px;
    display: flex;
}

.introduction_backGroundArrow__35NoK{
    position: relative;
    right: -10px;
    -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
    object-fit: cover;
    width: 400px;
    height: 400px;  
}

.introduction_imageContainer__1qwBZ{
    background-image: url("/static/media/introduction background.dda42b5f.png");
    background-size: 100% 110%; /* This scales the background image to cover the entire container */
    background-repeat: no-repeat; 
    width: 400px;
    height: 400px;
}

.introduction_leftContainer__yLzqq{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
}
.introduction_rightContainer__3CP6Y{
    width: 50%;
}
.introduction_headingContainer__o8UWY{
    font-size: 25px;
}
.introduction_titleContainer__14nLz{
    font-size: 25px;
}
.introduction_buttonContainer__3RzF0{
    font-size: 20px;
    background-color: var(--themeColor);
    padding: 10px 20px 10px 20px;
    border-radius: 10px;
    color: white;
    cursor: pointer;
}
@media (max-width: 1300px) {
    .introduction_IntroductionBlockParent__2A9oL {
        width: 80%;
    }
}


@media (max-width: 600px) {

    .introduction_MainIntroductionBlockParent__1ImXp {
        width: 100%;
    }

    .introduction_IntroductionBlockParent__2A9oL {
        width: 95%;
        height: auto;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .introduction_leftContainer__yLzqq{
        width: 100%;
        grid-gap: 20px;
        gap: 20px;
    }
    .introduction_titleContainer__14nLz{
        width: 100%;
        text-align: center;
    }
    .introduction_rightContainer__3CP6Y{
        margin-top: 50px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .introduction_headingContainer__o8UWY{
        margin-top: 30px;
    }


}
.topBlock_mainContainer__p3yOv{
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    margin-top: 50px;
    border-radius: 10px;
}

.topBlock_topContainer__3o2kj{
    width: 80%;
}

.topBlock_bottomContainer__1cRgJ{
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.topBlock_leftContainer__9eFv-{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
}
.topBlock_rightContainer__TI0O3{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.topBlock_imageContainer__3NMLd{
    background-image: url("/static/media/introduction background.dda42b5f.png");
    background-size: 100% 110%; /* This scales the background image to cover the entire container */
    background-repeat: no-repeat; 
    width: 400px;
    height: 500px;
}


.topBlock_backGroundArrow__1fyiq{
    position: relative;
    right: -50px;
    -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
    object-fit: cover;
    width: 500px;
    height: 500px;  
}

.topBlock_headingContainer__3S784{
    margin-top: 30px;
    font-size: 25px;
    font-weight: bold;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.topBlock_titleContainer__Dd8xO{
    width: 100%;
    font-size: 20px;
    font-weight: 600;

}

.topBlock_paraContainer__2NSHm{
    width: 100%;
    font-size: 18px;
    margin-bottom: 20px;
}

@media (max-width:600px) {
    .topBlock_bottomContainer__1cRgJ{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .topBlock_leftContainer__9eFv-{
        
        width: 100%;
        margin-top: 30px;
    }
    .topBlock_backGroundArrow__1fyiq{
        right: 0px;
        width: 400px;
        height: 400px;
    }
    .topBlock_imageContainer__3NMLd{
        height: 400px;
    }
}
.ProgramHighlights_HowitWorksContainer__1iVPh {
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--chatCommentBkgColor);
    padding-top: 40px;
    padding-bottom: 60px;
    border-style: none;
}

.ProgramHighlights_HowitworksTitle__UjF8F {
    font-size: 40px;
    font-weight: bold;
    color: var(--deepDarkThemeTextColor);
    background-color: var(--chatCommentBkgColor);
    margin-bottom: 50px;
}

.ProgramHighlights_helloTopContainer__anb0c {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-auto-rows: auto;
    grid-row-gap: 30px;
    row-gap: 30px;
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
    border-style: none;
    background-color: var(--chatCommentBkgColor);
}


.ProgramHighlights_paarent__xz2BC {
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    background-color: white;
}

.ProgramHighlights_paarent__xz2BC:hover {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.ProgramHighlights_logo__iIifm {
    margin-top: 30px;
    width: 80px;
    height: 80px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.ProgramHighlights_imagesExam__G7J84 {
    width: 100px;
    height: 100px;
    transition: all 0.3s ease;
}

.ProgramHighlights_imagesExam__G7J84:hover {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
}

.ProgramHighlights_description__9PpC_ {
    margin-top: 50px;
    font-size: 20px;
    text-align: center;
    margin-bottom: 20px;
    width: 90%;
}










@media (max-width: 1300px) {

    .ProgramHighlights_HowitworksTitle__UjF8F {
        width: 100%;
        text-align: center;
        font-size: 25px;
    }


    .ProgramHighlights_paarent__xz2BC {
        width: 100%;
    }


}



@media (max-width: 600px) {


}
.HowItWorks_rulesAndRegulationsParent__2fHNq {
    margin-top: 40px;
    width: 100%;
}

.HowItWorks_rulesAndRegulationsMain__1jK2e {
    width: 100%;
    border-style: none;
}

.HowItWorks_innerDiv__2omMG {
    width: 1200px;
    margin: auto;
}

.HowItWorks_authorName__1VGqj {
    font-size: 10px;
    color: black;
    position: relative;
    top: 20px;
    left: -20px;
}

.HowItWorks_serchContainer__1SB52 {
    background-color: var(--bgColor);
    height: 50px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid var(--cardBorderColor);
}

.HowItWorks_serchbox__2O-RC {
    margin-left: 10px;
    width: 90%;

    background-color: white;
    border-radius: 8px;
    height: 28px;
    padding-left: 10px;
    font-size: 14px;
    border: none;
    border: 1px solid var(--cardBorderColorLight);
}

.HowItWorks_serchbox__2O-RC:focus {
    border-style: solid;
    background-color: white;
    border-width: 2px;
    outline: none !important;
    border-color: var(--themeColor);
}

.HowItWorks_btnContainer__mMeUZ {
    width: 100px;
    margin-left: 20px;
    height: 28px;
    background-color: var(--themeColor);
    border: none;
    border-radius: 8px;
    color: white;
    margin-right: 10px;
}

.HowItWorks_btnContainer__mMeUZ:hover {
    cursor: pointer;
    background-color: white;
    color: var(--themeColor);
    border: 1px solid var(--themeColor);
}

.HowItWorks_parentDiv__102uV {
    margin-top: 20px;
    display: flex;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(700px, auto));
    grid-row-gap: 20px;
    row-gap: 20px;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
}

.HowItWorks_leftBox__1YVjU {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border: 1px solid var(--cardBorderColor);
}

.HowItWorks_topHeadingSection__2MtWV {
    height: 40px;
    display: flex;
    justify-content: start;
    align-items: center;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    background-color: var(--bgColor);
    border-bottom: 1px solid var(--cardBorderColor);
}

.HowItWorks_iconContainer__3VWKL {
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    background-color: white;
    border-radius: 50%;
    border: 1px solid var(--cardBorderColor);
}

.HowItWorks_title__25vrU {
    width: 100%;
    margin-left: 10px;
    font-weight: bold;
    color: var(--darkText);
    text-align: center;
}

.HowItWorks_rightBoxContainer__36ier {}

.HowItWorks_topBox__1nxPV {
    width: 100%;

    border: 1px solid var(--cardBorderColor);
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.HowItWorks_midBox__1rkfi {
    margin-top: 20px;
    width: 100%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border: 1px solid var(--cardBorderColor);
}

.HowItWorks_bottomBox__2zeUO {
    margin-top: 20px;
    width: 100%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border: 1px solid var(--cardBorderColor);
}

.HowItWorks_mainContainer__C6W27 {}

.HowItWorks_mainIcon__2QI0p {}

.HowItWorks_noticeIcon__3MD_g {
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.HowItWorks_regIcon__3OdBd {
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.HowItWorks_ruleIcon__1WCzi {
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.HowItWorks_actIcon__AOY3n {
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.HowItWorks_rulesAndRegulationsParent__2fHNq {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-row-gap: 20px;
    row-gap: 20px;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
}

.HowItWorks_reraData__3C8rG {
    margin-top: 30px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(195px, 1fr));
    grid-row-gap: 20px;
    row-gap: 20px;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
}

.HowItWorks_Block1__1rtiy {
    height: 500px;
    border: 1px solid var(--themeColor);
}

.HowItWorks_Title__1uqDn {
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid var(--themeColor);
    background-color: var(--themeColor);
    font-weight: bold;
    color: white;
}

.HowItWorks_mainContainer__C6W27 {
    padding-top: 10px;
    display: flex;
    padding-right: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--cardBorderColorLight);
}

.HowItWorks_mainContainer__C6W27:hover {
    background-color: var(--bgColor);
    cursor: pointer;
}

.HowItWorks_nu__1g6FX {
    display: flex;
    justify-content: center;
    width: 15%;
    font-size: 14px;
}

.HowItWorks_details__1Q3tZ {
    margin-top: 10px;
    width: 100%;
    font-size: 15px;
    line-height: 24px;
    text-align: justify;
}

.HowItWorks_Block2__23K7W {
    height: 500px;
    border: 1px solid var(--themeColor);
}

.HowItWorks_Block3__2t7Ew {
    height: 500px;
    border: 1px solid var(--themeColor);
}

.HowItWorks_Block4__pyqmu {
    height: 500px;
    border: 1px solid var(--themeColor);
}

.HowItWorks_threeCardContainer__3jqo9 {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-row-gap: 20px;
    row-gap: 20px;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
}

.HowItWorks_topCard__39TAP {
    width: 1200px;
    border-style: none;
    display: flex;
    margin: auto;

}

.HowItWorks_topSectionJoinTopper___Wj1t {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.HowItWorks_textDiv__NDRw8 {
    border-style: none;
}

.HowItWorks_largeText__21is8 {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
}

.HowItWorks_smallText__3NHVN {
    margin-top: 30px;
    text-align: center;
    font-size: 18px;
}

.HowItWorks_joinNowButton__2VDr3 {
    height: 40px;
    width: 230px;
    border-radius: 30px;
    border-style: none;
    background-color: var(--themeColor);
    margin-top: 30px;
    cursor: pointer;
    font-family: roboto;
    color: white;
    font-size: 16px;
    font-weight: bold;
}









.HowItWorks_joinNowButton__2VDr3:hover {

    color: var(--themeColor);
    background: white;
    border-style: solid;
    border-color: var(--themeColor);
    border-width: 1px;
}



.HowItWorks_topRightSection__3xY16 {
    width: 50%;
    object-fit: cover;
}

.HowItWorks_girlIMageTop__3sTiY {
    width: 400px;
    height: 400px;
    object-fit: cover;
}

.HowItWorks_card1__2_EWX {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}

.HowItWorks_card2__1DzJX {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}

.HowItWorks_card3__rtRsS {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}

.HowItWorks_contentContainer__1ig0X {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.HowItWorks_img__3Htos {
    width: 60px;
    height: 60px;
    transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
    transition: transform 0.5s, -webkit-transform 0.5s;
    /* Add a smooth transition for the rotation effect */
    /* Initial state (not hovered) */
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
}

.HowItWorks_card1__2_EWX:hover .HowItWorks_img__3Htos {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
}

.HowItWorks_card2__1DzJX:hover .HowItWorks_img__3Htos {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
}

.HowItWorks_card3__rtRsS:hover .HowItWorks_img__3Htos {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
}

.HowItWorks_title__25vrU {
    width: 80%;
    margin-top: 10px;
    font-size: 22px;
    color: var(--deepDarkThemeTextColor);
    font-weight: bold;
    line-height: 28px;
}

/* .details {
    margin-top: 10px;
    text-align: center;
    line-height: 20px;
    font-size: 14px;
  } */

.HowItWorks_inconstructionMessage__gy-Sg {
    margin-top: 40px;
    color: red;
    font-size: 25px;
    border-style: solid;
    padding: 20px;
    border-radius: 10px;
}

.HowItWorks_shapethefutureCard__3bF6e {
    margin-top: 30px;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
        rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 10px;
}

.HowItWorks_shapetheFutureTitle__YT3yd {
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 30px;
    color: var(--deepDarkThemeTextColor);
}

.HowItWorks_shapetheFutureButton__2BfE3 {
    width: 100px;
    height: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: var(--themeColor);
    color: white;
    border: none;
    border-radius: 6px;
}

.HowItWorks_shapetheFutureButton__2BfE3:hover {
    cursor: pointer;
    color: var(--themeColor);
    border: 1px solid var(--themeColor);
    background-color: white;
}

.HowItWorks_shapethefutureDetails__1CJ1A {
    width: 80%;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    line-height: 26px;
    color: var(--deepDarkThemeTextColor);
    font-weight: 500;
    font-size: 18px;
}

.HowItWorks_mainTitle1__TM7Hr {
    margin-top: 30px;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 30px;
    color: var(--deepDarkThemeTextColor);
    display: flex;
    align-items: center;
    justify-content: center;
}

.HowItWorks_submainTitle__3_v6L {
    margin-top: 50px;
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: bold;
    color: var(--deepDarkThemeTextColor);
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: oblique;
    color: var(--themeColor);
    margin-left: 50px;
}

.HowItWorks_details1__32gF8 {
    margin-top: 50px;
    margin-bottom: 10px;
    text-align: center;
    line-height: 26px;
    color: var(--deepDarkThemeTextColor);
    font-weight: 500;
    font-size: 18px;
}

.HowItWorks_details2__XzWRe {
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: center;
    line-height: 26px;
    color: var(--deepDarkThemeTextColor);
    font-weight: 500;
    font-size: 18px;
}

.HowItWorks_mainTitle2__1BmH_ {
    margin-top: 50px;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 30px;
    color: var(--deepDarkThemeTextColor);
    display: flex;
    align-items: center;
    justify-content: center;
}

.HowItWorks_joinToppersCard__ZxBA7 {
    width: 100%;
    background-color: var(--chatCommentBkgColor);
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 20px;
    border-style: none;
    margin: auto;
}

.HowItWorks_newContainer__2_Hoa {
    margin-top: 50px;
    padding: 10px;
}

.HowItWorks_mainheadingTitle__PTEKP {
    font-weight: bolder;
    font-size: 30px;
    color: black;
    margin-bottom: 30px;
    text-align: center;
}

.HowItWorks_mainHead1__396Zr {
    margin-top: 15px;
    font-size: 17px;
    font-weight: bolder;
    color: var(--deepDarkThemeTextColor);
}

.HowItWorks_mainDetail1__OQ1gP {
    margin-top: 6px;
    font-size: 16px;
    color: var(--lightColor);
}

.HowItWorks_howitworks__2gJiV {
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.HowItWorks_howitworksTitle__1sTSF {
    text-align: center;
    font-weight: bolder;
    font-size: 30px;
    color: black;
    margin-bottom: 30px;
    text-align: center;
}

.HowItWorks_lastContainer__2eyQo {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.HowItWorks_mainCircle__1PFna {
    width: 50px;
    height: 50px;
    border: 1px solid var(--cardBorderColor);
    border-radius: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.HowItWorks_Acontaienr__3A0YQ {
    width: 80%;
    border: 1px solid var(--cardBorderColor);
    border-radius: 10px;
    margin: 10px;
}

.HowItWorks_titleA__1_xhc {
    background-color: var(--cardBorderColorLight);
    height: 30px;
    font-weight: bold;
    font-size: 18px;
    display: flex;
    right: 10px;
    align-items: center;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    padding-left: 10px;
}

.HowItWorks_desA__2zaDa {
    height: 30px;
    display: flex;
    align-items: center;
    padding-left: 10px;
}

@media (max-width: 1000px) {
    .HowItWorks_joinToppersCard__ZxBA7 {
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .HowItWorks_topCard__39TAP {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
        margin: 0;
    }

    .HowItWorks_submainTitle__3_v6L {
        text-align: center;
    }

    .HowItWorks_innerDiv__2omMG {
        width: 98%;
        margin: 0;
    }

    .HowItWorks_mainTitle1__TM7Hr {
        text-align: center;
    }

    .HowItWorks_details1__32gF8 {
        text-align: justify;
        margin: 10px;
    }

    .HowItWorks_details2__XzWRe {
        text-align: justify;
        margin: 10px;
    }

    .HowItWorks_threeCardContainer__3jqo9 {
        margin: 10px;
    }

    .HowItWorks_topSectionJoinTopper___Wj1t {
        width: 100%;
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .HowItWorks_topRightSection__3xY16 {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .HowItWorks_shapethefutureCard__3bF6e {
        width: 100%;
    }

    .HowItWorks_mainTitle2__1BmH_ {
        text-align: center;
    }

    .HowItWorks_howitworks__2gJiV {
        margin: 0;
        padding: 0;
    }

    .HowItWorks_lastContainer__2eyQo {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 0;
    }

    .HowItWorks_Acontaienr__3A0YQ {}

    .HowItWorks_titleA__1_xhc {
        height: 30px;
    }

    .HowItWorks_desA__2zaDa {
        height: auto;
    }
}

.HowItWorks_f_points_star1__1MSpb {
    /* Modify size here: */
    --size: 30px;
    --half-size: calc(var(--size) / 2);

    position: relative;
    display: block;
    color: var(--themeColor);
    width: 0px;
    height: 0px;
    border-right: var(--half-size) solid transparent;
    border-bottom: calc(var(--half-size) * 0.7) solid #333;
    border-left: var(--half-size) solid transparent;
    -webkit-transform: rotate(35deg);
            transform: rotate(35deg);
}

.HowItWorks_f_points_star1__1MSpb:before {
    border-bottom: calc(var(--half-size) * 0.8) solid #333;
    border-left: calc(var(--half-size) * 0.3) solid transparent;
    border-right: calc(var(--half-size) * 0.3) solid transparent;
    position: absolute;
    height: 0;
    width: 0;
    top: calc(var(--half-size) * 0.45 * -1);
    left: calc(var(--half-size) * 0.65 * -1);
    display: block;
    content: "";
    -webkit-transform: rotate(-35deg);
            transform: rotate(-35deg);
}

.HowItWorks_f_points_star1__1MSpb:after {
    position: absolute;
    display: block;
    color: #333;
    top: calc(var(--half-size) * 0.03);
    left: calc(var(--half-size) * -1.05);
    width: 0px;
    height: 0px;
    border-right: var(--half-size) solid transparent;
    border-bottom: calc(var(--half-size) * 0.7) solid #333;
    border-left: var(--half-size) solid transparent;
    -webkit-transform: rotate(-70deg);
            transform: rotate(-70deg);
    content: "";
}

.HowItWorks_f_points_star__2EDCu {}

.HowItWorks_whyToppersContainer__2Lj6W {
    margin-top: 50px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-auto-rows: auto;
    grid-row-gap: 30px;
    row-gap: 30px;
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
}

.HowItWorks_paarent__3IOSF {
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    background-color: white;
}

.HowItWorks_paarent__3IOSF:hover {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.HowItWorks_logo__3cBQp {
    margin-top: 30px;
    width: 80px;
    height: 80px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.HowItWorks_imagesExam__1zFMX {
    width: 80px;
    height: 80px;
    transition: all 0.3s ease;
}

.HowItWorks_imagesExam__1zFMX:hover {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
}

.HowItWorks_title__25vrU {
    margin-top: 20px;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}

.HowItWorks_description__3vEA- {
    margin-top: 20px;
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
    width: 90%;
}
.WhyMargadharsak_mainContainer__1SMnI{
    width: 80%;
}

.WhyMargadharsak_homeParent__1Lexq {
    width: 100%;
}

.WhyMargadharsak_topBar__13bSc {
    width: 100%;
    height: 30px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.WhyMargadharsak_National__1Wa6a {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--lightText);
}

.WhyMargadharsak_State__3Gq_T {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--lightText);
}

.WhyMargadharsak_newsBlock__31lLZ {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, auto));
    grid-row-gap: 10px;
    row-gap: 10px;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
}

.WhyMargadharsak_latest__1F0Rm {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
}

.WhyMargadharsak_latestTitle__A4zlS {
    font-weight: bold;
    color: var(--themeColor);
    font-size: 24px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    text-transform: uppercase;
}

.WhyMargadharsak_latestsubTitle__3G_43 {
    margin-top: 20px;
    font-weight: bold;
    color: #08203d;
    line-height: 55px;
    font-size: 45px;
}

.WhyMargadharsak_panImageBkg__21d_E {
    background-color: #e7efeb;
    background-repeat: no-repeat;
    height: auto;
    background-image: url("https://images.unsplash.com/photo-1523192193543-6e7296d960e4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80");
}

.WhyMargadharsak_newsBlock__31lLZ {
    width: 100%;
}

.WhyMargadharsak_stateNewsBlock__3gcHD {
    width: 100%;
}


.WhyMargadharsak_mainExamTitle__1HAOM {
    width: 100%;
    margin-top: 40px;
    font-size: 40px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--deepDarkThemeTextColor);
}



.WhyMargadharsak_HowitWorksContainer__2NMm4 {
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--chatCommentBkgColor);
    padding-top: 40px;
    padding-bottom: 60px;
    border-style: none;
}

.WhyMargadharsak_HowitworksTitle__1hU7- {
    font-size: 40px;
    font-weight: bold;
    color: var(--deepDarkThemeTextColor);
    background-color: var(--chatCommentBkgColor);
    margin-bottom: 50px;
}

.WhyMargadharsak_helloTopContainer__3Zk1c {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-auto-rows: auto;
    grid-row-gap: 30px;
    row-gap: 30px;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
    border-style: none;
    background-color: var(--chatCommentBkgColor);
}

.WhyMargadharsak_mainWhytoppersContainer__Hy7XA {
    margin-top: 30px;
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.WhyMargadharsak_whyToppersTitle__3MPdG {
    font-size: 40px;
    font-weight: bold;
    color: var(--deepDarkThemeTextColor);
}

.WhyMargadharsak_whyToppersContainer__2-6l2 {
    margin-top: 50px;

    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-auto-rows: auto;
    grid-row-gap: 30px;
    row-gap: 30px;
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
}

.WhyMargadharsak_ExamContainer__3dF18 {
    margin-top: 30px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-row-gap: 30px;
    row-gap: 30px;
    grid-column-gap: 30px;
    -webkit-column-gap: 30px;
            column-gap: 30px;
}

.WhyMargadharsak_bottombox__1jcFE {
    margin-top: 50px;
    padding-bottom: 30px;
    padding-top: 50px;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--chatCommentBkgColor);
    border-style: none;

    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;*/
}

.WhyMargadharsak_marginDivBottom__3C3Za {
    border-style: solid;
    width: 80%;
}



.WhyMargadharsak_bTitle__2eM9g {
    margin-top: 15px;
    font-size: 30px;
    font-weight: bold;
    color: var(--deepDarkThemeTextColor);
}

.WhyMargadharsak_bdetails__2_6MN {
    margin-top: 15px;
    font-size: 20px;
}

.WhyMargadharsak_bbutton__ytFGo {
    margin-top: 25px;
    width: 150px;
    height: 40px;
    border-radius: 4px;
    background-color: var(--themeColor);
    color: white;
    font-size: 16px;
    border: none;
}

.WhyMargadharsak_bbutton__ytFGo:hover {
    border: 1px solid var(--themeColor);
    background-color: white;
    cursor: pointer;
    color: var(--themeColor);
}

.WhyMargadharsak_paarent__36-KJ {
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    background-color: white;
}

.WhyMargadharsak_paarent__36-KJ:hover {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}


.WhyMargadharsak_boxSlideMobile__2yc_3 {
    display: none;
}



.WhyMargadharsak_logo__wTYie {
    margin-top: 30px;
    width: 80px;
    height: 80px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.WhyMargadharsak_imagesExam__3d3J6 {
    width: 80px;
    height: 80px;
    transition: all 0.3s ease;
}

.WhyMargadharsak_imagesExam__3d3J6:hover {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
}


.WhyMargadharsak_title__-dvcW {
    margin-top: 20px;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}

.WhyMargadharsak_description__UgI8e {
    margin-top: 20px;
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
    width: 90%;
}

@media (max-width: 1300px) {
    .WhyMargadharsak_latestTitle__A4zlS {
        font-size: 20px;
    }

    .WhyMargadharsak_latestsubTitle__3G_43 {
        margin-top: 10px;
        font-size: 30px;
    }

    .WhyMargadharsak_homeParent__1Lexq {
        width: 98%;
    }

    .WhyMargadharsak_mainExamTitle__1HAOM {
        width: 100%;
        text-align: center;
        font-size: 25px;
    }

    .WhyMargadharsak_HowitworksTitle__1hU7- {
        width: 100%;
        text-align: center;
        font-size: 25px;
    }

    .WhyMargadharsak_HowitWorksContainer__2NMm4 {}

    .WhyMargadharsak_paarent__36-KJ {
        width: 100%;
    }

    .WhyMargadharsak_whyToppersTitle__3MPdG {
        width: 100%;
        text-align: center;
        font-size: 25px;
    }

    .WhyMargadharsak_whyToppersContainer__2-6l2 {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .WhyMargadharsak_bottombox__1jcFE {
        width: 100%;
    }

    .WhyMargadharsak_bTitle__2eM9g {
        font-size: 22px;
        text-align: center;
    }

    .WhyMargadharsak_bdetails__2_6MN {
        font-size: 18px;
        text-align: justify;
    }
}



@media (max-width: 600px) {
    .WhyMargadharsak_boxSlideMobile__2yc_3 {
        display: block;
        border-style: none;
    }

    .WhyMargadharsak_boxSlideDesktop__1Flac {
        display: none;
    }

    .WhyMargadharsak_mainExamTitle__1HAOM {}




}
.Success_mainContainer__3h0oj{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    flex-direction: column;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    border-radius: 10px;
}

.Success_headingContainer__3LpDa{
    margin-top: 50px;
    font-size: 25px;
    font-weight: bolder;
    padding-left: 30px;
}

.Success_titleContainer__2NW6Y{
    margin-top: 30px;
    font-size: 20px;

}

.Success_bottomContainer__2ehHt{
    width: 100%;
    display:flex;
    justify-content: center;
    grid-gap: 30px;
    gap: 30px;
}

.Success_cardContainer__1VRAQ{
    margin-top: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.Success_descriptionContainer__20KaL{
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    width: 60%;
}
.Success_nameContainer__2EKMV{
    font-size: 18px;
    font-weight: bolder;
    margin-top: 20px;
}

.Success_imageInput__2bHVd{
    margin-top: 20px;
    border-radius: 200px;
    width: 200px;
    height: 200px;
    margin-bottom: 50px;
}

@media (max-width:600px) {
    .Success_bottomContainer__2ehHt{
        flex-direction: column;
    }
}
.Footer_mainContainer__2ufa8{
    width: 100%;
    background-color: var(--themeColor);
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.Footer_headingContainer__CHJii{
    margin-top: 30px;
    font-size: 25px;
    font-weight: bolder;
    color: white;
}

.Footer_descriptionContainer__1wTxd{
    margin-top: 30px;
    color: white;
    font-size: 20px;
}

.Footer_buttonContainer__t0MoI{
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 20px;
    font-weight: bold;
    background-color: white;
    color: black;
    padding: 10px 20px 10px 20px;
    border-radius: 10px;
    cursor: pointer;
}
.MeetMentors_mainConatiner__2Ghut{
    width: 80%;
    margin-top: 50px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    border-radius: 10px;

}

.MeetMentors_headingContainer__1WhmJ{
    text-align: center;
    font-size: 25px;
    font-weight: bolder;
    margin-top: 20px;
}

.MeetMentors_descriptionContainer__3Fr_2{
    text-align: center;
    font-size: 20px;
    margin-top: 20px;
}

.MeetMentors_imageContainer__1538i{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    grid-gap: 30px;
    gap: 30px;
    margin-bottom: 30px;
}

.MeetMentors_image__2SoWf{
    width: 300px;
    height: 300px;
    object-fit: cover;
    border-radius: 10px;

}

.MeetMentors_nameContainer__23BRg{
    margin-top: 20px;
    font-size: 14px;
    text-align: center;
    font-weight: bold;

}

