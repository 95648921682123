.bookingForm {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: grey;
  z-index: 10000;
  right: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

}

.formBox {
  margin-top: 5%;
  width: 380px;
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  justify-content: center;
}


.editmobileNuBox {
  background-color: var(--bodyBkgColor);
  border-radius: 5px;
  /* background-color: #f0f2f5; */
  height: 40px;
  width: 90%;
  padding-left: 10px;
  border: 2px solid var(--cardBorderColorLight);
  font-size: 14px;
  margin: auto;
  border-color: grey;
}

.editmobileNuBox:focus {
  padding-left: 8px;
  padding-right: 8px;
  border-style: solid;
  border-width: 2px;
  outline: none !important;
  border-color: var(--themeColor);
  background-color: white;
}

.formContentBox {

  margin-top: 50px;
  padding: 30px;
}


.textInfoForm {
  height: 30px;
  font-weight: bold;
}


.fieldtype {
  border-style: none;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  border-radius: 5px;
  padding: 10px;
  background-color: pink;
}

.tosContainer{
  display: inline;
  color: blue;
  text-decoration: underline;
  cursor: pointer
}

.bookSlotButton {
  width: 100%;
  background-color: var(--themeColor);
  border-style: none;
  border-radius: 5px;
  color: white;
  margin: auto;
  height: 45px;
  cursor: pointer;
  margin-top: 30px;
  font-size: 20px;
}

.errorContainer{
  margin-top: 10px;
  color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bolder;
}

.bookSlotButton:hover {}


.closeButton {}

.closeButtonDiv {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}


.loginToMeetingLinkButton {
  margin-top: 20px;
  border-style: none;
  height: 35px;
  width: 100%;
  border-radius: 5px;
  color: var(--themeColor);
  cursor: pointer;
}




.mainContainer {
  margin-top: 16px;
  display: flex;
  align-items: center;
  position: relative;
  border-style: solid;
  width: 315px;
  border-color: var(--themeColor);
  border-radius: 5px;
  height: 45px;
}
.detailsContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 315px;
  border: 3px solid var(--themeColor);
  height: 45px;
  border-radius: 5px;
  margin-top: 15px;
}

.detailsLabel{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}
.flagcode {
  display: flex;
  height: 80%;
  align-items: center;
}

.flagImg {
  height: 100%;
  aspect-ratio: 1/1;
  border-radius: 2px;
  margin-left: 10px;

}

.countryCode {
  margin-left: 10px;
  color: var(--greyTextColor);
  font-size: 20px;
  font-weight: bold;
  color: black;
  display: flex;
  border-style: none;
  height: 100%;
  align-items: center;
  padding-right: 5px;
}

.editmobileNuBox {
  border-radius: 4px;
  /* background-color: #f0f2f5; */
  height: 40px;
  border: 2px solid var(--themeColor);
  font-size: 20px;
  margin: auto;
  border-style: none;
  font-weight: bold;
  width: 50%;
}
.detailsInput{
  border-radius: 4px;
  height: 40px;
  border: 2px solid var(--themeColor);
  font-size: 20px;
  margin: auto;
  border-style: none;
  font-weight: bold;
  width: 70%;
  background-color: #f0f2f5;
}

.editmobileNuBox:focus {
  padding-left: 8px;
  padding-right: 8px;
  border-style: none;
  border-width: 1px;
  outline: none !important;
  border-color: var(--cardBorderColorLight);
  border-color: var(--cardBorderColor);
  background-color: white;
}






.checkBoxTermsAndConditions {
  margin-top: 50px;
  text-align: center;
}