

.parentContainer{

width: 90%;
border-style: none;
margin: auto;
height: 1000px;
margin-top: calc( 0.5 * var(--headerHeight) );
}





.daySection{
min-height: 50px;
border-style: none;
border-width:1px;
border-color: var(--cardBorderColor);
border-radius: var(--cardBorderRadius);
display: flex;
align-items: center;
color: var(--greyTextColor);
align-items: stretch;
margin-bottom: 10px;
}

.dayName{
width: 100%;
border-style: none;
margin-left:-10px;
margin-right: 10px;
background-color: var(--bodyBkgColor);
padding-top: 5px;
padding-bottom: 5px;
height: 20px;
margin: auto;
margin-left:-10px;
margin-right: 10px;
}



.dayClassContainer{

width: 90%;
border-style: none;
padding-top: 10px;
border-width: 5px;
border-color: var(--bodyBkgColor);
border-color: white;
border-radius: var(--cardBorderRadius);
display: flex;
flex-direction: column;
justify-content: center;
}


.leftDayNameContainer{
width: 10%;
border-style: none;
border-color: var(--cardBorderColor);
border-width: 1px;
display: flex;
align-items:center;
position: relative;
align-items: stretch;
border-radius: var(--cardBorderRadius);
border-left-style: solid;
}




.noClassDiv{
border-style: solid;
border-width: 1px;
border-color: var(--cardBorderColor);
padding: 20px;
border-radius: var(--cardBorderRadius);

}


.timeLine{
height: 30px;
width: 100%;
border-style: none;
margin: auto;
align-items: center;
margin: auto;
display: flex;
color: var(--greyTextColor);
margin-top: 20px;
margin-bottom: 10px;
white-space: nowrap;
}


.navButton{
border-style: none;
border-width: 1px;
border-color: var(--cardBorderColor);
margin-left: 5px;
margin-right: 5px;
border-radius: 3px;
color: grey;
padding-left:10px;
padding-right: 10px;
display: flex;
align-items: center;
padding-top: 8px;
padding-bottom: 8px;
cursor: pointer;
}

.navButton:hover{
background-color: lightgrey;
}

.infoText_i{
display: flex;
}

.dateText{
border-style: none;
display: flex;
align-items: center;
}


.classTimeLine{
width: 100%;
height: 1px;
background-color: var(--cardBorderColor);
display: flex;
align-items: center;
justify-content: space-between;
padding-right: 70px;
}

.oneClassBall{
height: 5px;
width: 5px;
border-radius: 50%;
background-color: grey;
margin-left: 5%;
position: relative
}


.oneClassExactTime{
position: absolute;
top: 5px;
}





.monitorBoxes{
width: 90%;
border-style: none;
display: flex;
justify-content: space-between;
}


.monitorOneBox{
height:100px;
width: 250px;
border-radius: 10px;
background-color: white;
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
border-style: solid;
border-color: lightgrey;
border-width: 1px;
}

.boxnum{
font-size: 30px;
}

.sessionInfo{
margin-bottom: 20px;
color: var(--themeColor);

}









