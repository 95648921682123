.courseViewDashboard {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
    border-style: solid;
    border-width: 1px;
    border-radius: var(--cardBorderRadius);
    padding: 15px;
    border-color: lightgrey;
    background-color: white;
    margin-bottom: 10px;
    max-width: 400px;
}
  
  .courseViewDashboard:hover {
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }
  
  .titleDiv {
    width: 100%; 
    border-style: none;
    margin-left: -0%;
    border-width: 1px;
    border-color: lightgrey;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
  
  }
  
  .classInfo {
    /* background-color: var(--themeColor); */
    color: var(--greyTextColor);
    padding-right: 10px; 
    border-radius: 7px;
    font-size: 14px;
    display: flex;
    border-style: none;
    align-items: center;

  }
  
  .time {
    margin-left: 5px;
    font-size: 14px;
    font-weight: 600;
    background-color: var(--themeColor);
    color: white;
  }
 


  .timeAll{
   margin-left: 5px;
   font-size: 14px;
   font-weight: 600;
  }


  .leftInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-style: none;
    border-color: green;
    height: 100%;
    width: calc(100% - 170px);
    align-items: start;
  }
  
  .toprightBoxes {
    display: flex;
    margin-right: 0px;
    justify-content: space-between;
    position: relative;
  }
 

 @media only screen and (max-width:600px){


 }



  .classId {
    background-color: white;
    color: var(--themeColor);
    border-radius: 2px;
    padding: 2px;
  }
  
  .topicsTitle {
   color: var(--deepDarkThemeTextColor);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    border-style: solid;
}
 
  
  .classData {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    align-items: center;
    display: flex;
    margin: auto;
    font-size: 12px;
    color: var(--themeColor);
    color: white;
  }
 

  .classDataAll{
  
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: auto;
    font-size: 12px;
    color: var(--themeColor);
  }







  .clockIcon {
    height: 16px;
    width: 16px;
    color: var(--themeColor);
  }
  
  .dotsButton {
    border-style: none;
    float: right;
    /* margin-left: 20px; */
    background-color: white;
    cursor: pointer;
    padding: 4px;
    /* border: 1px solid red; */
  }
  
  .dotsButton:hover {
    background-color: var(--bodyBkgColor);
    border-radius: 20%;
  }
  
  .middleDiv {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-style: solid;
    border-width: 1px;
    border-color: red;
    margin: auto;
  }
  
  .authorDiv {
    border-style: none;
    align-items: center;
    color: var(--lightGreyTintTextColor);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 20px;
    width: 80%;
    font-size: 14px;
    margin-top: 10px;
    display: flex;
    align-items: center;
  }
  
  
  .addressDiv {
    margin-top: 10px;
    border-style: none;
    border-width: 1px;
    color: var(--lightGreyTintTextColor);
    align-items: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 80%;
  }
  
  .redirButtons {
    display: flex;
    justify-content: right;
    border-style: none;
    float: right;
    align-items: center;
    border-color: yellow;
  }
  
  .joinClassButton {
    margin-top: 10px;
    height: 80%;
    border-style: none;
    border-radius: 2px;
    padding-left: 15px;
    padding-right: 15px;
    /* background-color: #d67b1a;
      background-color: orange; */
    color: white;
    width: 150px;
    /* border-radius: var(--cardBorderRadius); */
    cursor: pointer;
    font-family: Roboto;
    border-radius: 6px;
    background-color: transparent;
    color: #4c8bf5;
    border-radius: 4px;
    border: 1px solid #4c8bf5;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: orange;
    color: white;
    border-color:white;
    color: white;
    border-radius: 7px;
    font-weight: bold;
  }
  
 
  .joinClassButton:hover{
  
   box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  }


  .joinText {
    padding-left: 10px;
  }
  
  .viewClassButton {
    margin-top: 10px;
    height: 70%;
    margin-left: 10%;
    border-style: none;
    align-items: center;
    display: flex;
    width: 120px;
    cursor: pointer;
    /* border-radius: var(--cardBorderRadius); */
    border-radius: 4px;
    padding-left: 15px;
    color: var(--tintGreyColor1);
    font-family: Roboto;
    border: 1px sol id var(--cardBorderColor);
  }
  
  .viewClassButton:hover {
    background-color: white;
    border: 1px solid var(--cardBorderColor);
  }
  
  .viewdetailIcon {
    margin-left: 10px;
  }
  
  .classAddress {
    color: var(--tintGreyColor1);
    border-style: none;
    display: flex;
    align-items: center;
  }
  
  .lowerDiv {
    margin-top: 10px;
    border-style: none;
    width: 100%;
    border-width: 1px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border: 1px solid blue; */
  }
  
  .leftLowerDiv {
    height: 100%;
    width: calc(100% - 300px);
    /* border: 1px solid red; */
  }
  
  .rightLowerDiv {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    /* border: 1px solid green; */
  }
  
  .classStatus {
    color: green;
    border-style: none;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
  }
  
  .todayTopic {
    font-size: 14px;
    color: var(--greyTextColor);
    font-weight: 600;
    margin-left:10px;
  }
  
  .todayTopicDetail {
    padding-left: 10px;
    font-size: 14px;
  }
  
  .classTime {
    margin-right: 10px;
    border-width: 1px;
    padding: 5px;
    border-radius: 5px;
    border-color: var(--tintGreyColor2);
    color: var(--themeColor);
    color: var(--lightGreyTintTextColor);
    font-size: 14px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500;
  }
  
  .classNum {
    margin-left: 10px;
    border-style: none;
    color: var(--deepDarkThemeTextColor);
    /* color: var(--themeColor); */
    padding: 5px;
    font-size: 14px;
    background-color: var(--bodyBkgColor);
    border-radius: 16%;
    color: grey;
  }



.rightDotsDiv{




}


.dateText{
color: white;
display: inline-block;
padding: 5px;
background-color: var(--themeColor);
border-radius: 5px;
}



.minduration{
background-color: white;
border-radius: 5px;
padding-left: 5px;
padding-right: 5px;
color: var(--themeColor);
}



.studentNo{
color: white;
background-color: #50C878;
border-radius: 5px;
height: 15px;
min-width: 15px;
border-style: none;
border-color: black;
display: inline-block;
font-weight: bold;
padding: 5px;
align-items: center;
display: flex;
justify-content: center;
margin-left: 10px;
}


.paymentStatus{
margin-left: 10px;
}

.dateInfo{
color: grey;
font-weight: medium;
margin-top: 5px;
}



