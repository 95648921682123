


.successpage{


height: 500px;
width: 380px;
display: flex;
justify-content: center;
margin: auto;
align-items: center;
flex-direction: column;
}

.topDiv{

display: flex;
align-items: center;
}


.loginButton{

margin-top: 20px;
width: 200px;
height: 60px;
border-style: none;
background-color: var(--themeColor);
border-radius: 10px;
color: white;
font-size: 20px;
cursor: pointer;
}

.mainContainer{
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 500px;
    gap: 50px;

}