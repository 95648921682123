.HowitWorksContainer {
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--chatCommentBkgColor);
    padding-top: 40px;
    padding-bottom: 60px;
    border-style: none;
}

.HowitworksTitle {
    font-size: 40px;
    font-weight: bold;
    color: var(--deepDarkThemeTextColor);
    background-color: var(--chatCommentBkgColor);
    margin-bottom: 50px;
}

.helloTopContainer {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-auto-rows: auto;
    row-gap: 30px;
    column-gap: 30px;
    border-style: none;
    background-color: var(--chatCommentBkgColor);
}


.paarent {
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    background-color: white;
}

.paarent:hover {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.logo {
    margin-top: 30px;
    width: 80px;
    height: 80px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.imagesExam {
    width: 100px;
    height: 100px;
    transition: all 0.3s ease;
}

.imagesExam:hover {
    transform: rotate(360deg);
}

.description {
    margin-top: 50px;
    font-size: 20px;
    text-align: center;
    margin-bottom: 20px;
    width: 90%;
}










@media (max-width: 1300px) {

    .HowitworksTitle {
        width: 100%;
        text-align: center;
        font-size: 25px;
    }


    .paarent {
        width: 100%;
    }


}



@media (max-width: 600px) {


}