.websiteContent2 {
  margin-top: 100px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  row-gap: 10px;
  column-gap: 20px;
}

.HomeImageContainer2 {
  width: 100%;
  height: 400px;
  background-color: green;
  border-radius: 10px;
  object-fit: cover;
}

.detailContainer2 {
  width: 100%;
}

.aboutHeading1 {
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
}

.aboutHeading2 {
  margin-top: 20px;
  font-weight: bold;
  color: #08203d;
  line-height: 55px;
  font-size: 45px;
}

.aboutDetails {
  margin-top: 20px;
  font-size: 18px;
  color: var(--lightText);
  line-height: 32px;
  text-align: justify;
}

@media (max-width: 1000px) {

  .LeftImageRightTextParent{

    width: 98%;
    margin-right: 10px;
    margin-left: 10px;
  }
  .websiteContent2 {

  }

  .topperRequirementDiv {
  }

  .aboutDetails {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 30px;
  }

  .aboutHeading1 {
    text-align: center;
  }

  .aboutHeading2 {
    text-align: center;
  }
}
