.slotsContainer {
  width: calc(70% - 60px);
  background-color: white;
  border-style: none;
  display: flex;
  flex-direction: column;
  padding: 20px 30px 0px 10px;
  border-radius: 10px;
  gap: 50px;
  
}

.slotsCard{
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Creates 2 equal columns */
}

.nextPreButtonsDiv {
  height: 50px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
}


.navButton {
  border-style: none;
  padding: 5px;
  color: var(--themeColor);
  background-color: var(--chatCommentBkgColor);
  width: 100px;
  cursor: pointer;
  border-radius: 5px;
  height: 35px;
}


.dateText {
  margin: 20px;
  font-weight: bold;
  border-style: solid;
  border-width: 1px;
  border-color: var(--chatCommentBkgColor);
  border-radius: 5px;
  height: 35px;
  display: flex;
  align-items: center;
  width: 150px;
  justify-content: center;
}

.leftContainer{
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 10px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px; 

}

.rightContainer{
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 10px;
}

.bookButtonConatiner{
  background-color: var(--themeColor);
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  font-weight: bold;
}

.bookSlotContainer{
  background-color: var(--chatCommentBkgColor);
  color: white;
  border-radius: 50px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}


.videoMentoringContainer{
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}

.availableContainer{
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bookingContainer{
  display: flex;
  align-items: center;
  gap: 30px;
}

.priceContainer{
  font-size: 13px;
  font-weight: bolder;
  color: black;
  margin: 10px 15px 10px 15px;
}

.bookButton{
  padding: 10px 15px 10px 15px;
  font-size: 25px;
  font-weight: bolder;
  background-color: green;
  color: white;
  border-radius: 10px;
}

.headingContainer{
  font-size: 20px;
  font-weight: bolder;
  margin: 20px;
  margin-bottom: 10px;

}

.titleContainer{
  font-size: 18px;
  font-weight: 600;
  margin-left: 20px;
  margin-bottom: 20px;
}

.sessionContainer{
  margin-bottom: 20px;
}

.pointsContainer {
  list-style-type: none; /* Remove default bullets */
  padding: 0;
  margin: 0;
}

.eachContainer {
  position: relative;
  padding-left: 30px;  /* Spacing between items */
  margin-left: 30px;
  font-size: 15px;
}

.eachContainer::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 10px; /* Circle size */
  height: 10px; /* Circle size */
  background-color: black; /* Customize your circle color */
  border-radius: 50%; /* Makes the circle */
}


@media (max-width:600px) {
  .slotsContainer{
    flex-direction: column;
    gap: 50px;
    width: 100%;
    padding: 0px;
    margin-top: 50px;
  }
  .slotsCard{
    grid-template-columns: repeat(1, 1fr);
  }
  .priceContainer{
    font-size: 12px;
  }
}

@media (min-width:600px) and (max-width:1200px ) {
  .slotsContainer{
    flex-direction: column;
    gap: 100px;

  }

  .rightContainer{
    margin-left: 30%;
  }

}