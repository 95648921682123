
.contentDiv{
position: relative;
background-color: var(--bodyBkgColor);
width: 100%;
border-style: none;
border-color: orange;
height: 100%;
overflow-y: auto;
overflow-x:hidden;
}


.createSlotButton{

height: 40px;
margin-left: 30px;
margin-top: 30px;
width: 150px;
border-radius: 5px;
border-color: lightgrey;
border-style: solid;
border-width: 1px;
background-color: white;
color: var(--themeColor);
cursor: pointer;
}



.slotsContainer{

border-style: none;
width: 100%;
height: 2000px;
margin-top:30px;
}


.buttonContainer{
width: 100%;
border-style: none;
}

.slotsTitle{
border-style: none;	
font-size: 20px;
max-width: 500px;
margin: auto;
margin-bottom: 30px;

}




.monitorBoxes{
width: 90%;
border-style: none;
display: flex;
justify-content: space-between;
margin: auto;
margin-top: 50px;
}


.monitorOneBox{
height:70px;
width: 250px;
border-radius: 10px;
background-color: white;
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
border-style: solid;
border-color: lightgrey;
border-width: 1px;
}

.boxnum{
font-size: 18px;
display: flex;
align-items: center;
}








.setPriceBox{
width: 90%;
border-style: none;
display: flex;
justify-content: flex-start;
margin: auto;
margin-top: 50px;

}

.priceAmount{
margin-left: 10px;
font-weight: bold;
color: var(--themeColor);
}


.BankDetails{
width: 90%;
border-style: solid;
display: flex;
justify-content: flex-start;
margin: auto;
margin-top: 50px;
border-width: 1px;
border-color: lightgrey;
border-radius: 10px;
flex-direction: column;
}


.infoDivBank{
border-bottom-style: solid;
border-width: 1px;
border-color: lightgrey;
height: 50px;
display: flex;
justify-content: flex-start;
align-items: center;
padding-left: 20px;

}


.bankTitle{
width: 200px;
}


.setPriceBox{
height: 40px;
border-bottom-style: solid;
border-width: 1px;
border-color: lightgrey;
display: flex;
align-items:center;
}


.editPriceButton{
margin-left: 10px;
border-style: solid;
border-color: lightgrey;
border-width: 1px;
border-radius: 5px;
padding: 5px;
padding-left:10px;
padding-right: 10px;
cursor: pointer;
width: 100px;
}


.editPriceButton:hover{


}



.priceInputBox{

border-color: var(--themeColor)

}


.value_field{

height: 25px;
border-style: solid;
border-color: lightgrey;
font-size: 16px;
border-radius: 4px;
padding-left: 10px;
width: 120px;
}


.value_field:focus{
border-color: var(--themeColor);
outline: none !important;
color: var(--themeColor);
}


.boxtitle{
font-size: 15px;
}


.editBankDetailButton{

width: 100px;
border-style: solid;
border-width: 1px;
border-color: lightgrey;
margin-top:10px;
margin-bottom:10px;
margin-left: 10px;
height: 30px;
border-radius: 5px;
cursor: pointer;
}










