.mainContainer{
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    margin-top: 50px;
    border-radius: 10px;
}

.topContainer{
    width: 80%;
}

.bottomContainer{
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.leftContainer{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}
.rightContainer{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.imageContainer{
    background-image: url('introduction\ background.png');
    background-size: 100% 110%; /* This scales the background image to cover the entire container */
    background-repeat: no-repeat; 
    width: 400px;
    height: 500px;
}


.backGroundArrow{
    position: relative;
    right: -50px;
    transform: scaleX(-1);
    object-fit: cover;
    width: 500px;
    height: 500px;  
}

.headingContainer{
    margin-top: 30px;
    font-size: 25px;
    font-weight: bold;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.titleContainer{
    width: 100%;
    font-size: 20px;
    font-weight: 600;

}

.paraContainer{
    width: 100%;
    font-size: 18px;
    margin-bottom: 20px;
}

@media (max-width:600px) {
    .bottomContainer{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .leftContainer{
        
        width: 100%;
        margin-top: 30px;
    }
    .backGroundArrow{
        right: 0px;
        width: 400px;
        height: 400px;
    }
    .imageContainer{
        height: 400px;
    }
}