.mainContainer{
    width: 100%;
    background-color: var(--themeColor);
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.headingContainer{
    margin-top: 30px;
    font-size: 25px;
    font-weight: bolder;
    color: white;
}

.descriptionContainer{
    margin-top: 30px;
    color: white;
    font-size: 20px;
}

.buttonContainer{
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 20px;
    font-weight: bold;
    background-color: white;
    color: black;
    padding: 10px 20px 10px 20px;
    border-radius: 10px;
    cursor: pointer;
}