.websiteContent2 {
  margin-top: 100px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  row-gap: 10px;
  column-gap: 20px;
}

.HomeImageContainer2 {
  width: 100%;
  height: 400px;
  background-color: green;
  border-radius: 10px;
  object-fit: cover;
}

.detailContainer2 {
  width: 100%;
}

.aboutHeading1 {
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
}

.aboutHeading2 {
  margin-top: 20px;
  font-weight: bold;
  color: #08203d;
  line-height: 55px;
  font-size: 25px;
}

.aboutDetails {
  margin-top: 20px;
  font-size: 18px;
  color: var(--lightText);
  line-height: 32px;
  text-align: justify;
}

/* .bulletList{
  list-style-type: circle;
  padding-left: 0px;

}

.li {
  font-size: 16px;
  list-style-type: circle;

}

ul  {
  font-size: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  list-style-position: inside;
}  */

.topContainer {
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
}

.bulleticon {
  width: 8px;
  height: 8px;
  background-color: black;
  border-radius: 50px;
}

.MainHeading {
  margin-left: 8px;
  font-weight: bold;
  font-size: 16px;
}

.mainDetails {
  margin-top: 10px;
  font-size: 16px;
  line-height: 22px;
}

.topperRequirementDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1000px) {
  .LeftImageRightTextParent {
    width: 98%;
    margin-right: 10px;
    margin-left: 10px;
  }

  .websiteContent2 {
  }

  .topperRequirementDiv {
  }

  .aboutHeading1 {
    text-align: center;
  }

  .aboutHeading2 {
    margin-right: 10px;
    text-align: center;
    margin-left: 10px;
  }
}

.topperRequirementDiv {
  font-size: 20px;
}
