


.unitBarAchievement{

width: 93%;
border-bottom-style:solid ;
border-width: 1px;
margin-bottom: 20px;
border-color: lightgrey;
padding-bottom: 10px;
display: flex;
justify-content: space-between;
margin-top: 30px;

}


.infoDiv1{
font-weight: bold;
font-size: 20px;
}

.deleteButton{
height: 30px;
width: 30px;
border-style: none;
border-radius: 5px;
cursor: pointer;
}


