.profileCardDetails {
    margin-top: 20px;
    width: 100%;
    display: flex;
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
        rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.leftSide {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
}

.rightSide {
    width: 70%;
}

.profilePic {
    width: 200px;
    height: 200px;
    border: 10px;
}

.mainprofilePic {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 100%;
}

.designationContainer{
    margin-top: 20px;
    font-weight: bold;
}

.bookbtn {
    width: 120px;
    height: 30px;
    background-color: var(--themeColor);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
}

.bookbtn:hover {
    border: 1px solid var(--themeColor);
    cursor: pointer;
    background-color: white;
    color: var(--themeColor);
}

.bookbtn1 {

    width: 120px;
    height: 30px;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    font-weight: bold;

}

.bookNowContainer {
    padding: 7px 15px 7px 15px;
    font-size: 20px;
    background-color: var(--themeColor);
    color: white;
    font-weight: bolder;
    border-radius: 50px;
    cursor: pointer;
}

.priceHolder {
    display: flex;
    gap: 50px;
    align-items: center;
    margin-right: 20px;
}

.priceContainer {
    font-size: 20px;
    font-weight: bolder;
}

.topContainer {
    margin: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.NameContainer {
    font-size: 25px;
    font-weight: bold;
}

.rattingContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.rattingIcon {
    height: 30px;
    width: 30px;
    color: sandybrown;
}

.ratingNumber {
    margin-left: 10px;
    font-size: 16px;
    font-weight: bold;
    color: var(--greyTextColor);
}

.HeartBtn {
    height: 30px;
    width: 30px;
    color: var(--greyTextColor);
}

.midContainer {
    margin: 10px 20px 1px 20px;
    display: flex;
    align-items: center;
}

.location {
    height: 25px;
    width: 25px;
    color: var(--themeColor);
    margin-right: 5px;
}

.location1 {
    height: 30px;
    width: 30px;
    color: var(--greyTextColor);
}

.locationName {
    margin-left: 10px;
    color: var(--themeColor);
}

.bottomContainer {
    margin: 20px;
    display: flex;
    align-items: start;
    justify-content: space-between;
}

.bookMainContainer{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.teachesContainer {
    display: flex;
    align-items: center;
    padding-top: 8px;
    margin-bottom: 8px;
    padding-left: 12px;
    padding-right: 12px;
    background-color: var(--cardBorderColorLight);
    border-radius: 10px;
    border: 1px solid var(--cardBorderColor);
    border-style: none;
}

.icon1 {
    height: 30px;
    width: 30px;
    border-radius: 10px;
}

.detailsContainer {
    margin-left: 10px;
    margin-top: 6px;
    margin-bottom: 6px;
}

.heading {
    font-weight: bold;
}

.headingDetails {
    margin-top: 6px;
}

.languageContainer {
    display: flex;
    align-items: center;
    margin: 20px;
    margin-top: 10px;
}

.languageTitle {
    font-size: 18px;
    font-weight: bold;
}

.lang {
    margin-left: 10px;
}

.expertContainer {
    font-size: 15px;
    font-weight: 600;
    background-color: white;
    padding: 5px 15px 5px 15px;
    color: var(--themeColor);
    border: 2px solid var(--themeColor);
    border-radius: 10px;
    margin-top: 20px;
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 15px;

}

.priceHolder {}

.ratingContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 30px;
    margin-bottom: 30px;
}

@media (max-width: 1000px) {
    .profileParent {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 500px;


    }

    .mainContainer {
        width: 98%;
        padding: 0px;
    }

    .profileCardDetails {
        display: flex;
        flex-direction: column;
    }

    /* .topContainer{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }
  
  .ratingContainer{
    margin-top: 10px;
  
  }
  .HeartBtn{
    margin-top: 10px;
  
  }
  
  .bottomContainer{
    display: flex;
    flex-direction: column;
  } */

}

@media (max-width: 600px) {
    .profileCardDetails {
        width: 100%;
        margin: 0px;
        flex-direction: column;
    }

    .leftSide {
        padding: 20px 0px 20px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .priceHolder {
        position: fixed;
        gap: 100px;
        align-items: center;
        bottom: 50px;
        background-color: white;
        border-radius: 10px;
        z-index: 1000;
        right: 0px;
        left: 20px;
        width: calc(100% - 150px);
        padding: 20px 50px 20px 50px;
        justify-content: center;
    }

    .rightSide {
        width: 100%;
    }

    .profilePic {
        width: calc(100% - 40px);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .mainprofilePic {
        width: 200px;
        border-radius: 200px;
    }


    .topContainer {
        flex-direction: column;
        gap: 10px;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 30px;
    }




}


@media (min-width:600px) and (max-width:1200px) {
    .profileCardDetails {
        width: 100%;
        margin: 0px;
        align-items: center;
        flex-direction: column;
    }

    .leftSide {
        padding: 20px 0px 20px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        
    }

    .priceHolder {
        position: fixed;
        gap: 100px;
        align-items: center;
        bottom: 50px;
        background-color: white;
        border-radius: 10px;
        z-index: 1000;
        right: 0px;
        left: 20px;
        width: calc(100% - 150px);
        padding: 20px 50px 20px 50px;
        justify-content: center;
    }

    .topContainer {
        flex-direction: column;
        gap: 10px;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 30px;
    }

    .rightSide {
        margin-left: 20%;
    }
}