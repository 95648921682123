.mainContainer{
    width: 80%;
}

.homeParent {
    width: 100%;
}

.topBar {
    width: 100%;
    height: 30px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.National {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--lightText);
}

.State {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--lightText);
}

.newsBlock {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, auto));
    row-gap: 10px;
    column-gap: 10px;
}

.latest {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
}

.latestTitle {
    font-weight: bold;
    color: var(--themeColor);
    font-size: 24px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    text-transform: uppercase;
}

.latestsubTitle {
    margin-top: 20px;
    font-weight: bold;
    color: #08203d;
    line-height: 55px;
    font-size: 45px;
}

.panImageBkg {
    background-color: #e7efeb;
    background-repeat: no-repeat;
    height: auto;
    background-image: url("https://images.unsplash.com/photo-1523192193543-6e7296d960e4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80");
}

.newsBlock {
    width: 100%;
}

.stateNewsBlock {
    width: 100%;
}


.mainExamTitle {
    width: 100%;
    margin-top: 40px;
    font-size: 40px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--deepDarkThemeTextColor);
}



.HowitWorksContainer {
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--chatCommentBkgColor);
    padding-top: 40px;
    padding-bottom: 60px;
    border-style: none;
}

.HowitworksTitle {
    font-size: 40px;
    font-weight: bold;
    color: var(--deepDarkThemeTextColor);
    background-color: var(--chatCommentBkgColor);
    margin-bottom: 50px;
}

.helloTopContainer {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-auto-rows: auto;
    row-gap: 30px;
    column-gap: 10px;
    border-style: none;
    background-color: var(--chatCommentBkgColor);
}

.mainWhytoppersContainer {
    margin-top: 30px;
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.whyToppersTitle {
    font-size: 40px;
    font-weight: bold;
    color: var(--deepDarkThemeTextColor);
}

.whyToppersContainer {
    margin-top: 50px;

    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-auto-rows: auto;
    row-gap: 30px;
    column-gap: 30px;
}

.ExamContainer {
    margin-top: 30px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    row-gap: 30px;
    column-gap: 30px;
}

.bottombox {
    margin-top: 50px;
    padding-bottom: 30px;
    padding-top: 50px;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--chatCommentBkgColor);
    border-style: none;

    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;*/
}

.marginDivBottom {
    border-style: solid;
    width: 80%;
}



.bTitle {
    margin-top: 15px;
    font-size: 30px;
    font-weight: bold;
    color: var(--deepDarkThemeTextColor);
}

.bdetails {
    margin-top: 15px;
    font-size: 20px;
}

.bbutton {
    margin-top: 25px;
    width: 150px;
    height: 40px;
    border-radius: 4px;
    background-color: var(--themeColor);
    color: white;
    font-size: 16px;
    border: none;
}

.bbutton:hover {
    border: 1px solid var(--themeColor);
    background-color: white;
    cursor: pointer;
    color: var(--themeColor);
}

.paarent {
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    background-color: white;
}

.paarent:hover {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}


.boxSlideMobile {
    display: none;
}



.logo {
    margin-top: 30px;
    width: 80px;
    height: 80px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imagesExam {
    width: 80px;
    height: 80px;
    transition: all 0.3s ease;
}

.imagesExam:hover {
    transform: rotate(360deg);
}


.title {
    margin-top: 20px;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}

.description {
    margin-top: 20px;
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
    width: 90%;
}

@media (max-width: 1300px) {
    .latestTitle {
        font-size: 20px;
    }

    .latestsubTitle {
        margin-top: 10px;
        font-size: 30px;
    }

    .homeParent {
        width: 98%;
    }

    .mainExamTitle {
        width: 100%;
        text-align: center;
        font-size: 25px;
    }

    .HowitworksTitle {
        width: 100%;
        text-align: center;
        font-size: 25px;
    }

    .HowitWorksContainer {}

    .paarent {
        width: 100%;
    }

    .whyToppersTitle {
        width: 100%;
        text-align: center;
        font-size: 25px;
    }

    .whyToppersContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .bottombox {
        width: 100%;
    }

    .bTitle {
        font-size: 22px;
        text-align: center;
    }

    .bdetails {
        font-size: 18px;
        text-align: justify;
    }
}



@media (max-width: 600px) {
    .boxSlideMobile {
        display: block;
        border-style: none;
    }

    .boxSlideDesktop {
        display: none;
    }

    .mainExamTitle {}




}