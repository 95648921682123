.card {
  background-color: white;
  border-radius: 6px;
  align-items: center;
  border: 1px solid var(--cardBorderColor);
  border: 1px solid var(--cardBorderColor);
  margin: auto;
  flex: 0 0 80%;
  box-sizing: border-box;
  margin-left: 15px;



}


.img {

  width: 100%;
  aspect-ratio:1.7/1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px 7px 0px 0px;
}
.title {
  margin-top: 20px;
  font-size: 20px;
  font-weight: bolder;
  text-align: center;
}

.description1 {
  margin-top: 10px;
  font-size: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.description2 {
  margin-top: 10px;
  font-size: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ViewToppersBtn{
height: 40px;
background-color: var(--themeColor);
display: flex;
justify-content: center;
align-items: center;
border-radius: 20px;
padding-left: 30px;
padding-right: 30px;
color: white;
margin: auto;
margin-bottom: 20px;
margin-top: 20px;
width: 200px;
cursor: pointer;
}


