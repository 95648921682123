.MainIntroductionBlockParent {
    width: 100%;
    display: flex;
    background-color: var(--chatCommentBkgColor);
    display: flex;
    justify-content: center;
    align-items: center;
}

.IntroductionBlockParent {
    border-style: none;
    width: 1200px;
    height: 400px;
    display: flex;
}

.backGroundArrow{
    position: relative;
    right: -10px;
    transform: scaleX(-1);
    object-fit: cover;
    width: 400px;
    height: 400px;  
}

.imageContainer{
    background-image: url('introduction\ background.png');
    background-size: 100% 110%; /* This scales the background image to cover the entire container */
    background-repeat: no-repeat; 
    width: 400px;
    height: 400px;
}

.leftContainer{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.rightContainer{
    width: 50%;
}
.headingContainer{
    font-size: 25px;
}
.titleContainer{
    font-size: 25px;
}
.buttonContainer{
    font-size: 20px;
    background-color: var(--themeColor);
    padding: 10px 20px 10px 20px;
    border-radius: 10px;
    color: white;
    cursor: pointer;
}
@media (max-width: 1300px) {
    .IntroductionBlockParent {
        width: 80%;
    }
}


@media (max-width: 600px) {

    .MainIntroductionBlockParent {
        width: 100%;
    }

    .IntroductionBlockParent {
        width: 95%;
        height: auto;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .leftContainer{
        width: 100%;
        gap: 20px;
    }
    .titleContainer{
        width: 100%;
        text-align: center;
    }
    .rightContainer{
        margin-top: 50px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .headingContainer{
        margin-top: 30px;
    }


}