.mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.mainExamTitle {
    width: 100%;
    margin-top: 40px;
    font-size: 40px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--deepDarkThemeTextColor);
}

.boxSlideMobile {
    display: none;
}

.mainExamDescription{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    font-size: 20px;
}

@media (max-width:1300px) {
    .mainExamTitle {
        width: 100%;
        text-align: center;
        font-size: 25px;
    }
}

@media (max-width:600px) {
    .mainExamTitle {}

    .boxSlideMobile {
        display: block;
        border-style: none;
    }

    .boxSlideDesktop {
        display: none;
    }
}