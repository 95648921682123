.mainConatiner{
    width: 80%;
    margin-top: 50px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    border-radius: 10px;

}

.headingContainer{
    text-align: center;
    font-size: 25px;
    font-weight: bolder;
    margin-top: 20px;
}

.descriptionContainer{
    text-align: center;
    font-size: 20px;
    margin-top: 20px;
}

.imageContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    gap: 30px;
    margin-bottom: 30px;
}

.image{
    width: 300px;
    height: 300px;
    object-fit: cover;
    border-radius: 10px;

}

.nameContainer{
    margin-top: 20px;
    font-size: 14px;
    text-align: center;
    font-weight: bold;

}