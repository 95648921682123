.profileParent {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
}

.mainContainer {
    width: 80%;
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.leftContainer {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.rightContainer {
    width: 40%;
}

.middleContainer,
.midContainer,
.fqaContainer {
    width: 100%;
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
        rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.aboutmeContainer,
.educationContainer,
.experienceContainer,
.faqTitle {
    margin: 30px;
}

.aboutmeTitle,
.experienceTitle,
.educationTitle,
.faqTitle {
    font-size: 25px;
    font-weight: bolder;
    margin-bottom: 10px;
}

.aboutmeDiscription,
.educationDescription,
.experienceDescription {
    font-size: 14px;
    font-weight: 500;
    margin-left: 10px;
}

.qualificationContainer,
.locationContainer {
    margin-bottom: 5px;
}

.qualificationContainer {
    font-weight: 600;
}

.mentorHeading {
    margin: 25px;
    font-size: 25px;
    font-weight: bolder;
}

.mentorPoint {
    margin-left: 50px;
    margin-bottom: 30px;

}
.experienceCard{
    font-size: 15px;
    margin-bottom: 20px;
}

.mentorPointQuestion {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
}

.mentorPointAnswer {
    margin-left: 30px;
    font-size: 14px;
    font-weight: 500;
}

.faqList {
    list-style: none;
    padding: 0;
    margin: 0;
}

.faqList li {
    position: relative;
    padding-left: 30px;
    margin-bottom: 10px;
    display: flex;
    width: calc(100% - 100px);
    align-items: center;
    justify-content: space-between;
}

.faqList li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    background-color: black;
    border-radius: 50%;
}

.faqDescription {
    margin-left: 30px;
}

.faqDescriptionTitle {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
}

.faqDescriptionPoints {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 40px;
    margin-left: 20px;
}

.bookYourSlotContainer {
    width: 100%;
}

.bodyContainer {
    display: none;
}

@media (min-width:600px) and (max-width:1200px) {}


@media (max-width:600px) {
    .mainContainer {
        display: none;
    }
    .profileParent{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .bodyContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;

    }

    .rightContainer {
        width: 90%;
    }

    .fqaContainer {
        width: 100%;
    }

    .leftContainer {
        width: 90%;
    }

    .bookingCard {
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        bottom: 0px;
        z-index: 10000;
        width: 100%;
    }

    .bookSlotContainer {
        background-color: var(--chatCommentBkgColor);
        color: white;
        border-radius: 50px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        margin-top: 20px;
        width: 90%;


    }

    .priceContainer {
        font-size: 12px;
        font-weight: bolder;
        color: black;
        margin: 10px 15px 10px 15px;
    }
    .bookButtonConatiner{
        background-color: var(--themeColor);
        width: 60%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        font-weight: bold;
      }


}