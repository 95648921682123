.courseViewDashboard{


box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
    border-style: solid;
    border-width: 1px;
    border-radius: var(--cardBorderRadius);
    padding: 15px;
    border-color: lightgrey;
    background-color: white;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
}


.time{
font-weight: bold;
color: var(--themeColor);
height: 30px;
}


.duration{

color: grey;
border-style: solid;
border-width: 2px;
color: var(--themeColor);
border-radius:5px; 
padding: 5px;
}


.date{
color: grey;
font-weight: bold;
}



.imageTag{
height: 30px;
width: 30px;
border-radius: 5px;
}


.joinButton{

height: 40px;
width: 150px;
background-color: #C35214;
border-style: none;
border-radius: 10px;
font-weight: bold;
color: white;
cursor: pointer;
background-color:green;
}


.joinButtonComplete{
height: 40px;
width: 150px;
background-color: #C35214;
border-style: none;
border-radius: 10px;
font-weight: bold;
color: white;
cursor: pointer;
background-color:lightgrey;

}




.nameOfperson{
  font-weight: bold;
}


.timeDiv2{

  border-style: none;
  display: flex;
  flex-direction: column;

}

.examName{

margin-left: 10px;

}





@media only screen and (max-width: 700px) {

.courseViewDashboard{
flex-direction: column;
justify-content: center;
align-items:center;
}

.personDiv{

display: flex;
margin-bottom: 20px;
}


.timeDiv{
display: flex;
justify-content: space-between;
margin-bottom: 10px;
}

.date{
display: flex;
}

.timeDiv2{
flex-direction: row;
margin-bottom: 10px;
}

.examName{
margin-left:10px;
}


}






