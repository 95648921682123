.mainContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    flex-direction: column;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    border-radius: 10px;
}

.headingContainer{
    margin-top: 50px;
    font-size: 25px;
    font-weight: bolder;
    padding-left: 30px;
}

.titleContainer{
    margin-top: 30px;
    font-size: 20px;

}

.bottomContainer{
    width: 100%;
    display:flex;
    justify-content: center;
    gap: 30px;
}

.cardContainer{
    margin-top: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.descriptionContainer{
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    width: 60%;
}
.nameContainer{
    font-size: 18px;
    font-weight: bolder;
    margin-top: 20px;
}

.imageInput{
    margin-top: 20px;
    border-radius: 200px;
    width: 200px;
    height: 200px;
    margin-bottom: 50px;
}

@media (max-width:600px) {
    .bottomContainer{
        flex-direction: column;
    }
}