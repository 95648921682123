.parentNews {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.latest {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.latestTitle {
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
}

.latestsubTitle {
  margin-top: 20px;
  font-weight: bold;
  color: #08203d;
  line-height: 55px;
  font-size: 45px;
}

.newsContainer {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.newsBlock1 {
  margin-bottom: 20px;
  width: 100%;
  height: auto;
  display: flex;
  padding: 20px;
  border-radius: 10px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.imgAndHeading {
  width: 40%;
}

.mainImg {
  width: 100%;
  height: 100%;
  background-color: aqua;
  border-radius: 10px;
  object-fit: cover;
}

.mainTitle {
  color: var(--darkText);
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
}

.contentDetails {
  margin-left: 16px;
  width: 60%;
}

.newsDetails {
  margin-top: 20px;
  text-align: justify;
  line-height: 26px;
  font-size: 18px;
  color: var(--lightText);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 7;
}

.readMore {
  float: left;
  margin-top: 20px;
  width: 120px;
  height: 30px;
  display: flex;
  justify-content: end;
  color: white;
  font-weight: 500;
  border: none;
  padding: 6px;
  background-color: var(--themeColor);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  font-size: 12px;
}

.readMore:hover {
  cursor: pointer;
}

/* .newsBlock2 {
  width: 100%;
  height: 210px;
  display: flex;
  margin-left: 20px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
} */

@media (max-width: 1000px) {
  .parentNews {
    width: 100%;
    margin: 0;
    font-size: 14px;
  }

  .imgAndHeading {
    width: 100%;

    height: 400px;
    object-fit: cover;
  }

  .newsBlock1 {
    display: flex;
    flex-direction: column;
  }

  .contentDetails {
    width: 98%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0;
  }

  .mainTitle {
    margin-top: 10px;
    width: 100%;
  }

  .newsDetails {
    margin-top: 10px;
    width: 100%;
  }
}
