.about {
  display: flex;
  flex-direction: column;
  font-size: 30px;
  margin-top: 40px;
}

.mainTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: var(--themeColor);
  font-size: 24px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;
}

.topTitle {
  width: 100%;
  margin-top: 50px;
  line-height: 20px;
  display: grid;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 15px;
  color: var(--darkText);
}

.parentCard {
  margin: 10px;
  display: flex;
  width: 70%;
  border-radius: 6px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  padding: 20px;
  max-width: 1100px;
  margin: auto;
  margin-top: 30px;
}

.leftContainer {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.picContainer {
  margin-top: 20px;
  width: 150px;
  height: 150px;
  border-radius: 100%;
}

.personpic {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 100%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.price {
  margin-top:5px;
  font-weight: bolder;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 24px;
  color: var(--themeColor);
  display: flex;
  justify-content: center;
}

.pricephr {
  margin-top: 10px;
  font-weight: bold;
  font-size: 14px;
}

.midContainer {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  font-size: 14px;
}

.FeaturedTitleBtn {
  width: 130px;
  background-color: white;
  color: var(--themeColor);
  font-weight: bold;
  padding: 5px;
  border-radius: 6px;
  border: 2px solid var(--themeColor);
  display: flex;
  align-items: center;
  justify-content: center;
}

.Username {
  margin-top: 10px;
  font-weight: bolder;
  font-size: 20px;
  cursor: pointer;
}

.Username:hover {
  color: var(--themeColor);
}

.aContainer {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.earthIcon {
  height: 20px;
  width: 20px;
  background-color: grey;
  border-radius: 10px;
}

.cityName {
  margin-left: 10px;
}

.langContainer {
  margin-top: 10px;
  display: flex;
}

.l1Container {
  display: flex;
  align-items: center;
}

.l2Container {
  margin-left: 10px;
  display: flex;
  align-items: center;
}

.l1icon {
  color: var(--themeColor);
}

.l1Lang {
  margin-left: 10px;
}

.lessonContainer {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.lessionIcon {
  color: var(--themeColor);
}

.firstContainer {
  margin-left: 10px;
  display: flex;
}

.lessionTitle {
  font-size: 14px;
  font-weight: bold;
  color: var(--darkText);
}

.lessionDetails {
}

.secContainer {
  margin-left: 10px;
  display: flex;
}

.lessionTitle {
  font-size: 14px;
  font-weight: bold;
  color: var(--darkText);
}

.lessionDetails {
  margin-left: 10px;
}

.aboutuserDetails {
  margin-top: 10px;
  line-height: 24px;
  border-style: none;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  text-align: justify;
  border-style: none;
}


.aboutuserDetails1{
margin-top: 10px;
  line-height: 24px;
  border-style: none;
  overflow: hidden;
  width: 100%;
  display: -webkit-box; /* Add this line */
  -webkit-line-clamp: 3; /* Adjust the number of lines as needed */
  -webkit-box-orient: vertical; /* Add this line */
  text-align: justify;
  border-style: solid;
}

.aboutuserDetails1 {
  margin-top: 10px;
  line-height: 24px;
  border-style: none;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-align: justify;
  border-style: solid;
  position: relative; /* Add this line */
}

.aboutuserDetails1::after {
  content: '...'; /* Add ellipsis content */
  position: absolute;
  bottom: 0;
  right: 0;
  padding-left: 30px; /* Adjust as needed */
}





.readMoreBtn {
  margin-top: 10px;
  color: var(--themeColor);
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
  border-style: none;
  background-color: white;
}

.rightContainer {
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.ratingContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.starContainer {
  border-style: none;
  height: 30px;
  width: 30px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.star {
  margin-left: 10px;
  font-weight: bold;
  font-size: 14px;
}

.totalRating {
  margin-top: 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bookBtnContainer {
  margin-top: 10px;
  width: 120px;
  height: 35px;
  background-color: var(--themeColor);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border-style: none;
  cursor: pointer;
  visibility: hidden;
}



.bookBtnContainer:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}



.bookBtnContainer1 {
  margin-top: 10px;
  width: 120px;
  height: 35px;
  background-color: var(--themeColor);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border-style: none;
  cursor: pointer;
  margin-left: 20px;
}



.bookBtnContainer1:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}







.bookTitle {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottomContainer {
  float: right;
  border-color: 1px solid gainsboro;
}

.iconContainerheart {
  float: right;
  width: 20px;
  height: 20px;
  margin: 10px;
}

.buttonContainer{
  display: flex;
  align-items: center;
  width: 100%;
  
}

@media (max-width: 1300px) {
  .about {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .parentCard {
    margin-top: 10px;
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
  }

  .leftContainer {
    width: 100%;
  }

  .midContainer {
    width: 100%;
    padding: 10px;
  }

  .aboutuserDetails {
    width: 94%;
    text-align: justify;
  }

  .rightContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
  }

  .ratingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bookBtnContainer {
    margin-top: 20px;
    visibility: visible;
  }

  .bookBtnContainer1{
  visibility: hidden;
  }





}
