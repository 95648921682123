.stars {
    display: flex;
  }
  
  .star {
    width: 24px;
    height: 24px;
    background-color: lightgray; /* Empty star color */
    position: relative;
    clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
  }
  
  .filledStar {
    height: 100%;
    background-color: #ffd203; /* Filled star color */
    position: absolute;
    top: 0;
    left: 0;
    clip-path: inherit;
    transition: width 0.2s ease-in-out; /* Smooth transition */
  }
  