.mt{

    margin-top: 30px;
    font-size: 20px;
    font-weight: bold;
    color: var(--deepDarkThemeTextColor);

}

.d{
    margin-top: 10px;
    font-size: 16px;
    color: var(--deepDarkThemeTextColor);
    line-height: 24px;
}

.t{
    margin-top: 20px;
    font-size: 16px;
    font-weight: bold;
    color: var(--deepDarkThemeTextColor);
}


.highlight{
}

.bottomContainer{
    margin-top: 20px;
    background-color: var( --chatCommentBkgColor);
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.btnContainer{
    background-color: var(--themeColor);
    padding: 4px;
    color: white;
    margin: 20px;
    font-size: 14px;
    border-radius: 6px;
}